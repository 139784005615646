import { storage } from './storage'
import { apiRelationService } from '../api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import store from '@/store'
import { localAuthService } from './local-auth-service'

export const localRelationService = {

  relationKey: 'relation',

  haveRelation () {
    return storage.exists(this.relationKey)
  },

  getRelationObject () {
    const relation = storage.get(this.relationKey)
    return relation != null ? JSON.parse(relation) : null
  },

  async getRelation () {
    const relationId = localAuthService.getTokenObject().relation_id

    return await apiRelationService.getRelation(relationId).then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        store.commit('relation/setRelation', response.data)
        const relation = new RelationModel().fromResponse(response.data)
        return relation
      }
      return null
    })
  }
}
