
import { dateHelper } from '@/globals/helpers/DateHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import Vue from 'vue'
import Counter from '../Counter.vue'
export default Vue.extend({
  components: { Counter },
  props: {
    isInvoicesLoaded: {
      type: Boolean,
      required: true
    },
    selectedFilterDates: {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      all: 0,
      notExpired: 0,
      expired: 0,
      payed: 0
    }
  },
  computed: {
    invoices: {
      get (): Invoice[] {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Invoice[]) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    filteredInvoices (): Invoice[] {
      return this.invoices.filter((invoice) => {
        return this.filterDates(invoice)
      })
    }
  },
  watch: {
    invoices () {
      if (this.all === 0) {
        this.setCounters()
      }
    },
    selectedFilterDates () {
      this.setCounters()
    }
  },
  beforeMount () {
    if (this.selectedFilterDates !== null) {
      this.setCounters()
    }
  },
  methods: {
    resetCounters () {
      this.all = 0
      this.notExpired = 0
      this.expired = 0
      this.payed = 0
    },
    setCounters () {
      this.resetCounters();
      (this.filteredInvoices as Invoice[]).forEach((invoice) => {
        this.all = this.all + invoice.total

        if (invoice.isStatusSent() && invoice.isExpired()) {
          this.expired = this.expired + invoice.total
        } else if (invoice.isStatusPartiallyPaid() && invoice.isExpired()) {
          this.expired = this.expired + invoice.getOpenAmount()
        } else {
          this.notExpired = this.notExpired + invoice.total
        }

        if (invoice.isStatusPartiallyPaid()) {
          this.payed = this.payed + invoice.amount_paid
        }

        if (invoice.isStatusFullyPaid()) {
          this.payed = this.payed + invoice.total
        }
      })
    },

    filterDates (invoice: Invoice) {
      const selectedFilterDates = this.selectedFilterDates as Array<Date>|null
      const invoiceDate = dateHelper.toDate(invoice.invoice_date)
      let valid = false
      if (selectedFilterDates === null) {
        valid = true
      }
      if (selectedFilterDates !== null && selectedFilterDates[0] !== null && selectedFilterDates[0] <= invoiceDate) {
        if (selectedFilterDates[1] === null) {
          valid = true
        }
        if (selectedFilterDates[1] !== null && selectedFilterDates[1] >= invoiceDate) {
          valid = true
        }
      }

      return valid
    }
  }
})
