
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { CurrencyEnum } from '@/globals/enums/CurrencyEnum'
import { PaymentPeriod } from '@/globals/enums/PaymentPeriod'
import { EnterpriseRelationTypeEnum } from '@/globals/enums/EnterpriseRelationType'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { Notify } from '@/globals/controllers/Notification'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { ClientModel } from '@/globals/models/client/Client'
import { landCodes } from '@/globals/enums/LandCodesEnum'

export default Vue.extend({
  components: {
    PrimaryModal,
    Dropdown,
    InputField,
    PrimaryButton
  },
  props: {
    relationId: {
      type: Number,
      required: true
    },
    oldClient: {
      type: Object,
      required: false
    },
    isSupplier: {
      type: Boolean,
      required: false,
      default: false
    },
    isBooking: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      userStore: this.$store.state.user,
      isEssentialsLoaded: false,
      isEdit: false,
      client: new EnterpriseRelationRequestModel(),
      clientErrors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
      currencies: new CurrencyEnum().getCurrencies(),
      paymentPeriods: new PaymentPeriod().getPaymentPeriods(),
      submitDisabled: true,
      isCocChanged: false,
      landCodes
    }
  },
  computed: {
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    }
  },
  beforeMount () {
    if (this.oldClient) {
      this.client = new EnterpriseRelationRequestModel().fromRelationModel(this.oldClient)
      this.isEdit = true
    }

    if (this.isBooking) {
      this.client.isEmailRequired = false
    }

    this.client.enterprise_relation_type = this.isSupplier ? EnterpriseRelationTypeEnum.SUPPLIER : EnterpriseRelationTypeEnum.CLIENT
  },
  methods: {
    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data
            this.client.name = info.name
            this.client.address = info.address
            this.client.postal_code = info.postal_code
            this.client.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.mergeErrors(response.data)
          }
        })
      }
    },
    async submitForm (e: any) {
      e.preventDefault()

      if (!this.validationErrors()) {
        if (this.isEdit) {
          await this.updateClient()
        } else {
          await this.createClient()
        }
      }
    },
    validationErrors () {
      this.clearErrors()
      let error = false

      if (this.isEnterpriseClient()) {
        if (!this.client.name) {
          this.clientErrors.name.push('Bedrijfsnaam is verplicht')
          error = true
        }
      } else {
        if (!this.client.first_name) {
          this.clientErrors.first_name.push('Voornaam is verplicht')
          error = true
        }

        if (!this.client.last_name) {
          this.clientErrors.last_name.push('Achternaam is verplicht')
          error = true
        }
      }

      if (!this.isBooking) {
        if (!this.client.email) {
          this.clientErrors.email.push('E-mailadres is verplicht')
          error = true
        }
        if (!this.client.general_email) {
          this.clientErrors.general_email.push('E-mailadres is verplicht')
          error = true
        }

        if (!this.client.address) {
          this.clientErrors.address.push('Adres is verplicht')
          error = true
        }

        if (!this.client.postal_code) {
          this.clientErrors.postal_code.push('Postcode is verplicht')
          error = true
        }

        if (!this.client.city) {
          this.clientErrors.city.push('Plaats is verplicht')
          error = true
        }
      }

      return error
    },
    clearErrors () {
      this.clientErrors = entityHelper.generateErrorEntity(this.client)
    },
    isEnterpriseClient () {
      return this.client.name || this.client.coc_number || this.client.tax_number || this.isSupplier
    },

    async createClient () {
      if (this.isEnterpriseClient()) {
        await this.createEnterpriseClient()
      } else {
        await this.createPrivateClient()
      }
    },
    async createEnterpriseClient () {
      await apiRelationService.createEnterpriseRelation(this.relationId, this.client).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          if (response.data.relation.id && this.isTwinfieldConnected) {
            await this.createTwinfieldRelation(response.data.relation.id)
          }
          await this.returnRelation(response.data.relation)
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },
    async createPrivateClient () {
      await apiRelationService.createPrivateRelation(this.relationId, this.client).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          if (response.data.relation.id && this.isTwinfieldConnected) {
            await this.createTwinfieldRelation(response.data.relation.id)
          }
          await this.returnRelation(response.data.relation)
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },

    async updateClient () {
      await apiRelationService.updateRelation(this.client.id, this.client).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          await this.returnRelation(response.data.relation)
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },
    mergeErrors (errors: object) {
      this.clientErrors = {
        ...this.clientErrors,
        ...errors
      }
    },
    async returnRelation (data: any) {
      const relation = new RelationModel().fromResponse(data)
      const newClient = new ClientModel()
      newClient.type = this.client.enterprise_relation_type
      newClient.client = relation

      if (this.isEdit) {
        newClient.id = this.oldClient.id
        this.$store.dispatch('client/updateClient', newClient)
        new Notify().updated()
        this.$emit('updated', newClient)
      } else {
        this.$store.dispatch('client/addClient', newClient)
        new Notify().create('Gelukt!', 'De relatie is succesvol aangemaakt')
        this.$emit('created', newClient)
      }
    },
    async createTwinfieldRelation (relationId: number) {
      await apiAdministrationService.createTwinfieldClientRelation(relationId)
    },

    onBlurEmail (event: string) {
      if (!this.client.general_email) {
        this.client.general_email = event
      }
    }
  }
})
