const state = () => ({
  env: (window as any).appConfig.ENV
})

const getters = {
  isDev: (state: any): boolean => {
    return state.env === 'dev'
  },
  isLive: (state: any): boolean => {
    return state.env === 'prod'
  }
}

const actions = {
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
