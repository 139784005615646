export const RouteNames = {
  login: {
    name: 'login'
  },
  register: {
    name: 'register'
  },
  forgotPassword: {
    name: 'forgotpassword'
  },
  resetPassword: {
    name: 'resetpassword'
  },
  adviser: {
    name: 'adviser',
    register: {
      name: 'adviser_register'
    }
  },
  activate: {
    name: 'activate'
  },
  acceptInvitation: {
    name: 'acceptinvitation'
  },
  onboarding: {
    name: 'onboarding',
    welcome: {
      name: 'onboarding_welcome'
    },
    adviser: {
      welcome: {
        name: 'onboarding_adviser_welcome'
      },
      relations: {
        name: 'onboarding_adviser_relations'
      },
      finished: {
        name: 'onboarding_adviser_finished'
      }
    }
  }
}
