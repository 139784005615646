import { objectHelper } from '@/globals/helpers/ObjectHelper'
import { TaskModel } from '@/modules/task/models/Task'
import { TaskUserType } from '../../enums/TaskUserType'
import { TaskUserModel } from '../TaskUser'

export class TaskModelRequest {
  id = 0
  title = ''
  body = ''
  relation_to = null as null|number
  task_users = [] as Array<TaskUserModel>
  status = 0
  duration = 0
  period_at = null as string|null
  planned_at = null as string|null
  done_at = null as null|Date

  constructor (userId: number, data?: any) {
    if (data) {
      this.setData(userId, data)
    }
  }

  setData (userId: number, data: TaskModel) {
    this.id = data.id
    this.title = data.title
    this.body = data.body
    this.relation_to = data.relation_to?.id ?? null
    this.task_users = this.getTaskUsers(userId, data)
    this.status = data.status
    this.duration = data.duration
    this.period_at = data.period_at
    this.planned_at = data.planned_at
    this.done_at = data.done_at
  }

  getTaskUsers (userId: number, task: TaskModel): Array<TaskUserModel> {
    const requestTaskUsers = [] as Array<TaskUserModel>
    const createdBy = objectHelper.cloneObject(task.getCreatedBy()) as TaskUserModel
    requestTaskUsers.push(createdBy)

    const assignedTo = objectHelper.cloneObject(task.getAssignedTo()) as TaskUserModel
    requestTaskUsers.push(assignedTo)

    if (createdBy.user_id === assignedTo.user_id) {
      createdBy.type = TaskUserType.CREATED_AND_ASSIGNED
      requestTaskUsers.splice(1, 1)
    } else {
      if (createdBy.user_id !== userId && assignedTo.user_id !== userId) {
        task.sharedToIds.push(userId)
      }
    }

    task.sharedToIds.forEach(id => {
      const taskUserModel = new TaskUserModel()
      taskUserModel.task_id = task.id
      taskUserModel.user_id = id
      taskUserModel.type = TaskUserType.SHARED_TO
      requestTaskUsers.push(taskUserModel)
    })

    return requestTaskUsers
  }
}
