
import Vue from 'vue'
import SideNav from '@/modules/settings/views/company/components/SideNav.vue'
import EnterpriseTwinfield from '@/modules/settings/views/company/twinfield/components/EnterpriseTwinfield.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import AdministrationTwinfield from './components/AdministrationTwinfield.vue'

export default Vue.extend({
  components: {
    SideNav,
    EnterpriseTwinfield,
    AdministrationTwinfield
  },
  data () {
    return {
      isAccountant: this.$store.getters['user/isAccountant']
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    }
  }
})
