
import Vue from 'vue'

import { InvoiceRepeatSettings } from '@/modules/invoicing/models/invoice/InvoiceRepeatSettings'
import { InvoiceRepeat } from '@/modules/invoicing/enums/InvoiceRepeat'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { platformHelper } from '@/globals/helpers/PlatformHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { UnitTypeLong } from 'dayjs'

export default Vue.extend({
  components: {
    PrimaryModal,
    InputField,
    DatePicker,
    PrimaryButton
  },
  data () {
    return {
      repeatSettingsErrors: entityHelper.generateErrorEntity(new InvoiceRepeatSettings()) as any,
      repeatEnum: new InvoiceRepeat(),
      repeats: new InvoiceRepeat().getInvoiceRepeats(),
      dateMonthBack: dateHelper.toDayjs(new Date()).add(-1, 'M').toISOString().slice(0, 10),
      infiniteRepeat: true,
      isEdit: false,
      platformHelper,
      invoiceStatus: new InvoiceStatus()
    }
  },
  computed: {
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    },
    repeatSettings (): InvoiceRepeatSettings {
      return this.invoice.recurrence
    },
    periodEnd (): string {
      const duration = { amount: 1, unit: 'month' } as { amount: number; unit: UnitTypeLong }

      if (this.repeatSettings.recurrence_type === this.repeatEnum.weekly.value) {
        duration.amount = 7
        duration.unit = 'day'
      } else if (this.repeatSettings.recurrence_type === this.repeatEnum.quarterly.value) {
        duration.amount = 3
        duration.unit = 'month'
      } else if (this.repeatSettings.recurrence_type === this.repeatEnum.halfyearly.value) {
        duration.amount = 6
        duration.unit = 'month'
      } else if (this.repeatSettings.recurrence_type === this.repeatEnum.yearly.value) {
        duration.amount = 1
        duration.unit = 'year'
      }

      return dateHelper.toDateString(dateHelper.toDayjs(this.repeatSettings.next_period_start).add(duration.amount, duration.unit).add(-1, 'd').toDate())
    }
  },
  watch: {
    infiniteRepeat (infiniteRepeat) {
      if (infiniteRepeat) {
        this.repeatSettings.max_recurrences = 0
      }
    }
  },
  beforeMount () {
    if (this.repeatSettings.id) {
      this.isEdit = true
      this.dateMonthBack = dateHelper.toDayjs(this.repeatSettings.next_date).add(-1, 'M').toISOString().slice(0, 10)
    }

    if (this.repeatSettings.max_recurrences) {
      this.infiniteRepeat = false
    }
  },
  methods: {
    submitForm (e: any) {
      e.preventDefault()

      this.repeatSettings.active = true
      this.invoice.status = this.invoiceStatus.recurrence_template.value
      this.$emit('close')
    },
    abort () {
      this.repeatSettings.active = false

      if (!this.invoice.isBaseInvoice()) {
        this.invoice.status = this.invoiceStatus.concept.value
      }
      this.$emit('close')
    }
  }
})
