
import Vue from 'vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { QuestionModel } from '../../models/Question'
import QuestionsDataTable from './components/QuestionsDataTable.vue'
import BookDocument from '@/modules/administration/components/documents/book-document/BookDocument.vue'
import { localAuthService } from '@/network/local/local-auth-service'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'

export default Vue.extend({
  components: { QuestionsDataTable, BookDocument },
  data () {
    return {
      isLoading: true,
      questions: [] as QuestionModel[],
      selectedQuestion: new QuestionModel(),
      isBookDocumentActive: false,
      wasImpersonated: false
    }
  },
  computed: {
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated'] as boolean
    }
  },
  beforeMount () {
    this.fetchQuestions()
    this.wasImpersonated = this.$store.getters['user/isImpersonated'] as boolean
  },
  methods: {
    async fetchQuestions () {
      await apiAdministrationService.getPendingLogs().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          arrayHelper.clear(this.questions)
          response.data.forEach((questionRaw: any) => {
            const question = new QuestionModel().fromResponse(questionRaw)
            this.questions.push(question)
          })
        }
      }).finally(() => { this.isLoading = false })
    },

    async leaveDocument () {
      this.isBookDocumentActive = false

      if (!this.wasImpersonated && this.isImpersonated) {
        await localAuthService.unImpersonate()
      }

      this.fetchQuestions()
    }
  }
})
