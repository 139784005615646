interface Options {
  text: string;
  value: number;
}
export class GenderEnum {
  male: Options = {
    text: 'Man',
    value: 0
  }

  female: Options = {
    text: 'Vrouw',
    value: 1
  }

  getGenders () {
    return [
      this.male,
      this.female
    ]
  }
}
