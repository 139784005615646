
import Vue from 'vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'

export default Vue.extend({
  components: { SkeletonLoader },
  props: {
    log: {
      required: true
    }
  },
  data () {
    return {
      dateHelper
    }
  }
})
