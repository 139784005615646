
import Vue from 'vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import PrimaryModal from '../modals/PrimaryModal.vue'

export default Vue.extend({
  components: {
    PrimaryModal,
    PrimaryButton
  },
  data () {
    return {
      isActive: false,
      resolve: null as any,
      reject: null as any,
      message: '',
      subMessage: '',
      agreeButtonText: '',
      cancelButtonText: '',
      isAgreeButtonError: false,
      isCancelButtonError: false
    }
  },
  methods: {
    open (options?: {
      message: string;
      subMessage: string;
      agreeButtonText: string;
      cancelButtonText: string;
      isAgreeButtonError: boolean;
      isCancelButtonError: boolean;
      isDeleting: boolean;
    }) {
      this.message = options?.message ?? 'Weet je het zeker?'
      this.subMessage = options?.subMessage ?? ''
      this.agreeButtonText = options?.agreeButtonText ?? 'Doorgaan'
      this.cancelButtonText = options?.cancelButtonText ?? 'Annuleren'
      this.isAgreeButtonError = options?.isAgreeButtonError ?? false
      this.isCancelButtonError = options?.isCancelButtonError ?? false

      if (options?.isDeleting) {
        this.isAgreeButtonError = true
        this.agreeButtonText = options?.agreeButtonText ?? 'Verwijderen'
      }

      this.isActive = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.isActive = false
    },
    cancel () {
      this.resolve(false)
      this.isActive = false
    }
  }
})
