export enum LedgerAccount {
    Balance = 0,
    ProfitAndLoss = 1,
    CostCenter = 2,
    Projects = 3,
    Customers = 4,
    Suppliers = 5,
    TagType = 6,
    Vat = 7
}
