
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  props: {
    bookingItems: {
      type: Array,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    isTagDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    PrimaryModal,
    PrimaryButton,
    Dropdown
  },
  data () {
    return {
      isEssentialsLoaded: false,
      selectedTag: null as null|string,
      submitDisabled: true
    }
  },
  computed: {
    bookingItemIds (): Array<number> {
      const ids = [] as Array<number>

      this.bookingItems.forEach((bookingItem: any) => {
        ids.push(bookingItem.id)
      })

      return ids
    }
  },
  methods: {
    async submitForm (onlyLabel = false) {
      if (this.selectedTag) {
        await this.labelItems(this.selectedTag, onlyLabel)
      }
    },

    async labelItems (selectedTag: string, onlyLabel: boolean) {
      await apiAdministrationService.labelMultipleBookingItems(this.bookingItemIds, selectedTag).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          if (onlyLabel) {
            this.$emit('tagged')
          } else {
            this.tagItems()
          }
        }
      })
    },
    async tagItems () {
      await apiAdministrationService.tagMultipleBookingItems(this.bookingItemIds).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$emit('tagged')
        }
      })
    },
    getBookingItemIds (): Array<number> {
      const ids = [] as Array<number>

      this.bookingItems.forEach((bookingItem: any) => {
        ids.push(bookingItem.id)
      })

      return ids
    }

  }
})
