
import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import Counter from '../Counter.vue'
export default Vue.extend({
  components: { Counter },
  props: {
    dates: {
      type: Array
    }
  },
  data () {
    return {
      isLoading: true,
      turnOver: 0,
      turnOverGain: 0,
      costs: 0,
      costsGain: 0,
      result: 0,
      resultGain: 0,
      vat: 0,
      vatGain: 0
    }
  },
  computed: {
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    }
  },
  watch: {
    dates () {
      this.setStatsTwinfield()
    }
  },
  async beforeMount () {
    await this.setStatsTwinfield()
  },
  methods: {
    async setStatsTwinfield () {
      this.isLoading = true
      await apiAdministrationService.getFinancialStats(this.dates[0], this.dates[1]).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const counters = response.data
          this.turnOver = counters.turn_over
          this.costs = counters.costs
          this.result = counters.result
          this.vat = counters.vat
          this.turnOverGain = counters.turn_over_gain
          this.costsGain = counters.cost_gain
          this.resultGain = counters.result_gain
          this.vatGain = counters.vat_gain
        }
      }).finally(() => { this.isLoading = false })
    }
  }
})
