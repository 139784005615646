export const timeHelper = {
  getHoursAndMinutesFromMinutes (minutes: number): { hours: number, minutes: number } {
    const hours = this.getHoursFromMinutes(minutes)
    const mins = this.getRestMinutesFromMinutes(minutes)
    return { hours, minutes: mins }
  },
  getHoursFromMinutes (minutes: number): number {
    return Math.trunc(minutes / 60)
  },
  getRestMinutesFromMinutes (minutes: number): number {
    return minutes % 60
  }
}
