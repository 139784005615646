import { RelationModel } from '@/globals/models/relation/Relation'

export class AdministrationModel {
  id = 0
  is_default = 0
  relation = new RelationModel() as RelationModel
  relation_id = 0
  type = 0
  user_id = 0

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.is_default = data.is_default
      this.relation = data.relation
      this.relation_id = data.relation_id
      this.type = data.type
      this.user_id = data.user_id
    }
  }

  fromResponse (data?: any) {
    this.setData(data)
    this.relation = new RelationModel().fromResponse(data.relation)

    return this
  }
}
