export class ShortcutModel {
  id = 0
  name = ''
  description = null as null|string
  url = ''
  icon_url = null as null|string

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: ShortcutModel) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.description = data.description
      this.url = this.setUrl(data.url)
      this.icon_url = data.icon_url
    }
  }

  setUrl (url: string): string {
    if (!url.startsWith('http')) {
      url = 'http://' + url
    }

    return url
  }

  fromResponse (data: ShortcutModel) {
    this.setData(data)
    return this
  }
}
