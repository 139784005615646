
import Vue from 'vue'
import { BookingItemLine } from '@/modules/administration/models/BookingItemLine'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import TextArea from '@/globals/components/forms/TextArea.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { DefaultLedgerAccountModel } from '@/globals/models/DefaultLedgerAccount'
import { ClientRelationModel } from '@/globals/models/relation/ClientRelation'
import { TaxDefaultModel } from '@/globals/models/TaxDefault'
import { VatEnum } from '@/globals/enums/Vat'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'
import PriceTextField from '@/globals/components/only-text/PriceTextField.vue'

export default Vue.extend({
  props: {
    selectedClient: {
      type: ClientRelationModel,
      required: false
    },
    selectedClientCount: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    linesErrors: {
      type: Array,
      required: true
    },
    shortDescription: {
      type: String
    }
  },
  components: {
    InputField,
    PrimaryButton,
    TextArea,
    Dropdown,
    SkeletonLoader,
    PriceTextField
  },
  data () {
    return {
      errors: [] as Array<any>,
      ledgerAccountsSelection: [] as Array<{ value: number; text: string }>,
      costCentersSelection: [] as Array<{ value: number; text: string }>,
      projectsSelection: [] as Array<{ value: number; text: string }>,
      isTotalElementAtTop: false,
      foreignSaleTypes: [{ value: 0, text: 'Diensten' }, { value: 1, text: 'Goederen' }]
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    bookingLines: {
      get (): Array<BookingItemLine> {
        return this.currentBookingItem.booking_lines
      },
      set (bookingItemLines: Array<BookingItemLine>) {
        this.currentBookingItem.booking_lines = bookingItemLines
        return bookingItemLines
      }
    },
    isDisabled (): boolean {
      return this.currentBookingItem.isBooked()
    },
    supplierId (): number|null {
      return this.currentBookingItem.supplier_relation_id
    },
    ledgerAccounts (): Array<LedgerAccountModel> {
      return this.$store.getters['administration/ledgerAccounts']
    },
    taxDefaults (): Array<TaxDefaultModel> {
      return this.$store.getters['administration/taxDefaults']
    },
    isPurchaseTag (): boolean {
      return this.currentBookingItem.tag === 'INK'
    },
    isSaleTag (): boolean {
      return this.currentBookingItem.tag === 'VRK'
    },
    taxes (): Array<LedgerAccountModel> {
      if (this.isSaleTag) {
        return this.ledgerAccounts.filter((ledger) => ledger.type === LedgerAccount.Vat && ledger.vat_is_sale)
      } else if (this.isPurchaseTag) {
        return this.ledgerAccounts.filter((ledger) => ledger.type === LedgerAccount.Vat && !ledger.vat_is_sale)
      } else {
        return this.ledgerAccounts.filter((ledger) => ledger.type === LedgerAccount.Vat)
      }
    },
    calcTotalExVat (): number {
      let total = 0

      this.bookingLines.forEach((line: BookingItemLine) => {
        if (line.value) {
          const exVat = line.value * 1
          total = total + exVat
        }
      })

      return total
    },
    calcTotalVat (): number {
      let total = 0

      this.bookingLines.forEach((line: BookingItemLine) => {
        if (line.vat) {
          const vat = line.vat
          total = total + vat
        }
      })

      return total
    },

    calcDifference (): number {
      const amountIncVat = this.currentBookingItem.amount
      let amountLineIncVat = this.calcTotalExVat + this.calcTotalVat
      amountLineIncVat = parseFloat(amountLineIncVat.toFixed(2))
      return Math.abs(amountIncVat - amountLineIncVat)
    }
  },
  watch: {
    currentBookingItem () {
      this.setEssentials()
    },

    selectedClientCount () {
      if (this.selectedClient) {
        this.setLedgerAccountFromRelation()

        if (this.bookingLines.length === 0) {
          this.setPlaceholderLine()
        }
      }
    },

    calcDifference () {
      if (this.bookingLines.length && this.bookingLines[0].vat_ledger_account_id && this.calcDifference.toFixed(2) === '0.01') {
        this.bookingLines[0].vat = this.bookingLines[0].vat + 0.01
      }
    },
    shortDescription (newDes: string, oldDes: string) {
      this.bookingLines.forEach((bookingLine: BookingItemLine) => {
        if (oldDes === bookingLine.description) {
          bookingLine.description = newDes
        }
      })
    },
    linesErrors () {
      if (this.linesErrors.length > 0) {
        this.errors = this.linesErrors
      }
    }
  },
  beforeMount () {
    this.setLedgerSelectionLists()
    this.setEssentials()
  },
  mounted () {
    const form = document.getElementById('booking-form-form')
    if (form) {
      form.addEventListener('scroll', this.handleScroll)
    }
  },
  destroyed () {
    const form = document.getElementById('booking-form-form')
    if (form) {
      form.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll () {
      const bookingProducts = document.getElementById('booking-products')
      if (bookingProducts) {
        const position = bookingProducts.getBoundingClientRect().top
        if (position <= 153) {
          this.isTotalElementAtTop = true
        } else {
          this.isTotalElementAtTop = false
        }
      }
    },

    setLedgerSelectionLists () {
      this.ledgerAccounts.forEach((ledgerAccount) => {
        const option = { value: ledgerAccount.id, text: ledgerAccount.getLedgerCodeName() }
        if (ledgerAccount.type === LedgerAccount.Balance || ledgerAccount.type === LedgerAccount.ProfitAndLoss) {
          this.ledgerAccountsSelection.push(option)
        } else if (ledgerAccount.type === LedgerAccount.CostCenter) {
          this.costCentersSelection.push(option)
        } else if (ledgerAccount.type === LedgerAccount.Projects) {
          this.projectsSelection.push(option)
        }
      })
    },

    setEssentials () {
      arrayHelper.clear(this.errors)
      if (this.isAllEmptyLines(this.currentBookingItem.booking_lines)) {
        this.setLedgerAccountFromRelation()
      }
      if (this.bookingLines.length === 0) {
        this.setPlaceholderLine()
      }
      this.setVatPercentages()
      this.setBookingItemLinesErrors()
    },
    setLedgerAccountFromRelation () {
      arrayHelper.clear(this.bookingLines)
      let ledgerAccount = null as null|DefaultLedgerAccountModel

      if (this.selectedClient) {
        ledgerAccount = this.selectedClient.ledger_account
      }

      if (ledgerAccount) {
        ledgerAccount.default_lines.forEach((ledgerLine, i: number) => {
          this.addBookingLine()
          this.bookingLines[i].ledger_account_id = ledgerLine.ledger_account_id
          this.bookingLines[i].vat_ledger_account_id = ledgerLine.vat_ledger_account_id
          this.bookingLines[i].description = ledgerLine.description
          this.bookingLines[i].cost_place_ledger_account_id = ledgerLine.cost_place
          this.bookingLines[i].project_ledger_account_id = ledgerLine.project

          if (ledgerLine.vat_ledger_account_id) {
            this.setVatPercentage(this.bookingLines[i], ledgerLine.vat_ledger_account_id)
          }

          if (i === 0) {
            this.bookingLines[i].value = this.calcTotalExVat
            this.toExVat(this.bookingLines[i])
            this.calculateVat(this.bookingLines[i])
          }
        })
      }
    },

    setPlaceholderLine () {
      this.addBookingLine()
      this.bookingLines[0].value = this.currentBookingItem.amount
    },
    addBookingLine () {
      const line = new BookingItemLine()
      this.errors.push(entityHelper.generateErrorEntity(new BookingItemLine()))
      line.booking_item_id = this.currentBookingItem.id
      line.description = this.shortDescription

      if (this.calcDifference !== 0) {
        line.value = this.calcDifference
      }

      this.bookingLines.push(line)
    },

    setVatPercentages () {
      this.currentBookingItem.booking_lines.forEach((line: BookingItemLine) => {
        if (line.vat_ledger_account_id) {
          this.setVatPercentage(line, line.vat_ledger_account_id)
          this.calculateVat(line)
        }
      })
    },
    calculateVat (line: BookingItemLine) {
      if (line.value) {
        const exVat = line.value
        const vat = (exVat / 100) * line.vat_percentage
        line.vat = vat
      } else {
        line.vat = 0.00
      }
    },

    setBookingItemLinesErrors () {
      arrayHelper.clear(this.errors)
      for (let i = 0; i < this.currentBookingItem.booking_lines.length; i++) {
        this.errors.push(entityHelper.generateErrorEntity(new BookingItemLine()))
        if (this.linesErrors[i] !== undefined) {
          this.errors[i] = { ...this.errors[i], ...this.linesErrors[i] as object }
        }
      }
    },

    removeBookingLine (index: number) {
      this.bookingLines.splice(index, 1)
    },

    toExVat (line: BookingItemLine) {
      if (line.value) {
        const inclVat = line.value
        const totalPercentage = 100 + line.vat_percentage
        line.value = inclVat / totalPercentage * 100
      }
    },

    getPriceFormat (value: number): string {
      return value ? priceHelper.getLocalPrice(value) : '0,00'
    },

    setVatPercentage (line: BookingItemLine, id: number) {
      line.vat_percentage = this.getPercentageByVatId(id)
    },
    getPercentageByVatId (vatId: number): number {
      const tax = this.taxes.find((tax) => tax.id === vatId)
      return tax?.vat_percentage ?? 0
    },

    setDefaultVatByLedgerAccount (ledgerAccountId: number, line: BookingItemLine) {
      const tax = this.taxes.find((tax) => tax.vat_default_ledger_account_id === ledgerAccountId)

      if (tax) {
        line.vat_ledger_account_id = tax.id
        line.vat_percentage = tax.vat_percentage
        this.calculateVat(line)
      }
    },

    isLineSellInsideEu (line: BookingItemLine): boolean {
      const tax = this.taxDefaults.find(tax => tax.ledger_account_id === line.vat_ledger_account_id)

      return tax?.tax_id === new VatEnum().saleInsideEu.value
    },

    setSellInsideEu (line: BookingItemLine) {
      if (this.isLineSellInsideEu(line)) {
        line.foreign_sale_type = 0

        if (this.selectedClient) {
          line.supplier_tax_code = this.selectedClient.client.enterprise?.tax_number ?? ''
        }
      } else {
        line.foreign_sale_type = null
        line.supplier_tax_code = null
      }
    },

    isAllEmptyLines (lines: Array<BookingItemLine>): boolean {
      let isEmpty = true

      lines.forEach(line => {
        if (line.isNotEmpty()) {
          isEmpty = false
        }
      })

      return isEmpty
    },

    getTaxNameById (id: number): string {
      return this.taxes.find(t => t.id === id)?.name ?? ''
    }
  }
})
