import { render, staticRenderFns } from "./Shortcut.vue?vue&type=template&id=4be4eeb0&scoped=true&"
import script from "./Shortcut.vue?vue&type=script&lang=ts&"
export * from "./Shortcut.vue?vue&type=script&lang=ts&"
import style0 from "./Shortcut.vue?vue&type=style&index=0&id=4be4eeb0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be4eeb0",
  null
  
)

export default component.exports