import { RouteConfig } from 'vue-router'
import Invoicing from '../views/Invoicing.vue'
import Invoice from '../views/invoice/Invoice.vue'
import Overview from '../views/invoice/overview/Overview.vue'
import Create from '../views/invoice/create/Create.vue'
import Edit from '../views/invoice/edit/Edit.vue'
import Product from '../views/product/Product.vue'
import ProductOverview from '../views/product/overview/Overview.vue'
import { RouteNames } from './RouteNames'

const routes = RouteNames
const invoicingRoutes: Array<RouteConfig> = [
  {
    path: '/invoicing',
    name: routes.invoicing.name,
    component: Invoicing,
    redirect: { name: routes.invoicing.invoice.overview.name },
    meta: {
      name_nl: 'overzicht'
    },
    children: [
      {
        path: 'invoice',
        name: routes.invoicing.invoice.name,
        component: Invoice,
        redirect: { name: routes.invoicing.invoice.overview.name },
        meta: {
          name_nl: 'overzicht'
        },
        children: [
          {
            path: 'overview',
            name: routes.invoicing.invoice.overview.name,
            component: Overview
          },
          {
            path: 'create',
            name: routes.invoicing.invoice.create.name,
            component: Create
          },
          {
            path: 'edit/:id',
            name: routes.invoicing.invoice.edit.name,
            component: Edit
          }
        ]
      },
      {
        path: 'product',
        name: routes.invoicing.product.name,
        component: Product,
        meta: {
          name_nl: 'overzicht'
        },
        children: [
          {
            path: 'overview',
            name: routes.invoicing.product.overview.name,
            component: ProductOverview
          }
        ]
      }
    ]
  }
]

export default invoicingRoutes
