
import Vue from 'vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconMoreDots from '@/globals/components/icons/IconMoreDots.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'

export default Vue.extend({
  components: {
    IconBase,
    IconMoreDots
  },
  props: {
    invoice: {
      type: Invoice,
      required: true
    }
  },
  data () {
    return {
      invoiceStatus: new InvoiceStatus()
    }
  }
})
