
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    IconBase,
    IconChevronDown
  },
  data () {
    return {
      documentStatus: new DocumentState()
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    isToProgress (): boolean {
      return this.currentBookingItem.status === this.documentStatus.TOPROGRESS.value
    }
  }
})
