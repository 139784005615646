import { client } from '../client'

export const apiRelationService = {

  baseUrl: (window as any).appConfig.API_RELATION,

  /*
    *   General endpoints
  */
  async getRelations () {
    const url = this.baseUrl + 'relation'

    return await client.get(url)
  },

  async getDefaultRelation () {
    const url = this.baseUrl + 'default'

    return await client.get(url)
  },

  async getRelation (relationId: number|string) {
    const url = this.baseUrl + 'relation/' + relationId

    return await client.get(url)
  },

  async createRelation (data: any) {
    const url = this.baseUrl + 'relation/enterprise'

    return await client.post(url, data)
  },

  async uploadLogo (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/logo'

    return await client.post(url, data)
  },

  async setDefaultRelation (relationId: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/default'

    return await client.put(url)
  },

  async updatePrivateRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/private'

    return await client.put(url, data)
  },

  async updateEnterpriseRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/enterprise'

    return await client.put(url, data)
  },

  async updateRelation (relationId: any, data: any) {
    const url = this.baseUrl + 'relation/' + relationId

    return await client.put(url, data)
  },

  async hideRelation (relationId: number, id: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/hide/' + id

    return await client.put(url)
  },

  async showRelation (relationId: number, id: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/show/' + id

    return await client.put(url)
  },

  async activateRelation (relationId: number, id: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/activate/' + id

    return await client.put(url)
  },

  async deactivateRelation (relationId: number, id: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/deactivate/' + id

    return await client.put(url)
  },

  async getUsersFromRelation (relationId: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/users'

    return await client.get(url)
  },

  /*
    *   Enterprise endpoints
  */
  async getClients (relationId: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/clients'

    return await client.get(url)
  },

  async getClient (fromRelationId: number|string, toRelationId: number|string) {
    const url = this.baseUrl + 'relation/' + fromRelationId + '/client/' + toRelationId

    return await client.get(url)
  },

  async createPrivateRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/enterprise/client/private'

    return await client.post(url, data)
  },

  async createEnterpriseRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/enterprise/client/enterprise'

    return await client.post(url, data)
  },

  async getCocData (coc_number: string) {
    const url = this.baseUrl + 'kvkdata'

    return await client.get(url, { coc_number })
  },

  /*
    *   Administration endpoints
    */
  async getAdministrationRelations (relationId: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/enterprises'

    return await client.get(url)
  },
  async getParentRelation (relationId: number) {
    const url = this.baseUrl + 'administration/' + relationId + '/parent'

    return await client.get(url)
  },
  async getAdministrationRelationsUsers (relationId: number) {
    const url = this.baseUrl + 'administration/' + relationId + '/enterprises/users'

    return await client.get(url)
  },

  async createAdministrationRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/administration/enterprise'

    return await client.post(url, data)
  },

  async createAdministrationPrivateRelation (relationId: number, data: any) {
    const url = this.baseUrl + 'relation/' + relationId + '/administration/private'

    return await client.post(url, data)
  },

  async createAdministration (data: any) {
    const url = this.baseUrl + 'administration'

    return await client.post(url, data)
  },

  async getAdministrationNonOwner (administrationId: number, userId: number) {
    const url = this.baseUrl + 'administration/' + administrationId + '/user/' + userId

    return await client.get(url)
  },

  async addAdministrationsToUser (relationId: number, userId: number, relations: any) {
    const url = this.baseUrl + 'administration/' + relationId + '/user/' + userId

    return await client.post(url, { relations })
  },

  async deleteUserFromAdministration (relationId: number, userId: number) {
    const url = this.baseUrl + 'administration/' + relationId + '/user/' + userId

    return await client.delete(url)
  },

  async updateBooking (relationId: number, enterpriseId: number, enabled: boolean) {
    const url = this.baseUrl + 'relation/' + relationId + '/administration/enterprise/updatebooking'

    return await client.put(url, { enterprise_relation_id: enterpriseId, booking_enabled: enabled })
  }
}
