
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import CreateUpdateProductModal from '../create-update/CreateUpdateProduct.vue'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Product } from '@/modules/invoicing/models/product/Product'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'
import { DataTableHeader } from 'vuetify'
import { priceHelper } from '@/globals/helpers/PriceHelper'

export default Vue.extend({
  props: {
    invoicePrefix: {
      type: String,
      required: false
    }
  },
  components: {
    InputField,
    PrimaryButton,
    BlankSlate,
    CreateUpdateProductModal,
    IconBase,
    IconLookingGlass
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      search: '',
      headers: [
        { text: 'Naam', value: 'title', width: '30%' },
        { text: 'Beschrijving', value: 'description', width: '60%' },
        { text: 'Prijs', value: 'value', align: 'right', width: '10%', sortable: false }
      ] as DataTableHeader[],
      products: [] as Array<any>,
      selectedProduct: null as any,
      isCreateProductModalActive: false,
      isUpdateProductModalActive: false,
      priceHelper
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false
      await this.fetchProducts()
      this.essentialsLoaded = true
    },
    async fetchProducts () {
      this.products.length = 0
      await apiInvoiceService.getAllProducts().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const products = response.data
          this.buildProductsTable(products)
        }
      })
    },

    setFirstInvoiceCreatedTrue () {
      this.$store.dispatch('invoice/setIsFirstCreatedInvoice', true)
    },
    buildProductsTable (products: Array<any>) {
      products.forEach(productRaw => {
        const product = new Product(productRaw)
        if (product.active) {
          this.products.push(product)
        }
      })
    },

    isProducts () {
      return this.products.length > 0
    },

    showCreateProductModal () {
      this.isCreateProductModalActive = true
    },

    hideCreateProductModal () {
      this.isCreateProductModalActive = false
    },

    showUpdateProductModal () {
      this.isUpdateProductModalActive = true
    },

    hideUpdateProductModal () {
      this.isUpdateProductModalActive = false
    },

    onCreatedProduct () {
      this.loadEssentials()
      this.hideCreateProductModal()
    },

    onUpdatedProduct () {
      this.loadEssentials()
      this.hideCreateProductModal()
      this.selectedProduct = null
    },

    updateProduct (product: Product) {
      this.selectedProduct = product
      this.showUpdateProductModal()
    }
  }
})
