
import Vue from 'vue'

import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import StatusColor from '@/modules/invoicing/components/invoice/invoices-datatable/StatusColor.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  props: {
    invoiceId: {
      type: Number,
      required: false
    },
    invoicePrefix: {
      type: String,
      required: false
    },
    _invoices: {
      type: Array,
      required: false
    }
  },
  components: {
    StatusColor,
    PrimaryButton
  },
  data () {
    return {
      invoiceStatus: new InvoiceStatus() as InvoiceStatus,
      headers: [
        { text: 'Nummer', align: 'start', value: 'number' },
        { text: 'Datum', value: 'date' },
        { text: 'Totaalbedrag', value: 'total_price', align: 'right' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false, width: 100 }
      ],
      invoices: [] as Array<any>
    }
  },
  beforeMount () {
    this.setInvoices()
  },
  methods: {
    setInvoices () {
      this._invoices.forEach((invoiceRaw: any) => {
        if (invoiceRaw.id !== this.invoiceId) {
          this.buildInvoices(invoiceRaw)
        }
      })
    },
    buildInvoices (invoiceRaw: any) {
      const invoice = new Invoice().fromResponse(invoiceRaw)
      const number = invoice.getInvoiceNumber(this.invoicePrefix)
      const contact = invoice.to_relation ? invoice.to_relation.getRelationName() : ''
      const date = dateHelper.toShortMonthDateString(invoice.invoice_date)
      const status = this.invoiceStatus.getTextByValue(invoice.status)
      const totalPrice = priceHelper.getLocalPrice(invoice.total)
      const tableItem = {
        id: invoice.id,
        contact,
        number,
        date,
        total_price: '€ ' + totalPrice,
        status,
        selected: false,
        invoiceStatus: this.invoiceStatus.getInvoiceStateByValue(invoice.status),
        invoice
      }

      this.invoices.push(tableItem)
    },

    async sendInvoice (item: any) {
      await apiInvoiceService.sendInvoiceBulk([item.id]).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          item.invoiceStatus = this.invoiceStatus.sent
          new Notify().success('Gelukt!', 'Factuur ' + item.number + ' wordt verzonden')
        }
      })
    },

    goToInvoice (invoice: Invoice) {
      if (invoice.id !== null) {
        this.$store.dispatch('invoicing/setInvoice', null)
        this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id: invoice.id.toString() } })
      }
    }
  }
})
