import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { TablePaginationModel } from '@/globals/models/TablePagination'
import { InvoiceTableFilter } from '@/modules/invoicing/models/invoice/InvoiceTableFilter'
import { objectHelper } from '@/globals/helpers/ObjectHelper'

function setDossierFilter (filter: any) {
  sessionStorage.setItem('invoice_filter', JSON.stringify(filter))
}

const state = () => ({
  invoice: null as Invoice|null,
  invoices: [] as Array<Invoice>,
  filter: sessionStorage.getItem('invoice_filter') ? new InvoiceTableFilter(JSON.parse(sessionStorage.getItem('invoice_filter') as string)) : new InvoiceTableFilter(),
  tablePagination: new TablePaginationModel()
})

const getters = {
  invoice: (state: any) => {
    return state.invoice
  },
  invoices: (state: any) => {
    return state.invoices
  },
  filter: (state: any) => {
    return state.filter
  },
  tablePagination: (state: any) => {
    return state.tablePagination
  }
}

const actions = {
  setInvoice ({ commit }: any, invoice: Invoice) {
    commit('setInvoice', invoice)
  },
  setInvoices ({ commit }: any, invoices: Array<Invoice>) {
    commit('setInvoices', invoices)
  },
  setTablePagination ({ commit }: any, tablePagination: TablePaginationModel) {
    commit('setTablePagination', tablePagination)
  },
  setFilter ({ commit }: any, filter: InvoiceTableFilter) {
    commit('setFilter', filter)
  },
  setRecurrenceCount ({ commit }: any, count: number) {
    commit('setRecurrenceCount', count)
  },
  clearStore ({ commit }: any) {
    commit('clearStore')
  }
}

const mutations = {
  setInvoice (state: any, invoice: Invoice|null) {
    if (invoice !== null) {
      const newItem = new Invoice().fromResponse(objectHelper.cloneObject(invoice))
      state.invoice = newItem
    } else {
      state.invoice = null
    }
  },
  setInvoices (state: any, invoices: Array<Invoice>) {
    state.invoices = invoices
  },
  setTablePagination (state: any, tablePagination: TablePaginationModel) {
    state.tablePagination = tablePagination
  },
  setRecurrenceCount (state: any, count: number) {
    state.recurrenceCount = count
  },
  setFilter (state: any, filter: InvoiceTableFilter) {
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDate (state: any, date: number|null) {
    const filter = state.filter
    filter.date = date
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDateFrom (state: any, from: number|null) {
    const filter = state.filter
    filter.from = from
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDateTo (state: any, to: number|null) {
    const filter = state.filter
    filter.to = to
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterinvoiceExpired (state: any, isExpired: boolean|null) {
    const filter = state.filter
    filter.invoiceExpired = isExpired
    setDossierFilter(filter)
    state.filter = filter
  },
  clearStore (state: any) {
    state.invoice = null
    arrayHelper.clear(state.invoices)
    state.tablePagination = new TablePaginationModel()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
