
import Vue from 'vue'
import Draggable from 'vuedraggable'
import { TaskModel } from '@/modules/task/models/Task'
import Task from '../../components/task/Task.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { TaskStatusEnum } from '@/modules/task/enums/TaskStatus'
import { TaskModelRequest } from '@/modules/task/models/request/Task'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'
import { UserModel } from '@/globals/models/user/User'
import { AdministrationModel } from '@/globals/models/Administration'

export default Vue.extend({
  components: {
    Draggable,
    Task,
    SkeletonLoader
  },
  props: {
    status: {
      type: Object, // Enum
      required: true
    },
    search: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      taskStatusEnum: new TaskStatusEnum(),
      isCreating: false,
      dragOptions: {
        animation: 200,
        disabled: this.status.value === new TaskStatusEnum().WaitingForResponse.value,
        ghostClass: 'drop--ghost',
        draggable: '.task--draggable',
        filter: '.filtered--out'
      },
      groupOptions: null as null|object
    }
  },
  computed: {
    _tasks: {
      get (): Array<TaskModel> {
        let list = this.$store.getters['task/getListByStatus'](this.status.value) as Array<TaskModel>
        list = this.sortTaskList(list)
        return list
      },
      async set (tasks: Array<TaskModel>) {
        this.$store.dispatch('task/setTaskList', { status: this.status.value, tasks })
        return tasks
      }
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    administrations (): Array<AdministrationModel> {
      return this.$store.getters['relation/administrations']
    },
    filteredTasks (): Array<TaskModel> {
      return this._tasks.filter((task: TaskModel) => {
        const isAssignedToValid = this.isAssignedToFilterValid(task)
        const isSharedToValid = this.isSharedToFilterValid(task)
        const isRelationToValid = this.isRelationToFilterValid(task)
        const isSearchValid = this.isSearchFilterValid(task)

        return isAssignedToValid && isSharedToValid && isRelationToValid && isSearchValid
      })
    },
    isWaitingForResponse (): boolean {
      return this.status.value === this.taskStatusEnum.WaitingForResponse.value
    }
  },
  beforeMount () {
    this.groupOptions = {
      name: this.status.value,
      pull: this.handleDraggingPull,
      put: true
    }
  },
  methods: {
    handleDraggingPull (to: any, from: any, item: any) {
      const taskId = parseInt(item.dataset.taskId)
      const task = this.$store.getters['task/findTaskById'](taskId) as TaskModel
      const isSharedToMe = task.isSharedToUser(this.user.id)
      const fromGroupName = from.options.group.name
      const toGroupName = to.options.group.name

      if (isSharedToMe && fromGroupName !== toGroupName) {
        return false
      }
      return true
    },
    sortTaskList (list: Array<TaskModel>): Array<TaskModel> {
      if (this.status.value === this.taskStatusEnum.WaitingForResponse.value) {
        list = list.sort((f, s) => {
          if (f.planned_at === null && s.planned_at !== null) {
            return 1
          }
          if (s.planned_at === null && f.planned_at !== null) {
            return -1
          }
          if (f.planned_at !== null && s.planned_at !== null && f.getPlannedDate() < s.getPlannedDate()) {
            return -1
          }
          return 0
        })
      }
      return list
    },
    isFilteredOut (taskId: number): boolean {
      return this.filteredTasks.findIndex(t => t.id === taskId) === -1
    },
    isAssignedToFilterValid (task: TaskModel) {
      if (this.taskStore.filters.assignedTo === null) {
        return true
      } else if (task.getAssignedTo().user_id === this.taskStore.filters.assignedTo) {
        return true
      }

      return false
    },
    isSharedToFilterValid (task: TaskModel) {
      if (this.taskStore.filters.sharedTo === null) {
        return true
      } else if (this.taskStore.filters.sharedTo === 'none' && !task.sharedToIds.length) {
        return true
      } else if (this.taskStore.filters.sharedTo === 'me' && (task.getSharedToUser(this.user.id) || task.sharedToIds.length === 0)) {
        return true
      } else if (this.taskStore.filters.sharedTo === 'others' && !task.getSharedToUser(this.user.id)) {
        return true
      }

      return false
    },
    isRelationToFilterValid (task: TaskModel) {
      if (this.taskStore.filters.relationTo === null) {
        return true
      } else if (task.relation_to && task.relation_to.id === this.taskStore.filters.relationTo) {
        return true
      }

      return false
    },
    isSearchFilterValid (task: TaskModel): boolean {
      const title = task.title.toLowerCase()
      const body = task.body.toLowerCase()
      const created_by = task.getCreatedBy().user.getName().toLowerCase()
      const assignedTo = task.getAssignedTo().user.getName().toLowerCase()
      const client = task.relation_to.getRelationName().toLowerCase()
      const isValidSharedTo = task.getSharedTo().findIndex(shared => shared.user.getName().toLowerCase().includes(this.search)) >= 0
      return title.includes(this.search) || body.includes(this.search) || created_by.includes(this.search) || assignedTo.includes(this.search) || client.includes(this.search) || isValidSharedTo
    },
    async onMove () {
      this.reorder([this._tasks])
    },
    async onAdd (e: any) {
      e.item.classList.add('display--none')
      const task = this._tasks.find(task => task.id === parseInt(e.item.dataset.taskId))

      if (task) {
        task.status = this.status.value

        if (task.status === this.taskStatusEnum.Done.value) {
          task.done_at = new Date()
        } else {
          task.done_at = null
        }

        await this.updateTask(task)
      }

      const fromListStatus = parseInt(e.from.dataset.groupId)
      const fromList = this.$store.getters['task/getListByStatus'](fromListStatus)
      this.reorder([fromList, this._tasks])
    },
    async updateTask (task: TaskModel) {
      await apiTaskService.updateTask(new TaskModelRequest(this.user.id, task))
    },

    async reorder (taskLists: Array<Array<TaskModel>>) {
      const orderedLists = [] as Array<Array<number>>

      taskLists.forEach((taskList) => {
        const orderedList = this.generateOrderList(taskList)
        orderedLists.push(orderedList)
      })

      await apiTaskService.updateOrder(orderedLists)
    },
    generateOrderList (tasks: Array<TaskModel>): Array<number> {
      const ordered = [] as Array<any>

      for (let i = 0; i < tasks.length; i++) {
        ordered.push(tasks[i].id)
      }

      return ordered
    },

    setIsDragging (isDragging: boolean) {
      this.$store.dispatch('task/setIsDragging', isDragging)
    },

    async addTask (start = false) {
      const task = await this.createTask()
      await apiTaskService.createComment(task.id, 'Taak is aangemaakt', true)
      if (task.id) {
        if (start) {
          this._tasks.unshift(task)
          this.reorder([this._tasks])
        } else {
          this._tasks.push(task)
        }
        this.$store.dispatch('task/setSelectedTask', task)
      }
    },
    async createTask (): Promise<TaskModel> {
      this.isCreating = true
      const task = new TaskModel()
      task.status = this.status.value

      await apiTaskService.createTask(new TaskModelRequest(this.user.id, task)).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          task.fromResponse(response.data)
        }
        this.isCreating = false
      })

      return task
    }
  }
})
