export const entityHelper = {
  generateErrorEntity (model: object) {
    let object = {}
    Object.keys(model).forEach(key => {
      const test = { [key]: [] }
      const general = { general: [] }
      object = {
        ...object,
        ...test,
        ...general
      }
    })

    return object
  }
}
