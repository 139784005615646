import { EnterpriseRelationTypeEnum } from '@/globals/enums/EnterpriseRelationType'
import { DefaultLedgerAccountModel } from '../DefaultLedgerAccount'
import { RelationModel } from './Relation'

export class ClientRelationModel {
  id = null as null|number
  client = new RelationModel()
  ledger_account = null as DefaultLedgerAccountModel|null
  type = EnterpriseRelationTypeEnum.CLIENT

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.client = data.client
      this.ledger_account = data.ledger_account
      this.type = data.type
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    if (data.client) {
      this.client = new RelationModel().fromResponse(data.client)
    }
    if (data.ledger_account) {
      this.ledger_account = new DefaultLedgerAccountModel().fromResponse(data.ledger_account)
    }

    return this
  }
}
