<template>
      <div :style="styleWrapper">
        <label
        v-if="isLabel"
        :class="{
          'height--small': labelHeightSmall
        }"
        >{{ label }}</label>
        <div class="textarea__wrapper">
          <textarea
            :style="styleObject"
            :class="{
              'form--textarea': !errorModel.length,
              'form--textarea validation--error': errorModel.length,
              'height--small': heightSmall,
              'is--disabled': disabled
            }"
            :disabled="disabled"
            v-on:input="$emit('input', $event.target.value)"
            v-on:blur="$emit('blur', $event.target.value)"
            :value="value"
            :maxlength="maxLength"
            :placeholder="placeholder"
          />
          <div v-if="isLoading" class="loading__bar">
            <v-progress-linear indeterminate height="2" />
          </div>
        </div>
        <ul v-if="value && value.length === maxLength" class="form__validation"><li>Maximale lengte van {{maxLength}} tekens bereikt</li></ul>
        <ul v-if="errorModel.length" class="form__validation">
          <li v-for="error in errorModel" :key="error.id">{{ error }}</li>
        </ul>
      </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: false
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    placeholder: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    styleWrapper: {
      type: Object,
      required: false
    },
    styleObject: {
      type: Object,
      required: false
    },
    heightSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    labelHeightSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/_globals';

.textarea__wrapper {
  @include flex();
  flex-direction: column;
  position: relative;

  .form--textarea {
    resize: none;
    outline: none;
  }
  .loading__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
