<template>
  <transition name="modal">
    <div class="modal__mask" @keydown.esc="$emit('close')" @keydown.enter="$emit('key_enter')" tabindex="0" ref="modal">
      <div class="modal__wrapper" @click.self="() => isCloseOutside ? $emit('close') : {}">
        <div :class="{
          'modal__container': true
          }">
          <div v-if="isCloseButton" @click="$emit('close')" class="modal__close"><i class="fas fa-times"></i></div>
          <div v-if="title" class="modal__header">
            <span class="modal--title">{{title}}</span>
          </div>
          <div class="modal__content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    isCloseOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    isCloseButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.$refs.modal.focus()
  }
}
</script>
<style lang="scss">
  @import '@/styles/_globals';

  .modal__content .modal-form__footer {
    @include flex(flex-end, center);
    padding-top: 32px;
  }
</style>

<style lang="scss" scoped>
  @import '@/styles/_globals';

.modal__mask {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 37, 59, 0.2);
  display: table;
  transition: opacity 0.3s ease;
  outline: none;

  .modal__wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal__container {
      @include flex();
      flex-direction: column;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      position: relative;
      width: 100%;
      max-width: 440px;
      overflow-y: auto;
      max-height: 100vh;
      padding: 32px;

      @include desktop-down {
          height: 100vh;
      }

      .modal__close {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 8px 16px;
        cursor: pointer;

        > i {
          @include theme() {
              color: theme-get('text-subtitle-semi');
          }
        }
      }

      .modal__header {
        padding-bottom: 32px;
        overflow: hidden;
        text-overflow: ellipsis;

        .modal--title {
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          white-space: nowrap;
        }
      }

      .modal__content {
        height: calc(100% - 59px);
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter-active .modal__container,
.modal-leave-active .modal__container {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

</style>
