import { InvoiceTableTab } from '../../enums/InvoiceTableTab'

export class InvoiceTableFilter {
  tab = InvoiceTableTab.ALL
  invoiceExpired = null
  date = null
  from = null
  to = null

  constructor (data?: InvoiceTableFilter) {
    if (data) {
      this.tab = data.tab
      this.invoiceExpired = data.invoiceExpired
      this.date = data.date
      this.from = data.from
      this.to = data.to
    }
  }
}
