import { ClientModel } from '@/globals/models/client/Client'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'

const state = () => ({
  clients: []
})

const getters = {
  clients: (state: any): Array<ClientModel> => {
    return state.clients
  }
}

const actions = {
  async setClients ({ commit }: any, relationId: number) {
    await apiRelationService.getClients(relationId).then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const clients = [] as Array<ClientModel>
        response.data.forEach((clientRaw: any) => {
          const client = new ClientModel().fromResponse(clientRaw)
          clients.push(client)
        })
        commit('setClients', clients)
      }
    })
  },
  addClient ({ commit }: any, client: ClientModel) {
    commit('addClient', client)
  },
  updateClient ({ commit }: any, client: ClientModel) {
    commit('updateClient', client)
  },
  removeClientByRelationId ({ commit }: any, id: number) {
    commit('removeClientByRelationId', id)
  },
  clearStore ({ commit }: any) {
    commit('clearStore')
  }
}

const mutations = {
  setClients (state: any, clients: Array<ClientModel>) {
    state.clients = clients
  },
  addClient (state: any, client: ClientModel) {
    state.clients.push(client)
  },
  removeClientByRelationId (state: any, id: number) {
    const index = (state.clients as Array<ClientModel>).findIndex(c => c.client.id === id)

    if (index >= 0) {
      state.clients.splice(index, 1)
    }
  },
  updateClient (state: any, client: ClientModel) {
    const clientIndex = (state.clients as Array<ClientModel>).findIndex(c => c.client.id === client.client.id)

    if (clientIndex >= 0) {
      state.clients.splice(clientIndex, 1)
      state.clients.push(client)
    }
  },
  clearStore (state: any) {
    arrayHelper.clear(state.clients)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
