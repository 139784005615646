
import Vue from 'vue'
import { AdministrationModel } from '@/globals/models/Administration'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { localAuthService } from '@/network/local/local-auth-service'
import { RelationModel } from '@/globals/models/relation/Relation'
import { parserHelper } from '@/globals/helpers/ParserHelper'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      headers: [
        { text: 'Bedrijfsnaam', value: 'name' },
        { text: 'Soort', value: 'type' },
        { text: 'Standaard', value: 'is_default' },
        { text: '', sortable: false, value: 'actions', align: 'end', width: 350 }
      ],
      tableData: [] as Array<any>,
      selected: null as any
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    administrations (): Array<AdministrationModel> {
      return this.$store.getters['relation/administrations']
    }
  },
  watch: {
    administrations () {
      this.setTable(this.administrations)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false
      if (this.administrations.length > 0) {
        this.setTable(this.administrations)
      } else {
        await this.$store.dispatch('relation/setAdministrations')
      }
      this.essentialsLoaded = true
    },
    setTable (administrations: Array<AdministrationModel>) {
      administrations.forEach(administration => {
        this.addAdministrationToTable(administration, this.relation.id)
      })
    },
    addAdministrationToTable (administration: AdministrationModel, currentRelationId: number) {
      const isActive = administration.relation_id === currentRelationId
      const isDefault = parserHelper.numToBool(administration.is_default)
      if (administration.relation.enterprise) {
        const item = {
          relationId: administration.relation_id,
          name: administration.relation.getRelationName(),
          type: administration.type === 1 ? 'Adviesbureau' : 'Onderneming',
          is_default: isDefault,
          is_active: isActive,
          row_class: isActive ? 'is--active' : ''
        }

        this.tableData.push(item)
      }
    },
    async setDefaultRelation (item: any) {
      await apiRelationService.setDefaultRelation(item.relationId).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.setDefaultRelationTrue(item.relationId)
        }
      })
    },
    setDefaultRelationTrue (relationId: number) {
      this.tableData.forEach((item: any) => {
        if (relationId === item.relationId) {
          item.is_default = 1
        } else {
          item.is_default = 0
        }
      })
    },

    async switchAdministration (item: any) {
      if (!item.is_active) {
        await localAuthService.switchToAdministration(item.relationId, false).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.setActiveRelationTrue(item.relationId)
            this.$store.dispatch('invoicing/clearStore')
            this.$store.dispatch('client/clearStore')
            this.$store.dispatch('administration/clearStore')
            this.$store.dispatch('book/clearStore')
            this.$router.push({ name: 'dashboard' })
          }
        })
      }
    },

    setActiveRelationTrue (relationId: number) {
      this.tableData.forEach((item: any) => {
        if (relationId === item.relationId) {
          item.is_active = true
        } else {
          item.is_active = false
        }
      })
    }
  }
})
