export const RouteNames = {
  administration: {
    name: 'administration',
    document: {
      name: 'administration_document',
      overview: {
        name: 'administration_document_overview'
      }
    },
    accountant: {
      name: 'administration_accountant',
      relation: {
        overview: {
          name: 'administration_accountant_relation_overview'
        }
      }
    },
    praxis: {
      name: 'administration_praxis',
      btw: {
        name: 'administration_praxis_btw'
      }
    }
  }
}
