import { RelationModel } from '@/globals/models/relation/Relation'
import { parserHelper } from '@/globals/helpers/ParserHelper'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiRelationService } from '@/network/api/api-relation-service'
import { AdministrationModel } from '@/globals/models/Administration'

const state = () => ({
  relation: sessionStorage.getItem('relation') ? JSON.parse(sessionStorage.getItem('relation') ?? '') : '',
  isPraxis: sessionStorage.getItem('relation') ? JSON.parse(sessionStorage.getItem('relation') ?? '').id === 140 || JSON.parse(sessionStorage.getItem('relation') ?? '').id === 127 || ((window as any).appConfig.ENV === 'dev' && JSON.parse(sessionStorage.getItem('relation') ?? '').id === 13) : false,
  isOwner: sessionStorage.getItem('is_owner') ? JSON.parse(sessionStorage.getItem('is_owner') ?? '') : 0,
  administrations: []
})

const getters = {
  isBookingEnabled: (state: any): boolean => {
    const administrationRelation = (state.relation as RelationModel).administration_relation
    if (administrationRelation != null) {
      return parserHelper.numToBool(administrationRelation.booking_enabled)
    }

    return false
  },
  isPrivate: (state: any): boolean => {
    return getters.relation(state).private !== null
  },
  isEnterprise: (state: any): boolean => {
    return getters.relation(state).enterprise !== null
  },
  isPraxis: (state: any): boolean => {
    return state.isPraxis
  },
  isOwner: (state: any): boolean => {
    return state.isOwner === 1
  },
  relation: (state: any): RelationModel => {
    return new RelationModel().fromResponse(state.relation)
  },
  administrations: (state: any): Array<AdministrationModel> => {
    return state.administrations
  },
  defaultAdministration: (state: any): AdministrationModel|null => {
    return getters.administrations(state).find(a => a.is_default === 1) ?? null
  }
}

const actions = {
  setRelation ({ commit }: any, relation: RelationModel) {
    commit('setRelation', relation)
  },
  setIsOwner ({ commit }: any, isOwner: number) {
    commit('setIsOwner', isOwner)
  },
  async setAdministrations ({ commit }: any) {
    await apiRelationService.getRelations().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const administrations = [] as Array<AdministrationModel>
        response.data.forEach((data: any) => {
          administrations.push(new AdministrationModel().fromResponse(data))
        })
        commit('setAdministrations', administrations)
      }
    })
  }
}

const mutations = {
  setRelation (state: any, relation: any) {
    sessionStorage.setItem('relation', JSON.stringify(relation))
    state.relation = relation
    state.isPraxis = relation.id === 140 || relation.id === 127 || ((window as any).appConfig.ENV === 'dev' && relation.id === 13)
  },
  setIsOwner (state: any, isOwner: number) {
    sessionStorage.setItem('is_owner', JSON.stringify(isOwner))
    state.isOwner = isOwner
  },
  setAdministrations (state: any, administrations: Array<AdministrationModel>) {
    state.administrations = administrations
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
