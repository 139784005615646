
import Vue from 'vue'
import SideNav from '@/modules/settings/views/company/components/SideNav.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import { Notify } from '@/globals/controllers/Notification'
import EditSendTemplate from '@/modules/invoicing/components/invoice/invoice-send/edit-send-template/EditSendTemplate.vue'
import InvoicePrefixList from './components/invoice-prefix/InvoicePrefixList.vue'

export default Vue.extend({
  components: {
    SideNav,
    PrimaryButton,
    EditSendTemplate,
    InvoicePrefixList
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      isEditSendTemplate: false,
      isInvoicePrefixModal: true
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  methods: {
    onUpdatedTemplate () {
      this.isEditSendTemplate = false
      new Notify().updated()
    }
  }
})
