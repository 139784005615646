import { RouteConfig } from 'vue-router'
import { RouteNames } from './RouteNames'
import Settings from '../views/Settings.vue'
import SettingsAccount from '../views/account/Account.vue'
import SettingsAccountProfile from '../views/account/profile/Profile.vue'
import SettingsAccountPassword from '../views/account/password/Password.vue'

import SettingsAdministration from '../views/administration/Administration.vue'
import SettingsAdministrationOverview from '../views/administration/overview/Overview.vue'
import SettingsAdministrationCreate from '../views/administration/create/Create.vue'

import SettingsCompany from '../views/company/Company.vue'
import SettingsCompanyEdit from '../views/company/edit/Edit.vue'
import SettingsCompanyTwinfield from '../views/company/twinfield/Twinfield.vue'
import SettingsCompanyUsers from '../views/company/users/Users.vue'
import SettingsCompanyInvoicing from '../views/company/invoicing/Invoicing.vue'
import store from '@/store/index'
import { RoleEnum } from '@/globals/enums/Role'
import { routeHelper } from '@/globals/helpers/RouteHelper'

const globalStore = store.state as any
const routes = RouteNames
const settingsRoutes: Array<RouteConfig> = [
  {
    path: '/settings',
    name: routes.settings.name,
    component: Settings,
    redirect: { name: routes.settings.account.profile.name },
    children: [
      {
        path: 'account',
        name: routes.settings.account.name,
        component: SettingsAccount,
        redirect: { name: routes.settings.account.profile.name },
        children: [
          {
            path: 'profile',
            name: routes.settings.account.profile.name,
            component: SettingsAccountProfile
          },
          {
            path: 'password',
            name: routes.settings.account.password.name,
            component: SettingsAccountPassword
          }
        ]
      },
      {
        path: 'company',
        name: 'settings_company',
        component: SettingsCompany,
        redirect: { name: routes.settings.company.edit.name },
        children: [
          {
            path: 'edit',
            name: routes.settings.company.edit.name,
            component: SettingsCompanyEdit
          },
          {
            path: 'twinfield',
            name: routes.settings.company.twinfield.name,
            component: SettingsCompanyTwinfield
          },
          {
            path: 'users',
            name: routes.settings.company.users.name,
            component: SettingsCompanyUsers
          },
          {
            path: 'invoicing',
            name: routes.settings.company.invoicing.name,
            component: SettingsCompanyInvoicing,
            beforeEnter: (to: any, from: any, next: any) => {
              const isEnterprise = globalStore.user.token.type === RoleEnum.ENTERPRISE
              if (isEnterprise) {
                next()
              } else {
                next({ name: routeHelper.dashboardRoutes.dashboard.name })
              }
            }
          }
        ]
      },
      {
        path: 'administration',
        name: routes.settings.administration.name,
        component: SettingsAdministration,
        redirect: { name: routes.settings.administration.overview.name },
        meta: {
          name_nl: 'overzicht'
        },
        children: [
          {
            path: 'overview',
            name: routes.settings.administration.overview.name,
            component: SettingsAdministrationOverview
          },
          {
            path: 'create',
            name: routes.settings.administration.create.name,
            component: SettingsAdministrationCreate
          }
        ]
      }
    ]
  }
]

export default settingsRoutes
