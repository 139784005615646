export const landCodes = [
  {
    text: 'Andorra',
    value: 'AD'
  },
  {
    text: 'Verenigde Arabische Emiraten',
    value: 'AE'
  },
  {
    text: 'Afghanistan',
    value: 'AF'
  },
  {
    text: 'Antigua en Barbuda',
    value: 'AG'
  },
  {
    text: 'Anguilla',
    value: 'AI'
  },
  {
    text: 'Albanië',
    value: 'AL'
  },
  {
    text: 'Armenië',
    value: 'AM'
  },
  {
    text: 'Nederlandse Antillen',
    value: 'AN'
  },
  {
    text: 'Angola',
    value: 'AO'
  },
  {
    text: 'Antarctica',
    value: 'AQ'
  },
  {
    text: 'Argentinië',
    value: 'AR'
  },
  {
    text: 'Amerikaans-Samoa',
    value: 'AS'
  },
  {
    text: 'Oostenrijk',
    value: 'AT'
  },
  {
    text: 'Australië',
    value: 'AU'
  },
  {
    text: 'Aruba',
    value: 'AW'
  },
  {
    text: 'Åland',
    value: 'AX'
  },
  {
    text: 'Azerbeidzjan',
    value: 'AZ'
  },
  {
    text: 'Bosnië en Herzegovina',
    value: 'BA'
  },
  {
    text: 'Barbados',
    value: 'BB'
  },
  {
    text: 'Bangladesh',
    value: 'BD'
  },
  {
    text: 'België',
    value: 'BE'
  },
  {
    text: 'Burkina Faso',
    value: 'BF'
  },
  {
    text: 'Bulgarije',
    value: 'BG'
  },
  {
    text: 'Bahrein',
    value: 'BH'
  },
  {
    text: 'Burundi',
    value: 'BI'
  },
  {
    text: 'Benin',
    value: 'BJ'
  },
  {
    text: 'Saint-Barthélemy',
    value: 'BL'
  },
  {
    text: 'Bermuda',
    value: 'BM'
  },
  {
    text: 'Brunei',
    value: 'BN'
  },
  {
    text: 'Bolivia',
    value: 'BO'
  },
  {
    text: 'Bonaire, Sint Eustatius en Saba',
    value: 'BQ'
  },
  {
    text: 'Brazilië',
    value: 'BR'
  },
  {
    text: 'Bahama\'s',
    value: 'BS'
  },
  {
    text: 'Bhutan',
    value: 'BT'
  },
  {
    text: 'Bouveteiland',
    value: 'BV'
  },
  {
    text: 'Botswana',
    value: 'BW'
  },
  {
    text: 'Wit-Rusland',
    value: 'BY'
  },
  {
    text: 'Belize',
    value: 'BZ'
  },
  {
    text: 'Canada',
    value: 'CA'
  },
  {
    text: 'Cocos Eilanden',
    value: 'CC'
  },
  {
    text: 'Congo-Kinshasa',
    value: 'CD'
  },
  {
    text: 'Centraal-Afrikaanse Republiek',
    value: 'CF'
  },
  {
    text: 'Congo-Brazzaville',
    value: 'CG'
  },
  {
    text: 'Zwitserland',
    value: 'CH'
  },
  {
    text: 'Ivoorkust',
    value: 'CI'
  },
  {
    text: 'Cook Eilanden',
    value: 'CK'
  },
  {
    text: 'Chili',
    value: 'CL'
  },
  {
    text: 'Kameroen',
    value: 'CM'
  },
  {
    text: 'China',
    value: 'CN'
  },
  {
    text: 'Colombia',
    value: 'CO'
  },
  {
    text: 'Costa Rica',
    value: 'CR'
  },
  {
    text: 'Cuba',
    value: 'CU'
  },
  {
    text: 'Kaapverdië',
    value: 'CV'
  },
  {
    text: 'Curaçao',
    value: 'CW'
  },
  {
    text: 'Christmas Eiland',
    value: 'CX'
  },
  {
    text: 'Cyprus',
    value: 'CY'
  },
  {
    text: 'Tsjechië',
    value: 'CZ'
  },
  {
    text: 'Duitsland',
    value: 'DE'
  },
  {
    text: 'Djibouti',
    value: 'DJ'
  },
  {
    text: 'Denemarken',
    value: 'DK'
  },
  {
    text: 'Dominica',
    value: 'DM'
  },
  {
    text: 'Dominicaanse Republiek',
    value: 'DO'
  },
  {
    text: 'Algerije',
    value: 'DZ'
  },
  {
    text: 'Ecuador',
    value: 'EC'
  },
  {
    text: 'Estland',
    value: 'EE'
  },
  {
    text: 'Egypte',
    value: 'EG'
  },
  {
    text: 'Westelijke Sahara',
    value: 'EH'
  },
  {
    text: 'Eritrea',
    value: 'ER'
  },
  {
    text: 'Spanje',
    value: 'ES'
  },
  {
    text: 'Ethiopië',
    value: 'ET'
  },
  {
    text: 'Finland',
    value: 'FI'
  },
  {
    text: 'Fiji',
    value: 'FJ'
  },
  {
    text: 'Falklandeilanden',
    value: 'FK'
  },
  {
    text: 'Micronesia',
    value: 'FM'
  },
  {
    text: 'Faeröer',
    value: 'FO'
  },
  {
    text: 'Frankrijk',
    value: 'FR'
  },
  {
    text: 'Gabon',
    value: 'GA'
  },
  {
    text: 'Verenigd Koninkrijk',
    value: 'GB'
  },
  {
    text: 'Grenada',
    value: 'GD'
  },
  {
    text: 'Georgië',
    value: 'GE'
  },
  {
    text: 'Frans-Guyana',
    value: 'GF'
  },
  {
    text: 'Guernsey',
    value: 'GG'
  },
  {
    text: 'Ghana',
    value: 'GH'
  },
  {
    text: 'Gibraltar',
    value: 'GI'
  },
  {
    text: 'Groenland',
    value: 'GL'
  },
  {
    text: 'Gambia',
    value: 'GM'
  },
  {
    text: 'Guinee',
    value: 'GN'
  },
  {
    text: 'Guadeloupe',
    value: 'GP'
  },
  {
    text: 'Equatoriaal-Guinea',
    value: 'GQ'
  },
  {
    text: 'Griekenland',
    value: 'GR'
  },
  {
    text: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    value: 'GS'
  },
  {
    text: 'Guatemala',
    value: 'GT'
  },
  {
    text: 'Guam',
    value: 'GU'
  },
  {
    text: 'Guinee-Bissau',
    value: 'GW'
  },
  {
    text: 'Guyana',
    value: 'GY'
  },
  {
    text: 'Hong Kong',
    value: 'HK'
  },
  {
    text: 'Heard en McDonald Eilanden',
    value: 'HM'
  },
  {
    text: 'Honduras',
    value: 'HN'
  },
  {
    text: 'Kroatië',
    value: 'HR'
  },
  {
    text: 'Haïti',
    value: 'HT'
  },
  {
    text: 'Hongarije',
    value: 'HU'
  },
  {
    text: 'Indonesië',
    value: 'ID'
  },
  {
    text: 'Ierland',
    value: 'IE'
  },
  {
    text: 'Israël',
    value: 'IL'
  },
  {
    text: 'Man',
    value: 'IM'
  },
  {
    text: 'India',
    value: 'IN'
  },
  {
    text: 'Brits Indische Oceaanterritorium',
    value: 'IO'
  },
  {
    text: 'Irak',
    value: 'IQ'
  },
  {
    text: 'Iran',
    value: 'IR'
  },
  {
    text: 'IJsland',
    value: 'IS'
  },
  {
    text: 'Italië',
    value: 'IT'
  },
  {
    text: 'Jersey',
    value: 'JE'
  },
  {
    text: 'Jamaica',
    value: 'JM'
  },
  {
    text: 'Jordanië',
    value: 'JO'
  },
  {
    text: 'Japan',
    value: 'JP'
  },
  {
    text: 'Kenia',
    value: 'KE'
  },
  {
    text: 'Kirgizië',
    value: 'KG'
  },
  {
    text: 'Cambodja',
    value: 'KH'
  },
  {
    text: 'Kiribati',
    value: 'KI'
  },
  {
    text: 'Comoren',
    value: 'KM'
  },
  {
    text: 'Saint Kitts en Nevis',
    value: 'KN'
  },
  {
    text: 'Noord-Korea',
    value: 'KP'
  },
  {
    text: 'Zuid-Korea',
    value: 'KR'
  },
  {
    text: 'Koeweit',
    value: 'KW'
  },
  {
    text: 'Kaaiman Eilanden',
    value: 'KY'
  },
  {
    text: 'Kazachstan',
    value: 'KZ'
  },
  {
    text: 'Laos',
    value: 'LA'
  },
  {
    text: 'Libanon',
    value: 'LB'
  },
  {
    text: 'Saint Lucia',
    value: 'LC'
  },
  {
    text: 'Liechtenstein',
    value: 'LI'
  },
  {
    text: 'Sri Lanka',
    value: 'LK'
  },
  {
    text: 'Liberia',
    value: 'LR'
  },
  {
    text: 'Lesotho',
    value: 'LS'
  },
  {
    text: 'Litouwen',
    value: 'LT'
  },
  {
    text: 'Luxemburg',
    value: 'LU'
  },
  {
    text: 'Letland',
    value: 'LV'
  },
  {
    text: 'Libië',
    value: 'LY'
  },
  {
    text: 'Marokko',
    value: 'MA'
  },
  {
    text: 'Monaco',
    value: 'MC'
  },
  {
    text: 'Moldavië',
    value: 'MD'
  },
  {
    text: 'Montenegro',
    value: 'ME'
  },
  {
    text: 'Sint-Maarten (Franse Deel)',
    value: 'MF'
  },
  {
    text: 'Madagaskar',
    value: 'MG'
  },
  {
    text: 'Marshall Eilanden',
    value: 'MH'
  },
  {
    text: 'Noord-Macedonië',
    value: 'MK'
  },
  {
    text: 'Mali',
    value: 'ML'
  },
  {
    text: 'Myanmar',
    value: 'MM'
  },
  {
    text: 'Mongolië',
    value: 'MN'
  },
  {
    text: 'Macau',
    value: 'MO'
  },
  {
    text: 'Noordelijke Marianen',
    value: 'MP'
  },
  {
    text: 'Martinique',
    value: 'MQ'
  },
  {
    text: 'Mauritanië',
    value: 'MR'
  },
  {
    text: 'Montserrat',
    value: 'MS'
  },
  {
    text: 'Malta',
    value: 'MT'
  },
  {
    text: 'Mauritius',
    value: 'MU'
  },
  {
    text: 'Maldiven',
    value: 'MV'
  },
  {
    text: 'Malawi',
    value: 'MW'
  },
  {
    text: 'Mexico',
    value: 'MX'
  },
  {
    text: 'Maleisië',
    value: 'MY'
  },
  {
    text: 'Mozambique',
    value: 'MZ'
  },
  {
    text: 'Namibië',
    value: 'NA'
  },
  {
    text: 'Nieuw-Caledonië',
    value: 'NC'
  },
  {
    text: 'Niger',
    value: 'NE'
  },
  {
    text: 'Norfolk',
    value: 'NF'
  },
  {
    text: 'Nigeria',
    value: 'NG'
  },
  {
    text: 'Nicaragua',
    value: 'NI'
  },
  {
    text: 'Nederland',
    value: 'NL'
  },
  {
    text: 'Noorwegen',
    value: 'NO'
  },
  {
    text: 'Nepal',
    value: 'NP'
  },
  {
    text: 'Nauru',
    value: 'NR'
  },
  {
    text: 'Niue',
    value: 'NU'
  },
  {
    text: 'Nieuw-Zeeland',
    value: 'NZ'
  },
  {
    text: 'Oman',
    value: 'OM'
  },
  {
    text: 'Panama',
    value: 'PA'
  },
  {
    text: 'Peru',
    value: 'PE'
  },
  {
    text: 'Frans-Polynesië',
    value: 'PF'
  },
  {
    text: 'Papoea-Nieuw-Guinea',
    value: 'PG'
  },
  {
    text: 'Filipijnen',
    value: 'PH'
  },
  {
    text: 'Pakistan',
    value: 'PK'
  },
  {
    text: 'Polen',
    value: 'PL'
  },
  {
    text: 'Saint-Pierre en Miquelon',
    value: 'PM'
  },
  {
    text: 'Pitcairneilanden',
    value: 'PN'
  },
  {
    text: 'Puerto Rico',
    value: 'PR'
  },
  {
    text: 'Palestina',
    value: 'PS'
  },
  {
    text: 'Portugal',
    value: 'PT'
  },
  {
    text: 'Palau',
    value: 'PW'
  },
  {
    text: 'Paraguay',
    value: 'PY'
  },
  {
    text: 'Qatar',
    value: 'QA'
  },
  {
    text: 'Réunion',
    value: 'RE'
  },
  {
    text: 'Roemenië',
    value: 'RO'
  },
  {
    text: 'Servië',
    value: 'RS'
  },
  {
    text: 'Rusland',
    value: 'RU'
  },
  {
    text: 'Rwanda',
    value: 'RW'
  },
  {
    text: 'Saoedi-Arabië',
    value: 'SA'
  },
  {
    text: 'Salomonseilanden',
    value: 'SB'
  },
  {
    text: 'Seychellen',
    value: 'SC'
  },
  {
    text: 'Soedan',
    value: 'SD'
  },
  {
    text: 'Zweden',
    value: 'SE'
  },
  {
    text: 'Singapore',
    value: 'SG'
  },
  {
    text: 'Sint-Helena, Ascension en Tristan da Cunha',
    value: 'SH'
  },
  {
    text: 'Slovenië',
    value: 'SI'
  },
  {
    text: 'Spitsbergen en Jan Mayen',
    value: 'SJ'
  },
  {
    text: 'Slowakije',
    value: 'SK'
  },
  {
    text: 'Sierra Leone',
    value: 'SL'
  },
  {
    text: 'San Marino',
    value: 'SM'
  },
  {
    text: 'Senegal',
    value: 'SN'
  },
  {
    text: 'Somalië',
    value: 'SO'
  },
  {
    text: 'Suriname',
    value: 'SR'
  },
  {
    text: 'Zuid-Soedan',
    value: 'SS'
  },
  {
    text: 'Sao Tomé en Principe',
    value: 'ST'
  },
  {
    text: 'El Salvador',
    value: 'SV'
  },
  {
    text: 'Sint Maarten (Nederlandse Deel)',
    value: 'SX'
  },
  {
    text: 'Syrië',
    value: 'SY'
  },
  {
    text: 'Swaziland',
    value: 'SZ'
  },
  {
    text: 'Turks- en Caicoseilanden',
    value: 'TC'
  },
  {
    text: 'Tsjaad',
    value: 'TD'
  },
  {
    text: 'Franse Zuidelijke Gebieden',
    value: 'TF'
  },
  {
    text: 'Togo',
    value: 'TG'
  },
  {
    text: 'Thailand',
    value: 'TH'
  },
  {
    text: 'Tadzjikistan',
    value: 'TJ'
  },
  {
    text: 'Tokelau',
    value: 'TK'
  },
  {
    text: 'Oost-Timor',
    value: 'TL'
  },
  {
    text: 'Turkmenistan',
    value: 'TM'
  },
  {
    text: 'Tunesië',
    value: 'TN'
  },
  {
    text: 'Tonga',
    value: 'TO'
  },
  {
    text: 'Turkije',
    value: 'TR'
  },
  {
    text: 'Trinidad en Tobago',
    value: 'TT'
  },
  {
    text: 'Tuvalu',
    value: 'TV'
  },
  {
    text: 'Taiwan',
    value: 'TW'
  },
  {
    text: 'Tanzania',
    value: 'TZ'
  },
  {
    text: 'Oekraïne',
    value: 'UA'
  },
  {
    text: 'Oeganda',
    value: 'UG'
  },
  {
    text: 'Kleine Pacifische Eilanden van de Verenigde Staten',
    value: 'UM'
  },
  {
    text: 'Verenigde Staten',
    value: 'US'
  },
  {
    text: 'Uruguay',
    value: 'UY'
  },
  {
    text: 'Oezbekistan',
    value: 'UZ'
  },
  {
    text: 'Vaticaan Stad',
    value: 'VA'
  },
  {
    text: 'Saint Vincent en de Grenadines',
    value: 'VC'
  },
  {
    text: 'Venezuela',
    value: 'VE'
  },
  {
    text: 'Maagdeneilanden, Britse',
    value: 'VG'
  },
  {
    text: 'Maagdeneilanden, Verenigde Staten',
    value: 'VI'
  },
  {
    text: 'Vietnam',
    value: 'VN'
  },
  {
    text: 'Vanuatu',
    value: 'VU'
  },
  {
    text: 'Wallis en Futuna',
    value: 'WF'
  },
  {
    text: 'Samoa',
    value: 'WS'
  },
  {
    text: 'Verenigd Koninkrijk',
    value: 'XI'
  },
  {
    text: 'Jemen',
    value: 'YE'
  },
  {
    text: 'Mayotte',
    value: 'YT'
  },
  {
    text: 'Zuid-Afrika',
    value: 'ZA'
  },
  {
    text: 'Zambia',
    value: 'ZM'
  },
  {
    text: 'Zimbabwe',
    value: 'ZW'
  }]
