
import Vue from 'vue'
export default Vue.extend({
  props: {
    iconName: {
      type: String
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    _style: {
      type: Object,
      required: false
    }
  }
})
