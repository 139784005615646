import { BookingItem } from '@/modules/administration/models/BookingItem'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { ClientRelationModel } from '@/globals/models/relation/ClientRelation'
import { objectHelper } from '@/globals/helpers/ObjectHelper'
import { TablePaginationModel } from '@/globals/models/TablePagination'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { DocumentTableFilter } from '@/modules/administration/models/DocumentTableFilter'

const state = () => ({
  bookingItems: [] as Array<BookingItem>,
  tableItems: [] as Array<any>,
  isGoingNextPage: false,
  isGoingPreviousPage: false,
  filter: sessionStorage.getItem('booking_filter') ? new DocumentTableFilter(JSON.parse(sessionStorage.getItem('booking_filter') as string)) : new DocumentTableFilter(),
  tablePagination: new TablePaginationModel(),
  currentBookingItem: null as null|BookingItem,
  clientRelations: [] as Array<ClientRelationModel>,
  isClientRelationsLoading: false,
  relationsAdded: [] as Array<number>,
  relationInvoiceIdsDict: {},
  currencies: []
})

const getters = {
  bookingItems: (state: any) => {
    return state.bookingItems
  },
  currentBookingItem: (state: any) => {
    return state.currentBookingItem
  },
  clientRelations: (state: any) => {
    return state.clientRelations
  },
  isClientRelationsLoading: (state: any) => {
    return state.isClientRelationsLoading
  },
  currencies: (state: any) => {
    return state.currencies
  },
  filter: (state: any) => {
    return state.filter
  },
  tablePagination: (state: any) => {
    return state.tablePagination
  },
  isPreviousBookingItem (state: any): boolean {
    const currentId = state.currentBookingItem.id
    const currentIndex = state.tableItems.findIndex((item: any) => item.id === currentId)
    let isPrevious = false

    if (currentIndex >= 0) {
      if (currentIndex > 0) {
        isPrevious = true
      } else if (state.tablePagination.page > 1) {
        isPrevious = true
      }
    } else if (state.tableItems.length) {
      isPrevious = true
    }

    return isPrevious
  },
  previousBookingItem (state: any): null|BookingItem {
    const currentId = state.currentBookingItem.id
    const currentIndex = state.tableItems.findIndex((item: any) => item.id === currentId)
    let previous = null as null|BookingItem

    if (currentIndex >= 0) {
      if (currentIndex > 0) {
        previous = state.tableItems[currentIndex - 1].bookingItem
      } else if (state.tablePagination.page > 1) {
        state.isGoingPreviousPage = true
        state.tablePagination.page--
      }
    } else if (state.tableItems.length) {
      previous = state.tableItems[0].bookingItem
    }

    return previous
  },

  isNextBookingItem (state: any): boolean {
    const currentId = state.currentBookingItem.id
    const currentIndex = state.tableItems.findIndex((item: any) => item.id === currentId)
    let isNext = false

    if (currentIndex >= 0) {
      if (currentIndex < state.tableItems.length - 1) {
        isNext = true
      } else if (state.tablePagination.page < state.tablePagination.pageCount) {
        isNext = true
      }
    } else if (state.tableItems.length) {
      isNext = true
    }

    return isNext
  },

  nextBookingItem (state: any): null|BookingItem {
    const currentId = state.currentBookingItem.id
    const currentIndex = state.tableItems.findIndex((item: any) => item.id === currentId)
    let next = null as null|BookingItem

    if (currentIndex >= 0) {
      if (currentIndex < state.tableItems.length - 1) {
        next = state.tableItems[currentIndex + 1].bookingItem
      } else if (state.tablePagination.page < state.tablePagination.pageCount) {
        state.isGoingNextPage = true
        state.tablePagination.page++
      }
    } else if (state.tableItems.length) {
      next = state.tableItems[0].bookingItem
    }

    return next
  }
}

const actions = {
  setBookingItems ({ commit }: any, bookingItems: Array<BookingItem>) {
    commit('setBookingItems', bookingItems)
  },
  updateCurrentBookingItem ({ commit }: any, bookingItem: BookingItem) {
    commit('updateCurrentBookingItem', bookingItem)
  },
  setCurrentBookingItem ({ commit }: any, bookingItem: BookingItem) {
    commit('setCurrentBookingItem', bookingItem)
  },
  saveCurrentBookingItem ({ commit }: any) {
    commit('saveCurrentBookingItem')
  },
  setIsClientRelationsLoading ({ commit }: any, isClientRelationsLoading: boolean) {
    commit('setIsClientRelationsLoading', isClientRelationsLoading)
  },
  setFilter ({ commit }: any, filter: DocumentTableFilter) {
    commit('setFilter', filter)
  },
  setTablePagination ({ commit }: any, tablePagination: TablePaginationModel) {
    commit('setTablePagination', tablePagination)
  },
  setTableItems ({ commit }: any, items: any) {
    commit('setTableItems', items)
  },
  async setClientRelations ({ commit }: any) {
    commit('setIsClientRelationsLoading', true)

    await apiAdministrationService.getClientRelations().then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        const clientRelations = [] as Array<ClientRelationModel>

        response.data.forEach((clientRelationRaw: any) => {
          const clientRelation = new ClientRelationModel().fromResponse(clientRelationRaw)
          clientRelations.push(clientRelation)
        })

        commit('setClientRelations', clientRelations)
      }
    }).finally(() => { commit('setIsClientRelationsLoading', false) })
  },
  goToNextBookingItem ({ commit }: any) {
    commit('goToNextBookingItem')
  },
  setCurrencies ({ commit }: any, currencies: any) {
    commit('setCurrencies', currencies)
  },
  clearStore ({ commit }: any) {
    commit('clearStore')
  }
}

const mutations = {
  setBookingItems (state: any, bookingItems: Array<BookingItem>) {
    state.bookingItems = bookingItems
  },
  updateCurrentBookingItem (state: any, bookingItem: BookingItem) {
    mutations.setCurrentBookingItem(state, bookingItem)
    mutations.saveCurrentBookingItem(state)
  },
  saveCurrentBookingItem (state: any) {
    const index = (state.bookingItems as Array<BookingItem>).findIndex(item => item.id === state.currentBookingItem.id)

    if (index >= 0) {
      const newItem = new BookingItem().fromResponse(objectHelper.cloneObject(state.currentBookingItem));
      (state.bookingItems as Array<BookingItem>).splice(index, 1, newItem)
    }
  },
  setCurrentBookingItem (state: any, bookingItem: BookingItem|null) {
    const newItem = new BookingItem().fromResponse(objectHelper.cloneObject(bookingItem))
    state.currentBookingItem = newItem
  },
  setClientRelations (state: any, clientRelations: Array<ClientRelationModel>) {
    state.clientRelations = clientRelations
  },
  setIsClientRelationsLoading (state: any, isClientRelationsLoading: boolean) {
    state.isClientRelationsLoading = isClientRelationsLoading
  },

  setCurrencies (state: any, currencies: any) {
    state.currencies = currencies
  },

  setTablePagination (state: any, tablePagination: TablePaginationModel) {
    state.tablePagination = tablePagination
  },
  setFilter (state: any, filter: DocumentTableFilter) {
    sessionStorage.setItem('booking_filter', JSON.stringify(filter))
    state.filter = filter
  },

  setTableItems (state: any, bookingItems: Array<any>) {
    state.tableItems = bookingItems

    if (state.isGoingNextPage) {
      state.isGoingNextPage = false
      mutations.setCurrentBookingItem(state, state.tableItems[0].bookingItem)
    }

    if (state.isGoingPreviousPage) {
      state.isGoingNextPage = false
      mutations.setCurrentBookingItem(state, state.tableItems[state.tableItems.length - 1].bookingItem)
    }
  },
  goToNextBookingItem (state: any) {
    const nextBookingItem = getters.nextBookingItem(state)

    if (nextBookingItem) {
      mutations.setCurrentBookingItem(state, nextBookingItem)
    }
  },
  clearStore (state: any) {
    arrayHelper.clear(state.bookingItems)
    arrayHelper.clear(state.tableItems)
    arrayHelper.clear(state.relationsAdded)
    arrayHelper.clear(state.clientRelations)
    arrayHelper.clear(state.currencies)
    state.tablePagination = new TablePaginationModel()
    state.currentBookingItem = null
    state.relationInvoiceIdsDict = {}
    state.isGoingNextPage = false
    state.isGoingPreviousPage = false
    state.isClientRelationsLoading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
