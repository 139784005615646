
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { entityHelper } from '@/globals/helpers/EntityHelper'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      password: {
        current_password: '',
        password: '',
        password_confirm: ''
      },
      passwordErrors: {} as any,
      notify: new Notify(),
      submitDisabled: true
    }
  },
  methods: {
    emptyPasswordFields () {
      this.password.current_password = ''
      this.password.password = ''
      this.password.password_confirm = ''
    },
    async updatePassword () {
      await apiAuthService.updatePassword(this.password).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.emptyPasswordFields()
          this.submitDisabled = true
          this.notify.updated()
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.passwordErrors = {
            ...this.passwordErrors,
            ...response.data
          }
        }
      })
    },
    submitPasswordForm (e: any) {
      e.preventDefault()
      this.passwordErrors = entityHelper.generateErrorEntity(this.password)

      if (!this.isValidationError()) { this.updatePassword() }
    },
    isValidationError () {
      let error = false

      if (!this.password.current_password) {
        this.passwordErrors.current_password.push('Huidig wachtwoord is verplicht.')
        error = true
      }
      if (!this.password.password) {
        this.passwordErrors.password.push('Nieuw wachtwoord is verplicht.')
        error = true
      }
      if (!this.password.password_confirm) {
        this.passwordErrors.password_confirm.push('Herhalen nieuw wachtwoord is verplicht.')
        error = true
      }
      if (this.password.current_password === this.password.password) {
        this.passwordErrors.password.push('Kies een nieuw wachtwoord.')
        error = true
      }
      if (this.password.password !== this.password.password_confirm) {
        this.passwordErrors.password_confirm.push('Wachtwoord komt niet overeen.')
        error = true
      }
      return error
    }
  },
  beforeMount () {
    this.passwordErrors = entityHelper.generateErrorEntity(this.password)
  }
})
