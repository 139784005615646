
import Vue from 'vue'

import { HttpStatus } from '@/globals/enums/HttpStatus'
import { InvoicePrefixModel } from '@/globals/models/invoice/InvoicePrefix'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import InvoicePrefixDetail from './InvoicePrefixDetail.vue'
import GreyArea from '@/globals/components/blank-slates/GreyArea.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    InvoicePrefixDetail,
    GreyArea,
    IconBase,
    IconPlus
  },
  data () {
    return {
      isEssentialsLoaded: true,
      prefixes: [] as Array<InvoicePrefixModel>,
      dateHelper,
      isPrefixModal: false,
      selected: null
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.fetchAllPrefix()
      this.isEssentialsLoaded = true
    },

    async fetchAllPrefix () {
      await apiInvoiceService.fetchPrefixes().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((prefixRaw: any) => {
            const prefix = new InvoicePrefixModel().fromResponse(prefixRaw)
            this.prefixes.push(prefix)
          })
        }
      })
    },

    async deletePrefix (id: number, index: number) {
      await apiInvoiceService.deletePrefix(id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.prefixes.splice(index, 1)
        }
      })
    },

    onUpdated (prefix: InvoicePrefixModel) {
      const item = this.prefixes.find(p => p.id === prefix.id)

      if (item) {
        item.setData(prefix)
      }
      this.isPrefixModal = false
    },

    onCreated (prefix: InvoicePrefixModel) {
      this.prefixes.push(prefix)
      this.isPrefixModal = false
    }
  }
})

