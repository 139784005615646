<template>
      <div class="input__item">
        <label>{{ label }}</label>
        <div class="input__group">
        <input
          :class="
          errorModel.length ? 'validation--error ' : '' +
          showPassword ? 'show--password-icon ' : ''
          " :type="inputType"
          :name="name"
          v-on:input="$emit('input', $event.target.value)"
        />
        <i v-if="showPassword" :class="{
          'password--eye': true,
          'fas fa-eye': !isPasswordShown,
          'fas fa-eye-slash': isPasswordShown
        }"
        @click="showHidePassword()"
        />
        </div>
        <ul v-if="errorModel.length" class="form__validation">
          <li v-for="error in errorModel" :key="error.id">{{ error }}</li>
        </ul>
      </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    errorModel: {
      type: Array,
      required: true
    },
    showPassword: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      isPasswordShown: false,
      inputType: 'text'
    }
  },
  beforeMount () {
    this.inputType = this.type
  },
  methods: {
    showHidePassword () {
      this.isPasswordShown = !this.isPasswordShown

      if (this.isPasswordShown) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input__item input {
  &.show--password-icon {
    padding-right: 60px;
  }
}
.input__group {
  position: relative;

  .password--eye {
    position: absolute;
    margin: auto;
    right: 20px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
