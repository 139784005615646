import { RouteConfig } from 'vue-router'
import Relation from '../views/Relations.vue'
import Overview from '../views/overview/Overview.vue'
import Create from '../views/create/Create.vue'
import Edit from '../views/edit/Edit.vue'
import { RouteNames } from './RouteNames'

const routes = RouteNames
const relationRoutes: Array<RouteConfig> = [
  {
    path: '/relation',
    name: routes.relation.name,
    component: Relation,
    redirect: { name: routes.relation.overview.name },
    meta: {
      name_nl: 'overzicht'
    },
    children: [
      {
        path: 'overview',
        name: routes.relation.overview.name,
        component: Overview,
        meta: {
          name_nl: 'overzicht'
        }
      },
      {
        path: 'create',
        name: routes.relation.create.name,
        component: Create,
        meta: {
          name_nl: 'nieuw'
        }
      },
      {
        path: 'edit/:id',
        name: routes.relation.edit.name,
        component: Edit
      }
    ]
  }
]

export default relationRoutes
