interface Options {
    text: string;
    value: number;
}
export class CurrencyEnum {
  euro: Options = {
    text: '€ - Euro - EUR',
    value: 0
  }

  dollar: Options = {
    text: '$ - Dollar - USD',
    value: 1
  }

  getCurrencies () {
    return [
      this.euro,
      this.dollar
    ]
  }
}
