
import Vue from 'vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { TaskModel } from '@/modules/task/models/Task'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { objectHelper } from '@/globals/helpers/ObjectHelper'
import { UserModel } from '@/globals/models/user/User'

export default Vue.extend({
  props: {
    tasks: {
      type: Array,
      required: true
    }
  },
  components: {
    BlankSlate,
    PrimaryButton,
    Avatar
  },
  computed: {
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    latestTasks (): TaskModel[] {
      let clonedTasks = [] as TaskModel[]

      (this.tasks as TaskModel[]).forEach(task => {
        const clonedTask = new TaskModel(objectHelper.cloneObject(task))
        clonedTasks.push(clonedTask)
      })

      clonedTasks = clonedTasks.sort((a, b) => a.created_at > b.created_at ? -1 : 0)

      if (clonedTasks.length > 3) {
        clonedTasks.length = 3
      }

      return clonedTasks
    }
  },
  methods: {
    goToTask (task: TaskModel) {
      this.$store.dispatch('task/setSelectedTask', task)
      this.$router.push({ name: routeHelper.taskRoutes.task.overview.name })
    }
  }
})
