interface Options {
    text: string;
    value: number;
}
export class ProductUnit {
  piece: Options = {
    text: 'Stuks',
    value: 0
  }

  hour: Options = {
    text: 'Uur',
    value: 1
  }

  day: Options = {
    text: 'Dag',
    value: 2
  }

  gram: Options = {
    text: 'Gram',
    value: 3
  }

  kilogram: Options = {
    text: 'Kilogram',
    value: 4
  }

  liter: Options = {
    text: 'Liter',
    value: 5
  }

  meter: Options = {
    text: 'Meter',
    value: 6
  }

  kilometer: Options = {
    text: 'Kilometer',
    value: 7
  }

  set: Options = {
    text: 'Set',
    value: 8
  }

  post: Options = {
    text: 'Post',
    value: 9
  }

  getAll () {
    return [
      this.piece,
      this.set,
      this.post,
      this.hour,
      this.day,
      this.gram,
      this.kilogram,
      this.liter,
      this.meter,
      this.kilometer
    ]
  }

  getByValue (value: number) {
    const all = this.getAll()

    let data = this.piece

    all.forEach((one: any) => {
      if (one.value === value) {
        data = one
      }
    })

    return data
  }
}
