
import Vue from 'vue'
import { ProjectModel } from '../../../../models/Project'

export default Vue.extend({
  props: {
    search: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      headers: [
        { text: 'Project omschrijving', value: 'description' },
        { text: 'Bedrijfsnaam', value: 'company_name' },
        { text: 'E-mail', value: 'email' }
      ],
      projects: [] as Array<any>
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = true
    },

    buildProjectsTable (projectsRaw: Array<any>) {
      projectsRaw.forEach(projectRaw => {
        const project = new ProjectModel(projectRaw)
        this.insertProject(project)
      })
    },
    insertProject (project: ProjectModel) {
      const tableItem = {
        id: project.id,
        description: project.description
      }

      this.projects.push(tableItem)
    },

    isProjects (): boolean {
      return this.projects.length > 0
    }
  }
})
