var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"single-select__wrapper"},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label || _vm.forceLabel),expression:"label || forceLabel"}],class:{
      'height--small': _vm.labelHeightSmall,
    },domProps:{"innerHTML":_vm._s(_vm.forceLabel ? '<br>' : _vm.label)}}),_c('v-select',{class:{
      'single-select__dropdown': true,
      'validation--error': _vm.errorModel.length,
      'height--large': _vm.heightLarge,
      'height--small': _vm.heightSmall,
      'dropdown__multiple': _vm.isMultiple,
      'btn__style': _vm.isBtnStyle,
      'bg--white': _vm.isBackgroundWhite
    },style:(_vm.selectStyle),attrs:{"items":_vm.filteredData,"dense":true,"height":_vm.isMultiple ? 'auto' : _vm.currentHeight,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"loading":_vm.loading,"item-text":_vm.itemText,"item-value":_vm.itemValue,"multiple":_vm.isMultiple,"hide-details":true,"item-color":'var(--color-primary-red)',"item-disabled":_vm.itemDisabled,"clearable":_vm.isClearable,"outlined":""},on:{"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$emit('blur', _vm.val)},"focus":function($event){return _vm.onFocus()}},scopedSlots:_vm._u([(_vm.noDataSlot)?{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}:null,(_vm.isItemSlot)?{key:`item`,fn:function({item, on, attrs}){return [_vm._t("item",null,{"item":item,"on":on,"attrs":attrs})]}}:null,(_vm.prependSlot || _vm.isSearch)?{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"prepend-item__slot"},[_vm._t("prepend-item"),(_vm.isSearch)?_c('form',{staticClass:"search__form"},[_c('input-field',{ref:"searchDropdown",attrs:{"isAppendIcon":""},on:{"blur":function($event){return _vm.handleSearchBlur()}},scopedSlots:_vm._u([{key:"appendIcon",fn:function(){return [_c('i',{staticClass:"append--icon"},[_c('icon-base',{attrs:{"width":16,"height":16,"viewBox":'0 0 16 16',"iconColor":'#fff'}},[_c('icon-looking-glass')],1)],1)]},proxy:true}],null,false,2957849114),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],2)]},proxy:true}:null,{key:"append",fn:function(){return [_c('icon-base',{attrs:{"width":16,"height":16,"viewBox":'0 0 16 16'}},[_c('icon-chevron-down')],1)]},proxy:true}],null,true),model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),(_vm.errorModel.length)?_c('ul',{staticClass:"form__validation"},_vm._l((_vm.errorModel),function(error){return _c('li',{key:error.id},[_vm._v(_vm._s(error))])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }