import { DocumentTableTab } from '../enums/DocumentTableTab'

export class DocumentTableFilter {
  tab = DocumentTableTab.ALL
  date = null
  from = null
  to = null

  constructor (data?: DocumentTableFilter) {
    if (data) {
      this.tab = data.tab
      this.date = data.date
      this.from = data.from
      this.to = data.to
    }
  }
}
