
import Vue from 'vue'
export default Vue.extend({
  props: {
    maxDept: {
      type: Number,
      required: false
    },
    currentPage: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      breadcrumbs: [] as Array<object>
    }
  },
  mounted () {
    this.setBreadcrumbs()
  },
  methods: {
    setBreadcrumbs () {
      const route = this.$route
      const param = this.getParam()
      const parent = route.matched[route.matched.length - 1].parent

      this.breadcrumbs.push({ route: parent?.name, name: parent?.meta.name_nl })
      this.breadcrumbs.push({ route: route.name, name: param ?? route.meta?.name_nl })
    },
    getParam (): string|null {
      return this.$route.params.id ? this.$route.params.id : null
    }
  }
})
