
import Vue from 'vue'

// https://github.com/lian-yue/vue-upload-component/blob/master/docs/views/examples/Full.vue
import FileUpload from 'vue-upload-component'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { localAuthService } from '@/network/local/local-auth-service'

export default Vue.extend({
  props: {
    uploadUrl: {
      type: String,
      required: true
    }
  },
  components: {
    FileUpload,
    PrimaryButton
  },
  data () {
    return {
      uploadCompleted: false,
      files: [],
      accept: 'application/pdf,image/png,image/gif,image/jpeg,image/webp',
      extensions: ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'webp'],
      minSize: 0,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 50,
      name: 'file',
      postAction: this.uploadUrl,
      putAction: '/upload/put',
      headers: {
        Authorization: 'Bearer ' + localAuthService.getToken()
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      }
    }
  },

  methods: {
    isNoFiles (): boolean {
      return this.files.length === 0
    },
    isUploading (): boolean {
      // eslint-disable-next-line
      // @ts-ignore
      return this.$refs.upload && this.$refs.upload.active
    },
    isUploadComplete (): boolean {
      // eslint-disable-next-line
      // @ts-ignore
      return this.uploadCompleted && this.$refs.upload && this.$refs.upload.uploaded
    },
    onAddData () {
      this.addData.show = false
      // eslint-disable-next-line
      // @ts-ignore
      if (!this.$refs.upload.features.html5) {
        alert('Your browser does not support')
        return
      }
      const file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      })
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.upload.add(file)
    }
  }
})
