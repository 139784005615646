var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"task",class:{
  'task__card': true,
  'task--draggable': true,
  'filtered--out': _vm.isFilteredOut,
  'is--selected': _vm.isSelected,
  'is--done': _vm.isDone,
  'is--shared': _vm.isSharedToMe
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickOuter.apply(null, arguments)}}},[(!_vm.isSharedToMe)?_c('card-menu',{staticClass:"card-menu--toggle",on:{"edit":function($event){return _vm.clickOuter()},"delete":function($event){return _vm.deleteTask()}}}):_vm._e(),_c('div',{staticClass:"task-card__content"},[_c('tooltip',{attrs:{"label":_vm.assignedTo.user.getName() + ' is toegewezen'}},[_c('avatar',{staticClass:"comment--avatar",attrs:{"label":_vm.assignedTo.user.getName(),"imgUrl":_vm.assignedTo.user.avatar_url,"backgroundColorByAlphabet":true,"isSelf":_vm.user.id === _vm.assignedTo.user.id,"size":30,"fontSize":14}})],1),_c('div',{staticClass:"content__right"},[(_vm._task.relation_to.id)?_c('div',{staticClass:"content--customer"},[_c('span',{staticClass:"subtitle--light"},[_vm._v(_vm._s(_vm._task.relation_to.getRelationName()))])]):_vm._e(),_c('div',{staticClass:"content--title"},[_c('span',{staticClass:"weight--600"},[_vm._v(_vm._s(_vm._task.title))])]),_c('div',{staticClass:"content--body"},[_c('span',{staticClass:"subtitle--semi"},[_vm._v(_vm._s(_vm._task.body))])]),_c('div',{staticClass:"content__footer"},[(_vm._task.planned_at)?_c('span',{class:{
          'subtitle--light': true,
          'is--error': _vm._task.isPlannedDatePassed()
          }},[_vm._v(_vm._s(_vm.dateHelper.toShortMonthDateString(_vm._task.planned_at)))]):_c('span'),(_vm.isSharedToOthers)?_c('div',{staticClass:"mini__avatars"},[_c('tooltip',{attrs:{"label":'Gedeeld met:<br><br>' + _vm.getSharedToNames()}},_vm._l((_vm._task.getSharedTo()),function(sharedTo,index){return _c('avatar',{directives:[{name:"show",rawName:"v-show",value:(index < 3),expression:"index < 3"}],key:index,attrs:{"label":sharedTo.user.getName(),"size":20}})}),1)],1):_vm._e()])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm._task.isUpdating),expression:"_task.isUpdating"}],staticClass:"task__loader"},[_c('div',{staticClass:"loader--bg"}),_c('i',{staticClass:"fas fa-spinner fa-pulse"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }