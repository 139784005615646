
import Vue from 'vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import { localAuthService } from '@/network/local/local-auth-service'
import InputField from '../../forms/InputField.vue'
import { AdministrationModel } from '@/globals/models/Administration'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import IconBase from '../../icons/IconBase.vue'
import IconFactory from '../../icons/IconFactory.vue'
import IconChevronDown from '../../icons/IconChevronDown.vue'
import IconLookingGlass from '../../icons/IconLookingGlass.vue'
export default Vue.extend({
  components: { InputField, IconBase, IconFactory, IconChevronDown, IconLookingGlass },
  data () {
    return {
      menu: false,
      isLoading: false,
      switchAdministrationId: null as null|number,
      search: ''
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    administrations (): Array<AdministrationModel> {
      return this.$store.getters['relation/administrations']
    },
    filteredAdministrations (): Array<AdministrationModel> {
      const administrations = this.administrations.filter(administration => administration.relation.active && administration.relation.getRelationName().toLowerCase().includes(this.search.toLowerCase()))
      return administrations.sort((a, b) => a.relation.getRelationName().localeCompare(b.relation.getRelationName()))
    }
  },
  watch: {
    async menu () {
      if (this.menu) {
        const refs = this.$refs as any
        setTimeout(function () {
          refs.searchRelation.$refs.input.focus()
        }, 100)
      }
      if (this.menu && !this.administrations.length) {
        this.isLoading = true
        await this.$store.dispatch('relation/setAdministrations')
        this.isLoading = false
      }
    }
  },

  methods: {
    async switchAdministration (relationId: number) {
      this.switchAdministrationId = relationId
      await localAuthService.switchToAdministration(relationId, false).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.dispatch('invoicing/clearStore')
          this.$store.dispatch('client/clearStore')
          this.$store.dispatch('administration/clearStore')
          this.$store.dispatch('book/clearStore')
          this.menu = false
          this.$router.push({ name: 'dashboard', query: { refresh: 'true' } })
        }
      }).finally(() => { this.switchAdministrationId = null })
    },
    navigateToNewAdministration () {
      this.menu = false
      const routeName = routeHelper.settingsRoutes.settings.administration.create.name

      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    }
  }
})
