import { render, staticRenderFns } from "./TextArea.vue?vue&type=template&id=71b060fa&scoped=true&"
import script from "./TextArea.vue?vue&type=script&lang=js&"
export * from "./TextArea.vue?vue&type=script&lang=js&"
import style0 from "./TextArea.vue?vue&type=style&index=0&id=71b060fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b060fa",
  null
  
)

export default component.exports