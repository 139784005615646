
import Vue from 'vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  data () {
    return {
      userStore: this.$store.state.user,
      relationStore: this.$store.state.relation,
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      routeHelper
    }
  },
  computed: {
    isOwner (): boolean {
      return this.relationStore.isOwner
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    }
  }
})
