

import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'

export default Vue.extend({
  components: {
    PrimaryModal,
    PrimaryButton
  },
  data () {
    return {
    }
  },
  methods: {}
})

