import { BookingItem } from '@/modules/administration/models/BookingItem'
import { client } from '../client'

export const apiAdministrationService = {
  baseUrl: (window as any).appConfig.API_ADMINISTRATION,

  /*
    * Booking item endpoints
    */
  async getBookingItems () {
    const url = this.baseUrl + 'bookingitem'

    return await client.get(url)
  },

  async getBookingItem (id: number) {
    const url = this.baseUrl + 'bookingitem/' + id

    return await client.get(url)
  },

  async updateBookingItem (bookingItem: BookingItem, saveBookingLines: boolean) {
    const url = this.baseUrl + 'bookingitem/' + bookingItem.id

    return await client.put(url, { bookingItem, saveBookingLines })
  },

  async getStats () {
    const url = this.baseUrl + 'stats'

    return await client.get(url)
  },

  async getStatsNoOwner () {
    const url = this.baseUrl + 'statsno'

    return await client.get(url)
  },

  async splitBookingItem (itemId: any, data: any) {
    const url = this.baseUrl + 'bookingitem/' + itemId + '/split'

    return await client.put(url, data)
  },

  async mergeBookingItems (items: Array<number>) {
    const url = this.baseUrl + 'bookingitem/merge'

    return await client.post(url, { booking_item_ids: items })
  },

  async book (bookingItem: BookingItem, saveBookingLines: boolean) {
    const url = this.baseUrl + 'bookingitem/' + bookingItem.id + '/book'

    return await client.put(url, { bookingItem, saveBookingLines })
  },

  async updateLabel (itemId: any, data: any) {
    const url = this.baseUrl + 'bookingitem/' + itemId + '/label'

    return await client.put(url, data)
  },

  async tagBookingItem (itemId: any) {
    const url = this.baseUrl + 'bookingitem/' + itemId + '/tag'

    return await client.put(url)
  },

  async labelMultipleBookingItems (bookingItemIds: Array<number>, label: string) {
    const url = this.baseUrl + 'bookingitem/label/multiple'

    return await client.put(url, { booking_item_ids: bookingItemIds, label })
  },

  async tagMultipleBookingItems (bookingItemIds: Array<number>) {
    const url = this.baseUrl + 'bookingitem/tag/multiple'

    return await client.put(url, { booking_item_ids: bookingItemIds })
  },

  async moveBookingItem (bookingItemId: number, relationId: number) {
    const url = this.baseUrl + 'bookingitem/' + bookingItemId + '/transfer'

    return await client.put(url, { relation_id: relationId })
  },

  async getBookingItemLogs (bookingItemId: any) {
    const url = this.baseUrl + 'bookingitem/' + bookingItemId + '/log'

    return await client.get(url)
  },

  async createBookingItemLog (bookingItemId: any, message: string) {
    const url = this.baseUrl + 'bookingitem/' + bookingItemId + '/log'

    return await client.post(url, { message })
  },

  async deleteBookingItem (id: number) {
    const url = this.baseUrl + 'bookingitem/' + id

    return await client.delete(url)
  },

  async getLedgerAccounts () {
    const url = this.baseUrl + 'ledgeraccounts'

    return await client.get(url)
  },

  async getLedgerAccountByRelationId (relationId: number) {
    const url = this.baseUrl + 'relation/' + relationId

    return await client.get(url)
  },

  async getClientRelations () {
    const url = this.baseUrl + 'relations'

    return await client.get(url)
  },
  async getRelationInvoiceIds (relationId: number) {
    const url = this.baseUrl + 'relation/' + relationId + '/invoiceIds'

    return await client.get(url)
  },
  async createTwinfieldClientRelation (dayaClientRelationId: number, bookingItemId?: number) {
    const url = this.baseUrl + 'relation'

    return await client.post(url, { relation_id: dayaClientRelationId, booking_item_id: bookingItemId })
  },
  async updateLinkToTwinfieldClient (dayaClientId: number, uncoupled_id: number) {
    const url = this.baseUrl + 'relation/save'

    return await client.put(url, { relation_id: dayaClientId, id: uncoupled_id })
  },
  async getUncoupledClientRelations () {
    const url = this.baseUrl + 'relations/uncoupled'

    return await client.get(url)
  },
  async getPendingLogs () {
    const url = this.baseUrl + 'pendinglogs'

    return await client.get(url)
  },

  async getDashboardStats () {
    const url = this.baseUrl + 'dashboardstats'

    return await client.get(url)
  },

  getBookingItemUploadUrl () {
    return this.baseUrl + 'bookingitem/upload'
  },

  /*
    * Invoice
    */
  async getInvoice (invoiceId: number) {
    const url = this.baseUrl + 'invoice/' + invoiceId

    return await client.get(url)
  },

  /*
    * Twinfield
    */
  async getTwinfieldEnterprises (organisationId: number) {
    const url = this.baseUrl + 'enterprises/' + organisationId

    return await client.get(url)
  },
  async saveTwinfieldEnterprise (relationId: number|string, code: string, name: string, organisationId: number) {
    const url = this.baseUrl + 'saveEnterprise'

    return await client.post(url, { relation_id: relationId, office_code: code, office_name: name, credentials_id: organisationId })
  },
  async getTwinfieldCredentials () {
    const url = this.baseUrl + 'credentials'

    return await client.get(url)
  },
  async createTwinfieldCredentials (data: any) {
    const url = this.baseUrl + 'credentials'

    return await client.post(url, data)
  },
  async updateTwinfieldCredentials (data: any) {
    const url = this.baseUrl + 'credentials'

    return await client.put(url, data)
  },
  async setTwinfieldDefaults (data: Array<any>) {
    const url = this.baseUrl + 'defaults'

    return await client.post(url, { defaults: data })
  },
  async getTwinfieldTaxDefaults () {
    const url = this.baseUrl + 'taxdefaults'

    return await client.get(url)
  },
  async setTwinfieldTaxDefaults (data: Array<any>) {
    const url = this.baseUrl + 'taxdefaults'

    return await client.post(url, { defaults: data })
  },
  async syncLedgerAccounts () {
    const url = this.baseUrl + 'sync'

    return await client.post(url)
  },
  async checkConnection () {
    const url = this.baseUrl + 'checkconnection'

    return await client.get(url)
  },
  async authorizeTwinfield () {
    const url = this.baseUrl + 'twinfield/authorize'

    return await client.get(url)
  },
  async getOrganisations () {
    const url = this.baseUrl + 'organizations'

    return await client.get(url)
  },
  async getFinancialStats (start: any, end: any) {
    const url = this.baseUrl + 'financialstats'

    return await client.get(url, { start, end })
  },

  /*
    * Other
    */
  async fetchImportedFiles () {
    const url = this.baseUrl + 'import'

    return await client.get(url)
  },
  async dannyImport (data: any) {
    const url = this.baseUrl + 'import'

    return await client.post(url, data)
  },
  async praxisBtw (data: any) {
    const url = this.baseUrl + 'importPdf'

    return await client.post(url, data)
  },

  async fetchCurrencies () {
    const url = this.baseUrl + 'currencies'

    return await client.get(url)
  },

  async downloadFile (url: string) {
    return await client.get(url)
  }
}
