
import Vue from 'vue'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { RelationModel } from '@/globals/models/relation/Relation'
import { UserModel } from '@/globals/models/user/User'
import { localAuthService } from '@/network/local/local-auth-service'
import { ImpersonateModel } from '@/globals/models/auth/Impersonate'
import InputField from '../../forms/InputField.vue'
import IconBase from '../../icons/IconBase.vue'
import IconFactory from '../../icons/IconFactory.vue'
import IconChevronDown from '../../icons/IconChevronDown.vue'
import IconLookingGlass from '../../icons/IconLookingGlass.vue'
import IconCircleArrow from '../../icons/IconCircleArrow.vue'
export default Vue.extend({
  components: { InputField, IconBase, IconFactory, IconChevronDown, IconLookingGlass, IconCircleArrow },
  data () {
    return {
      menu: false,
      isLoading: false,
      isLeaving: false,
      switchRelationId: null as null|number,
      search: ''
    }
  },
  computed: {
    isOwner (): boolean {
      return this.$store.getters['relation/isOwner']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    relations (): Array<AdministrationRelationModel> {
      return this.$store.getters['administration/administrationRelations']
    },
    filteredRelations (): Array<AdministrationRelationModel> {
      const relations = this.relations.filter(relation => relation.enterprise.active && relation.enterprise.getRelationName().toLowerCase().includes(this.search.toLowerCase()))
      return relations.sort((a, b) => a.enterprise.getRelationName().localeCompare(b.enterprise.getRelationName()))
    },
    impersonation (): null|ImpersonateModel {
      return this.$store.getters['user/impersonation']
    }
  },
  watch: {
    async menu () {
      if (this.menu) {
        const refs = this.$refs as any
        setTimeout(function () {
          refs.searchRelation.$refs.input.focus()
        }, 100)
      }
      if (this.menu && !this.relations.length) {
        this.isLoading = true
        await this.loadRelations()
        this.isLoading = false
      }
    }
  },
  async beforeMount () {
    if (!this.relations.length) {
      this.isLoading = true
      await this.loadRelations()
      this.isLoading = false
    }
  },
  methods: {
    async loadRelations () {
      if (this.impersonation !== null) {
        this.$store.dispatch('administration/setAdministrationRelations', this.impersonation.relations)
      } else {
        if (this.isOwner) {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelations', this.relation.id)
        } else {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelationsNonOwner', { relationId: this.relation.id, userId: this.user.id })
        }
      }
    },

    async impersonate (relationId: number) {
      this.switchRelationId = relationId
      await localAuthService.switchToAdministration(relationId, true)
      this.switchRelationId = null
      this.menu = false
      this.search = ''
      this.navigateToDashBoard()
    },

    async leaveImpersonated () {
      this.isLeaving = true
      await localAuthService.unImpersonate()
      this.isLeaving = false
      this.menu = false
      this.search = ''
      this.navigateToDashBoard()
    },

    navigateToDashBoard () {
      this.$router.push({ name: 'dashboard', query: { refresh: 'true' } })
    }
  }
})
