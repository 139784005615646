<template>
  <div id="auth">
    <div id="forgot-password">
      <forgot-password-component />
    </div>
  </div>
</template>

<script>
import ForgotPasswordComponent from '@/modules/auth/components/forgot-password/ForgotPasswordComponent.vue'

export default {
  components: {
    ForgotPasswordComponent
  }
}
</script>

<style scoped lang="scss">
  @import '@/styles/_globals';

  #forgot-password {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;

    @include desktop-up {
      top: 10vh;
    }
  }
</style>
