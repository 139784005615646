
import Vue from 'vue'

import SideNav from '@/modules/settings/views/account/components/SideNav.vue'
import Password from '@/modules/settings/views/account/password/components/ChangePasswordForm.vue'

export default Vue.extend({
  components: {
    SideNav,
    Password
  }
})
