
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { localRelationService } from '@/network/local/local-relation-service'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  props: {
    create: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      relationId: null,
      enterpriseRelation: new EnterpriseRelationRequestModel(),
      enterpriseRelationErrors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
      isCocChanged: false,
      file: null as any,
      previewLogoUrl: null as null|string,
      submitDisabled: true
    }
  },
  computed: {
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    if (!this.create) {
      this.getRelationData()
    }
  },
  methods: {
    async getRelationData () {
      this.previewLogoUrl = this.relation.logo_url

      this.enterpriseRelation = new EnterpriseRelationRequestModel().fromRelationModel(this.relation)
      this.disableSubmit()
    },

    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data as any
            this.enterpriseRelation.name = info.name
            this.enterpriseRelation.address = info.address
            this.enterpriseRelation.postal_code = info.postal_code
            this.enterpriseRelation.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.handleUnprocessableEntity(response.data)
          }
        })
      }
    },

    async createRelation () {
      await apiRelationService.createRelation(this.enterpriseRelation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const relation = new RelationModel().fromResponse(response.data.relation)
          if (this.file !== null) {
            await this.uploadLogo(relation.id)
          }
          new Notify().success('Gelukt!', 'Administratie is aangemaakt')
          this.$emit('created', relation)
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handleUnprocessableEntity(response.data)
        }
      })
    },
    async updateRelation () {
      await apiRelationService.updateRelation(this.enterpriseRelation.id, this.enterpriseRelation).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          new Notify().success('Gelukt!', 'Je instellingen zijn succesvol opgeslagen')
          this.returnUpdatedRelation()
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handleUnprocessableEntity(response.data)
        }
      })
    },

    handleUnprocessableEntity (data: any) {
      this.enterpriseRelationErrors = {
        ...this.enterpriseRelationErrors,
        ...data
      }
    },
    async onSelectedImage (e: any) {
      if (!this.create) {
        this.submitDisabled = true
      }
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        this.file = file

        if (this.enterpriseRelation.id) {
          await this.uploadLogo(this.enterpriseRelation.id)
          await this.returnUpdatedRelation()
          this.previewLogoUrl = this.relation.logo_url
        }
      }
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Logo mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },
    async uploadLogo (relationId: number) {
      const formData = new FormData()
      formData.append('file', this.file)

      await apiRelationService.uploadLogo(relationId, formData).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.file = null
        }
      })
    },
    async returnUpdatedRelation () {
      await localRelationService.getRelation()
      this.disableSubmit()
    },
    disableSubmit () {
      this.submitDisabled = true
    },
    submitForm (e: any) {
      e.preventDefault()
      this.clearErrors()

      const validationError = this.validateform()

      if (!validationError) {
        if (this.create) {
          this.createRelation()
        } else {
          this.updateRelation()
        }
      }
    },

    clearErrors () {
      this.enterpriseRelationErrors = entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel())
    },

    validateform () {
      let error = false

      if (!this.enterpriseRelation.email) {
        this.enterpriseRelationErrors.email.push('E-mailadres is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.tax_number) {
        this.enterpriseRelationErrors.tax_number.push('BTW nummer is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.bank_account) {
        this.enterpriseRelationErrors.bank_account.push('Bankrekening is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.address) {
        this.enterpriseRelationErrors.address.push('Adres is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.city) {
        this.enterpriseRelationErrors.city.push('Stad is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.postal_code) {
        this.enterpriseRelationErrors.postal_code.push('Postcode is verplicht.')
        error = true
      }

      if (this.relation.isEnterprise()) {
        if (!this.enterpriseRelation.name) {
          this.enterpriseRelationErrors.name.push('Bedrijfsnaam is verplicht.')
          error = true
        }
      }

      return error
    },
    getEmailAddress () {
      return 'administratie' + (this.isDev() ? '-dev' : '') + '.' + this.relation.id + '@daya.nl'
    },
    isDev (): boolean {
      return (window as any).appConfig.ENV === 'dev'
    }
  }
})
