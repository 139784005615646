
import Vue from 'vue'
import GreyArea from '@/globals/components/blank-slates/GreyArea.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { ShortcutModel } from '@/globals/models/Shortcut'
import { apiAuthService } from '@/network/api/api-auth-service'
import AddShortcut from './add-shortcut/AddShortcut.vue'
import Shortcut from './shortcut/Shortcut.vue'

export default Vue.extend({
  components: { Shortcut, PrimaryButton, GreyArea, SkeletonLoader, AddShortcut },
  data () {
    return {
      isLoading: true,
      shortcuts: [] as ShortcutModel[],
      isAddShortcutModalActive: false
    }
  },
  async beforeMount () {
    await this.fetchShortcuts()
    this.isLoading = false
  },
  methods: {
    async fetchShortcuts () {
      await apiAuthService.getShortcuts().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((shortcutRaw: any) => {
            const shortcut = new ShortcutModel().fromResponse(shortcutRaw)
            this.shortcuts.push(shortcut)
          })
        }
      })
    },

    onCreatedShortcut (shortcut: ShortcutModel) {
      this.shortcuts.push(shortcut)
      this.isAddShortcutModalActive = false
    },

    deleteShortcut (shortcut: ShortcutModel) {
      apiAuthService.deleteShortcut(shortcut.id)
      const index = this.shortcuts.findIndex(s => s.id === shortcut.id)

      if (index >= 0) {
        this.shortcuts.splice(index, 1)
      }
    }
  }
})
