import { LedgerAccount } from '../enums/LedgerAccount'
export class LedgerAccountModel {
  id = 0
  code = ''
  name = ''
  active = 1
  default_ledger_account = 0
  relation_default_description = ''
  relation_default_ledger_account_id = 0
  relation_default_vat_ledger_account_id = 0
  type = LedgerAccount.Balance
  vat_default_ledger_account_id = 0
  vat_is_sale = 0 as number
  vat_ledger_account_id = 0
  vat_percentage = 0

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.code = data.code
      this.name = data.name
      this.active = data.active
      this.default_ledger_account = data.default_ledger_account
      this.relation_default_description = data.relation_default_description
      this.relation_default_ledger_account_id = data.relation_default_ledger_account_id
      this.relation_default_vat_ledger_account_id = data.relation_default_vat_ledger_account_id
      this.type = data.type
      this.vat_default_ledger_account_id = data.vat_default_ledger_account_id
      this.vat_is_sale = data.vat_is_sale
      this.vat_ledger_account_id = data.vat_ledger_account_id
      this.vat_percentage = data.vat_percentage
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }

  getLedgerCodeName (): string {
    return this.code + ' - ' + this.name
  }
}
