
import { dateHelper } from '@/globals/helpers/DateHelper'
import Vue from 'vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    currentDate: {
      required: false
    },
    minDate: {
      type: String,
      required: false
    },
    maxDate: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      dateHelper,
      date: dateHelper.toDateString() as Array<string>|string,
      menu: false
    }
  },
  computed: {
    isCorrectRange (): boolean {
      return this.isRange && this.date.length === 2
    }
  },
  beforeMount () {
    if (this.isRange) {
      const dates = [] as Array<string>
      if (this.currentDate) {
        (this.currentDate as Array<any>).forEach(date => {
          if (date !== null) {
            dates.push(dateHelper.toDateString(date))
          }
        })
      } else {
        dates.push(dateHelper.toDateString())
      }
      this.date = dates
    } else {
      if (this.currentDate) {
        this.date = dateHelper.toDateString((this.currentDate as string|Date))
      }
    }
  },
  methods: {
    handleInput () {
      if (!this.isRange) {
        this.menu = false
        this.$emit('input', this.date)
      }
    }
  }
})
