
import Vue from 'vue'
import Company from '@/modules/relation/components/relation/company/Company.vue'
import Client from '@/modules/relation/components/client/Client.vue'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import Person from '../../components/relation/person/Person.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import InfoBar from '@/globals/components/blank-slates/InfoBar.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    Breadcrumbs,
    Company,
    Client,
    Person,
    InfoBar,
    PrimaryButton
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      selectedRelationType: 0,
      relationTypes: [
        { text: 'Onderneming', value: 0 },
        { text: 'Persoon', value: 1 }
      ],
      isEssentialsLoaded: false,
      organisations: null as null|any
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    if (this.isAccountant && this.selectedRelationType === 0) {
      this.loadEssentials()
    }
  },
  methods: {
    async loadEssentials () {
      await this.fetchOrganisations()
      this.isEssentialsLoaded = true
    },
    async fetchOrganisations () {
      await apiAdministrationService.getOrganisations().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.organisations = response.data
        }
      })
    },
    navigateToRelationOverview () {
      this.$router.push({ name: routeHelper.relationRoutes.relation.overview.name })
    },
    navigateToTwinfieldSettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.twinfield.name })
    }
  }
})
