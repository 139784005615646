const state = () => ({
  isLoading: false,
  isFullDayaLoader: false
})

const getters = {
  isLoading: (state: any) => {
    return state.isLoading
  },
  isFullDayaLoader: (state: any) => {
    return state.isFullDayaLoader
  }
}

const actions = {
  setIsLoading ({ commit }: any, isLoading: boolean) {
    commit('setIsLoading', isLoading)
  },
  setIsFullDayaLoader ({ commit }: any, isLoading: boolean) {
    commit('setIsFullDayaLoader', isLoading)
  }
}

const mutations = {
  setIsLoading (state: any, isLoading: boolean) {
    state.isLoading = isLoading
  },
  setIsFullDayaLoader (state: any, isLoading: boolean) {
    state.isFullDayaLoader = isLoading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
