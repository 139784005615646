
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import ClientForm from '@/globals/components/client/ClientForm.vue'
import LinkTwinfieldClientModal from '@/modules/administration/components/twinfield/LinkClient.vue'
import BookingLines from '@/modules/administration/components/documents/book-document/booking-details/booking-form/booking-lines/BookingLines.vue'

import { BookingItem } from '@/modules/administration/models/BookingItem'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { EnterpriseRelationTypeEnum } from '@/globals/enums/EnterpriseRelationType'
import { Notify } from '@/globals/controllers/Notification'
import { BookingItemLine } from '@/modules/administration/models/BookingItemLine'
import ActionButton from './action-button/ActionButton.vue'
import { platformHelper } from '@/globals/helpers/PlatformHelper'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { ClientRelationModel } from '@/globals/models/relation/ClientRelation'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { dateHelper } from '@/globals/helpers/DateHelper'
import BookingFormLogs from './logs/BookingFormLogs.vue'
import { DefaultLedgerAccountModel } from '@/globals/models/DefaultLedgerAccount'
import InvoicePreviewModal from './invoice-preview/InvoicePreviewModal.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconAddClient from '@/globals/components/icons/IconAddClient.vue'
import { ClientModel } from '@/globals/models/client/Client'

export default Vue.extend({
  props: {
    tag: {
      type: LedgerAccountModel,
      required: true
    },
    log: {
      required: true
    }
  },
  components: {
    PrimaryButton,
    InputField,
    DatePicker,
    Dropdown,
    BookingLines,
    ClientForm,
    LinkTwinfieldClientModal,
    ActionButton,
    BookingFormLogs,
    InvoicePreviewModal,
    IconBase,
    IconAddClient
  },
  data () {
    return {
      bookStore: this.$store.state.book,
      bookingItemErrors: entityHelper.generateErrorEntity(new BookingItem()) as any,
      isEssentialsLoaded: false,
      isLinesErrors: false,
      isAmountDifference: false,
      documentState: new DocumentState(),
      isClientModal: false,
      isUpdateClientModal: false,
      isLinkClientModal: false,
      ledgerAccountSelection: [] as Array<{value: number; text: string}>,
      clientRelations: [] as Array<{value: any; text: string}>,
      supplierRelations: [] as Array<{value: any; text: string}>,
      isSaveBookingLines: false,
      platformHelper,
      selectedClient: null as null|ClientRelationModel,
      dateHelper,
      isPreviewModalActive: false,
      isCurrenciesLoading: false,
      onSelectedClientCount: 0,
      isSelectedClientLoading: false,
      isRelationsLoading: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isNextBookingItem (): BookingItem|null {
      return this.$store.getters['book/isNextBookingItem']
    },
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    supplierId (): number|null {
      return this.currentBookingItem.supplier_relation_id
    },
    ledgerAccounts (): Array<LedgerAccountModel> {
      return this.$store.getters['administration/ledgerAccounts']
    },
    isDisabled (): boolean {
      return this.currentBookingItem.isBooked() || this.currentBookingItem.isInProgress()
    },
    isStateToBeBooked (): boolean {
      return this.documentState.TOBEBOOKED.value === this.currentBookingItem.status
    },
    isPurchaseTag (): boolean {
      return this.currentBookingItem.tag === 'INK'
    },
    isSaleTag (): boolean {
      return this.currentBookingItem.tag === 'VRK'
    },
    clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    },
    clientsList (): Array<any> {
      return this.isPurchaseTag ? this.supplierRelations : this.clientRelations
    },
    currencies (): Array<any> {
      return this.$store.getters['book/currencies']
    },
    isSelectedClientJustAdded (): boolean {
      const foundIndex = (this.bookStore.relationsAdded as Array<number>).findIndex(r => r === this.selectedClient?.client.id)
      return foundIndex >= 0
    }
  },
  watch: {
    tag () {
      this.setLedgerAccountIdByTag()
      if (!this.isDisabled) {
        this.validateSelectedRelation()
      }
    },
    currentBookingItem () {
      this.selectedClient = null
      this.setEssentials()
      this.scrollTop()
    },
    clients () {
      arrayHelper.clear(this.supplierRelations)
      arrayHelper.clear(this.clientRelations)
      this.buildRelationList(this.clients)
    },
    currencies () {
      if (!this.currentBookingItem.currency && this.currencies.length) {
        this.currentBookingItem.currency = this.currencies[0].code
      }
    }
  },
  async beforeMount () {
    this.isEssentialsLoaded = false
    this.setLedgerAccountSelection()

    if (!this.currencies.length) {
      this.setCurrencies()
    }

    if (!this.clients.length) {
      await this.$store.dispatch('client/setClients', this.relation.id)
    } else {
      this.buildRelationList(this.clients)
    }

    await this.setEssentials()
    this.isEssentialsLoaded = true
  },
  methods: {
    async setEssentials () {
      this.isEssentialsLoaded = false
      this.setBookingItemErrors()
      this.isClientModal = false
      this.isUpdateClientModal = false

      if (!this.currentBookingItem.ledger_account_id) {
        this.setLedgerAccountIdByTag()
      }
      if (this.currentBookingItem.supplier_relation_id) {
        await this.setSelectedClient(this.currentBookingItem.supplier_relation_id)

        if (!this.currentBookingItem.description) {
          this.currentBookingItem.description = this.selectedClient?.ledger_account?.default_description ?? ''
        }
      }

      if (!this.isDisabled) {
        this.validateSelectedRelation()
      }

      if (!this.currentBookingItem.currency && this.currencies.length) {
        this.currentBookingItem.currency = this.currencies[0].code
      }
      this.isEssentialsLoaded = true
    },
    async setSelectedClient (id: number) {
      const clientRelation = new ClientRelationModel()
      const client = this.clients.find(c => c.client.id === id)
      if (client) {
        clientRelation.client = client.client
        clientRelation.type = client.type
        clientRelation.ledger_account = await this.fetchLedgerAccount(id)
        this.selectedClient = clientRelation
      }
    },
    async fetchLedgerAccount (relationId: number): Promise<DefaultLedgerAccountModel|null> {
      let ledgerAccountModel = null
      this.isSelectedClientLoading = true
      await apiAdministrationService.getLedgerAccountByRelationId(relationId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          if (Object.keys(response.data).length > 0) {
            ledgerAccountModel = new DefaultLedgerAccountModel().fromResponse(response.data)
          }
        }
      }).finally(() => { this.isSelectedClientLoading = false })

      return ledgerAccountModel
    },
    async onSelectedClient (id: number) {
      await this.setSelectedClient(id)
      arrayHelper.clear(this.bookingItemErrors.supplier_relation_id)
      if (this.selectedClient !== null && this.selectedClient.ledger_account !== null && this.selectedClient.ledger_account.default_description !== null) {
        this.currentBookingItem.description = this.selectedClient.ledger_account.default_description
      } else {
        this.currentBookingItem.description = ''
      }
      if (!this.isDisabled) {
        this.validateSelectedRelation()
      }
      this.onSelectedClientCount++
    },

    scrollTop () {
      const scrollContainer = document.getElementById('booking-form-form')

      if (scrollContainer) {
        scrollContainer.scrollTo(0, 0)
      }
    },

    setLedgerAccountSelection () {
      this.ledgerAccounts.forEach(ledgerAccount => {
        if (ledgerAccount.type === LedgerAccount.Balance || ledgerAccount.type === LedgerAccount.ProfitAndLoss) {
          this.ledgerAccountSelection.push({ value: ledgerAccount.id, text: ledgerAccount.getLedgerCodeName() })
        }
      })
    },

    setLedgerAccountIdByTag () {
      const found = this.ledgerAccounts.find(ledgerAccount => ledgerAccount.id === this.tag.default_ledger_account)

      if (found) {
        this.currentBookingItem.ledger_account_id = found.id
      }
    },

    buildRelationList (clients: Array<ClientModel>) {
      clients.forEach(client => {
        const isSupplier = client.type === EnterpriseRelationTypeEnum.SUPPLIER
        this.addRelationToList(client.client, isSupplier)
      })
    },
    addRelationToList (relation: RelationModel, isSupplier: boolean) {
      const listRelation = {
        text: relation.getRelationName(),
        value: relation.id
      }
      if (isSupplier) {
        this.supplierRelations.push(listRelation)
      } else {
        this.clientRelations.push(listRelation)
      }
    },

    async onClientLinked (relationId: number) {
      this.addToLinkedDictionary(relationId)
      this.currentBookingItem.supplier_relation_id = relationId
      await this.validateSelectedRelation()
      this.hideLinkClientModal()
    },

    async onClientCreated (client: ClientModel) {
      if (client.client.id) {
        this.currentBookingItem.supplier_relation_id = client.client.id
        this.setSelectedClient(client.client.id)
        this.addToLinkedDictionary(client.client.id)
        await this.validateSelectedRelation()
      }
    },
    async onClientUpdated (client: ClientModel) {
      if (client.client.id) {
        this.setSelectedClient(client.client.id)
        this.validateSelectedRelation()
      }
    },
    addToLinkedDictionary (relationId: number) {
      this.bookStore.relationsAdded.push(relationId)
    },

    async validateSelectedRelation () {
      if (this.currentBookingItem.supplier_relation_id) {
        const relationId = this.currentBookingItem.supplier_relation_id
        this.validateRelationHasLedger()

        if (!this.bookStore.relationInvoiceIdsDict[relationId]) {
          await this.setRelationInvoiceIds(relationId)
        }

        this.handleValidInvoiceNumber()
      }
    },

    validateRelationHasLedger (): boolean {
      if (this.selectedClient && this.selectedClient.ledger_account === null && !this.isSelectedClientJustAdded) {
        this.bookingItemErrors.supplier_relation_id.push('Deze relatie is nog niet gekoppeld met Twinfield')
        return true
      }

      return false
    },

    async setRelationInvoiceIds (relationId: number) {
      this.isRelationsLoading = true
      await apiAdministrationService.getRelationInvoiceIds(relationId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.bookStore.relationInvoiceIdsDict[relationId] = []

          response.data.forEach((el: any) => {
            this.setInvoiceNumberInRelationInvoiceIdsDict(relationId, el.id, el.invoice_number)
          })
        }
      }).finally(() => { this.isRelationsLoading = false })
    },
    setInvoiceNumberInRelationInvoiceIdsDict (relationId: number, bookingItemId: number, invoiceNumber: string) {
      this.bookStore.relationInvoiceIdsDict[relationId].push({ bookingItemId, invoiceNumber })
    },

    handleValidInvoiceNumber () {
      if (!this.isValidInvoiceNumber()) {
        this.bookingItemErrors.invoice_number.push('')
      } else {
        (this.bookingItemErrors.invoice_number as string[]).forEach((error, index) => {
          if (error === '') {
            (this.bookingItemErrors.invoice_number as string[]).splice(index, 1)
          }
        })
      }
    },

    isValidInvoiceNumber (): boolean {
      if (!this.isDisabled && this.currentBookingItem.invoice_number && this.currentBookingItem.supplier_relation_id && this.bookStore.relationInvoiceIdsDict[this.currentBookingItem.supplier_relation_id]) {
        const foundIndex = (this.bookStore.relationInvoiceIdsDict[this.currentBookingItem.supplier_relation_id] as Array<any>).findIndex(invoice => invoice.invoiceNumber === this.currentBookingItem.invoice_number && invoice.bookingItemId !== this.currentBookingItem.id)

        if (foundIndex !== -1) {
          return false
        }
      }

      return true
    },

    showClientModal () {
      this.isClientModal = true
    },
    hideClientModal () {
      this.isClientModal = false
    },

    showUpdateClientModal () {
      this.isUpdateClientModal = true
    },
    hideUpdateClientModal () {
      this.isUpdateClientModal = false
    },

    showLinkClientModal () {
      this.isLinkClientModal = true
    },
    hideLinkClientModal () {
      this.isLinkClientModal = false
    },

    async submitForm () {
      const validationError = this.validateform()

      if (!validationError) {
        await this.book()
      } else {
        if (this.isAmountDifference) {
          new Notify().error('Er mag geen verschil zijn in het factuurbedrag.', 'Vul de juiste bedragen in')
        } else {
          new Notify().entityError()
        }
      }
    },

    validateform (): boolean {
      this.bookingItemErrors = entityHelper.generateErrorEntity(new BookingItem())
      let error = false

      if (!this.currentBookingItem.invoice_number) {
        this.bookingItemErrors.invoice_number.push('Factuurnummer is verplicht.')
        error = true
      }
      if (!this.currentBookingItem.date) {
        this.bookingItemErrors.date.push('Factuurdatum is verplicht.')
        error = true
      }
      if (!this.currentBookingItem.ledger_account_id) {
        this.bookingItemErrors.ledger_account_id.push('Grootboekrekening is verplicht.')
        error = true
      }
      if (!this.currentBookingItem.amount) {
        this.bookingItemErrors.amount.push('Bedrag is verplicht.')
        error = true
      }
      if (!this.currentBookingItem.relation) {
        this.bookingItemErrors.relation.push('Veld is verplicht.')
        error = true
      }
      if (this.currentBookingItem.currency == null) {
        this.bookingItemErrors.currency.push('Valuta is verplicht.')
        error = true
      }
      if (!this.currentBookingItem.description) {
        this.bookingItemErrors.description.push('Korte beschrijving is verplicht.')
        error = true
      }

      if (!this.currentBookingItem.supplier_relation_id) {
        this.bookingItemErrors.supplier_relation_id.push('Relatie is verplicht')
        error = true
      }

      if (!this.isValidInvoiceNumber()) {
        this.bookingItemErrors.invoice_number.push('Factuurnummer bestaat al')
        error = true
      }

      error = this.validateRelationHasLedger()

      error = this.validateLines()
      if (this.isAmountDifference) {
        error = true
      }

      return error
    },

    validateLines () {
      let error = false

      for (let i = 0; i < this.currentBookingItem.booking_lines.length; i++) {
        const element = this.currentBookingItem.booking_lines[i]
        this.bookingItemErrors.booking_lines[i] = entityHelper.generateErrorEntity(new BookingItemLine())

        if (!element.ledger_account_id) {
          this.bookingItemErrors.booking_lines[i].ledger_account_id.push('Grootboekrekening is verplicht.')
          error = true
        }

        if (!element.value || element.value === 0) {
          this.bookingItemErrors.booking_lines[i].value.push('Bedrag is verplicht.')
          error = true
        }
        if (!element.vat_ledger_account_id) {
          this.bookingItemErrors.booking_lines[i].vat_ledger_account_id.push('BTW is verplicht.')
          error = true
        }
      }
      return error
    },

    async updateBookingItem () {
      await apiAdministrationService.updateBookingItem(this.currentBookingItem, this.isSaveBookingLines).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          if (this.isSaveBookingLines) {
            // this.updateRelationDefaultBookingLines()
            this.isSaveBookingLines = false
          }
          const bookingItem = new BookingItem().fromResponse(response.data)
          this.$store.dispatch('book/updateCurrentBookingItem', bookingItem)
          new Notify().updated()
        }
      })
    },

    async setCurrencies () {
      this.isCurrenciesLoading = true
      await apiAdministrationService.fetchCurrencies().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const data = response.data as Array<{id: number; code: string; name: string }>
          this.$store.dispatch('book/setCurrencies', data)
        }
      }).finally(() => { this.isCurrenciesLoading = false })
    },

    async book () {
      await apiAdministrationService.book(this.currentBookingItem, this.isSaveBookingLines).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.setInvoiceNumberInRelationInvoiceIdsDict(this.currentBookingItem.supplier_relation_id ?? 0, this.currentBookingItem.id, this.currentBookingItem.invoice_number)

          if (this.isSaveBookingLines) {
            this.isSaveBookingLines = false
          }
          // const bookingItem = new BookingItem().fromResponse(response.data.bookingItem)
          this.currentBookingItem.status = this.documentState.INPROGRESS.value
          this.$store.dispatch('book/updateCurrentBookingItem', this.currentBookingItem)
          new Notify().success('Gelukt!', this.currentBookingItem.invoice_number + ' wordt verwerkt')

          if (this.isNextBookingItem) {
            this.goNextBookingItem()
          } else {
            this.$emit('close')
          }
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },

    setBookingItemErrors () {
      this.bookingItemErrors = entityHelper.generateErrorEntity(new BookingItem())
      if (this.currentBookingItem.booking_errors) {
        try {
          const errors = JSON.parse(this.currentBookingItem.booking_errors)
          this.mergeErrors(errors)
        } catch (error) {}
      }
    },
    mergeErrors (errors: any) {
      const errs = [] as Array<any>
      for (const [key, value] of Object.entries(errors)) {
        const property = key as any
        const propertyErrors = value as any
        if (property !== 'lines') {
          errs[property] = []

          if (typeof propertyErrors === 'string') {
            errs[property].push(propertyErrors)
          } else if (typeof propertyErrors === 'object') {
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(propertyErrors)) {
              errs[property].push(value)
            }
          }
        }
      }

      this.bookingItemErrors = {
        ...this.bookingItemErrors,
        ...errs
      }

      if (errors.lines) {
        this.mergeLineErrors(errors.lines)
      }
      if (errors.booking_lines) {
        this.mergeLineErrors(errors.booking_lines)
      }
    },
    mergeLineErrors (lines: any) {
      const bookLines = [] as Array<any>
      for (const [key, value] of Object.entries(lines)) {
        const lineIndex = parseInt(key)
        const properties = value as object
        bookLines[lineIndex] = []
        for (const [property, value] of Object.entries(properties)) {
          const errors = value
          bookLines[lineIndex][property] = []
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(errors)) {
            bookLines[lineIndex][property].push(value)
          }
        }
      }
      this.bookingItemErrors.booking_lines = bookLines
    },

    goNextBookingItem () {
      this.$store.commit('book/goToNextBookingItem')
    }
  }
})
