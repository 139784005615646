
import Vue from 'vue'
import Draggable from 'vuedraggable'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  props: {
    bookingItems: {
      type: Array,
      required: true
    }
  },
  components: {
    Draggable,
    PrimaryModal,
    PrimaryButton
  },
  data () {
    return {
      bookStore: this.$store.state.book,
      dialogStore: this.$store.state.dialog,
      files: [] as Array<{ bookingItem: BookingItem; isSelected: boolean }>,
      dragOptions: {
        animation: 200,
        ghostClass: 'drop--ghost',
        direction: 'horizontal',
        forceFallback: true
      },
      isDragging: false,
      selected: ''
    }
  },
  beforeMount () {
    this.bookingItems.forEach((bookingItem: any) => {
      this.files.push(
        {
          bookingItem: bookingItem as BookingItem,
          isSelected: true
        }
      )
    })
  },
  methods: {
    async onAdd (e: any) {
      e.item.classList.add('display--none')
    },

    async mergeFiles () {
      const ids = this.getIdsFromArray()
      if (ids.length > 1) {
        const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Deze ' + ids.length + ' items samenvoegen?' })
        if (isConfirmed) {
          await apiAdministrationService.mergeBookingItems(ids).then((response: any) => {
            if (response.status === HttpStatus.CREATED) {
              this.$emit('merged')
            }
          })
        }
      } else {
        new Notify().error('Samenvoegen', 'Selecteer minimaal twee bestanden')
      }
    },

    getIdsFromArray (): Array<number> {
      const ids = [] as Array<number>

      this.files.forEach((file) => {
        if (file.isSelected) {
          ids.push(file.bookingItem.id)
        }
      })

      return ids
    }
  }
})

