
import Vue from 'vue'
import UserModal from './user-modal/UserModal.vue'
import { AdministrationUser } from '@/globals/models/user/AdministrationUser'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { RoleEnum } from '@/globals/enums/Role'
import { AdministrationUserInviteStatusEnum } from '@/globals/enums/AdministrationUserStatus'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'

export default Vue.extend({
  components: {
    PrimaryButton,
    UserModal,
    Avatar,
    IconBase,
    IconPlus
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      role: RoleEnum,
      userStatus: AdministrationUserInviteStatusEnum,
      headers: [
        { text: 'Contactpersoon', value: 'name' },
        { text: 'E-mail', value: 'email' }
      ],
      users: [] as Array<AdministrationUser>,
      selectedUser: null as null|AdministrationUser,
      isUserModalActive: false,
      relations: [] as Array<RelationModel>
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant']
    },
    administrationRelations (): Array<AdministrationRelationModel> {
      return this.$store.getters['administration/administrationRelations']
    }
  },
  watch: {
    administrationRelations () {
      this.setRelations(this.administrationRelations)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false

      if (this.isAccountant && this.relationStore.isOwner) {
        if (!this.administrationRelations.length) {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelations', this.relation.id)
        } else {
          this.setRelations(this.administrationRelations)
        }
      }
      await this.fetchUsers()
      this.essentialsLoaded = true
    },

    async setRelations (admininistrationRelations: Array<AdministrationRelationModel>) {
      arrayHelper.clear(this.relations)
      admininistrationRelations.forEach((administrationRelation: AdministrationRelationModel) => {
        this.relations.push(administrationRelation.enterprise)
      })
    },

    async fetchUsers () {
      await apiAuthService.getUsers().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.users = []
          this.buildTable(response.data)
        }
      })
    },
    buildTable (data: any) {
      data.forEach((item: any) => {
        const user = new AdministrationUser(item)
        this.addUserToTable(user)
      })
    },
    addUserToTable (user: AdministrationUser) {
      this.users.push(user)
    },

    editUser (user: AdministrationUser) {
      this.selectedUser = user
      this.showAddUserModal()
    },
    deleteUser (user: AdministrationUser) {
      const index = this.users.indexOf(user)
      if (index >= 0) {
        this.users.splice(index, 1)
      }
    },

    unsetSelectedUser () {
      this.selectedUser = null
    },
    showAddUserModal () {
      this.isUserModalActive = true
    },
    hideAddUserModal () {
      this.isUserModalActive = false
    }
  }
})
