var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"onboarding-stepper"}},[_c('div',{class:{
    'step': true,
    'is--done': _vm.step > 1,
    'is__active': _vm.step === 1
    }},[_c('span',[_vm._v("Welkom")]),(_vm.step === 1)?_c('i',{staticClass:"fas fa-dot-circle"}):_c('i',{staticClass:"far fa-check-circle"})]),_c('hr'),_c('div',{class:{
    'step': true,
    'is--done': _vm.step > 2,
    'is__active': _vm.step === 2
    }},[_c('span',[_vm._v("Relaties")]),(_vm.step <= 2)?_c('i',{staticClass:"far fa-circle"}):_c('i',{staticClass:"far fa-check-circle"})]),_c('hr'),_c('div',{class:{
    'step': true,
    'is__active': _vm.step === 3
    }},[_c('span',[_vm._v("Finished")]),(_vm.step !== 3)?_c('i',{staticClass:"far fa-circle"}):_c('i',{staticClass:"far fa-check-circle"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }