import { RelationModel } from '@/globals/models/relation/Relation'
import { UserModel } from '@/globals/models/user/User'

export class QuestionModel {
  id = 0
  booking_item_id = 0
  message = ''
  relation_id = 0
  relation = new RelationModel()
  user_id = 0
  user = new UserModel()
  created_at = ''

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: QuestionModel) {
    if (data) {
      this.id = data.id
      this.booking_item_id = data.booking_item_id
      this.message = data.message
      this.relation_id = data.relation_id
      this.user_id = data.user_id
      this.user = data.user
      this.created_at = data.created_at
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    if (data.user) {
      this.user = new UserModel().fromResponse(data.user)
    }

    if (data.relation) {
      this.relation = new RelationModel().fromResponse(data.relation)
    }

    return this
  }
}
