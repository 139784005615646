
import Vue from 'vue'
import Navbar from './globals/components/navbar/Navbar.vue'
import ConfirmDialog from './globals/components/confirm-dialog/ConfirmDialog.vue'
import update from '@/globals/mixins/update'
import FullDayaLoader from './globals/components/loaders/FullDayaLoader.vue'
import { routeHelper } from './globals/helpers/RouteHelper'

export default Vue.extend({
  components: { ConfirmDialog, Navbar, FullDayaLoader },
  data () {
    return {
      dialogStore: this.$store.state.dialog
    }
  },
  mixins: [update],
  computed: {
    isNavbarActive (): boolean {
      return !routeHelper.getNoNavBarRouteNames().includes(this.$route.name ?? '')
    },
    isFullLoaderActive (): boolean {
      return this.$store.getters['loading/isFullDayaLoader']
    }
  },
  mounted () {
    this.dialogStore.confirmDialog = this.$refs.dialog
  }
})
