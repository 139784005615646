
import Avatar from '@/globals/components/avatar/Avatar.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconCircleCheck from '@/globals/components/icons/IconCircleCheck.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { UserModel } from '@/globals/models/user/User'
import { TaskModel } from '@/modules/task/models/Task'
import Vue from 'vue'
export default Vue.extend({
  components: { IconBase, IconCircleCheck, Avatar },
  props: {
    tasks: {
      type: Array,
      required: true
    },
    quarter: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      required: true
    }
  },
  computed: {
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    isFinished (): boolean {
      return (new Date().getFullYear() > this.year || (new Date().getFullYear() === this.year && dateHelper.getQuarter(new Date()) > this.quarter)) && this.openTasks.length === 0
    },
    openTasks (): Array<TaskModel> {
      return (this.tasks as TaskModel[]).filter(task => this.filterQuarter(task, this.quarter))
    }
  },
  methods: {
    filterQuarter (task: TaskModel, quarter: number): boolean {
      return task.period_at !== null && dateHelper.getQuarter(task.period_at) === quarter
    },
    goToTask (task: TaskModel) {
      this.$store.dispatch('task/setSelectedTask', task)
      this.$router.push({ name: routeHelper.taskRoutes.task.overview.name })
    }
  }
})
