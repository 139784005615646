import Vue from 'vue'
import Vuetify, {
  VRow,
  VCol
} from 'vuetify/lib'
import nl from './lang/nl'
import { Ripple } from 'vuetify/lib/directives'
import VuetifyChevronRight from './icons/VuetifyChevronRight.vue'

Vue.use(Vuetify, { directives: { Ripple }, components: { VRow, VCol } })

export default new Vuetify(
  {
    lang: {
      locales: { nl },
      current: 'nl'
    },
    theme: {
      themes: {
        light: {
          primary: '#23253B',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FD4154',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        }
      }
    },
    icons: {
      values: {
        next: {
          component: VuetifyChevronRight
        }
        // dropdown: {
        //   component: VuetifyChevronDown
        // }
      }
    }
  }
)
