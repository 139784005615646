
import Vue from 'vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { UserModel } from '@/globals/models/user/User'
import { RelationModel } from '@/globals/models/relation/Relation'
import IconBase from '../../icons/IconBase.vue'
import IconChevronDown from '../../icons/IconChevronDown.vue'
import Avatar from '../../avatar/Avatar.vue'
export default Vue.extend({
  components: { IconBase, IconChevronDown, Avatar },
  data () {
    return {
      isLoading: false,
      userStore: this.$store.state.user
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    }
  },
  methods: {
    navigateToCompanySettings () {
      // eslint-disable-next-line
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name }).catch(err => { })
    },
    navigateToProfile () {
      // eslint-disable-next-line
      this.$router.push({ name: routeHelper.settingsRoutes.settings.account.profile.name }).catch(err => {})
    },
    navigateToAdministrationSettings () {
      // eslint-disable-next-line
      this.$router.push({ name: routeHelper.settingsRoutes.settings.administration.overview.name }).catch(err => {})
    },
    logout () {
      localStorage.clear()
      sessionStorage.clear()
      location.href = '/'
    },

    async leaveImpersonated () {
      this.isLoading = true
      this.$emit('leave_impersonated')
    }
  }
})
