
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { downloadHelper } from '@/globals/helpers/DownloadHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { apiInvoiceService } from '@/network/api/api-invoice-service'

export default Vue.extend({
  components: { PrimaryButton },
  computed: {
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    }
  },
  methods: {
    isImage (url: string): boolean {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
    },
    isPdf (url: string): boolean {
      return url.endsWith('.pdf')
    },

    openUrl (url: string) {
      downloadHelper.openUrl(url)
    },

    async deleteAttachment (id: number) {
      if (this.invoice.id) {
        await apiInvoiceService.deleteAttachment(this.invoice.id, id).then((response: any) => {
          if (response.status === HttpStatus.DELETED) {
            this.removeFromAttachments(id)
            this.$emit('deleted')
            new Notify().success('Gelukt!', 'Bijlage is verwijderd')
          }
        })
      }
    },
    removeFromAttachments (id: number) {
      for (let index = 0; index < this.invoice.attachments.length; index++) {
        const element = this.invoice.attachments[index]
        if (element.id === id) {
          this.invoice.attachments.splice(index, 1)
          break
        }
      }
    }
  }

})
