import { UserModel } from '@/globals/models/user/User'
import { BookingItemNote } from './BookingItemNote'

export class BookingItemLog {
  booked = null as null|{ date: string; user: null|UserModel }
  tag = null as null|{ date: string; user: null|UserModel }
  upload = null as null|{ date: string; user: null|UserModel }
  notes = [] as Array<BookingItemNote>

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: BookingItemLog) {
    if (data) {
      this.booked = data.booked
      this.tag = data.tag
      this.upload = data.upload
      this.notes = data.notes
    }
  }

  fromResponse (data: BookingItemLog) {
    this.setData(data)

    if (this.booked !== null && this.booked.user !== null) {
      this.booked.user = new UserModel().fromResponse(this.booked.user)
    }
    if (this.tag !== null && this.tag.user !== null) {
      this.tag.user = new UserModel().fromResponse(this.tag.user)
    }
    if (this.upload !== null && this.upload.user !== null) {
      this.upload.user = new UserModel().fromResponse(this.upload.user)
    }
    if (data.notes.length) {
      const notes = [] as Array<BookingItemNote>
      data.notes.forEach(noteRaw => {
        const note = new BookingItemNote().fromResponse(noteRaw)
        notes.push(note)
      })
      this.notes = notes
    }

    return this
  }
}
