interface Options {
    text: string;
    value: number;
}
export class InvoiceRepeat {
  weekly: Options = {
    text: 'Iedere week',
    value: 0
  }

  monthly: Options = {
    text: 'Iedere maand',
    value: 1
  }

  quarterly: Options = {
    text: 'Ieder kwartaal',
    value: 2
  }

  halfyearly: Options = {
    text: 'Ieder half jaar',
    value: 3
  }

  yearly: Options = {
    text: 'Ieder jaar',
    value: 4
  }

  getInvoiceRepeats () {
    return [
      this.weekly,
      this.monthly,
      this.quarterly,
      this.halfyearly,
      this.yearly
    ]
  }

  getStateByValue (value: number) {
    const states = this.getInvoiceRepeats()

    let finalState = states[0]

    states.forEach((state: any) => {
      if (state.value === value) {
        finalState = state
      }
    })

    return finalState
  }

  getStateByText (text: string) {
    const states = this.getInvoiceRepeats()

    let finalState = states[0]

    states.forEach((state: any) => {
      if (state.text === text) {
        finalState = state
      }
    })

    return finalState
  }

  getTextByValue (value: number): string {
    return this.getStateByValue(value).text as string
  }

  getValueByText (text: string) {
    return this.getStateByText(text).value as any
  }
}
