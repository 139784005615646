
import Vue from 'vue'
import { AdministrationUser } from '@/globals/models/user/AdministrationUser'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiRelationService } from '@/network/api/api-relation-service'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { RoleEnum } from '@/globals/enums/Role'

export default Vue.extend({
  props: {
    users: {
      type: Array,
      required: true
    },
    currentUser: {
      type: Object,
      required: false
    },
    relations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton,
    PrimaryModal
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      dialogStore: this.$store.state.dialog,
      isEssentialsLoaded: false,
      user: new AdministrationUser(),
      userErrors: entityHelper.generateErrorEntity(new AdministrationUser()) as any,
      isDisabled: true,
      isNewUser: true,
      isAddUserModalActive: false,
      search: '',
      data: [] as Array<{ id: number; name: string; type: number|null }>,
      headers: [
        {
          text: 'Onderneming',
          align: 'start',
          value: 'name',
          width: '50%'
        },
        { text: 'Boekhouding', value: 'isAdministration', sortable: false },
        { text: 'Facturatie', value: 'isInvoicing', sortable: false }
      ],
      selected: [] as Array<{ id: number; name: string; type: number|null }>,
      roles: [
        {
          text: 'Facturatie',
          value: RoleEnum.ENTERPRISE
        },
        {
          text: 'Boekhouding',
          value: RoleEnum.ACCOUNTANT
        }
      ]
    }
  },
  watch: {
    selected () {
      if (this.isEssentialsLoaded) {
        this.isDisabled = false
      }

      for (let i = 0; i < this.selected.length; i++) {
        const item = this.selected[i]
        if (item.type === null) {
          item.type = RoleEnum.ACCOUNTANT
        }
      }

      for (let i = 0; i < this.data.length; i++) {
        const selectedIndex = this.selected.findIndex(selected => selected.id === this.data[i].id)

        if (selectedIndex === -1) {
          this.data[i].type = null
        }
      }
    }
  },
  computed: {
    isEditUser (): boolean {
      return this.currentUser !== null
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant']
    }
  },
  beforeMount () {
    if (this.currentUser) {
      this.user = new AdministrationUser(this.currentUser)
      this.isNewUser = false
    }
    this.user.role = this.isAccountant ? RoleEnum.ACCOUNTANT : RoleEnum.ENTERPRISE
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      if (this.isAccountant && this.relationStore.isOwner && this.isEditUser) {
        await this.fetchRelationsForThisUser()
      } else {
        this.setData()
      }
      this.isEssentialsLoaded = true
    },
    setData () {
      (this.relations as Array<RelationModel>).forEach((relation: RelationModel) => {
        this.data.push(
          {
            id: relation.id,
            name: relation.getRelationName(),
            type: null
          }
        )
      })
    },
    async fetchRelationsForThisUser () {
      await apiRelationService.getAdministrationNonOwner(this.relation.id, this.user.id ?? 0).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const relations = [] as Array<AdministrationRelationModel>

          response.data.forEach((res: any) => {
            relations.push(new AdministrationRelationModel().fromResponse(res))
          })
          this.setData()
          this.setCurrentRelations(relations)
        }
      })
    },
    setCurrentRelations (relations: Array<AdministrationRelationModel>) {
      relations.forEach((relation: AdministrationRelationModel) => {
        const found = this.data.find(rel => rel.id === relation.enterprise.id)

        if (found) {
          found.type = relation.user_relation.type
          this.selected.push(found)
        }
      })
    },

    onSelectedType (item: any) {
      this.isDisabled = false
      const selectedIndex = this.selected.findIndex(selected => selected.id === item.id)

      if (item.type !== null && selectedIndex === -1) {
        this.selected.push(item)
      } else if (item.type === null && selectedIndex >= 0) {
        this.selected.splice(selectedIndex, 1)
      }
    },
    async submitForm () {
      if (!this.isDisabled && !this.isValidationError()) {
        if (this.isNewUser) {
          await this.createUser()
        } else {
          await this.updateUser()
        }
      }
    },
    isValidationError (): boolean {
      this.userErrors = entityHelper.generateErrorEntity(new AdministrationUser())

      let error = false

      if (!this.user.name) {
        this.userErrors.name.push('Voornaam is verplicht.')
        error = true
      }
      if (!this.user.last_name) {
        this.userErrors.last_name.push('Achternaam is verplicht.')
        error = true
      }
      if (!this.user.email) {
        this.userErrors.email.push('Email is verplicht.')
        error = true
      }
      if (this.isAccountant && this.relationStore.isOwner && !this.selected.length) {
        new Notify().error('Fout', 'Selecteer minimaal één onderneming')
        error = true
      }

      if (this.isNewUser) {
        this.users.forEach((userRaw: any) => {
          const user = new AdministrationUser(userRaw)
          if (user.email === this.user.email) {
            this.userErrors.email.push('Deze gebruiker bestaat al')
            error = true
          }
        })
      }

      return error
    },
    async createUser () {
      await apiAuthService.createUser(this.user).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          this.user.id = response.data.user.id

          if (this.isAccountant && this.relationStore.isOwner) {
            await this.saveAdministrations()
          }
          new Notify().success('Gelukt!', this.user.name + ' is toegevoegd')
          this.$emit('created', this.user)
        }
      })
    },

    async updateUser () {
      await apiAuthService.updateForUser(this.user, this.user.id ?? 0)
      if (this.isAccountant && this.relationStore.isOwner) {
        await this.saveAdministrations()
      }
      new Notify().updated()
      this.$emit('updated', this.user)
    },
    async saveAdministrations () {
      await apiRelationService.addAdministrationsToUser(this.relation.id, this.user.id ?? 0, this.selected)
    },

    async deleteUser () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: this.user.name + ' verwijderen?', isDeleting: true })

      if (isConfirmed) {
        await apiRelationService.deleteUserFromAdministration(this.relation.id, this.user.id ?? 0).then(async (response: any) => {
          if (response.status === HttpStatus.OK) {
            new Notify().success('Gelukt!', this.user.name + ' is verwijderd')
            this.$emit('deleted')
          }
        })
      }
    }
  }
})
