
import Vue from 'vue'

import { InvoiceSend } from '@/modules/invoicing/models/invoice/InvoiceSend'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import TextArea from '@/globals/components/forms/TextArea.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { Notify } from '@/globals/controllers/Notification'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import EditSendTemplate from './edit-send-template/EditSendTemplate.vue'
import { InvoiceTemplateModel } from '@/globals/models/invoice/InvoiceTemplate'
import ComboBox from '@/globals/components/forms/ComboBox.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'

export default Vue.extend({
  components: {
    PrimaryModal,
    TextArea,
    PrimaryButton,
    EditSendTemplate,
    ComboBox
  },
  props: {
    invoiceNumber: {
      type: String,
      required: true
    },
    relationTo: {
      type: RelationModel,
      required: true
    },
    isReminder: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      emails: [] as Array<string>,
      relationStore: this.$store.state.relation,
      invoicingStore: this.$store.state.invoicing,
      dateHelper,
      isEssentialsLoaded: false,
      invoiceTemplate: new InvoiceTemplateModel(),
      invoiceState: new InvoiceStatus(),
      invoiceSend: new InvoiceSend(),
      invoiceSendErrors: entityHelper.generateErrorEntity(new InvoiceSend()) as any,
      isToSendByEmail: true,
      isEditSendTemplate: false,
      isClickedManually: false
    }
  },
  computed: {
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    invoiceAmount (): string {
      return priceHelper.getEURFormat(this.invoice.total)
    },
    amountOpen (): string {
      return priceHelper.getEURFormat(this.invoice.total - this.invoice.amount_paid)
    }
  },
  async beforeMount () {
    const emailRelationTo = this.relationTo.getInvoiceEmail()
    if (emailRelationTo) {
      this.invoiceSend.emails.push(emailRelationTo.trim())
    }
    this.invoiceSend.is_reminder = this.isReminder
    await this.loadEssentials()
    this.invoiceSend.message = this.getMessage()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.setInvoiceTemplate()
      this.isEssentialsLoaded = true
    },
    async setInvoiceTemplate () {
      await apiInvoiceService.getInvoiceTemplate().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          if (response.data) {
            this.invoiceTemplate = new InvoiceTemplateModel().fromResponse(response.data)
          }
        }
      })
    },

    getMessage (): string {
      const message = this.getParsedTemplate(this.invoiceTemplate.id ? this.invoiceTemplate.template : this.getDefaultTemplate())
      return message
    },

    getParsedTemplate (template: string): string {
      const lines = new Map<string, string>()
      lines.set('{from_company_name}', this.relation.getRelationName())
      lines.set('{bank_account}', this.relation.bank_account)
      lines.set('{from_contact_name}', this.relation.getContactName())
      lines.set('{from_email}', this.relation.email)
      lines.set('{to_company_name}', this.relationTo.getRelationName())
      lines.set('{to_contact_first_name}', this.relationTo.getContactFirstName())
      lines.set('{to_contact_last_name}', this.relationTo.getContactLastName())
      lines.set('{invoice_amount}', this.invoiceAmount)

      let parsedTemplate = template

      for (const [key, value] of lines.entries()) {
        parsedTemplate = parsedTemplate.replaceAll(key, value)
      }

      return parsedTemplate
    },

    getDefaultTemplate (): string {
      const defaultMessage = 'Beste {to_company_name},\n\n' +
      'Namens {from_company_name} ontvang je hierbij factuur {invoice_number} met een totaalbedrag van € {invoice_amount}. ' +
      'Het bovenstaande totaalbedrag kun je onder vermelding van het factuurnummer op ons rekeningnummer {bank_account} overmaken.\n\n' +
      'Wanneer je vragen hebt over deze factuur verzoeken wij je contact op te nemen met {from_contact_name} van {from_company_name}. Dit kun je doen door te reageren op deze mail of een mail te sturen naar {from_email}.\n\n' +
      'Met vriendelijke groet,\n\n' +
      '{from_contact_name}\n' +
      '{from_company_name}'

      return defaultMessage
    },

    submitForm (e: any) {
      e.preventDefault()

      if (this.isReminder) {
        if (!this.validationReminderErrors()) { this.sendRemider() }
      } else {
        if (this.isToSendByEmail) {
          if (!this.validationErrors()) { this.sendByEmail() }
        } else {
          this.stateToSent()
        }
      }
    },
    validationErrors () {
      this.invoiceSendErrors = entityHelper.generateErrorEntity(new InvoiceSend())
      let error = false

      if (this.invoiceSend.emails.length === 0) {
        this.invoiceSendErrors.emails.push('Minimaal 1 e-mailadres is verplicht')
        error = true
      }

      if (!this.invoiceSend.message) {
        this.invoiceSendErrors.message.push('Bericht is verplicht')
        error = true
      }

      return error
    },
    validationReminderErrors () {
      this.invoiceSendErrors = entityHelper.generateErrorEntity(new InvoiceSend())
      let error = false

      if (this.invoiceSend.emails.length === 0) {
        this.invoiceSendErrors.emails.push('Minimaal 1 e-mailadres is verplicht')
        error = true
      }

      return error
    },

    async sendByEmail () {
      for (let i = 0; i < this.invoiceSend.emails.length; i++) {
        this.invoiceSend.emails[i] = this.invoiceSend.emails[i].trim()
      }

      await apiInvoiceService.sendInvoice(this.invoice.id, this.invoiceSend).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const invoice = new Invoice().fromResponse(response.data.invoice)
          if (this.invoice.isStatusSavedConcept()) {
            this.invoice.status = invoice.status
            this.invoice.prefix = invoice.prefix
            this.invoice.prefix_id = invoice.prefix_id
            new Notify().success('Gelukt!', 'Factuur is verzonden naar ' + this.invoiceSend.emails.toString())
            this.$emit('sent')
          } else {
            new Notify().success('Gelukt!', 'Factuur is opnieuw verzonden naar ' + this.invoiceSend.emails.toString())
            this.$emit('close')
          }
        }
      })
    },
    async sendRemider () {
      await apiInvoiceService.sendInvoiceReminders([this.invoice.id ?? 0], this.invoiceSend.emails).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.invoice.reminder_sent++
          new Notify().success('Gelukt!', 'Herinnering voor de factuur is verzonden naar ' + this.invoiceSend.emails.toString())
          this.$emit('sent')
        }
      })
    },
    async stateToSent () {
      if (!this.isClickedManually) {
        new Notify().success('Gelukt!', this.invoiceNumber + ' is verzonden')
        this.isClickedManually = true
        await apiInvoiceService.invoiceStateSent(this.invoice.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            const invoice = new Invoice().fromResponse(response.data.invoice)
            this.invoice.status = invoice.status
            this.invoice.prefix = invoice.prefix
            this.invoice.prefix_id = invoice.prefix_id
            this.$emit('sentManual')
          }
        })
      } else {
        this.$emit('close')
      }
    },

    onUpdatedTemplate (invoiceTemplate: InvoiceTemplateModel) {
      this.invoiceTemplate = invoiceTemplate
      this.invoiceSend.message = this.getParsedTemplate(invoiceTemplate.template)
      this.isEditSendTemplate = false
    }
  }
})

