
import Vue from 'vue'
import LoginComponent from '@/modules/auth/components/login/LoginComponent.vue'

export default Vue.extend({
  components: {
    LoginComponent
  },
  data () {
    return {
      redirect: ''
    }
  },
  beforeMount () {
    this.redirect = this.$route.params.redirect ?? ''
  }
})
