
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import InvoiceSendComponent from '../invoice-send/InvoiceSendComponent.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    PrimaryButton,
    InvoiceSendComponent
  },
  props: {
    relationTo: {
      required: true
    },
    isSubmitDisabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      invoiceState: new InvoiceStatus(),
      showSendModal: false,
      isReminder: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    },
    invoiceNumber (): string {
      return this.invoice.getInvoiceNumber(this.relation.invoice_prefix, this.isStateConcept)
    },
    isStateConcept (): boolean {
      return this.invoice.status === this.invoiceState.concept.value
    },
    isStatusMinimalSent (): boolean {
      return this.invoice.status >= this.invoiceState.sent.value
    },
    isDisabled (): boolean {
      return !this.isSubmitDisabled || this.relation.address === ''
    }
  },
  methods: {
    onClickSend () {
      this.isReminder = false
      this.showSendModal = true
    },
    navigateToInvoices () {
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.overview.name })
    }
  }
})
