
import Vue from 'vue'

import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { QuestionModel } from '@/modules/question/models/Question'
import { dateHelper } from '@/globals/helpers/DateHelper'

export default Vue.extend({
  components: {
    Avatar,
    BlankSlate
  },
  data () {
    return {
      isLoaded: false,
      questions: [] as QuestionModel[]
    }
  },
  computed: {
    latestQuestions (): QuestionModel[] {
      const questions = this.questions

      if (questions.length > 3) {
        questions.length = 3
      }

      return questions
    }
  },
  beforeMount () {
    this.fetchQuestions()
    this.isLoaded = true
  },
  methods: {
    async fetchQuestions () {
      await apiAdministrationService.getPendingLogs().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((questionRaw: any) => {
            const question = new QuestionModel().fromResponse(questionRaw)
            this.questions.push(question)
          })
        }
      })
    },

    toDateTimeFormat (date: string): string {
      return dateHelper.toFullDateHourMinute(date)
    }
  }
})
