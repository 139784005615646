
import Vue from 'vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { TaskModel } from '@/modules/task/models/Task'
import { UserModel } from '@/globals/models/user/User'
import InputField from '@/globals/components/forms/InputField.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'

export default Vue.extend({
  components: {
    Dropdown,
    InputField,
    IconBase,
    IconLookingGlass
  },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      sharedActions: [
        { value: null, text: 'Alles' },
        { value: 'none', text: 'Geen' },
        { value: 'me', text: 'Gedeeld met mij' },
        { value: 'others', text: 'Gedeeld met anderen' }
      ],
      search: ''
    }
  },
  computed: {
    allTasks (): Array<TaskModel> {
      return this.$store.getters['task/getCombinedTaskLists']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    filterAssigned (): Array<{ name: string; id: number|null }> {
      const assigned = [] as Array<{ name: string; id: number|null }>
      assigned.push({ id: null, name: 'Alles' })
      assigned.push({ id: this.user.id, name: 'Jezelf' })

      for (let i = 0; i < this.allTasks.length; i++) {
        const task = this.allTasks[i]
        const id = task.getAssignedTo().user_id
        const name = task.getAssignedTo().user.getName()

        const foundIndex = assigned.findIndex(user => user.id === id)
        if (foundIndex === -1) {
          assigned.push({ id, name })
        }
      }

      return assigned
    },
    filterRelationTo (): Array<{ name: string; id: number|null }> {
      const relations = [] as Array<{ name: string; id: number|null }>
      relations.push({ id: null, name: 'Alles' })
      relations.push({ id: 0, name: 'Geen' })

      for (let i = 0; i < this.allTasks.length; i++) {
        const task = this.allTasks[i]
        if (task.relation_to) {
          const id = task.relation_to.id
          const name = task.relation_to.getRelationName()

          if (id) {
            relations.push({ id, name })
          }
        }
      }

      return relations
    }
  },
  methods: {
    isCreatedByMe (task: TaskModel): boolean {
      return task.isCreatedByUser(this.user.id)
    }
  }
})
