
import Vue from 'vue'
import InvoiceComponent from '@/modules/invoicing/components/invoice/InvoiceComponent.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InfoBar from '@/globals/components/blank-slates/InfoBar.vue'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { BookingItemLine } from '@/modules/administration/models/BookingItemLine'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { ClientModel } from '@/globals/models/client/Client'

export default Vue.extend({
  components: {
    PrimaryButton,
    InfoBar,
    InvoiceComponent
  },
  data () {
    return {
      invoicingStore: this.$store.state.invoicing,
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      essentialsLoaded: false,
      invoiceId: null as any,
      invoiceState: new InvoiceStatus()
    }
  },
  computed: {
    invoice: {
      get (): Invoice {
        return this.invoicingStore.invoice ?? new Invoice()
      },
      set (invoice: Invoice) {
        this.invoicingStore.invoice = invoice
        return invoice
      }
    },
    _clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    },
    invoiceNumber (): string {
      let number = ''

      if (this.invoice) {
        number = this.invoice.getInvoiceNumber(this.relation.invoice_prefix, this.invoice.isStatusConcept())

        if (this.invoice.recurrence.id && this.invoice.id === this.invoice.recurrence.base_invoice_id) {
          number = 'Basis template'
        }
      }

      return number
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    ledgerAccounts (): Array<any> {
      return this.$store.getters['administration/ledgerAccounts']
    }
  },
  beforeMount () {
    this.invoiceId = parseInt(this.$route.params.id)
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      if (!this.invoice.id || this.invoice.recurrence_id || this.invoiceId !== this.invoice.id) {
        await this.getInvoice()
      }

      if (!this._clients.length) {
        await this.getClients()
      }

      if (this.invoice.id !== null && this.invoice.id === this.invoice.recurrence.base_invoice_id) {
        await this.fetchRecurrenceInvoices(this.invoice.id)
      }

      if (this.isTwinfieldConnected) {
        if (this.ledgerAccounts.length === 0) {
          await this.$store.dispatch('administration/setLedgerAccounts')
        }

        await this.getInvoiceProjectAndCostplace()
      }

      this.essentialsLoaded = true
    },
    async getInvoice () {
      await apiInvoiceService.getInvoice(this.invoiceId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.invoice = new Invoice().fromResponse(response.data) as Invoice
        }
      })
    },

    async getClients () {
      await this.$store.dispatch('client/setClients', this.relation.id)
    },

    async fetchRecurrenceInvoices (invoiceId: number) {
      await apiInvoiceService.getRecurrenceInvoicesById(invoiceId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.invoice.recurrence.invoices = response.data.invoices
        }
      })
    },
    async getInvoiceProjectAndCostplace () {
      await apiAdministrationService.getInvoice(this.invoiceId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((rawBookingLine: any) => {
            const bookingLine = new BookingItemLine().fromResponse(rawBookingLine)
            const product = this.invoice.products.find(product => product.product_id === bookingLine.invoice_product_id)
            if (product) {
              product.cost_place_ledger_id = bookingLine.cost_place_ledger_account_id
              product.project_ledger_id = bookingLine.project_ledger_account_id
            }
          })
        }
      })
    },

    navigateToCompanySettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name })
    }
  }
})
