
import Vue from 'vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import IconCalendar from '@/globals/components/icons/IconCalendar.vue'

export default Vue.extend({
  components: {
    DatePicker,
    IconBase,
    IconChevronDown,
    IconCalendar,
    PrimaryButton
  },
  props: {
    _selected: {
      type: Number,
      required: true
    },
    dates: {
      type: Array,
      required: false
    },
    isPrependIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      selections: [
        { value: 0, text: 'Deze maand' },
        { value: 1, text: 'Vorige maand' },
        { value: 2, text: 'Huidig kwartaal' },
        { value: 3, text: 'Huidig jaar' },
        { value: 4, text: '' }
      ],
      selected: 0,
      isMenuActive: false,
      dateHelper,
      selectedRange: [] as Array<Date|null>,
      maxDate: dateHelper.toDateString(new Date())
    }
  },
  beforeMount () {
    this.selected = this._selected
    this.setSelectedOnMount()

    const dates = this.dates as Array<Date>
    if (this._selected === 4 && dates) {
      this.selections[4].text = this.dateHelper.toShortMonthYearString(dates[0]) + ' / ' + this.dateHelper.toShortMonthYearString(dates[1])
    }
  },
  methods: {
    setSelectedOnMount () {
      if (this.selected === 0) {
        this.selectThisMonth()
      } else if (this.selected === 1) {
        this.selectLastMonth()
      } else if (this.selected === 2) {
        this.selectThisQuarter()
      } else if (this.selected === 3) {
        this.selectThisYear()
      }
    },
    selectThisMonth () {
      const from = this.today()
      this.selected = 0
      this.selectedRange = [from, null]
      this.returnDates([from, null])
    },
    selectLastMonth () {
      const from = this.today()
      from.setMonth(from.getMonth() - 1)
      this.selected = 1
      this.selectedRange = [from, from]
      this.returnDates([from, from])
    },
    selectThisQuarter () {
      const from = this.today()
      const start = dateHelper.getStartOfQuarter(from).toDate()
      start.setDate(10)
      const end = dateHelper.getEndOfQuarter(from).toDate()
      end.setDate(10)
      this.selected = 2
      this.selectedRange = [start, end]
      this.returnDates([start, end])
    },
    selectThisYear () {
      const from = this.today()
      from.setMonth(0)
      from.setDate(10)
      this.selected = 3
      this.selectedRange = [from, null]
      this.returnDates([from, null])
    },
    selectRange (range: Array<string>) {
      let from = dateHelper.toDate(range[0])
      from.setDate(10)
      let to = dateHelper.toDate(range[1])
      to.setDate(10)
      this.selected = 4

      if (to < from) {
        const oldFrom = from
        from = to
        to = oldFrom
      }

      this.selections[4].text = this.dateHelper.toShortMonthYearString(from) + ' / ' + this.dateHelper.toShortMonthYearString(to)
      this.selectedRange = [from, null]
      this.returnDates([from, to])
    },
    today (): Date {
      let date = dateHelper.toDate()
      date = dateHelper.resetTime(date)

      return date
    },
    returnDates (dates: Array<Date|null>) {
      this.isMenuActive = false
      this.$emit('selected', { dates, selected: this.selected })
    }
  }
})
