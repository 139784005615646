export const RouteNames = {
  settings: {
    name: 'settings',
    account: {
      name: 'settings_account',
      profile: {
        name: 'settings_account_profile'
      },
      password: {
        name: 'settings_account_password'
      }
    },
    company: {
      name: 'settings_company',
      edit: {
        name: 'settings_company_edit'
      },
      twinfield: {
        name: 'settings_company_twinfield'
      },
      users: {
        name: 'settings_company_user'
      },
      invoicing: {
        name: 'settings_company_invoicing'
      }
    },
    administration: {
      name: 'settings_administration',
      overview: {
        name: 'settings_administration_overview'
      },
      create: {
        name: 'settings_administration_create'
      }
    }
  }
}
