
import Vue from 'vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconArrowUpSmall from '@/globals/components/icons/IconArrowUpSmall.vue'
import IconArrowDownSmall from '@/globals/components/icons/IconArrowDownSmall.vue'

export default Vue.extend({
  components: { SkeletonLoader, IconBase, IconArrowUpSmall, IconArrowDownSmall },
  props: {
    title: {
      type: String,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    gain: {
      type: Number,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    isPrice: {
      type: Boolean,
      default: true
    },
    isRoundedAmount: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      count: 0,
      animationDuration: 1000,
      frameDuration: 1000 / 60,
      easeOutQuad: (t: any) => t * (2 - t),
      priceHelper
    }
  },
  computed: {
    isNeutralGain (): boolean {
      return this.gain === 0
    },
    isGained (): boolean {
      return this.gain > 0
    },
    isNotGained (): boolean {
      return this.gain < 0
    }
  },
  watch: {
    totalCount () {
      this.countUp()
    }
  },
  mounted () {
    if (this.totalCount !== 0) {
      this.countUp()
    }
  },
  methods: {
    countUp () {
      const totalFrames = Math.round(this.animationDuration / this.frameDuration)
      let frame = 0
      const countTo = this.totalCount
      const counter = setInterval(() => {
        frame++
        const progress = this.easeOutQuad(frame / totalFrames)
        const currentCount = countTo * progress
        this.count = currentCount

        if (frame === totalFrames) {
          clearInterval(counter)
        }
      }, this.frameDuration)
    }
  }
})
