interface Options {
    text: string;
    value: number;
}
export class PaymentPeriod {
  direct: Options = {
    text: 'Per ommegaande',
    value: 0
  }

  week: Options = {
    text: 'Week',
    value: 7
  }

  twoweeks: Options = {
    text: 'Twee weken',
    value: 14
  }

  threeweeks: Options = {
    text: 'Drie weken',
    value: 21
  }

  month: Options = {
    text: 'Maand',
    value: 30
  }

  fourtyfivedays: Options = {
    text: '45 dagen',
    value: 45
  }

  sixtydays: Options = {
    text: '60 dagen',
    value: 60
  }

  ninetydays: Options = {
    text: '90 dagen',
    value: 90
  }

  getPaymentPeriods () {
    return [
      this.direct,
      this.week,
      this.twoweeks,
      this.threeweeks,
      this.month,
      this.fourtyfivedays,
      this.sixtydays,
      this.ninetydays
    ]
  }
}
