import { render, staticRenderFns } from "./Administrations.vue?vue&type=template&id=223ed8da&scoped=true&"
import script from "./Administrations.vue?vue&type=script&lang=ts&"
export * from "./Administrations.vue?vue&type=script&lang=ts&"
import style0 from "./Administrations.vue?vue&type=style&index=0&id=223ed8da&prod&lang=scss&"
import style1 from "./Administrations.vue?vue&type=style&index=1&id=223ed8da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223ed8da",
  null
  
)

export default component.exports