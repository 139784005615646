export class InvoiceAttachment {
  id: number|null = null
  invoice_id: number|null = null
  url = ''
  file_name = ''

  constructor (data?: any) {
    if (data) {
      this.id = data.id
      this.invoice_id = data.invoice_id
      this.url = data.url
      this.file_name = data.file_name
    }
  }
}
