
import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { apiRelationService } from '@/network/api/api-relation-service'
import { localRelationService } from '@/network/local/local-relation-service'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { RelationModel } from '@/globals/models/relation/Relation'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      enterpriseRelation: new EnterpriseRelationRequestModel(),
      errors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
      isCocChanged: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    this.enterpriseRelation = new EnterpriseRelationRequestModel().fromRelationModel(this.relation)
  },
  methods: {
    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data as any
            this.enterpriseRelation.name = info.name
            this.enterpriseRelation.address = info.address
            this.enterpriseRelation.postal_code = info.postal_code
            this.enterpriseRelation.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.handle422Errors(response.data)
          }
        })
      }
    },

    submitForm () {
      this.clearErrors()
      const validationError = this.validateform()

      if (!validationError) {
        this.updateRelation()
      }
    },
    validateform () {
      let error = false

      if (!this.enterpriseRelation.name) {
        this.errors.name.push('Bedrijfsnaam is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.coc_number) {
        this.errors.coc_number.push('KVK nummer is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.tax_number) {
        this.errors.tax_number.push('BTW nummer is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.postal_code) {
        this.errors.postal_code.push('Postcode is verplicht.')
        error = true
      }
      if (!this.enterpriseRelation.city) {
        this.errors.city.push('Stad is verplicht.')
        error = true
      }

      return error
    },
    clearErrors () {
      this.errors = entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel())
    },

    async updateRelation () {
      await apiRelationService.updateRelation(this.enterpriseRelation.id, this.enterpriseRelation).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          await this.returnUpdatedRelation()
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handle422Errors(response.data)
        }
      })
    },
    handle422Errors (data: any) {
      this.errors = {
        ...this.errors,
        ...data
      }
    },
    async returnUpdatedRelation () {
      await localRelationService.getRelation()
      this.returnDone()
    },
    returnDone () {
      this.$emit('done')
    }
  }
})
