
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: { PrimaryModal, PrimaryButton },
  props: {
    file: {
      type: File,
      required: true
    }
  },
  data () {
    return {
      cropper: null as null|Cropper,
      filePath: ''
    }
  },
  beforeMount () {
    this.filePath = this.getTemporaryPath(this.file)
  },
  mounted () {
    const image = document.getElementById('avatar-img') as any
    this.cropper = new Cropper(image, {
      preview: '.cropper__preview',
      aspectRatio: 1,
      viewMode: 1
    })
  },
  destroyed () {
    this.cropper?.clear()
  },
  methods: {
    getTemporaryPath (file: File): string {
      return URL.createObjectURL(file)
    },

    onSelect () {
      if (this.cropper !== null) {
        const croppedCanvas = this.cropper.getCroppedCanvas({
          width: 64
        })

        const roundedCanvas = this.getRoundedCanvas(croppedCanvas)
        roundedCanvas.toBlob((blob) => {
          if (blob !== null) {
            this.$emit('success', blob)
          }
        })
      }
    },

    getRoundedCanvas (sourceCanvas: HTMLCanvasElement): HTMLCanvasElement {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d') as CanvasRenderingContext2D
      const width = sourceCanvas.width
      const height = sourceCanvas.height

      canvas.width = width
      canvas.height = height
      context.imageSmoothingEnabled = true
      context.drawImage(sourceCanvas, 0, 0, width, height)
      context.globalCompositeOperation = 'destination-in'
      context.beginPath()
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
      context.fill()

      return canvas
    }
  }
})
