<template>
      <div :class="{
      'blank-slate__info-bar': true,
      'state--info': info,
      'state--success': success,
      'state--warning': warning,
      'state--error': error
      }">
        <div class="bar__text">
          <i v-show="warning" class="fas fa-exclamation-triangle"></i>
          <div>
            <h3>{{title}}</h3>
            <span class="subtitle">{{subtitle}}</span>
          </div>
        </div>
        <slot></slot>
      </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    info: {
      type: Boolean,
      required: false,
      default: false
    },
    success: {
      type: Boolean,
      required: false,
      default: false
    },
    warning: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .blank-slate__info-bar {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 24px;

    >.bar__text {
        display: flex;
      >i {
        padding-left: 8px;
        padding-top: 8px;
        padding-right: 20px;
      }
      > div > span{
        display: block;
      }
    }

    &.state--warning {
      background-color: rgb(255, 99, 71);
      i,
      h3,
      span {
        color: white;

        &.subtitle {
          opacity: .75;
        }
      }
    }
  }
</style>
