import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/nl'

export const dateHelper = {
  toShortMonthDateString (date: Date|string): string {
    return dayjs(date).format('ll')
  },
  toShortMonthYearString (date: Date|string): string {
    return dayjs(date).format('MMM YYYY')
  },
  toLongMonthYearString (date: Date|string): string {
    return dayjs(date).format('MMMM YYYY')
  },
  toFullDateHourMinute (date: Date|string): string {
    return dayjs(date).format('LLL')
  },
  toNumericDateString (date: Date|string) {
    return dayjs(date).format('DD-MM-YYYY')
  },
  toDate (date?: Date|string): Date {
    return dayjs(date).toDate()
  },
  getTimeFromDateTime (dateTime: Date): string {
    return dateTime.getHours() + ':' + dateTime.getMinutes()
  },
  getDifferenceInDays (date: Date, compareTo: Date) {
    return dayjs(date).diff(compareTo, 'days')
  },
  toUnix (dateTime: Date|string): number {
    return dayjs(dateTime).unix()
  },
  toDateString (date?: Date|string) {
    return dayjs(date).format('YYYY-MM-DD')
  },
  fromNow (date: any) {
    return dayjs(date).fromNow()
  },
  toDateZeroTime (date?: Date|string): Date {
    return dayjs(this.toDateString(date)).toDate()
  },
  toShortLocalMonthYearString (date: Date|string) {
    return dayjs(date).format('MMM YY')
  },
  getQuarter (date: Date|string): number {
    return dayjs(date).quarter()
  },
  getStartOfQuarter (date: Date|string) {
    return dayjs(date).startOf('quarter')
  },
  getEndOfQuarter (date: Date|string) {
    return dayjs(date).endOf('quarter')
  },
  resetTime (date: string|Date): Date {
    if (typeof date === 'string' || date instanceof String) {
      date = this.toDate(date.toString())
    }

    date.setHours(0, 0, 0, 0)

    return date
  },
  isDateToday (date: string|Date): boolean {
    return this.toDateString(date) === this.toDateString(new Date())
  },
  toDayjs (date: string|Date): Dayjs {
    return dayjs(date)
  },
  toFormatString (date: string|Date, options?: Intl.DateTimeFormatOptions): string {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    return new Intl.DateTimeFormat('nl-NL', options).format(date)
  }
}
