
import Vue from 'vue'
import IconBase from '../icons/IconBase.vue'
import IconChevronDown from '../icons/IconChevronDown.vue'
import IconLookingGlass from '../icons/IconLookingGlass.vue'
import InputField from './InputField.vue'

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    value: {
      required: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    selectStyle: {
      required: false,
      default: () => { return {} }
    },
    noDataSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    prependSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    isItemSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: 32
    },
    heightLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    heightSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    labelHeightSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    itemText: {
      type: String,
      required: false
    },
    itemValue: {
      type: String,
      required: false
    },
    itemDisabled: {
      required: false
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    isClearable: {
      type: Boolean,
      required: false,
      default: false
    },
    isBtnStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    isBackgroundWhite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InputField,
    IconBase,
    IconLookingGlass,
    IconChevronDown
  },
  data () {
    return {
      search: '',
      filteredData: this.data,
      val: this.value
    }
  },

  computed: {
    currentHeight (): number {
      if (this.heightLarge) {
        return 40
      } else if (this.heightSmall) {
        return 24
      } else {
        return this.height
      }
    }
  },
  watch: {
    search () {
      this.getData()
    },
    data () {
      this.getData()
    },
    value (value: any) {
      this.val = value
    }
  },
  methods: {
    onFocus () {
      if (this.isSearch) {
        const refs = this.$refs as any
        setTimeout(function () {
          refs.searchDropdown.$refs.input.focus()
        }, 100)
      }
    },
    handleSearchBlur () {
      setTimeout(() => { this.search = '' }, 100)
      this.$emit('blur', this.val)
    },
    getData () {
      let data = this.data
      if (this.isSearch && this.search !== '') {
        data = this.filterData(data)
      }
      this.filteredData = data
    },
    filterData (data: any) {
      const filteredData = []
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        let value = this.itemValue ?? 'value'
        const text = this.itemText ?? 'text'
        value = typeof element[value] === 'string' ? element[value].toLowerCase() : ''
        if (value.includes(this.search.toLowerCase()) || element[text].toLowerCase().includes(this.search.toLowerCase())) {
          filteredData.push(data[i])
        }
      }
      return filteredData
    }
  }
})
