import { BookingItemLine } from './BookingItemLine'
import { RelationModel } from '@/globals/models/relation/Relation'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { DocumentState } from '../enums/DocumentState'
export class BookingItem {
  id = 0
  relation_id: number|null = null
  supplier_relation_id: number|null = null
  supplier: RelationModel|null = null
  file_name = ''
  url = ''
  amount_pages = 1
  creditor = ''
  invoice_number = ''
  date = dateHelper.toDateString(new Date())
  reference = ''
  description = ''
  tag: null|string = null
  ledger_account_id: number|null = null
  tagged_date: string|null = null
  updated_at: string|null = null
  uploaded_date: string|null = null
  amount = 0.00
  bank_account = 0
  currency = ''
  status = 0
  is_credit = false
  relation: object | null = null
  booking_lines: Array<BookingItemLine> = []
  booking_errors = null as null|string

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.relation_id = data.relation_id
      this.supplier_relation_id = data.supplier_relation_id
      this.supplier = data.supplier
      this.file_name = data.file_name
      this.url = data.url
      this.amount_pages = data.amount_pages
      this.creditor = data.creditor
      this.invoice_number = data.invoice_number
      this.date = data.date
      this.reference = data.reference
      this.description = data.description
      this.ledger_account_id = data.ledger_account_id
      this.tag = data.tag ? data.tag : null
      this.tagged_date = data.tagged_date
      this.uploaded_date = data.uploaded_date
      this.updated_at = data.updated_at
      this.amount = data.amount
      this.bank_account = data.bank_account
      this.currency = data.currency ?? ''
      this.status = data.status
      this.is_credit = data.is_credit ?? false
      this.relation = data.relation
      this.booking_errors = data.booking_errors
      this.booking_lines = data.booking_lines
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    if (data.supplier) {
      this.supplier = new RelationModel().fromResponse(data.supplier)
    }

    // if (data.date) {
    //   this.date = data.date
    // }

    // if (data.tagged_date) {
    //   this.tagged_date = dateHelper.toDateTime(data.tagged_date)
    // }

    // if (data.uploaded_date) {
    //   this.uploaded_date = dateHelper.toDateTime(data.uploaded_date)
    // }

    this.booking_lines = []
    data.booking_lines.forEach((lineRaw: any) => {
      const line = new BookingItemLine().fromResponse(lineRaw)
      this.booking_lines.push(line)
    })

    return this
  }

  isBooked (): boolean {
    return this.status === new DocumentState().BOOKED.value
  }

  isInProgress (): boolean {
    return this.status === new DocumentState().INPROGRESS.value
  }
}
