import { EnterpriseContactModel as EnterpriseContact } from './EnterpriseContact'

export class EnterpriseRelationModel {
  id: number|null = null
  name = ''
  general_email = ''
  phone = ''
  tax_number = ''
  mobile = ''
  coc_number = ''
  invoice_address = ''
  visiting_address = ''
  legal_form = ''
  contact: EnterpriseContact = new EnterpriseContact()

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.general_email = data.general_email
      this.phone = data.phone
      this.tax_number = data.tax_number
      this.mobile = data.mobile
      this.coc_number = data.coc_number
      this.invoice_address = data.invoice_address
      this.visiting_address = data.visiting_address
      this.legal_form = data.legal_form
      this.contact = data.contact
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
