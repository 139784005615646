
import Vue from 'vue'
import Chart from '@/globals/components/charts/Chart.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'

export default Vue.extend({
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart
  },
  data () {
    return {
      isEssentialsLoaded: true,
      invoiceStatus: new InvoiceStatus(),
      maxMonthsBack: 13,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            text: 'Omzet excl. btw'
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (item: any) {
                return priceHelper.toEUR(item.raw).format()
              }
            }
          }
        },
        interaction: {
          intersect: false
        },
        scales: {
          y: {
            display: true,
            min: 0,
            ticks: {
              callback: function (label: any, index: number) {
                if (index % 2 === 0) {
                  return priceHelper.toEUR(label, 0).format() + ',-'
                } else {
                  return ''
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    chartData (): object {
      const data = {} as any
      const chartData = {
        labels: this.getLabels(),
        datasets: [
          {
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            data: this.getClearData()
          }
        ]
      }

      for (let i = 0; i < this.invoices.length; i++) {
        const invoice = this.invoices[i] as Invoice

        if (invoice.status >= new InvoiceStatus().sent.value) {
          const localMonthYear = dateHelper.toShortLocalMonthYearString(invoice.invoice_date)
          if (!data[localMonthYear]) {
            data[localMonthYear] = 0
          }

          data[localMonthYear] = data[localMonthYear] + (invoice.total_ex_tax ?? 0)
        }
      }

      for (const property in data) {
        const index = chartData.labels.findIndex(label => label === property)

        if (index >= 0) {
          const value = data[property]
          chartData.datasets[0].data[index] = value
        }
      }
      return chartData
    }
  },
  methods: {
    getClearData (): Array<number> {
      const data = [] as Array<number>

      for (let i = 0; i < this.maxMonthsBack; i++) {
        data.push(0)
      }

      return data
    },
    getLabels (): Array<string> {
      const labels = [] as Array<string>

      for (let i = 0; i < this.maxMonthsBack; i++) {
        const date = new Date()
        date.setDate(1)
        const minusMonthDate = new Date(date.setMonth(date.getMonth() - i))
        const localMonthYear = dateHelper.toShortLocalMonthYearString(minusMonthDate)
        labels.push(localMonthYear)
      }
      return labels.reverse()
    }
  }
})
