interface Options {
  value: number;
  text: string;
  percentage: number;
}
export class VatEnum {
  twentyone: Options = {
    value: 1,
    text: '21%',
    percentage: 21
  }

  nine: Options = {
    value: 2,
    text: '9%',
    percentage: 9
  }

  noVat: Options = {
    value: 3,
    text: 'BTW geen',
    percentage: 0
  }

  vatShifted: Options = {
    value: 4,
    text: 'BTW verlegd',
    percentage: 0
  }

  saleOutsideEu: Options = {
    value: 5,
    text: 'Verkoop buiten EU',
    percentage: 0
  }

  saleInsideEu: Options = {
    value: 6,
    text: 'Verkoop binnen EU',
    percentage: 0
  }

  getVats (): Array<Options> {
    return [
      this.twentyone,
      this.nine,
      this.noVat,
      this.vatShifted,
      this.saleOutsideEu,
      this.saleInsideEu
    ]
  }
}
