
import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiAuthService } from '@/network/api/api-auth-service'
import { localAuthService } from '@/network/local/local-auth-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { apiRelationService } from '@/network/api/api-relation-service'

export default Vue.extend({
  data () {
    return {
      mounted: false,
      code: this.$route.query.code,
      countDown: 5
    }
  },
  methods: {
    async activate () {
      apiAuthService.activate({ activation_code: this.code }).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          localAuthService.storeToken(response.data)
          await this.getRelation()
          this.countDownTimer()
          this.mounted = true
        } else {
          this.$router.push({ name: routeHelper.authRoutes.login.name })
        }
      })
    },
    async getRelation () {
      await apiRelationService.getDefaultRelation().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.commit('relation/setRelation', response.data.relation)
          this.$store.commit('relation/setIsOwner', response.data.is_owner)
        }
      })
    },

    redirect () {
      this.$router.push('/')
    },
    countDownTimer () {
      setTimeout(() => {
        this.countDown -= 1
        if (this.countDown === 0) { this.redirect() } else { this.countDownTimer() }
      }, 1000)
    }
  },
  beforeMount () {
    this.activate()
  }
})
