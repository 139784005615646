
import Vue from 'vue'
import { TaskModel } from '@/modules/task/models/Task'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { TaskStatusEnum } from '@/modules/task/enums/TaskStatus'
import CardMenu from './components/card-menu/CardMenu.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import Tooltip from '@/globals/components/tooltip/Tooltip.vue'
import { UserModel } from '@/globals/models/user/User'
import { TaskUserModel } from '@/modules/task/models/TaskUser'

export default Vue.extend({
  components: {
    Avatar,
    Tooltip,
    CardMenu
  },
  props: {
    _task: {
      type: TaskModel,
      required: true
    },
    isFilteredOut: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      dateHelper,
      taskStatusEnum: new TaskStatusEnum()
    }
  },
  computed: {
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    isDone (): boolean {
      return this._task.status === this.taskStatusEnum.Done.value
    },
    isSelected (): boolean {
      return this.taskStore.selectedTask !== null && this.taskStore.selectedTask.id === this._task.id
    },
    isSharedToMe (): boolean {
      return this._task.isSharedToUser(this.user.id)
    },
    isSharedToOthers (): boolean {
      return this._task.getSharedTo().filter(taskUser => taskUser.user_id !== this.user.id).length > 0
    },
    assignedTo (): TaskUserModel {
      return this._task.getAssignedTo()
    },
    createdBy (): TaskUserModel {
      return this._task.getCreatedBy()
    }
  },
  mounted () {
    if (this._task.isNew()) {
      this.scroll()
    }
  },
  methods: {
    clickOuter () {
      const isTasksUpdating = this.$store.getters['task/isTasksUpdating'] as boolean
      if (!isTasksUpdating) {
        this.$store.dispatch('task/setSelectedTask', this._task)
        setTimeout(() => this.scroll(), 20)
      }
    },
    scroll () {
      const task = this.$refs.task as any
      const rect = task.getBoundingClientRect()
      const windowWidth = window.innerWidth - 900
      const scrollContainer = document.getElementById('task-overview-wrapper')

      if (scrollContainer) {
        scrollContainer.scrollTo(task.offsetLeft - (windowWidth / 2) - (rect.width / 2), scrollContainer.scrollTop)
      }
    },
    deleteTask () {
      apiTaskService.deleteTask(this._task.id)
      const list = this.$store.getters['task/getListByStatus'](this._task.status) as Array<TaskModel>
      const index = list.findIndex(task => task.id === this._task.id)

      if (index >= 0) {
        list.splice(index, 1)
      }

      if (this.isSelected) {
        this.$store.dispatch('task/setSelectedTask', new TaskModel())
      }
    },
    getSharedToNames (): string {
      let result = ''

      this._task.getSharedTo().forEach((taskUser, index) => {
        if (index > 0) {
          result = result + '<br>'
        }

        result = result + taskUser.user.getName()
      })

      return result
    }
  }
})
