
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { PaymentPeriod } from '@/globals/enums/PaymentPeriod'
import { EnterpriseRelationTypeEnum } from '@/globals/enums/EnterpriseRelationType'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { ClientModel } from '@/globals/models/client/Client'
import { landCodes } from '@/globals/enums/LandCodesEnum'
import TextArea from '@/globals/components/forms/TextArea.vue'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    _client: {
      type: ClientModel,
      required: false
    }
  },
  components: {
    PrimaryButton,
    InputField,
    Dropdown,
    TextArea
  },
  data () {
    return {
      essentialsLoaded: false,
      userStore: this.$store.state.user,
      dialogStore: this.$store.state.dialog,
      relation: new EnterpriseRelationRequestModel(),
      relationErrors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
      paymentPeriods: new PaymentPeriod().getPaymentPeriods(),
      landCodes,
      relationActive: [
        { text: 'Ja', value: false },
        { text: 'Nee', value: true }
      ],
      relationTypes: [
        { text: 'Klant', value: EnterpriseRelationTypeEnum.CLIENT },
        { text: 'Leverancier', value: EnterpriseRelationTypeEnum.SUPPLIER }
      ],
      enterprises: [] as any,
      selectedEnterprise: null as any,
      isCocChanged: false,
      isEdit: false,
      submitDisabled: true
    }
  },
  computed: {
    selfRelation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    }
  },
  beforeMount () {
    if (this._client) {
      this.relation = new EnterpriseRelationRequestModel().fromRelationModel(this._client.client)
      this.relation.enterprise_relation_type = this._client.type
      this.isEdit = true
    }
  },
  methods: {
    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data as any
            this.relation.name = info.name
            this.relation.address = info.address
            this.relation.postal_code = info.postal_code
            this.relation.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.mergeRelationErrors(response.data)
          }
        })
      }
    },
    async submitForm (e: any) {
      e.preventDefault()
      this.clearErrors()
      if (!this.validationErrors()) {
        if (this.isEdit) {
          await this.updateClient()
        } else {
          await this.createClient()
        }
      } else {
        new Notify().entityError()
      }
    },
    clearErrors () {
      this.relationErrors = entityHelper.generateErrorEntity(this.relation)
    },
    validationErrors () {
      let error = false

      if (this.isEnterpriseClient()) {
        if (!this.relation.name) {
          this.relationErrors.name.push('Bedrijfsnaam is verplicht')
          error = true
        }
      } else {
        if (!this.relation.first_name) {
          this.relationErrors.first_name.push('Voornaam is verplicht')
          error = true
        }

        if (!this.relation.last_name) {
          this.relationErrors.last_name.push('Achternaam is verplicht')
          error = true
        }
      }

      if (!this.relation.email) {
        this.relationErrors.email.push('E-mailadres is verplicht')
        error = true
      }

      if (!this.relation.general_email) {
        this.relationErrors.general_email.push('E-mailadres is verplicht')
        error = true
      }

      if (!this.relation.address) {
        this.relationErrors.address.push('Adres is verplicht')
        error = true
      }

      if (!this.relation.postal_code) {
        this.relationErrors.postal_code.push('Postcode is verplicht')
        error = true
      }

      if (!this.relation.city) {
        this.relationErrors.city.push('Plaats is verplicht')
        error = true
      }

      return error
    },
    isEnterpriseClient () {
      return this.relation.name || this.relation.coc_number || this.relation.tax_number || this.isSupplier()
    },

    isSupplier () {
      return this.relation.enterprise_relation_type === EnterpriseRelationTypeEnum.SUPPLIER
    },

    async createClient () {
      if (this.isEnterpriseClient()) {
        await this.createEnterpriseClient()
      } else {
        await this.createPrivateClient()
      }
    },
    async createEnterpriseClient () {
      await apiRelationService.createEnterpriseRelation(this.relationId, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          if (this.isTwinfieldConnected) {
            await this.createTwinfieldRelation(response.data.relation.id)
          }
          await this.afterUpdatedOrCreated(response.data.relation)
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },
    async createPrivateClient () {
      await apiRelationService.createPrivateRelation(this.relationId, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          if (this.isTwinfieldConnected) {
            await this.createTwinfieldRelation(response.data.relation.id)
          }
          await this.afterUpdatedOrCreated(response.data.relation)
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },
    async createTwinfieldRelation (relationId: number) {
      await apiAdministrationService.createTwinfieldClientRelation(relationId)
    },

    async updateClient () {
      await apiRelationService.updateRelation(this.relation.id, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          await this.afterUpdatedOrCreated(response.data.relation)
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },
    async afterUpdatedOrCreated (data: any) {
      const relation = new RelationModel().fromResponse(data)
      relation.hidden = this.relation.hidden
      await this.toggleRelationActiveState(relation.id)

      this.returnRelation(relation)
    },
    async toggleRelationActiveState (id: number) {
      if (this.relation.hidden) {
        await this.setRelationNonActive(id)
      } else {
        await this.setRelationActive(id)
      }
    },
    async setRelationActive (id: number) {
      await apiRelationService.showRelation(this.relationId, id)
    },
    async setRelationNonActive (id: number) {
      await apiRelationService.hideRelation(this.relationId, id)
    },
    async returnRelation (relation: RelationModel) {
      const newClient = new ClientModel()
      newClient.type = this.relation.enterprise_relation_type
      newClient.client = relation

      if (this.isEdit) {
        newClient.id = this._client.id
        this.$store.dispatch('client/updateClient', newClient)
        new Notify().updated()
        this.$emit('updated', relation)
      } else {
        this.$store.dispatch('client/addClient', newClient)
        new Notify().create('Gelukt!', 'De relatie is succesvol aangemaakt')
        this.$emit('created', relation)
      }
    },

    async deactivateRelation () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze relatie wil verwijderen?', isDeleting: true })

      if (isConfirmed && this.relation.id !== null) {
        await apiRelationService.deactivateRelation(this.selfRelation.id, this.relation.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            this.$store.dispatch('client/removeClientByRelationId', this.relation.id)
            this.$emit('close')
          }
        })
      }
    },

    onBlurEmail (event: string) {
      if (!this.relation.general_email) {
        this.relation.general_email = event
      }
    },

    mergeRelationErrors (errors: object) {
      this.relationErrors = {
        ...this.relationErrors,
        ...errors
      }
    },

    navigateToTwinfieldSettings () {
      this.$router.push({ name: 'twinfield_settings' })
    }
  }
})
