
import Vue from 'vue'
import RegisterComponent from '@/modules/auth/components/register/RegisterComponent.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    RegisterComponent
  },
  methods: {
    navigateToAdvisorOnboarding () {
      this.$router.push({ name: routeHelper.authRoutes.onboarding.adviser.welcome.name })
    }
  }
})
