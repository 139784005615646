export class UserModel {
  id = 0
  name = ''
  last_name = ''
  email = ''
  avatar_url = null as null|string

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.last_name = data.last_name
      this.email = data.email
      this.avatar_url = data.avatar_url
    }
  }

  getName (): string {
    return this.name + ' ' + this.last_name
  }

  fromResponse (data: any) {
    this.setData(data)

    return this
  }
}
