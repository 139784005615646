import { RouteConfig } from 'vue-router'
import Question from '../views/Question.vue'
import Overview from '../views/overview/Overview.vue'
import { RouteNames } from './RouteNames'

const routes = RouteNames
const questionRoutes: Array<RouteConfig> = [
  {
    path: '/question',
    name: routes.question.name,
    component: Question,
    redirect: { name: routes.question.overview.name },
    meta: {
      name_nl: 'overzicht'
    },
    children: [
      {
        path: 'overview',
        name: routes.question.overview.name,
        component: Overview,
        meta: {
          name_nl: 'overzicht'
        }
      }
    ]
  }
]

export default questionRoutes
