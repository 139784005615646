
import Vue from 'vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'
export default Vue.extend({
  components: { IconBase, IconChevronDown },
  props: {
    name: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    isSubMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    filteredItems (): Array<{ name: string; routeName: string; active: boolean }> {
      return (this.items as Array<{ name: string; routeName: string; active: boolean }>).filter(item => item.active)
    }
  }
})
