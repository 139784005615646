
import Vue from 'vue'
import CurrencyInput from '@/globals/components/forms/VueCurrencyInput.vue'

export default Vue.extend({
  components: { CurrencyInput },
  props: {
    currency: {
      type: String,
      required: false,
      default: 'EUR'
    },
    value: {
      required: false
    },
    textAlignLeft: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currencyOptions: {
        locale: 'nl-NL',
        currency: this.currency,
        currencyDisplay: 'narrowSymbol',
        precision: 2,
        autoDecimalDigits: true,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        useGrouping: true,
        accountingSign: false
      },
      result: ''
    }
  }
})
