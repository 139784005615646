
import Vue from 'vue'
import CurrencyInput from '@/globals/components/forms/VueCurrencyInput.vue'

export default Vue.extend({
  components: { CurrencyInput },
  props: {
    label: {
      type: String,
      required: false
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    labelStyle: {
      type: Object,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    icon: {
      type: String,
      required: false
    },
    inputStyle: {
      type: Object,
      required: false
    },
    step: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      required: false
    },
    max: {
      required: false
    },
    value: {
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    align: {
      type: String,
      required: false,
      default: 'left'
    },
    isPrice: {
      type: Boolean,
      required: false,
      default: false
    },
    showPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    labelHeightSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    isNoStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    isPrependIcon: {
      type: Boolean,
      required: false
    },
    isAppendIconClass: {
      type: String,
      required: false
    },
    hideErrors: {
      type: Boolean,
      required: false,
      default: false
    },
    isOnlyNumbers: {
      type: Boolean,
      required: false,
      default: false
    },
    isAutoFocus: {
      type: Boolean,
      required: false,
      default: false
    },
    isAppendIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    isBtnStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    currency: {
      type: String,
      required: false,
      default: 'EUR'
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading,
      isLoading: false,
      isError: false,
      isPasswordShown: false,
      inputType: 'text',
      currencyOptions: {
        locale: 'nl-NL',
        currency: 'EUR',
        currencyDisplay: 'narrowSymbol',
        precision: 2,
        autoDecimalDigits: false,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        useGrouping: true,
        accountingSign: false
      }
    }
  },
  computed: {
    isNowLoading (): boolean {
      return this.loadingState.isLoading && this.showLoading
    },
    isDate (): boolean {
      return this.type === 'date'
    }
  },
  watch: {
    currency () {
      this.currencyOptions.currency = this.currency
    }
  },
  beforeMount () {
    this.inputType = this.type
  },
  mounted () {
    if (this.isAutoFocus) {
      this.focus()
    }
  },
  methods: {
    focus () {
      const refs = this.$refs as any
      window.setTimeout(function () {
        refs.input.focus()
      }, 0)
    },
    handleInput (e: any) {
      if (this.isOnlyNumbers) {
        e.target.value = e.target.value.replace(/\D/g, '')
      }
      this.$emit('input', e.target.value)
    },
    handleBlur (e: any) {
      if (this.isOnlyNumbers) {
        e.target.value = e.target.value.replace(/\D/g, '')
      }
      this.$emit('blur', e.target.value)
    },

    showHidePassword () {
      this.isPasswordShown = !this.isPasswordShown

      if (this.isPasswordShown) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    }
  }
})
