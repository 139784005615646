import { DefaultLedgerLine } from './DefaultLedgerLine'
export class DefaultLedgerAccountModel {
  id = 0
  relation_id = 0
  default_description = ''
  default_ledger_account_id = null as null|number
  default_lines = [] as Array<DefaultLedgerLine>
  default_vat_ledger_account_id = null as null|number
  enterprise_relation_id = null as null|number
  ledger_account_id = null as null|number
  name = ''

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.relation_id = data.relation_id
      this.default_description = data.default_description
      this.default_ledger_account_id = data.default_ledger_account_id
      this.default_lines = data.default_lines
      this.default_vat_ledger_account_id = data.default_vat_ledger_account_id
      this.enterprise_relation_id = data.enterprise_relation_id
      this.ledger_account_id = data.ledger_account_id
      this.name = data.name
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
