
import Vue from 'vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import IconBase from '../../icons/IconBase.vue'
import IconChevronDown from '../../icons/IconChevronDown.vue'
import IconCalendar from '../../icons/IconCalendar.vue'
import PrimaryButton from '../../buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    DatePicker,
    IconBase,
    IconChevronDown,
    IconCalendar,
    PrimaryButton
  },
  props: {
    _selected: {
      type: Number,
      required: true
    },
    dates: {
      type: Array,
      required: false
    },
    isPrependIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      selections: [
        { value: 0, text: 'Alle periodes' },
        { value: 1, text: 'Week tot heden' },
        { value: 2, text: 'Maand tot heden' },
        { value: 3, text: '3 maanden tot heden' },
        { value: 4, text: '' }
      ],
      selected: 0,
      isMenuActive: false,
      dateHelper,
      lastExportDate: '',
      lastExportFrom: '',
      lastExportTo: '',
      selectedRange: [] as Array<Date|null>
    }
  },
  beforeMount () {
    this.selected = this._selected
    this.setSelectedOnMount()

    const dates = this.dates as Array<Date>
    if (this._selected === 4 && dates) {
      this.selections[4].text = this.dateHelper.toNumericDateString(dates[0]) + ' / ' + this.dateHelper.toNumericDateString(dates[1])
    }
  },
  methods: {
    setSelectedOnMount () {
      if (this.selected === 0) {
        this.selectAllDays()
      } else if (this.selected === 1) {
        this.selectSevenDays()
      } else if (this.selected === 2) {
        this.selectThirtyDays()
      } else if (this.selected === 3) {
        this.selectThreemonths()
      }
    },
    selectAllDays () {
      this.selected = 0
      this.isMenuActive = false
      this.$emit('selected_all')
    },
    selectSevenDays () {
      const from = this.today()
      from.setDate(from.getDate() - 7)
      this.selected = 1
      this.selectedRange = [from, null]
      this.returnDates([from, null])
    },
    selectThirtyDays () {
      const from = this.today()
      from.setDate(from.getDate() - 30)
      this.selected = 2
      this.selectedRange = [from, null]
      this.returnDates([from, null])
    },
    selectThreemonths () {
      const from = this.today()
      from.setMonth(from.getMonth() - 3)
      this.selected = 3
      this.selectedRange = [from, null]
      this.returnDates([from, null])
    },
    selectRange (range: Array<string>) {
      let from = dateHelper.resetTime(range[0])
      let to = dateHelper.resetTime(range[1])
      this.selected = 4

      if (to < from) {
        const oldFrom = from
        from = to
        to = oldFrom
      }

      this.selections[4].text = this.dateHelper.toNumericDateString(from) + ' / ' + this.dateHelper.toNumericDateString(to)
      this.selectedRange = [from, null]
      this.returnDates([from, to])
    },
    today (): Date {
      let date = dateHelper.toDate()
      date = dateHelper.resetTime(date)

      return date
    },
    returnDates (dates: Array<Date|null>) {
      this.isMenuActive = false
      this.$emit('selected', { dates, selected: this.selected })
    }
  }
})
