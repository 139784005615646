
import Vue from 'vue'

import SideNav from '@/modules/settings/views/account/components/SideNav.vue'
import Profile from '@/modules/settings/views/account/profile/components/ProfileForm.vue'

export default Vue.extend({
  components: {
    SideNav,
    Profile
  },
  data: () => ({
  }),
  methods: {
  }
})
