import axios from 'axios'
import store from '../store/index'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '../globals/controllers/Notification'
import { apiAuthService } from './api/api-auth-service'

export const client = {
  clearStorage () {
    sessionStorage.clear()
    location.reload()
  },

  getToken (): string|null {
    return sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token') ?? '').token : null
  },

  handleErrors (response: any) {
    if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      new Notify().entityError()
    } else {
      new Notify({ duration: 10, close: true }).error(response.data.message ?? 'Er heeft een fout plaatsgevonden', response.status)
    }

    return response
  },

  getCallQueue: 0,
  async get (endPoint: string, params?: object, reqOptions?: object): Promise<any> {
    this.getCallQueue++
    this.enableLoading()

    const reqData = { params, headers: { Authorization: 'Bearer ' + this.getToken() }, ...reqOptions }

    return new Promise((resolve) => {
      axios.get(endPoint, reqData)
        .then((response: any) => {
          return resolve(response)
        })
        .catch(async (error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            // @ts-ignore
            if (!store.state.network.isRefreshing) {
              this.enableIsRefreshing()
              await apiAuthService.refresh().then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                  return resolve(this.get(endPoint, params, reqOptions))
                } else {
                  this.clearStorage()
                }
              })
                .finally(() => {
                  this.disableIsRefreshing()
                  if (this.getCallQueue === 0) {
                    this.disableLoading()
                  }
                })
            } else {
              const intervalId = setInterval(async () => {
                // @ts-ignore
                if (!store.state.network.isRefreshing) {
                  clearInterval(intervalId)
                  return resolve(this.get(endPoint, params, reqOptions))
                }
              }, 100)
            }
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => {
            this.getCallQueue--

            if (this.getCallQueue === 0) {
              this.disableLoading()
            }
          }
        )
    })
  },

  async put (endPoint: string, data = {}) {
    this.enableLoading()

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      }
    }
    return new Promise((resolve) => {
      axios.put(endPoint, data, headers)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            apiAuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.put(endPoint, data))
              } else {
                this.clearStorage()
              }
            })
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  async delete (endPoint: string) {
    this.enableLoading()

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      }
    }
    return new Promise((resolve) => {
      axios.delete(endPoint, headers)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            apiAuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.delete(endPoint))
              } else {
                this.clearStorage()
              }
            })
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  async post (endPoint: string, body = {} as any, refreshing = false, options?: { headers?: any }) {
    this.enableLoading()
    const headersData = {
      headers: options?.headers ?? {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      }
    }

    return new Promise((resolve) => {
      axios.post(endPoint, body, headersData)
        .then((response: any) => {
          return resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED && !refreshing) {
            apiAuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.post(endPoint, body, false))
              } else {
                this.clearStorage()
              }
            })
          } else if (error.response.status === HttpStatus.UNAUTHORIZED && refreshing) {
            this.clearStorage()
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  enableLoading () {
    store.commit('loading/setIsLoading', true)
  },
  disableLoading () {
    store.commit('loading/setIsLoading', false)
  },
  enableIsRefreshing () {
    store.dispatch('network/setIsRefreshing', true)
  },
  disableIsRefreshing () {
    store.dispatch('network/setIsRefreshing', false)
  }
}
