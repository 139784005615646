import { RouteConfig } from 'vue-router'
import { RouteNames } from './RouteNames'
import Dashboard from '../views/Dashboard.vue'

const routes = RouteNames
const dashboardRoutes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: routes.dashboard.name,
    component: Dashboard
  }
]

export default dashboardRoutes
