import { render, staticRenderFns } from "./BookingDetails.vue?vue&type=template&id=efb4ed8e&scoped=true&"
import script from "./BookingDetails.vue?vue&type=script&lang=ts&"
export * from "./BookingDetails.vue?vue&type=script&lang=ts&"
import style0 from "./BookingDetails.vue?vue&type=style&index=0&id=efb4ed8e&prod&lang=scss&"
import style1 from "./BookingDetails.vue?vue&type=style&index=1&id=efb4ed8e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efb4ed8e",
  null
  
)

export default component.exports