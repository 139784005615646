
import Vue from 'vue'

import InvoicesDataTable from '@/modules/invoicing/components/invoice/invoices-datatable/InvoicesDataTable.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    InvoicesDataTable
  },
  methods: {
    navigateToCreateInvoice () {
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.create.name })
    }
  }
})
