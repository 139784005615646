<template>
  <input
    ref="inputRef"
    type="text"
  />
</template>

<script>
import { watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
import { objectHelper } from '@/globals/helpers/ObjectHelper'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number,
    options: Object
  },
  setup (props) {
    const ops = objectHelper.cloneObject(props.options)
    const currency = ops.currency

    if (currency !== 'EUR' &&
        currency !== 'USD' &&
        currency !== 'JPY' &&
        currency !== 'GBP' &&
        currency !== 'BRL' &&
        currency !== 'INR' &&
        currency !== 'CNY' &&
        currency !== 'JPY' &&
        currency !== 'SAR' &&
        currency !== 'IRR' &&
        currency !== 'BGN') {
      ops.currency = 'EUR'
      ops.currencyDisplay = 'hidden'
    } else {
      ops.currencyDisplay = 'narrowSymbol'
    }

    const { inputRef, setOptions, setValue } = useCurrencyInput(ops)
    watch(
      () => props.value,
      (value) => {
        setValue(value)
      }
    )

    watch(
      () => props.options,
      (options) => {
        const ops = objectHelper.cloneObject(options)
        const currency = ops.currency

        if (currency !== 'EUR' &&
            currency !== 'USD' &&
            currency !== 'JPY' &&
            currency !== 'GBP' &&
            currency !== 'BRL' &&
            currency !== 'INR' &&
            currency !== 'CNY' &&
            currency !== 'JPY' &&
            currency !== 'SAR' &&
            currency !== 'IRR' &&
            currency !== 'BGN') {
          ops.currency = 'EUR'
          ops.currencyDisplay = 'hidden'
        } else {
          ops.currencyDisplay = 'narrowSymbol'
        }
        setOptions(ops)
      }, { deep: true }
    )
    return { inputRef }
  }
}
</script>
