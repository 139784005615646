var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:{
  'primary--btn': true,
  'hide--text': _vm.loadingState.isLoading && _vm.isLoading,
  'disabled': _vm.disabled || (_vm.loadingState.isLoading && _vm.isLoading),
  'is--pulse': _vm.pulse,
  'is--label': _vm.label,
  'outline': _vm.outline,
  'state--normal': _vm.stateNormal,
  'state--primary': _vm.statePrimary,
  'state--info': _vm.stateInfo,
  'state--success': _vm.stateSuccess,
  'state--warning': _vm.stateWarning,
  'state--danger': _vm.stateDanger,
  'text-state--light': _vm.textStateLight,
  'text-state--dark': _vm.textStateDark,
  'text-state--blue': _vm.textStateBlue,
  'text-state--danger': _vm.textStateDanger,
  'text-state--brand-red': _vm.textStateBrandRed,
  'bg-color--grey': _vm.bgColorGrey,
  'bg-color--brand-red': _vm.bgColorBrandRed,
  'size--none': _vm.sizeNone,
  'size--small': _vm.sizeSmall,
  'size--large': _vm.sizeLarge,
  'size--full': _vm.sizeFull,
  'height--fit-content': _vm.heightFitContent,
  'height--xs': _vm.heightXs,
  'height--small': _vm.heightSmall
},attrs:{"type":_vm.type,"disabled":_vm.disabled || _vm.isStateLoading},on:{"click":_vm.handleClick}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.isStateLoading),expression:"isStateLoading"}],staticClass:"fas fa-1x fa-circle-notch fa-spin spinner"}),(!_vm.isStateLoading && _vm.icon)?_c('i',{class:_vm.icon + ' prepend--icon'}):_vm._e(),(!_vm.isStateLoading)?_vm._t("prependIcon"):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" "),(!_vm.isStateLoading && _vm.appendIcon)?_c('i',{class:_vm.appendIcon + ' append--icon'}):_vm._e(),(!_vm.isStateLoading)?_vm._t("appendIcon"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }