export class InvoiceTemplateModel {
  id = 0
  relation_id = 0
  template = ''

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.relation_id = data.relation_id
      this.template = data.template
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
