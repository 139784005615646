
import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false
    },
    backgroundColorByAlphabet: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: Number,
      required: false,
      default: 32
    },
    fontSize: {
      type: Number,
      required: false,
      default: 14
    },
    imgUrl: {
      type: String,
      required: false
    },
    isSelf: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isValidImg: true
    }
  },
  methods: {
    getBackgroundColor () {
      if (this.imgUrl && this.isValidImg) {
        return 'transparent'
      }

      if (this.isSelf) {
        return 'rgb(251, 41, 255)'
      }

      if (this.backgroundColor) {
        return this.backgroundColor
      } else if (this.backgroundColorByAlphabet) {
        return this.getBackgroundByLabel()
      } else {
        return 'rgb(165, 65, 253)'
      }
    },
    getBackgroundByLabel () {
      const colors = ['#EE0090', '#E400F1', '#0000EA', '#01BDE8', '#01AD61', '#F97C25', '#E82C31']
      const charCode = this.label.charCodeAt(0)
      return colors[charCode % colors.length]
    }
  }
})
