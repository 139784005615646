
import Vue from 'vue'
import { TaskModel } from '@/modules/task/models/Task'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { CommentModel } from '@/modules/task/models/Comment'
import { dateHelper } from '@/globals/helpers/DateHelper'
import SkeletonLoader from '@/globals/components/skeleton-loader/SkeletonLoader.vue'
import Tooltip from '@/globals/components/tooltip/Tooltip.vue'
import { UserModel } from '@/globals/models/user/User'
import InputField from '@/globals/components/forms/InputField.vue'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton,
    Avatar,
    SkeletonLoader,
    Tooltip
  },
  data () {
    return {
      dateHelper,
      newComment: ''
    }
  },
  computed: {
    task: {
      get (): TaskModel {
        return this.$store.getters['task/task']
      },
      set (task: TaskModel) {
        this.$store.dispatch('task/setSelectedTask', task)
        return task
      }
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    }
  },
  watch: {
    task () {
      if (this.task.id) {
        const commentsWrapper = this.$refs.commentsWrapper as any
        commentsWrapper.scrollTop = commentsWrapper.scrollHeight
      }
    }
  },
  methods: {
    async submitComment () {
      if (this.newComment) {
        await apiTaskService.createComment(this.task.id, this.newComment).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const comment = new CommentModel().fromResponse(response.data)
            this.task.comments.push(comment)
            this.newComment = ''
          }
        })
      }
    },
    async deleteComment (commentId: number, reverseIndex: number) {
      const index = this.task.comments.length - 1 - reverseIndex
      await apiTaskService.deleteComment(this.task.id, commentId)
      this.task.comments.splice(index, 1)
    },
    isOwnComment (commentId: number) {
      return commentId === this.user.id
    }
  }
})
