
import Vue from 'vue'
import BookingForm from './booking-form/BookingForm.vue'
import BookingNotes from './booking-notes/BookingNotes.vue'
import PdfCutModal from '@/modules/administration/components/modals/pdf-cutter/PdfCutter.vue'
import MoveBookingItem from '@/modules/administration/components/modals/move-booking-item/MoveBookingItem.vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { Notify } from '@/globals/controllers/Notification'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { BookingItemNote } from '../../../../models/BookingItemNote'
import { BookingItemLog } from '../../../../models/BookingItemLog'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import InputField from '@/globals/components/forms/InputField.vue'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import ActionButton from './action-button/ActionButton.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import QuestionsColoredStateAction from './questions-colored-state-action/QuestionsColoredStateAction.vue'

export default Vue.extend({
  components: {
    ColoredState,
    BookingForm,
    BookingNotes,
    Dropdown,
    PdfCutModal,
    MoveBookingItem,
    InputField,
    ActionButton,
    PrimaryButton,
    QuestionsColoredStateAction
  },
  props: {
    isQuestionsActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEssentialsLoaded: false,
      bookStore: this.$store.state.book,
      dialogStore: this.$store.state.dialog,
      documentStates: new DocumentState(),
      activeTagsSelection: [] as Array<{ value: string; text: string }>,
      activeTab: 0,
      log: null as null|BookingItemLog,
      isCutPdfModal: false,
      isMoveBookingItemModal: false,
      questionsChangeStatusList: [] as Array<object>,
      tag: null as null|LedgerAccountModel
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    isBookingItemTagged (): boolean {
      return this.currentBookingItem.tag !== null
    },
    ledgerAccounts (): Array<LedgerAccountModel> {
      return this.$store.getters['administration/ledgerAccounts']
    },
    tags (): Array<LedgerAccountModel> {
      return this.ledgerAccounts.filter((ledger) => ledger.type === LedgerAccount.TagType)
    },
    tabs (): Array<any> {
      return [
        { value: 0, name: 'Factuur details', active: this.isBookingItemTagged },
        { value: 1, name: 'Vragen', active: true }
      ]
    },
    isDisabled (): boolean {
      return this.currentBookingItem.isBooked() || this.currentBookingItem.isInProgress()
    }
  },
  watch: {
    currentBookingItem () {
      this.log = null
      this.setEssentials()
    }
  },
  beforeMount () {
    this.log = null
    this.setActiveTags()
    this.setEssentials()
  },
  methods: {
    async setEssentials () {
      this.isEssentialsLoaded = false
      this.tag = this.tags.find(tag => tag.code === this.currentBookingItem.tag) ?? null
      this.activeTab = 0

      if (this.currentBookingItem.status === this.documentStates.QUESTIONS.value || this.isQuestionsActive) {
        this.switchTab(1)
      }
      this.setStatusSelections()

      if (this.currentBookingItem.status === this.documentStates.BOOKED.value) {
        await this.getBookingLogs()
      }

      this.isEssentialsLoaded = true
    },
    setStatusSelections () {
      this.questionsChangeStatusList.push(this.documentStates.QUESTIONS)
      if (this.isBookingItemTagged) {
        this.questionsChangeStatusList.push(this.documentStates.TOBEBOOKED)
      } else {
        this.questionsChangeStatusList.push(this.documentStates.TOPROGRESS)
      }
    },
    setActiveTags () {
      const activeTags = this.tags.filter(tag => tag.active)

      activeTags.forEach(tag => {
        this.activeTagsSelection.push({ value: tag.code, text: tag.name })
      })
    },

    async updateItemTag (code: any) {
      this.tag = this.tags.find(tag => tag.code === code) ?? null
      await apiAdministrationService.updateLabel(this.currentBookingItem.id, { tag: code }).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.currentBookingItem.supplier_relation_id = null
          this.saveItem()
        }
      })
    },

    async processBookingItem () {
      if (this.currentBookingItem.tag !== null) {
        await this.sendProcessBookingItem()
      } else {
        new Notify().error('Selecteer eerst een label', 'Een label is vereist om te verwerken')
      }
    },
    async sendProcessBookingItem () {
      await apiAdministrationService.tagBookingItem(this.currentBookingItem.id).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.currentBookingItem.status = this.documentStates.INPROGRESS.value
          this.saveItem()
          this.$store.dispatch('book/goToNextBookingItem')
        }
      })
    },

    updateStatus () {
      this.saveItem()
      this.setEssentials()
    },

    saveItem () {
      this.$store.dispatch('book/saveCurrentBookingItem')
    },

    showCutPdfModal () {
      this.isCutPdfModal = true
    },
    hideCutPdfModal () {
      this.isCutPdfModal = false
    },

    showMoveBookingItemModal () {
      this.isMoveBookingItemModal = true
    },
    hideMoveBookingItemModal () {
      this.isMoveBookingItemModal = false
    },

    async deleteBookingItem () {
      if (await this.confirmDeleteBookingItem()) {
        await apiAdministrationService.deleteBookingItem(this.currentBookingItem.id).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$emit('deleted', this.currentBookingItem.id)
            this.$store.commit('book/goToNextBookingItem')
          }
        })
      }
    },
    async confirmDeleteBookingItem (): Promise<boolean> {
      return await this.dialogStore.confirmDialog.open({ isDeleting: true })
    },

    async switchTab (tabValue: number) {
      this.activeTab = tabValue

      if (tabValue === 1 && this.log === null) {
        await this.getBookingLogs()
      }
    },
    async getBookingLogs () {
      await apiAdministrationService.getBookingItemLogs(this.currentBookingItem.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.log = new BookingItemLog().fromResponse(response.data)
        }
      })
    },
    addNote (note: BookingItemNote) {
      if (this.log !== null) {
        this.log.notes.push(note)
      }
    },
    async updateBookingItem () {
      await apiAdministrationService.updateBookingItem(this.currentBookingItem, false).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const bookingItem = new BookingItem().fromResponse(response.data)
          this.$store.dispatch('book/updateCurrentBookingItem', bookingItem)
        }
      })
    }
  }
})
