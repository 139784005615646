export class Product {
  id: number|null = null
  title = ''
  description = ''
  value = 0.00
  tax_id = 1
  unit = 0
  active = 1

  constructor (data?: any) {
    if (data) {
      this.id = data.id
      this.title = data.title
      this.description = data.description
      this.value = data.value
      this.tax_id = data.tax_id
      this.unit = data.unit
      this.active = data.active
    }
  }
}
