import { RoleEnum } from '@/globals/enums/Role'
import { AdministrationUserInviteStatusEnum } from '@/globals/enums/AdministrationUserStatus'

export class AdministrationUser {
  id: number|null = null
  name = ''
  last_name = ''
  email = ''
  role = RoleEnum.ENTERPRISE
  avatar_url = null
  status = AdministrationUserInviteStatusEnum.ACTIVE

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.last_name = data.last_name
      this.email = data.email
      this.role = data.role
      this.avatar_url = data.avatar_url
      this.status = data.status
    }
  }

  fromResponse (data: any) {
    this.setData(data)
    return this
  }

  getFullName (): string {
    return this.name + ' ' + this.last_name
  }
}
