
import Vue from 'vue'
import Stepper from '@/modules/auth/components/onboarding/stepper/Stepper.vue'
import CreateRelation from '@/modules/auth/components/onboarding/create-relation/CreateRelation.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'

export default Vue.extend({
  components: {
    Stepper,
    CreateRelation
  },
  data () {
    return {
      relationStore: this.$store.state.relation
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  async beforeMount () {
    window.scrollTo(0, 0)
  },
  methods: {
    navigateToFinished (skipped: boolean) {
      this.$router.replace({ name: routeHelper.authRoutes.onboarding.adviser.finished.name, query: { skipped: skipped.toString() } })
    }
  }
})
