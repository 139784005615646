
import Vue from 'vue'
import InvoiceComponent from '@/modules/invoicing/components/invoice/InvoiceComponent.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { ClientModel } from '@/globals/models/client/Client'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    InvoiceComponent
  },
  data () {
    return {
      bookStore: this.$store.state.book,
      invoicingStore: this.$store.state.invoicing,
      isEssentialsLoaded: false,
      isFirstInvoice: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    _clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    },
    invoices (): Array<Invoice> {
      return this.$store.getters['invoicing/invoices']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    ledgerAccounts (): Array<any> {
      return this.$store.getters['administration/ledgerAccounts']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.invoicingStore.invoice = new Invoice()

      await this.getClients()

      if (!this.invoices.length) {
        await this.fetchIsFirstInvoice()
      }

      if (this.isTwinfieldConnected) {
        if (this.ledgerAccounts.length === 0) {
          await this.$store.dispatch('administration/setLedgerAccounts')
        }
      }
      this.isEssentialsLoaded = true
    },
    async getClients () {
      if (!this._clients.length) {
        await this.$store.dispatch('client/setClients', this.relation.id)
      }
    },

    async fetchIsFirstInvoice () {
      await apiInvoiceService.getNextPrefix().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.isFirstInvoice = response.data.is_first
        }
      })
    },
    navigateToCompanySettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name })
    }
  }
})
