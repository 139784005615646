
import { apiAuthService } from '@/network/api/api-auth-service'
import { localAuthService } from '@/network/local/local-auth-service'
import Vue from 'vue'

export default Vue.extend({
  beforeMount () {
    if (this.$route.query.code) {
      this.acceptInvitation(this.$route.query.code.toString())
    } else {
      this.navigateToDashboard()
    }
  },
  methods: {
    async acceptInvitation (code: string) {
      await apiAuthService.acceptInvitation(code).then((response: any) => {
        sessionStorage.clear()
        localAuthService.storeToken(response.data)
        this.navigateToDashboard()
      })
    },
    navigateToDashboard () {
      this.$router.replace({ name: 'dashboard' }).catch()
    }
  }
})
