
import Vue from 'vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { RelationModel } from '@/globals/models/relation/Relation'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  components: {
    BlankSlate,
    PrimaryButton
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  methods: {
    getInvoiceNumber (invoice: Invoice) {
      return invoice.getInvoiceNumber(this.relation.invoice_prefix)
    },

    getTotalPrice (price: number) {
      return '€ ' + priceHelper.getLocalPrice(price)
    },

    getRelationName (relation: any) {
      return relation.private ? (relation.private.first_name + ' ' + relation.private.last_name) : relation.enterprise.name
    },

    getDate (date: string) {
      return dateHelper.toShortMonthDateString(new Date(date))
    },

    goToInvoice (invoice: Invoice) {
      if (invoice.id !== null) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id: invoice.id.toString() } })
      }
    }
  }
})
