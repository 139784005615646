<template>
      <div :class="{
        'colored__state': true,
        'state--info': isBackgroundPrimary,
        'state--success': isBackgroundSuccess,
        'state--warning': isBackgroundWarning,
        'state--error': isBackgroundError
        }">
        <span><i v-if="icon" :class="icon"></i> {{ label }}</span>
          <icon-base v-if="isSelection" :width="16" :height="16" :viewBox="'0 0 16 16'">
            <icon-chevron-down />
          </icon-base>
      </div>
</template>

<script>
import IconBase from '../icons/IconBase.vue'
import IconChevronDown from '../icons/IconChevronDown.vue'

export default {
  components: { IconBase, IconChevronDown },
  props: {
    label: {
      label: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false
    },
    isBackgroundPrimary: {
      type: Boolean,
      default: false
    },
    isBackgroundSuccess: {
      type: Boolean,
      default: false
    },
    isBackgroundWarning: {
      type: Boolean,
      default: false
    },
    isBackgroundError: {
      type: Boolean,
      default: false
    },
    isSelection: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/globals';

  .colored__state {
    @include flex(center, center);
    padding: 4px 8px;
    border-radius: 6px;

    &.state--info {
      background-color: var(--color-state-info);

      path {
        fill:#fff;
      }
    }
    &.state--success {
      background-color: var(--color-state-success);
    }
    &.state--warning {
      background-color: var(--color-state-warning);
    }
    &.state--error {
      background-color: var(--color-state-danger);
    }

    >span {
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      line-height: 18px;
    }
  }
</style>
