
import Vue from 'vue'

import { BookingItem } from '@/modules/administration/models/BookingItem'
import FullscreenModal from '../../../../../globals/components/modals/FullscreenModal.vue'
import BookingDetails from './booking-details/BookingDetails.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { parserHelper } from '@/globals/helpers/ParserHelper'
import BookImage from './book-image/BookImage.vue'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { RelationModel } from '@/globals/models/relation/Relation'
import { downloadHelper } from '@/globals/helpers/DownloadHelper'

export default Vue.extend({
  components: {
    FullscreenModal,
    BookingDetails,
    PrimaryButton,
    BookImage
  },
  props: {
    relationCreated: {
      type: RelationModel,
      required: true
    },
    isQuestionsActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFitWidth: true,
      isIframeActive: true,
      isLoaded: false
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    isPreviousBookingItem (): BookingItem|null {
      return this.$store.getters['book/isPreviousBookingItem']
    },
    isNextBookingItem (): BookingItem|null {
      return this.$store.getters['book/isNextBookingItem']
    },
    iframeSrc (): string {
      return this.currentBookingItem.url + '#view=fit' + (this.isFitWidth ? 'H' : '') + '&navpanes=0'
    },
    isImage (): boolean {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.currentBookingItem.url)
    },
    ledgerAccounts (): Array<LedgerAccountModel> {
      return this.$store.getters['administration/ledgerAccounts']
    },
    taxDefaults (): Array<{tax_id: number; default: number|null; ledger_account_id: number|null}> {
      return this.$store.getters['administration/taxDefaults']
    }
  },
  watch: {
    isFitWidth () {
      sessionStorage.setItem('booking_preview_fit_width', this.isFitWidth.toString())
      this.isIframeActive = false
      setTimeout(() => { this.isIframeActive = true }, 1)
    }
  },
  async beforeMount () {
    if (!this.ledgerAccounts.length) {
      await this.$store.dispatch('administration/setLedgerAccounts')
    }
    if (!this.taxDefaults.length) {
      await this.$store.dispatch('administration/setTaxDefaults')
    }

    const fromSession = sessionStorage.getItem('booking_preview_fit_width')
    if (fromSession !== null) {
      this.isFitWidth = parserHelper.stringToBool(fromSession)
    }
    this.isLoaded = true
  },
  mounted () {
    if (this.isQuestionsActive) {
      this.$emit('setQuestionsActive', false)
    }
  },
  methods: {
    goPreviousBookingItem () {
      const previousBookingItem = this.$store.getters['book/previousBookingItem']

      if (previousBookingItem) {
        this.$store.commit('book/setCurrentBookingItem', previousBookingItem)
      }
    },
    goNextBookingItem () {
      this.$store.dispatch('book/goToNextBookingItem')
    }
  }
})
