
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InvoicePartiallyPaidModal from '../partially-paid-modal/InvoicePartiallyPaidModal.vue'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    InvoicePartiallyPaidModal,
    IconBase,
    IconChevronDown
  },
  props: {
    invoiceId: {
      type: Number,
      required: true
    },
    currentPaid: {
      type: Number,
      required: true
    },
    totalInvoicePrice: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isPartiallyPaidModal: false
    }
  },
  methods: {
    async toPaid (completed: boolean, amount: number) {
      const totalPaid = this.currentPaid + amount

      if (totalPaid === this.totalInvoicePrice) {
        completed = true
      }

      await apiInvoiceService.invoiceStateToPayed(this.invoiceId, completed, totalPaid).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          if (completed) {
            this.$emit('fully_paid')
          } else {
            this.isPartiallyPaidModal = false
            this.$emit('partially_paid', totalPaid)
          }
        }
      })
    }
  }
})
