
import Vue from 'vue'
import IconBase from '../icons/IconBase.vue'
import IconChevronDown from '../icons/IconChevronDown.vue'
export default Vue.extend({
  components: { IconBase, IconChevronDown },
  props: {
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    value: {
      required: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    noDataSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    isSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    prependSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    isChips: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    value (value: any) {
      this.val = value
    }
  }
})
