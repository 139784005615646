var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'colored__state': true,
  'state--info': _vm.isBackgroundPrimary,
  'state--success': _vm.isBackgroundSuccess,
  'state--warning': _vm.isBackgroundWarning,
  'state--error': _vm.isBackgroundError
  }},[_c('span',[(_vm.icon)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.label))]),(_vm.isSelection)?_c('icon-base',{attrs:{"width":16,"height":16,"viewBox":'0 0 16 16'}},[_c('icon-chevron-down')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }