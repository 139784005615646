import { client } from '../client'
import { HttpStatus } from '../../globals/enums/HttpStatus'
import { localAuthService } from '../local/local-auth-service'
import { AdministrationUser } from '@/globals/models/user/AdministrationUser'
import { ShortcutModel } from '@/globals/models/Shortcut'

export const apiAuthService = {

  baseUrl: (window as any).appConfig.API_AUTH,
  baseUrlPrefix: (window as any).appConfig.API_AUTH + 'v1/',

  async login (data: any) {
    const url = this.baseUrl + 'login'

    return await client.post(url, data, true)
  },

  async register (data: any) {
    const url = this.baseUrl + 'register'

    return await client.post(url, data, true)
  },

  async refresh () {
    const url = this.baseUrl + 'refresh'

    const refreshPromise = client.post(url, {}, true) as Promise<any>
    await refreshPromise.then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        localAuthService.storeRefreshToken(response.data)
      }
    })

    return refreshPromise
  },

  async activate (data: any) {
    const url = this.baseUrl + 'activate'

    return await client.post(url, data)
  },

  async verifyTwoFactor (code: string, rememberMe: boolean, token?: string|undefined|null) {
    const url = this.baseUrlPrefix + 'verifyotp'
    let options
    if (token) {
      options = { headers: { Authorization: 'Bearer ' + token } }
    }

    return await client.post(url, { otp: code, remember_me: rememberMe }, false, options)
  },

  async updateUser (data: any) {
    const url = this.baseUrl + 'update'

    return await client.post(url, data)
  },

  async updatePassword (data: any) {
    const url = this.baseUrl + 'change-password'

    return await client.post(url, data)
  },

  async getUser () {
    const url = this.baseUrl + 'user'

    return await client.get(url)
  },

  async switchRole (relationId: number) {
    const url = this.baseUrl + 'relation'

    return await client.put(url, { relation_id: relationId })
  },

  async requestResetPassword (email: string) {
    const url = this.baseUrl + 'requestresetpassword'

    return await client.post(url, { email })
  },

  async resetPassword (password: string, code: string) {
    const url = this.baseUrl + 'resetpassword'

    return await client.post(url, { password, password_confirm: password, code })
  },

  // Prefix endpoint
  async changeAvatar (data: FormData) {
    const url = this.baseUrlPrefix + 'user/avatar'

    return await client.post(url, data)
  },

  async getUsers () {
    const url = this.baseUrlPrefix + 'users'

    return await client.get(url)
  },

  async getAllUsers () {
    const url = this.baseUrlPrefix + 'all'

    return await client.get(url)
  },

  async createUser (data: any) {
    const url = this.baseUrlPrefix + 'create'

    return await client.post(url, data)
  },

  async acceptInvitation (code: string) {
    const url = this.baseUrlPrefix + 'acceptinvitation'

    return await client.post(url, { code })
  },

  async updateForUser (data: AdministrationUser, userId: number) {
    const url = this.baseUrlPrefix + 'user/' + userId

    return await client.put(url, data)
  },

  // Relation endpoints
  async getUsersFromAdministration (relationId: number) {
    const url = this.baseUrlPrefix + 'relation/' + relationId + '/users'

    return await client.get(url)
  },

  // Shortcut endpoints
  async getShortcuts () {
    const url = this.baseUrlPrefix + 'shortcut'

    return await client.get(url)
  },

  async createShortcut (shortcut: ShortcutModel) {
    const url = this.baseUrlPrefix + 'shortcut'

    return await client.post(url, shortcut)
  },

  async updateShortcut (shortcut: ShortcutModel) {
    const url = this.baseUrlPrefix + 'shortcut/' + shortcut.id

    return await client.put(url, shortcut)
  },

  async deleteShortcut (id: number) {
    const url = this.baseUrlPrefix + 'shortcut/' + id

    return await client.delete(url)
  }
}
