
import Vue from 'vue'

import { InvoiceProduct } from '@/modules/invoicing/models/invoice/InvoiceProduct'
import { Tax } from '@/modules/invoicing/enums/Tax'
import CreateProductModal from '@/modules/invoicing/components/product/create-update/CreateUpdateProduct.vue'
import { Product } from '@/modules/invoicing/models/product/Product'
import { ProductUnit } from '@/modules/invoicing/enums/ProductUnit'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import ComboBox from '@/globals/components/forms/ComboBox.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { VatEnum } from '@/globals/enums/Vat'

export default Vue.extend({
  props: {
    errorModel: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InputField,
    Dropdown,
    ComboBox,
    CreateProductModal
  },
  data () {
    return {
      invoicingStore: this.$store.state.invoicing,
      userStore: this.$store.state.user,
      isEssentialsLoaded: false,
      products: [] as Array<Product>,
      productsSelectionList: [] as Array<{ value: number; text: string }>,
      taxes: new VatEnum().getVats(),
      taxesSelectionList: [] as Array<any>,
      costCenters: [] as Array<any>,
      projects: [] as Array<any>,
      discounts: [
        {
          text: '%',
          value: 'percentage'
        },
        {
          text: '€',
          value: 'value'
        }
      ],
      discount: 'percentage',
      isCreateProductModalActive: false,
      productUnit: new ProductUnit(),
      priceHelper,
      invoiceState: new InvoiceStatus(),
      isFirstInvoiceProductTouched: false
    }
  },
  computed: {
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    },
    errors (): Array<any> {
      const productErrors = [] as Array<any>

      this.invoice.products.forEach(product => {
        const errorModel = this.getValidationErrors(product)
        productErrors.push(errorModel)
      })

      return productErrors
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    ledgerAccounts (): Array<any> {
      return this.$store.getters['administration/ledgerAccounts']
    },

    isStatePartiallyPaid (): boolean {
      return this.invoice.status === this.invoiceState.partially_paid.value
    },
    isAllTitlesFilled (): boolean {
      let filled = true

      for (let i = 0; i < this.invoice.products.length; i++) {
        if (!this.invoice.products[i].title) {
          filled = false
        }
      }

      return filled
    },
    calcSubTotal () {
      let subTotal = 0.00 as any

      this.invoice.products.forEach((product: InvoiceProduct) => {
        subTotal += this.getProductPrice(product)
      })

      return subTotal
    },
    calcVats () {
      const vats = {} as any
      let btwShifted = 0

      this.invoice.products.forEach((product: InvoiceProduct) => {
        const price = this.getTotalProductPrice(product)

        let taxPercentage = 0
        const productTax = this.getTaxFromId(product.tax_id)
        if (productTax) {
          taxPercentage = productTax.percentage
        }

        if (product.tax_id === Tax.BTW_VERLEGD) {
          btwShifted += price
        } else if (taxPercentage > 0) {
          const tax = 'BTW (' + taxPercentage + '%)'

          if (vats[tax] == null) { vats[tax] = 0 }

          let vatPrice = price / 100 * taxPercentage

          if (this.invoice.incl_tax) {
            vatPrice = price / (100 + taxPercentage) * taxPercentage
          }

          vats[tax] = vats[tax] + vatPrice
          // vats[tax] = Math.round(((vats[tax] + vatPrice) + Number.EPSILON) * 100) / 100
        }
      })

      if (btwShifted !== 0) {
        const price = this.toFormatPrice(btwShifted)
        const key = 'BTW Verlegd (EUR ' + price + ')'
        vats[key] = 0
      }

      return vats
    },
    calcVatsTotal (): number {
      let total = 0

      // eslint-disable-next-line
      for (const [key, value] of Object.entries(this.calcVats)) {
        total = total + (value as number)
      }
      return total
    }
  },
  beforeMount () {
    if (this.invoice.products.length === 0) {
      this.addInvoiceProduct()
    }
    this.setDiscount()
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getProducts()
      this.buildProductsSelectionList()
      await this.getTaxes()
      this.setLedgerAccounts()
      this.isEssentialsLoaded = true
    },
    setDiscount () {
      if (this.invoice.discount_value > 0) { this.discount = 'value' }
    },

    setLedgerAccounts () {
      this.ledgerAccounts.forEach((ledgerAccount: any) => {
        if (ledgerAccount.type === LedgerAccount.CostCenter) {
          this.costCenters.push(this.getLedgerToSelection(ledgerAccount.id, ledgerAccount.code, ledgerAccount.name))
        } else if (ledgerAccount.type === LedgerAccount.Projects) {
          this.projects.push(this.getLedgerToSelection(ledgerAccount.id, ledgerAccount.code, ledgerAccount.name))
        }
      })
    },
    getLedgerToSelection (id: number, code: string, name: string): object {
      return { value: id, text: code + ' - ' + name }
    },

    async getProducts () {
      await apiInvoiceService.getAllProducts().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.products = response.data
        }
      })
    },

    buildProductsSelectionList () {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].active) {
          this.addToProductsSelectionList(this.products[i].id, this.products[i].title)
        }
      }
    },
    addToProductsSelectionList (value: any, text: string) {
      this.productsSelectionList.push({ value, text })
    },

    getTaxes () {
      this.taxes.forEach(tax => {
        this.taxesSelectionList.push({ value: tax.value, text: tax.text, disabled: false })
      })
    },

    getTaxFromId (taxId: number): any {
      const tax = this.taxes.find(x => x.value === taxId)

      return tax
    },

    getTaxNameFromId (taxId: number): string {
      let name = ''
      const tax = this.getTaxFromId(taxId)
      if (tax) {
        name = tax.name
      }

      return name
    },

    setProductAsSelected (product: InvoiceProduct, e: any) {
      if (e !== null) {
        if (typeof e === 'string') {
          product.product_id = null
        } else {
          product.product_id = e.value
        }
      }

      const baseProduct = this.products.find(x => x.id === product.product_id)
      if (baseProduct) {
        product.product_id = baseProduct.id
        product.value = baseProduct.value
        product.title = baseProduct.title
        product.description = baseProduct.title
        product.tax_id = baseProduct.tax_id as number
        product.unit = baseProduct.unit as number
      }
    },

    isDiscount (): boolean {
      return this.invoice.discount_value > 0 || this.invoice.discount_percentage > 0
    },
    clearDiscountFields () {
      this.invoice.discount_percentage = 0
      this.invoice.discount_value = 0.00
    },
    disableVats () {
      this.taxesSelectionList.forEach((tax: any) => {
        if (tax.value > 3) {
          tax.disabled = true
        }
      })
    },
    enableVats () {
      this.taxesSelectionList.forEach((tax: any) => {
        tax.disabled = false
      })
    },
    removeProduct (index: number) {
      this.invoice.products.splice(index, 1)
    },
    getProductPrice (product: InvoiceProduct) {
      let value = product.value

      if (Number.isNaN(value)) {
        value = 0
      }

      const price = product.amount * value

      return price
    },
    getPercentageFromValue (value: number, divideBy: number) {
      return (value / divideBy) * 100
    },
    getPriceWithDiscountPercentage (price: number, percentage: number) {
      const priceWithDiscountPercentage = 100 - percentage

      return (price / 100) * priceWithDiscountPercentage
    },
    getPriceWithDiscountValue (price: any) {
      // het kortingsbedrag delen door het totaalbedrag ex btw en dan heb je je kortingspercentage en dan is de formule hetzelfde
      const percentageFromValue = this.getPercentageFromValue(this.invoice.discount_value, this.calcSubTotal)

      return this.getPriceWithDiscountPercentage(price, percentageFromValue)
    },
    applyDiscounts (price: number) {
      if (this.isDiscount()) {
        price = this.getPriceWithDiscountPercentage(price, this.invoice.discount_percentage)
        price = this.getPriceWithDiscountValue(price)
      }

      return price
    },
    applyCreditPrice (price: number) {
      if (this.isCredit()) { return -1 * price } else { return price }
    },
    isCredit (): boolean {
      return this.invoice.is_credit
    },
    getTotalProductPrice (product: InvoiceProduct) {
      let price = this.getProductPrice(product)
      price = this.applyDiscounts(price)

      return price
    },
    calcTotalPriceExVat () {
      return this.calcSubTotal - this.getDiscountPrice()
    },
    calcTotalPrice () {
      const vats = this.calcVats
      let total = this.applyDiscounts(this.calcSubTotal)

      for (const vat in vats) {
        total += vats[vat]
      }

      return this.applyCreditPrice(total)
    },
    getDiscountPrice () {
      let discount = 0
      if (this.discount === 'percentage') { discount = (this.invoice.discount_percentage * this.calcSubTotal) / 100 } else { discount = this.invoice.discount_value }

      return discount
    },
    toFormatPrice (price: any) {
      price = this.applyCreditPrice(price)
      return priceHelper.getLocalPrice(price)
    },

    mayCreateNewRow () {
      let mayCreate = true

      if (this.invoice.products.length > 0) {
        const latestRow = this.invoice.products.length - 1
        if (!this.invoice.products[latestRow].title || !this.invoice.products[latestRow].amount || this.invoice.products[latestRow].value === null || !this.invoice.products[latestRow].tax_id) { mayCreate = false }
      }

      return mayCreate
    },
    addInvoiceProduct () {
      if (this.mayCreateNewRow()) {
        const invoiceProduct = new InvoiceProduct()
        this.invoice.products.push(invoiceProduct)
      }
    },

    showCreateProductModal () {
      this.isCreateProductModalActive = true
    },

    hideCreateProductModal () {
      this.isCreateProductModalActive = false
    },

    onProductCreated (productRaw: any) {
      this.products.push(productRaw)
      this.productsSelectionList.unshift({ value: productRaw.id, text: productRaw.title })

      for (let i = 0; i < this.invoice.products.length; i++) {
        if (!this.invoice.products[i].id) {
          this.invoice.products[i].id = productRaw.id
          this.setProductAsSelected(this.invoice.products[i], null)
        }
      }

      this.hideCreateProductModal()
    },

    onInputProductTitle (index: number, e: any) {
      this.setProductAsSelected(this.invoice.products[index], e)
      this.addInvoiceProduct()
    },

    getValidationErrors (product: InvoiceProduct): object {
      const errors = entityHelper.generateErrorEntity(new InvoiceProduct()) as any

      if (product.isTouched) {
        if (!product.amount) {
          errors.amount.push('Verplicht')
        }

        if (!product.title) {
          errors.title.push('Verplicht')
        }

        if (product.value === null) {
          errors.value.push('Verplicht')
        }
      }

      return errors
    }
  }
})
