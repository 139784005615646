import { AdministrationUser } from '../user/AdministrationUser'
export class RelationUserModel {
  id: number|null = null
  relation_id = 0
  user_id = 0
  type = 0
  is_default = 0
  is_owner = 0
  status = 0
  user = new AdministrationUser()

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.relation_id = data.relation_id
      this.user_id = data.user_id
      this.type = data.type
      this.is_default = data.is_default
      this.is_owner = data.is_owner
      this.status = data.status
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    if (data.user) {
      this.user = new AdministrationUser().fromResponse(data.user)
    }

    return this
  }
}
