
import Vue from 'vue'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import { BookingItem } from '@/modules/administration/models/BookingItem'

export default Vue.extend({
  components: {
    ColoredState
  },
  data () {
    return {
      documentStatus: new DocumentState()
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    }
  }
})
