export class TablePaginationModel {
  page = 1
  itemsPerPage = 10
  pageStart = 0
  pageStop = 0
  pageCount = 0
  itemsLength = 0

  constructor (data?: TablePaginationModel) {
    if (data) {
      this.page = data.page
      this.itemsPerPage = data.itemsPerPage
      this.pageStart = data.pageStart
      this.pageStop = data.pageStop
      this.pageCount = data.pageCount
      this.itemsLength = data.itemsLength
    }
  }
}
