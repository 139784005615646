
import Vue from 'vue'
import Chart from 'chart.js/auto'

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'line',
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null as any
    }
  },
  watch: {
    data () {
      this.chart.data = this.data
      this.chart.update()
    }
  },
  mounted () {
    this.chartConstructor(this.type, this.data, this.options)
  },
  methods: {
    chartConstructor (chartType: any, chartData: any, chartOptions: any) {
      const chartElement = this.$refs.canvas as any // document.querySelector('canvas') as any
      // eslint-disable-next-line
      this.chart = new Chart(chartElement, {
        type: chartType,
        data: chartData,
        options: chartOptions
      })
    }
  }
})
