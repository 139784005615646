
import Vue from 'vue'
import Counter from '../Counter.vue'
export default Vue.extend({
  components: { Counter },
  props: {
    counters: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  }
})
