
import { dateHelper } from '@/globals/helpers/DateHelper'
import { TaskStatusEnum } from '@/modules/task/enums/TaskStatus'
import { TaskModel } from '@/modules/task/models/Task'
import Vue from 'vue'
import OpenTask from './OpenTask.vue'
export default Vue.extend({
  components: { OpenTask },
  props: {
    tasks: {
      type: Array,
      required: true
    },
    year: {
      type: Number,
      default: new Date().getFullYear()
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      taskStatus: new TaskStatusEnum(),
      dateHelper
    }
  },
  computed: {
    filteredTasks (): TaskModel[] {
      return (this.tasks as TaskModel[]).filter(task => {
        return this.filterNotDone(task) && this.filterYear(task)
      })
    }
  },
  methods: {
    filterNotDone (task: TaskModel): boolean {
      return task.status !== this.taskStatus.Done.value
    },
    filterYear (task: TaskModel): boolean {
      return task.period_at !== null && dateHelper.toDate(task.period_at).getFullYear() === this.year
    }
  }
})
