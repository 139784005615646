
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  data () {
    return {
      isEssentialsLoaded: false,
      organisations: [] as Array<any>,
      twinfieldOffice: null
    }
  },
  computed: {
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.setConnections()
      await this.fetchOrganisations()
      this.isEssentialsLoaded = true
    },

    async setConnections () {
      await apiAdministrationService.checkConnection().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.commit('user/setConnections', response.data)
          this.twinfieldOffice = response.data.office
        }
      })
    },

    async fetchOrganisations () {
      await apiAdministrationService.getOrganisations().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.organisations = response.data
        }
      })
    },

    async authorizeTwinfield () {
      await apiAdministrationService.authorizeTwinfield().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          location.href = response.data
        }
      })
    }
  }
})
