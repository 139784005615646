import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import loading from './modules/loading'
import network from './modules/network'
import dialog from './modules/dialog'
import user from './modules/user'
import relation from './modules/relation'
import client from './modules/client'
import administration from './modules/administration'
import book from './modules/book'
import task from '@/modules/task/store/store'
import invoicing from './modules/invoicing'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    loading,
    network,
    dialog,
    user,
    relation,
    client,
    administration,
    book,
    task,
    invoicing
  }
})
