
import Vue from 'vue'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronRight from '@/globals/components/icons/IconChevronRight.vue'
import { QuestionModel } from '@/modules/question/models/Question'
import { localAuthService } from '@/network/local/local-auth-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { BookingItem } from '@/modules/administration/models/BookingItem'
export default Vue.extend({
  components: { Avatar, IconBase, IconChevronRight },
  props: {
    questions: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Vraag', value: 'name', width: 'auto' },
        { text: 'Ontvangen', value: 'created_at', width: 200 },
        { text: '', value: 'actions', sortable: false, align: 'end', width: 64 }
      ],
      clickedQuestionId: 0
    }
  },
  computed: {
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant'] as boolean
    }
  },
  methods: {
    toDateTimeFormat (date: string): string {
      return dateHelper.toFullDateHourMinute(date)
    },
    async navigateToQuestion (question: QuestionModel) {
      this.clickedQuestionId = question.id

      if (this.isAccountant) {
        const relationId = question.relation_id
        await localAuthService.switchToAdministration(relationId, true)
      }

      await this.fetchBookingItem(question.booking_item_id)
      this.clickedQuestionId = 0
      this.$emit('itemClicked', question)
    },
    async fetchBookingItem (id: number) {
      await apiAdministrationService.getBookingItem(id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const bookingItem = new BookingItem().fromResponse(response.data)
          this.$store.commit('book/setCurrentBookingItem', bookingItem)
        }
      })
    },
    navigateToBookingItem (id: number) {
      this.$router.push({ name: routeHelper.administrationRoutes.administration.document.overview.name, query: { id: id.toString() } })
    }
  }
})
