export const RouteNames = {
  relation: {
    name: 'relation',
    overview: {
      name: 'relation_overview'
    },
    create: {
      name: 'relation_create'
    },
    edit: {
      name: 'relation_edit'
    }
  }
}
