
import Vue from 'vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import TimePicker from '@/globals/components/timepicker/TimePicker.vue'
import { TaskModel } from '@/modules/task/models/Task'
import { apiTaskService } from '@/network/api/api-task-service'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import InputField from '@/globals/components/forms/InputField.vue'
import { TaskStatusEnum } from '@/modules/task/enums/TaskStatus'
import Comments from './comments/Comments.vue'
import { TaskModelRequest } from '@/modules/task/models/request/Task'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { timeHelper } from '@/globals/helpers/TimeHelper'
import { CommentModel } from '@/modules/task/models/Comment'
import Attachments from './attachments/Attachments.vue'
import { UserModel } from '@/globals/models/user/User'
import { apiAuthService } from '@/network/api/api-auth-service'
import { AdministrationUser } from '@/globals/models/user/AdministrationUser'
import { apiRelationService } from '@/network/api/api-relation-service'
import { RelationModel } from '@/globals/models/relation/Relation'
import { AdministrationTypeEnum } from '@/globals/enums/AdministrationType'
import { ClientModel } from '@/globals/models/client/Client'
import { TaskUserModel } from '@/modules/task/models/TaskUser'
import TextArea from '@/globals/components/forms/TextArea.vue'
import { AdministrationModel } from '@/globals/models/Administration'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    InputField,
    Dropdown,
    DatePicker,
    TimePicker,
    Comments,
    Attachments,
    TextArea,
    PrimaryButton
  },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      isEssentialsLoaded: false,
      taskStatusEnum: new TaskStatusEnum(),
      dateHelper,
      timeHelper,
      errors: entityHelper.generateErrorEntity(new TaskModel()) as any,
      administrationUsers: [] as AdministrationUser[],
      administrationRelations: [] as RelationModel[],
      administrationRelationUsers: [] as RelationModel[],
      isLoadingUsers: false
    }
  },
  computed: {
    task: {
      get (): TaskModel {
        return this.$store.getters['task/task']
      },
      set (task: TaskModel) {
        this.$store.dispatch('task/setSelectedTask', task)
        return task
      }
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },

    assignedTo (): TaskUserModel {
      return this.task.getAssignedTo()
    },

    createdBy (): TaskUserModel {
      return this.task.getCreatedBy()
    },

    sharedToIds (): Array<number> {
      const ids = [] as Array<number>
      this.task.getSharedTo().forEach(taskUser => {
        ids.push(taskUser.user_id)
      })
      return ids
    },
    administrationUserList (): { id: number; name: string; email: string }[] {
      const list = [] as { id: number; name: string; email: string }[]
      this.administrationUsers.forEach(administrationUser => {
        const user = {
          id: administrationUser.id ?? 0,
          name: administrationUser.getFullName(),
          email: administrationUser.email
        }
        list.push(user)
      })
      return list
    },
    administrationRelationUserList (): { id: number; name: string; email: string }[] {
      const list = [] as { id: number; name: string; email: string }[]
      this.administrationRelationUsers.forEach(administrationRelationUser => {
        const user = {
          id: administrationRelationUser.relation_user?.user_id ?? 0,
          name: administrationRelationUser.relation_user?.user.getFullName() ?? '',
          email: administrationRelationUser.relation_user?.user.email ?? ''
        }
        list.push(user)
      })
      return list
    },
    administrationRelationList (): { id: number; name: string }[] {
      const list = [] as { id: number; name: string }[]
      this.administrationRelations.forEach(administrationRelation => {
        const user = {
          id: administrationRelation.id,
          name: administrationRelation.getRelationName(),
          email: administrationRelation.email
        }
        list.push(user)
      })
      return list
    },

    assignedToList (): { id: number; name: string; email: string }[] {
      const res = [] as { id: number; name: string; email: string }[]
      let users = this.administrationUserList
      users = users.concat(this.administrationRelationUserList)

      if (!this.task.isAssignedToUser(this.user.id)) {
        users.unshift({
          id: this.task.getAssignedTo().user_id,
          name: this.task.getAssignedTo().user.getName(),
          email: this.task.getAssignedTo().user.email
        })
      }

      users.unshift({
        id: this.user.id,
        name: 'Jezelf',
        email: this.user.email
      })

      users.forEach(user => {
        const foundIndex = res.findIndex(res => res.id === user.id)
        if (foundIndex === -1) {
          res.push(user)
        }
      })

      return res
    },
    sharedToList (): { id: number; name: string; email: string }[] {
      const res = [] as { id: number; name: string; email: string }[]
      let users = this.administrationUserList
      users = users.concat(this.administrationRelationUserList)

      this.task.getSharedTo().forEach(sharedTo => {
        users.unshift({
          id: sharedTo.user_id,
          name: sharedTo.user.getName() ?? '',
          email: sharedTo.user.email ?? ''
        })
      })

      users.forEach(user => {
        const foundIndex = res.findIndex(res => res.id === user.id)
        if (foundIndex === -1) {
          res.push(user)
        }
      })

      return res.filter(user => user.id !== this.task.getAssignedTo().user_id && user.id !== this.task.getCreatedBy().user_id && user.id !== this.user.id)
    },
    relationToList (): { id: number; name: string }[] {
      const res = [] as { id: number; name: string }[]
      const list = this.administrationRelationList

      if (this.task.relation_to.id) {
        res.unshift({
          id: this.task.relation_to.id,
          name: this.task.relation_to.getRelationName()
        })
      }

      list.forEach(relation => {
        const foundIndex = res.findIndex(res => res.id === relation.id)
        if (foundIndex === -1) {
          res.push(relation)
        }
      })

      return res
    },
    administrations (): Array<AdministrationModel> {
      return this.$store.getters['relation/administrations']
    },
    defaultAdministration (): AdministrationModel|null {
      return this.$store.getters['relation/defaultAdministration']
    },
    isSharedToMe (): boolean {
      return this.task.isSharedToUser(this.user.id)
    },
    isEditDisabled (): boolean {
      return this.isSharedToMe
    },
    isCreatedByMe (): boolean {
      return this.task.isCreatedByUser(this.user.id)
    },
    isAssignedToMe (): boolean {
      return this.task.isAssignedToUser(this.user.id)
    },
    isWaitingForResponse (): boolean {
      return this.isCreatedByMe && !this.isAssignedToMe
    }
  },
  watch: {
    task () {
      if (this.task.id) {
        if (this.task.isNew()) {
          this.setFocusTitle()
        }
        this.loadEssentials()
      }
    }
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false

      if (!this.task.isCommentsLoaded) {
        await this.task.fetchComments()
      }

      if ((this.isAssignedToMe || this.isCreatedByMe) && !this.isAdministrationsSet()) {
        await this.setAdministrationLists()
      }

      this.isEssentialsLoaded = true
      this.task.isCommentsLoaded = true
    },

    setFocusTitle () {
      const title = this.$refs.title as any
      title.$refs.input.focus()
    },

    async updateAssignedTo (id: number) {
      const sharedIndex = this.task.sharedToIds.findIndex(sharedId => sharedId === id)
      if (sharedIndex >= 0) {
        this.task.sharedToIds.splice(sharedIndex, 1)
      }
      const newUser = this.assignedToList.find(item => item.id === id)
      let oldName = this.task.getAssignedTo().user.getName()
      let newName = newUser?.name ?? ''

      if (this.isCreatedByMe) {
        oldName = this.createdBy.user.getName()
      }
      if (this.createdBy.user_id === id) {
        newName = 'zichzelf'
      }

      const log = 'Deze taak is door ' + oldName + ' toegewezen aan ' + newName
      await this.createCommentLog(log)
      this.task.getAssignedTo().user_id = id
      await this.updateTask()
    },

    async updateTitle (value: string) {
      if (value !== '') {
        const log = 'Titel is gewijzigd naar ' + value
        await this.createCommentLog(log)
      }
      if (!this.task.isNew()) {
        await this.updateTask()
      }
    },

    async updatePeriodAt (value: string|null) {
      if (value) {
        value = dateHelper.toDateString(value)
      }

      if (this.task.period_at !== value) {
        this.task.period_at = value
        let log = 'Periode is gewijzigd naar "geen datum"'
        if (this.task.period_at) {
          log = 'Periode is gewijzigd naar ' + dateHelper.toLongMonthYearString(this.task.period_at)
        }
        await this.createCommentLog(log)
        await this.updateTask()
      }
    },

    async updatePlannedAt (value: string|null) {
      if (this.task.planned_at !== value) {
        this.task.planned_at = value
        let log = 'Planningsdatum is gewijzigd naar "geen datum"'
        if (this.task.planned_at) {
          log = 'Planningsdatum is gewijzigd naar ' + dateHelper.toShortMonthDateString(this.task.planned_at)
        }
        await this.createCommentLog(log)
        await this.updateTask()
      }
    },

    async updateDuration (value: number) {
      if (this.task.duration !== value) {
        this.task.duration = value
        let log = 'Duur is gewijzigd naar "geen duur"'
        if (this.task.duration) {
          log = 'Duur is gewijzigd naar ' + this.task.getDurationTimeString()
        }
        await this.createCommentLog(log)
        await this.updateTask()
      }
    },

    async updateTask () {
      this.task.isUpdating = true

      await apiTaskService.updateTask(new TaskModelRequest(this.user.id, this.task)).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.task.fromResponse(response.data)
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      }).finally(() => { this.task.isUpdating = false })
    },

    async createCommentLog (log: string) {
      await apiTaskService.createComment(this.task.id, log, true).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const comment = new CommentModel().fromResponse(response.data)
          this.task.comments.push(comment)
        }
      })
    },

    isAdministrationsSet (): boolean {
      return this.administrationUsers.length > 0 || this.administrationRelations.length > 0 || this.administrationRelationUsers.length > 0
    },

    async setAdministrationLists () {
      this.isLoadingUsers = true

      for (let i = 0; i < this.administrations.length; i++) {
        const administration = this.administrations[i]
        const administrationId = administration.relation.id

        const administrationUsers = await this.getAdministrationUsers(administrationId)
        this.administrationUsers = this.administrationUsers.concat(administrationUsers)

        if (administration?.type === AdministrationTypeEnum.ACCOUNTING) {
          const administrationRelations = await this.getAdministrationRelationsUsers(administrationId)
          const administrationRelationUsers = [] as Array<RelationModel>

          administrationRelations.forEach(relation => {
            if (relation.relation_user !== null) {
              administrationRelationUsers.push(relation)
            }
          })
          this.administrationRelations = this.administrationRelations.concat(administrationRelations)
          this.administrationRelationUsers = this.administrationRelationUsers.concat(administrationRelationUsers)
        } else {
          const clients = await this.getClients(administrationId)
          this.administrationRelations = this.administrationRelations.concat(clients)
          const parentUsers = await this.getParentUsers(administrationId)
          this.administrationUsers = this.administrationUsers.concat(parentUsers)
        }
      }

      this.isLoadingUsers = false
    },

    async getAdministrationUsers (relationId: number): Promise<AdministrationUser[]> {
      const users = [] as Array<AdministrationUser>
      await apiAuthService.getUsersFromAdministration(relationId).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((userRaw: any) => {
            const user = new AdministrationUser().fromResponse(userRaw)
            users.push(user)
          })
        }
      })
      return users
    },

    async getAdministrationRelationsUsers (relationId: number): Promise<RelationModel[]> {
      const relations = [] as RelationModel[]
      await apiRelationService.getAdministrationRelationsUsers(relationId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((administrationRelation: any) => {
            const relation = new RelationModel().fromResponse(administrationRelation.enterprise)
            relations.push(relation)
          })
        }
      })
      return relations
    },
    async getClients (administrationId: number): Promise<RelationModel[]> {
      const clients = [] as RelationModel[]
      await apiRelationService.getClients(administrationId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((clientRaw: any) => {
            const client = new ClientModel().fromResponse(clientRaw)
            clients.push(client.client)
          })
        }
      })
      return clients
    },
    async getParentUsers (administrationId: number): Promise<AdministrationUser[]> {
      let administrationUsers = [] as AdministrationUser[]
      await apiRelationService.getParentRelation(administrationId).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          if (response.data) {
            administrationUsers = await this.getAdministrationUsers(response.data.relation_id)
          }
        }
      })
      return administrationUsers
    }
  }
})
