
import Vue from 'vue'

import InputField from '@/modules/auth/components/form-components/InputField.vue'
import SubmitButton from '@/modules/auth/components/form-components/SubmitButton.vue'

import { User } from '@/modules/auth/models/User'
import { apiAuthService } from '@/network/api/api-auth-service'
import { localAuthService } from '@/network/local/local-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiRelationService } from '@/network/api/api-relation-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import TwoFactor from '../twofactor/TwoFactor.vue'
import { RedirectWhitelist } from './RedirectWhitelist'

export default Vue.extend({
  components: {
    InputField,
    SubmitButton,
    TwoFactor
  },
  props: {
    redirect: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      user: new User() as User,
      errors: entityHelper.generateErrorEntity(new User()) as any,
      remember: false,
      registerRoute: routeHelper.authRoutes.register.name,
      forgotPasswordRoute: routeHelper.authRoutes.forgotPassword.name
    }
  },
  computed: {
    isLive (): boolean {
      return this.$store.getters['app/isLive']
    }
  },
  methods: {
    checkLoginForm (e: any) {
      e.preventDefault()
      this.errors = entityHelper.generateErrorEntity(new User())
      let error = false

      if (!this.user.email) {
        this.errors.email.push('E-mail is verplicht.')
        error = true
      }
      if (!this.user.password) {
        this.errors.password.push('Wachtwoord is verplicht.')
        error = true
      }

      if (!error) {
        this.login()
      }
    },
    async login () {
      await apiAuthService.login(this.user).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const token = response.data

          let isValidCode = false
          if (token.qr_code_url && token.qr_code_url === 'valid') {
            isValidCode = true
          } else {
            isValidCode = this.isLive ? await (this.$refs.twofactor as any).open(token.token, token.qr_code_url) : true
          }

          if (isValidCode) {
            await localAuthService.handlePostLogin(token)
            await this.getRelation()

            if (this.redirect && RedirectWhitelist.findIndex(i => i === this.redirect) >= 0) {
              this.$router.push(this.redirect)
            } else {
              this.$router.push({ name: routeHelper.dashboardRoutes.dashboard.name })
            }
          }
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        } else {
          this.errors.general.push('Email of wachtwoord is onjuist')
        }
      })
    },
    async getRelation () {
      await apiRelationService.getDefaultRelation().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.commit('relation/setRelation', response.data.relation)
          this.$store.commit('relation/setIsOwner', response.data.is_owner)
        }
      })
    },
    closeTwoFactorModal () {
      (this.$refs.twofactor as any).cancel()
    }
  }
})
