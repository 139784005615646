
import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiRelationService } from '@/network/api/api-relation-service'
import { ClientModel } from '../../../../../globals/models/client/Client'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      dialogStore: this.$store.state.dialog,
      selected: [],
      headers: [
        { text: 'Bedrijfsnaam', value: 'company' },
        { text: 'Naam', value: 'name' },
        { text: 'E-mail', value: 'email' },
        { text: 'Soort', value: 'type' }
      ],
      relations: [] as Array<any>
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    }
  },
  watch: {
    clients () {
      this.buildClientsTable(this.clients)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      if (!this.clients.length) {
        await this.$store.dispatch('client/setClients', this.relation.id)
      } else {
        this.buildClientsTable(this.clients)
      }
      this.essentialsLoaded = true
    },

    buildClientsTable (clients: Array<ClientModel>) {
      this.relations = []
      clients.forEach(client => {
        if (client.client.active) {
          this.insertRelation(client)
        }
      })
    },
    insertRelation (client: ClientModel) {
      const relation = new RelationModel(client.client)
      const tableItem = {
        relation,
        id: relation.id,
        name: relation.getContactName(),
        company: relation.enterprise != null ? relation.enterprise.name : '',
        email: relation.email,
        type: client.isClient() ? 'Klant' : 'Leverancier',
        row_class: relation.hidden ? 'relation--hidden' : ''
      }

      this.relations.push(tableItem)
    },

    isRelations (): boolean {
      return this.relations.length > 0
    },

    navigateToDashboard () {
      this.$router.push({ name: 'dashboard' })
    },

    navigateToEditRelation (relationId: number) {
      const id = relationId.toString()
      this.$router.push({ name: routeHelper.relationRoutes.relation.edit.name, params: { id } })
    },

    async deactivateRelation (item: any) {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze relatie wil verwijderen?', isDeleting: true })

      if (isConfirmed) {
        await apiRelationService.deactivateRelation(this.relationId, item.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            const index = this.relations.indexOf(item)
            this.relations.splice(index, 1)
          }
        })
      }
    }
  }
})
