
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    toMinutes: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      time: null as null|string
    }
  },
  watch: {
    value () {
      this.time = this.toMinutes ? this.parseMinutesToTime(this.value as number) : this.value as string
    },
    menu () {
      if (!this.menu) {
        this.onTimeSelect()
      }
    }
  },
  methods: {
    onTimeSelect () {
      let value = this.time as any
      if (this.toMinutes) {
        value = this.parseMinutes(value)
      }
      this.$emit('input', value)
    },
    parseMinutes (time: string): number {
      const Hm = time.split(':') as Array<string>
      return (parseInt(Hm[0]) * 60) + parseInt(Hm[1])
    },
    parseMinutesToTime (minutes: number): string {
      const m = minutes % 60
      const h = (minutes - m) / 60

      return h.toString() + ':' + (m < 10 ? '0' : '') + m.toString()
    }
  }
})
