export class TokenModel {
  expires_in = 0
  is_owner = 0
  relation_id = 0
  token = ''
  token_type = ''
  type = 0

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: TokenModel) {
    if (data) {
      this.expires_in = data.expires_in
      this.is_owner = data.is_owner
      this.relation_id = data.relation_id
      this.token = data.token
      this.token_type = data.token_type
      this.type = data.type
    }
  }

  fromResponse (data: TokenModel) {
    this.setData(data)

    return this
  }
}
