// @ts-ignore
import { CurrencyEnum } from '@/globals/enums/CurrencyEnum'
import { EnterpriseRelationModel } from './EnterpriseRelation'
import { PrivateRelationModel } from './PrivateRelation'
import { AdministrationRelationModel } from './AdministrationRelation'
import { RelationUserModel } from './RelationUser'
export class RelationModel {
  id = 0
  type: number|null = null
  mobile = ''
  address = ''
  postal_code = ''
  city = ''
  bank_account = ''
  email = ''
  country = ''
  email_text = ''
  logo_url = ''
  invoice_prefix = ''
  note = ''
  payment_period: number|null = null
  active = true
  hidden = true

  // @ts-ignore
  currency: number = new CurrencyEnum().euro.value
  enterprise: EnterpriseRelationModel|null = null
  private: PrivateRelationModel|null = null
  administration_relation: AdministrationRelationModel|null = null
  relation_user = null as RelationUserModel|null

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.type = data.type
      this.mobile = data.mobile
      this.address = data.address
      this.postal_code = data.postal_code
      this.city = data.city
      this.bank_account = data.bank_account
      this.email = data.email
      this.country = data.country
      this.email_text = data.email_text
      this.logo_url = data.logo_url
      this.payment_period = data.payment_period
      this.currency = data.currency
      this.invoice_prefix = data.invoice_prefix
      this.note = data.note
      this.enterprise = data.enterprise
      this.private = data.private
      this.administration_relation = data.administration_relation
      this.relation_user = data.relation_user
      this.active = data.active
      this.hidden = data.hidden
    }
  }

  getRelationName (): string {
    let name = ''

    if (this.private) {
      name = this.private.first_name + ' ' + this.private.last_name
    } else if (this.enterprise) {
      name = this.enterprise.name
    }

    return name
  }

  getInvoiceEmail (): string|null {
    let email = this.email

    if (this.private !== null) {
      email = this.private.email
    } else if (this.enterprise !== null) {
      email = this.enterprise.general_email
    }

    return email
  }

  getContactName (): string {
    let name = ''

    if (this.private) {
      if (this.private.first_name && this.private.last_name) {
        name = this.private.first_name + ' ' + this.private.last_name
      }
    } else if (this.enterprise) {
      if (this.enterprise.contact.first_name && this.enterprise.contact.last_name) {
        name = this.enterprise.contact.first_name + ' ' + this.enterprise.contact.last_name
      }
    }

    return name
  }

  getContactFirstName (): string {
    return this.private ? this.private.first_name : this.enterprise?.contact.first_name ?? ''
  }

  getContactLastName (): string {
    return this.private ? this.private.last_name : this.enterprise?.contact.last_name ?? ''
  }

  isPrivate (): boolean {
    return this.private != null
  }

  isEnterprise (): boolean {
    return this.enterprise != null
  }

  isHidden (): boolean {
    return this.hidden
  }

  fromResponse (data?: any) {
    this.setData(data)

    if (this.isPrivate()) {
      this.private = new PrivateRelationModel().fromResponse(this.private)
    }

    if (this.isEnterprise()) {
      this.enterprise = new EnterpriseRelationModel().fromResponse(this.enterprise)
    }

    if (this.administration_relation) {
      this.administration_relation = new AdministrationRelationModel().fromResponse(this.administration_relation)
    }

    if (this.relation_user) {
      this.relation_user = new RelationUserModel().fromResponse(this.relation_user)
    }

    return this
  }
}
