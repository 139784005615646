
import Vue from 'vue'

export default Vue.extend({
  props: {
    height: {
      required: false,
      default: null
    },
    width: {
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'list-item-two-line'
    },
    backgroundTransparent: {
      type: Boolean,
      required: false,
      default: false
    },
    isNoPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
