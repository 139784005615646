import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import duration from 'dayjs/plugin/duration'

import 'dayjs/locale/nl'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.config.productionTip = false

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)
dayjs.extend(duration)
dayjs.locale('nl')

const hostname = window.location.hostname

if (hostname !== 'localhost') {
  Sentry.init({
    Vue,
    dsn: 'https://e8d4abd3f41a414098826194bd4c694f@sentry.stateless.nl/5',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['dev.daya.nl', 'app.daya.nl']
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
