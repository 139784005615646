export class Notify {
  title = ''
  subtitle = ''
  isError = false
  duration = 3000
  static = false
  close = false
  isDismissing = false

  constructor (options?: { duration?: number; static?: boolean; close?: boolean; }) {
    this.duration = options?.duration ? (options.duration * 1000) : this.duration

    if (options?.static) {
      this.static = true
      this.close = true
    }
    this.close = options?.close ?? this.close
  }

  create (title: string, subtitle: string, error = false) {
    this.title = title
    this.subtitle = subtitle
    this.isError = error

    this.newNotification()
  }

  success (title: string, subtitle?: string) {
    this.title = title
    this.subtitle = subtitle ?? ''

    this.newNotification()
  }

  error (title: string, subtitle: string) {
    this.title = title
    this.subtitle = subtitle
    this.isError = true

    this.newNotification()
  }

  updated () {
    this.title = 'Wijzigingen opgeslagen'
    this.subtitle = 'Al je wijzigingen zijn succesvol opgeslagen'
    this.newNotification()
  }

  deleted () {
    this.title = 'Gelukt!'
    this.subtitle = 'Het item is verwijderd'
    this.newNotification()
  }

  entityError () {
    this.title = 'Niet alle velden zijn juist ingevuld'
    this.subtitle = 'Vul de velden in'
    this.isError = true
    this.newNotification()
  }

  newNotification () {
    const notification = this.notificationTemplate()

    if (this.close) {
      const els = notification.getElementsByClassName('close__btn')
      if (els.length > 0) {
        els[0].addEventListener('click', () => this.onClick(notification))
      }
    }

    const notifications = this.getNotifications()
    notifications.appendChild(notification)

    if (!this.static) {
      setTimeout(() => {
        if (!this.isDismissing) {
          notification.classList.remove('slide-in')
          notification.classList.add('slide-out')
          this.dismiss(notification)
        }
      }, this.duration)
    }
  }

  getNotifications (): HTMLElement {
    return document.getElementById('notifications') as HTMLElement
  }

  onClick (notification: HTMLDivElement) {
    if (!this.isDismissing) {
      notification.classList.remove('slide-in')
      notification.classList.add('slide-out')
      this.dismiss(notification)
    }
  }

  dismiss (notification: HTMLDivElement) {
    this.isDismissing = true
    setTimeout(() => {
      const notifications = this.getNotifications()

      if (this.close) {
        const els = notification.getElementsByClassName('close__btn')
        if (els.length > 0) {
          els[0].removeEventListener('click', () => this.onClick(notification))
        }
      }
      notifications.removeChild(notification)
    }, 500)
  }

  notificationTemplate (): HTMLDivElement {
    const div = document.createElement('div')
    div.className = 'notification'
    div.classList.add('slider')
    div.classList.add('slide-in')
    div.classList.add('state--' + (this.isError ? 'error' : 'success'))
    div.innerHTML = `
      <div class="notification__content">
          <span v-if="title" class="notification--title">${this.title}</span>
          <span v-if="subtitle" class="notification--subtitle subtitle">${this.subtitle}</span>
      </div>
    `
    if (this.close) {
      const closeBtnHtml = `
      <div class="close__btn">
        <i class="fas fa-times"></i>
      </div>`
      div.innerHTML = div.innerHTML + closeBtnHtml
    }

    if (this.isError) {
      const errorIcon = `
      <svg width="24" height="24" viewBox="-2 -2 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 16.5L14 20L21 13" stroke="#FD4154" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 30C8.26801 30 2 23.732 2 16H-2C-2 25.9411 6.05887 34 16 34V30ZM30 16C30 23.732 23.732 30 16 30V34C25.9411 34 34 25.9411 34 16H30ZM16 2C23.732 2 30 8.26801 30 16H34C34 6.05887 25.9411 -2 16 -2V2ZM16 -2C6.05887 -2 -2 6.05887 -2 16H2C2 8.26801 8.26801 2 16 2V-2Z" fill="#FD4154" fill-opacity="0.4"/>
    </svg>
    `
      div.innerHTML = errorIcon + div.innerHTML
    } else {
      const successIcon = `
      <svg width="24" height="24" viewBox="-2 -2 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 16.5L14 20L21 13" stroke="#15E983" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 30C8.26801 30 2 23.732 2 16H-2C-2 25.9411 6.05887 34 16 34V30ZM30 16C30 23.732 23.732 30 16 30V34C25.9411 34 34 25.9411 34 16H30ZM16 2C23.732 2 30 8.26801 30 16H34C34 6.05887 25.9411 -2 16 -2V2ZM16 -2C6.05887 -2 -2 6.05887 -2 16H2C2 8.26801 8.26801 2 16 2V-2Z" fill="#15E983" fill-opacity="0.4"/>
    </svg>
    `
      div.innerHTML = successIcon + div.innerHTML
    }

    return div
  }
}
