
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiRelationService } from '@/network/api/api-relation-service'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { ImpersonateModel } from '@/globals/models/auth/Impersonate'

export default Vue.extend({
  props: {
    bookingItems: {
      type: Array,
      required: true
    }
  },
  components: {
    PrimaryModal,
    PrimaryButton,
    Dropdown
  },
  data () {
    return {
      isEssentialsLoaded: false,
      relations: [] as Array<any>,
      selectedRelation: null as null|number,
      submitDisabled: true
    }
  },
  computed: {
    bookingItemIds (): Array<number> {
      const ids = [] as Array<number>

      this.bookingItems.forEach((bookingItem: any) => {
        ids.push(bookingItem.id)
      })

      return ids
    },
    impersonation (): ImpersonateModel {
      return this.$store.getters['user/impersonation']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      const relationId = this.impersonation.relation.id

      if (relationId !== null) {
        await this.fetchRelations(relationId)
      }

      this.isEssentialsLoaded = true
    },
    async fetchRelations (id: number) {
      await apiRelationService.getAdministrationRelations(id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((relationRaw: any) => {
            const relation = new RelationModel().fromResponse(relationRaw.enterprise)
            const item = { value: relation.id, text: relation.getRelationName() }

            if (relation.active) {
              this.relations.push(item)
            }
          })
        }
      })
    },

    async submitForm () {
      if (this.selectedRelation) {
        this.moveBookingItem(this.selectedRelation)
      }
    },

    async moveBookingItem (relationId: number) {
      this.bookingItemIds.forEach(async (id: any) => {
        await apiAdministrationService.moveBookingItem(id, relationId)
      })
      this.$emit('moved')
    }
  }
})
