// @ts-ignore
import { CurrencyEnum } from '@/globals/enums/CurrencyEnum'
// @ts-ignore
import { PaymentPeriod } from '@/globals/enums/PaymentPeriod'
// @ts-ignore
import { EnterpriseRelationTypeEnum as EnterpriseRelationType } from '@/globals/enums/EnterpriseRelationType'
// @ts-ignore
import { RoleEnum as Role } from '@/globals/enums/Role'
import { RelationModel } from '@/globals/models/relation/Relation'

export class EnterpriseRelationRequestModel {
  id: number|null = null
  name = ''
  initial = ''
  first_name = ''
  last_name = ''
  email = ''
  general_email = ''
  phone = ''
  mobile = ''
  website = ''
  address = ''
  city = ''
  bank_account = ''
  postal_code = ''
  note = ''
  country = 'NL'
  currency: number = new CurrencyEnum().euro.value
  tax_number = ''
  coc_number = ''
  invoice_prefix = ''
  booking_enabled = true
  hidden = false
  active = false

  payment_period: number = new PaymentPeriod().month.value
  enterprise_relation_type: number = EnterpriseRelationType.CLIENT
  relation_user_type: number = Role.ENTERPRISE

  // validation
  isEmailRequired = true

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: any) {
    this.id = data.id
    this.name = data.name
    this.initial = data.initial
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.email = data.email
    this.general_email = data.general_email
    this.phone = data.phone
    this.mobile = data.mobile
    this.website = data.website
    this.address = data.address
    this.city = data.city
    this.bank_account = data.bank_account
    this.postal_code = data.postal_code
    this.note = data.note
    this.country = data.country ?? 'NL'
    this.currency = data.currency
    this.tax_number = data.tax_number
    this.coc_number = data.coc_number
    this.invoice_prefix = data.invoice_prefix
    this.payment_period = data.payment_period
    this.booking_enabled = data.booking_enabled === 1
    this.hidden = data.hidden === 1
  }

  fromRelationModel (relation: RelationModel): EnterpriseRelationRequestModel {
    let data = {}
    if (relation.enterprise) {
      data = {
        ...relation,
        ...relation.enterprise,
        ...relation.enterprise.contact
      }
    } else {
      data = {
        ...relation,
        ...relation.private
      }
    }

    if (relation.administration_relation) {
      data = {
        ...data,
        ...relation.administration_relation
      }
    }
    this.setData(data)
    this.id = relation.id

    if (relation.private) {
      this.email = relation.email
      this.general_email = relation.private.email
    }
    return this
  }
}
