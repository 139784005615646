export class AttachmentModel {
  id = 0
  filename = ''
  task_id = 0
  uploaded_by = 0
  url = ''
  mime_type = ''
  created_at = ''
  updated_at = ''

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: AttachmentModel) {
    this.id = data.id
    this.filename = data.filename
    this.task_id = data.task_id
    this.uploaded_by = data.uploaded_by
    this.url = data.url
    this.mime_type = data.mime_type
    this.created_at = data.created_at
    this.updated_at = data.updated_at
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
