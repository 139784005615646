
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    }
  },
  components: {
    InputField,
    PrimaryButton
  },
  data: () => ({
    relation: new EnterpriseRelationRequestModel(),
    relationErrors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
    isCocChanged: false
  }),
  methods: {
    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data as any
            this.relation.name = info.name
            this.relation.address = info.address
            this.relation.postal_code = info.postal_code
            this.relation.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.mergeRelationErrors(response.data)
          }
        })
      }
    },

    submitForm () {
      this.clearErrors()
      const validationError = this.validateform()

      if (!validationError) {
        this.createRelation()
      }
    },
    validateform (): boolean {
      let error = false

      if (!this.relation.name) {
        this.relationErrors.name.push('Bedrijfsnaam is verplicht.')
        error = true
      }
      if (!this.relation.coc_number) {
        this.relationErrors.coc_number.push('KvK-nummer is verplicht.')
        error = true
      }
      if (!this.relation.email) {
        this.relationErrors.email.push('Algemene email is verplicht.')
        error = true
      }

      return error
    },
    clearErrors () {
      this.relationErrors = entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel())
    },

    async createRelation () {
      await apiRelationService.createAdministrationRelation(this.relationId, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          this.returnDone()
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },
    returnDone () {
      this.$emit('done')
    },
    mergeRelationErrors (errors: object) {
      this.relationErrors = {
        ...this.relationErrors,
        ...errors
      }
    }
  }
})
