export class InvoiceProduct {
  id: number|null = null
  product_id: number|null = null
  title = ''
  description = ''
  amount = 1
  value = 0.00
  tax_id = 1
  discount_value = 0
  discount_percentage = 0
  unit = 0
  project_ledger_id = null as number|null
  cost_place_ledger_id = null as number|null

  // local props
  isTouched = false

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data: any) {
    if (data) {
      this.id = data.id
      this.product_id = data.product_id
      this.title = data.title
      this.description = data.description
      this.amount = data.amount ?? 1
      this.value = data.value
      this.tax_id = data.tax_id ?? 1
      this.discount_value = data.discount_value ?? 0
      this.discount_percentage = data.discount_percentage ?? 0
      this.unit = data.unit ?? 0
      this.project_ledger_id = data.project_ledger_id ?? null
      this.cost_place_ledger_id = data.cost_place_ledger_id ?? null
    }
  }

  fromResponse (data: any) {
    this.setData(data)

    if (data.product) {
      this.title = data.product.title
    }

    if (data.tax) {
      this.tax_id = data.tax.id
    }

    return this
  }
}
