import currency from 'currency.js'

export const priceHelper = {
  getLocalPrice (price: any) {
    return price.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  },
  getEURFormat (price: number): string {
    return price.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  },
  getEURFormatIcon (price: number): string {
    return '€ ' + this.getEURFormat(price)
  },
  validatePrice (price: any) {
    return /^(\d*([-.,](?=\d{1}))?\d+)+((?!\2)[-.,]\d\d)?$/.test(price)
  },

  toEUR (value: number, precision = 2, symbol = '€ ') {
    return currency(value, { symbol, precision, decimal: ',', separator: '.' })
  },

  formatNumber (n: any) {
    // format number 1000000 to 1.234,567
    return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },

  formatCurrency (value: any, blur: string) {
    // don't validate empty input
    if (value === '') { return '' }

    const isNegative = value.indexOf('-') >= 0

    // check for decimal
    if (value.indexOf(',') >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      const decimal_pos = value.indexOf(',')

      // split number by decimal point
      let left_side = value.substring(0, decimal_pos)
      let right_side = value.substring(decimal_pos)

      // add commas to left side of number
      left_side = this.formatNumber(left_side)

      // validate right side
      right_side = this.formatNumber(right_side)

      // On blur make sure 2 numbers after decimal
      if (blur === 'blur') {
        right_side += '00'
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2)

      // join number by .
      value = left_side + ',' + right_side
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      value = this.formatNumber(value)

      // final formatting
      if (blur === 'blur') {
        value += ',00'
      }
    }

    if (isNegative) {
      value = '-' + value
    }

    // send updated string to input
    return value
  }
}
