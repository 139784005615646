

import Vue from 'vue'

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading
    }
  }
})
