
import Vue from 'vue'
import Project from '../../components/project/Project.vue'
import { ProjectModel } from '../../models/Project'

export default Vue.extend({
  components: {
    Project
  },
  data () {
    return {
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      project: new ProjectModel()
    }
  }
})
