
import Vue from 'vue'
import Stepper from '@/modules/auth/components/onboarding/stepper/Stepper.vue'
import CompanySettings from '@/modules/auth/components/onboarding/company-settings/CompanySettings.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    Stepper,
    CompanySettings
  },
  beforeMount () {
    window.scrollTo(0, 0)
  },
  methods: {
    navigateToRelations () {
      this.$router.replace({ name: routeHelper.authRoutes.onboarding.adviser.relations.name })
    }
  }
})
