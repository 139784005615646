
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { objectHelper } from '@/globals/helpers/ObjectHelper'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'

export default Vue.extend({
  components: {
    PrimaryModal,
    PrimaryButton
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      loadingTask: null as any,
      loading: false,
      pdf: null as any,
      numPages: 0,
      groups: [] as Array<Array<number>>,
      isPreviewLoading: false,
      selectedPage: null as null|number
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.$store.getters['book/currentBookingItem']
    }
  },
  async mounted () {
    await this.loadPdf()
  },
  beforeDestroy () {
    if (this.loadingTask) {
      this.loadingTask.destroy()
    }
  },
  methods: {
    cutAllPages () {
      const combinedGroups = [] as Array<Array<number>>
      this.groups.forEach(group => {
        group.forEach(page => {
          combinedGroups.push([page])
        })
      })

      arrayHelper.clear(this.groups)
      this.groups = combinedGroups
      this.buildPdfPages(this.pdf)
    },

    async loadPdf () {
      this.loading = true
      await this.getPdfJS().then(async (pdfJs) => {
        this.loadingTask = pdfJs.getDocument(this.currentBookingItem.url)
        await this.loadingTask.promise.then(async (pdf: any) => {
          this.pdf = pdf
          this.numPages = pdf.numPages
          this.setGroup()
          await this.buildPdfPages(pdf)
        }).finally(() => {
          this.loading = false
        })
      })
    },
    async getPdfJS () {
      // eslint-disable-next-line
      // @ts-expect-error
      const pdfJs = await import('pdfjs-dist/build/pdf.min.js')
      // eslint-disable-next-line
      // @ts-expect-error
      pdfJs.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry')

      return pdfJs
    },
    buildPdfPages (pdf: any) {
      for (let i = 1; i <= this.numPages; i++) {
        pdf.getPage(i).then((page: any) => {
          this.buildPdfPage(page)
        })
      }
    },
    buildPdfPage (page: any, isPreview = false) {
      const viewport = isPreview ? this.getViewportPreview(page) : this.getViewport()
      const canvas = this.getNewCanvasElement(viewport)
      const context = canvas.getContext('2d')
      const canvasPage = document.getElementById(isPreview ? 'preview-page' : 'page-' + page.pageNumber) as HTMLElement

      if (isPreview) {
        canvasPage.innerHTML = ''
      }

      if (canvasPage && canvasPage.getElementsByTagName('canvas').length === 0) {
        canvasPage.prepend(canvas)
      }

      const renderContext = {
        canvasContext: context,
        viewport
      }
      page.render(renderContext)
    },
    getViewport (): any {
      const viewport = {} as any
      viewport.width = 166
      viewport.height = 236
      viewport.transform = [0.35, 0, 0, -0.35, -10, 296]
      viewport.viewBox = [0, 0, 595, 842]

      return viewport
    },
    getViewportPreview (page: any): any {
      const height = page.getViewport(1).viewBox[3]
      const width = page.getViewport(1).viewBox[2]

      const viewport = {} as any
      viewport.width = width
      viewport.height = height
      viewport.transform = [1, 0, 0, -1, 0, height]
      viewport.viewBox = [0, 0, 0, 0]

      return viewport
    },
    getNewCanvasElement (viewport: any): HTMLCanvasElement {
      const canvas = document.createElement('canvas')
      canvas.height = viewport.height
      canvas.width = viewport.width

      return canvas
    },

    buildPreview (pageNumber: number) {
      this.pdf.getPage(pageNumber).then((page: any) => {
        this.buildPdfPage(page, true)
      })
    },

    isMultiplePages () {
      return this.numPages > 1
    },

    async splitGroup (groupIndex: number, pageIndex: number) {
      let groupFirst = objectHelper.cloneObject(this.groups[groupIndex])
      let groupSecond = objectHelper.cloneObject(this.groups[groupIndex])
      groupFirst = groupFirst.splice(0, pageIndex + 1)
      groupSecond = groupSecond.splice(pageIndex + 1, groupSecond.length)
      this.groups.splice(groupIndex, 1, groupFirst)
      this.groups.splice(groupIndex + 1, 0, groupSecond)
      this.buildPdfPages(this.pdf)
    },
    mergeGroup (groupIndex: number) {
      const groupFirst = objectHelper.cloneObject(this.groups[groupIndex]) as Array<number>
      const groupSecond = objectHelper.cloneObject(this.groups[groupIndex + 1])
      this.groups.splice(groupIndex, 2, groupFirst.concat(groupSecond))
      this.buildPdfPages(this.pdf)
    },
    setGroup () {
      const group = [] as Array<number>
      for (let i = 1; i <= this.numPages; i++) {
        group.push(i)
      }
      this.groups.push(group)
    },

    async cutPdf () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je dit pdf wil knippen?' })

      if (this.groups.length > 1 && isConfirmed) {
        await apiAdministrationService.splitBookingItem(this.currentBookingItem.id, { pages: this.groups }).then((response: any) => {
          if (response.status === HttpStatus.CREATED) {
            new Notify().success('Gelukt!', 'Pdf is succesvol geknipt')
            this.$emit('completed')
          }
        })
      }
    }
  }
})
