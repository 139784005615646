import { RouteNames as AdministrationRoutes } from '@/modules/administration/router/RouteNames'
import { RouteNames as AuthRoutes } from '@/modules/auth/router/RouteNames'
import { RouteNames as DashboardRoutes } from '@/modules/dashboard/router/RouteNames'
import { RouteNames as InvoicingRoutes } from '@/modules/invoicing/router/RouteNames'
import { RouteNames as RelationRoutes } from '@/modules/relation/router/RouteNames'
import { RouteNames as SettingsRoutes } from '@/modules/settings/router/RouteNames'
import { RouteNames as TaskRoutes } from '@/modules/task/router/RouteNames'
import { RouteNames as QuestionRoutes } from '@/modules/question/router/RouteNames'

export const routeHelper = {
  administrationRoutes: AdministrationRoutes,
  authRoutes: AuthRoutes,
  dashboardRoutes: DashboardRoutes,
  invoicingRoutes: InvoicingRoutes,
  relationRoutes: RelationRoutes,
  settingsRoutes: SettingsRoutes,
  taskRoutes: TaskRoutes,
  questionRoutes: QuestionRoutes,
  getNoNavBarRouteNames (): string[] {
    return [
      this.authRoutes.login.name,
      this.authRoutes.register.name,
      this.authRoutes.adviser.register.name,
      this.authRoutes.forgotPassword.name,
      this.authRoutes.resetPassword.name,
      this.authRoutes.activate.name,
      this.authRoutes.acceptInvitation.name,
      this.authRoutes.onboarding.welcome.name,
      this.authRoutes.onboarding.adviser.welcome.name,
      this.authRoutes.onboarding.adviser.relations.name,
      this.authRoutes.onboarding.adviser.finished.name
    ]
  },
  noAuthRequiredRoutes (): string[] {
    return [
      this.authRoutes.login.name,
      this.authRoutes.register.name,
      this.authRoutes.adviser.register.name,
      this.authRoutes.forgotPassword.name,
      this.authRoutes.resetPassword.name,
      this.authRoutes.activate.name,
      this.authRoutes.acceptInvitation.name
    ]
  }
}
