interface Options {
    id: string;
    text: string;
    value: number;
}

export class DocumentState {
  TOPROGRESS: Options = {
    id: 'toprogress',
    text: 'Te verwerken',
    value: 0
  }

  TOBEBOOKED: Options = {
    id: 'tobebooked',
    text: 'Nog te boeken',
    value: 1
  }

  BOOKED: Options = {
    id: 'booked',
    text: 'Geboekt',
    value: 2
  }

  QUESTIONS: Options = {
    id: 'questions',
    text: 'Vragen',
    value: 3
  }

  INPROGRESS: Options = {
    id: 'inprogress',
    text: 'In verwerking',
    value: 4
  }

  getStateFromValue (value: number) {
    const states = this.getStates()

    let status = this.BOOKED
    states.forEach((state: any) => {
      if (state.value === value) {
        status = state
      }
    })
    return status
  }

  getStateFromText (text: string) {
    const states = this.getStates()

    let status = this.BOOKED
    states.forEach((state: any) => {
      if (state.text === text) {
        status = state
      }
    })
    return status
  }

  getStates () {
    return [
      this.BOOKED,
      this.TOBEBOOKED,
      this.QUESTIONS,
      this.TOPROGRESS,
      this.INPROGRESS
    ]
  }
}
