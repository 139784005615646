import { client } from '../client'
import { TaskModelRequest } from '../../modules/task/models/request/Task'

export const apiTaskService = {

  baseUrl: (window as any).appConfig.API_TASK,

  async fetchTasks () {
    const url = this.baseUrl + 'task'

    return await client.get(url)
  },

  async createTask (task: TaskModelRequest) {
    const url = this.baseUrl + 'task'

    return await client.post(url, task)
  },

  async updateTask (task: TaskModelRequest) {
    const url = this.baseUrl + 'task/' + task.id

    return await client.put(url, task)
  },

  async updateTaskSharedTo (taskId: number, sharedTo: Array<number>) {
    const url = this.baseUrl + 'task/' + taskId + '/shared'

    return await client.put(url, { shared_to: sharedTo })
  },

  async deleteTask (taskId: number) {
    const url = this.baseUrl + 'task/' + taskId

    return await client.delete(url)
  },

  async updateOrder (lists: Array<Array<number>>) {
    const url = this.baseUrl + 'reorder'

    return await client.put(url, { reorders: lists })
  },

  async fetchComments (taskId: number) {
    const url = this.baseUrl + 'task/' + taskId + '/comments'

    return await client.get(url)
  },

  async createComment (taskId: number, comment: string, isLog?: boolean) {
    const url = this.baseUrl + 'task/' + taskId + '/comment'

    return await client.post(url, { content: comment, is_log: isLog ?? false })
  },

  async deleteComment (taskId: number, commentId: number) {
    const url = this.baseUrl + 'task/' + taskId + '/comment/' + commentId

    return await client.delete(url)
  },

  async uploadAttachment (taskId: number, attachment: any) {
    const url = this.baseUrl + 'task/' + taskId + '/attachment'

    return await client.post(url, attachment)
  },

  async deleteAttachment (taskId: number, attachmentId: number) {
    const url = this.baseUrl + 'task/' + taskId + '/attachment/' + attachmentId

    return await client.delete(url)
  }
}
