export class EnterpriseContactModel {
  id: number|null = null
  first_name = ''
  last_name = ''
  initial = ''
  phone = ''

  constructor (data?: any) {
    if (data) {
      this.id = data.id
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.initial = data.initial
      this.phone = data.phone
    }
  }
}
