
import Vue from 'vue'

import Administrations from '@/modules/settings/views/administration/overview/components/Administrations.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    Administrations,
    IconBase,
    IconPlus
  },
  methods: {
    navigateToCreateAdministration () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.administration.create.name })
    }
  }
})
