
import Vue from 'vue'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: { PrimaryModal, PrimaryButton },
  data () {
    return {
      userStore: this.$store.state.user,
      isError: false,
      code: '' as any,
      isOnboarding: this.$route.query.onboarding,
      isActive: false,
      resolve: null as any,
      reject: null as any,
      token: null as null|undefined|string,
      qr: null as null|undefined|string,
      rememberMe: false
    }
  },
  watch: {
    isActive () {
      if (this.isActive) {
        this.focusInput()
      }
    }
  },
  methods: {
    open (token?: string, qr?: string) {
      this.code = ''
      this.isError = false
      this.token = token
      this.qr = qr
      return new Promise((resolve, reject) => {
        this.isActive = true
        this.resolve = resolve
        this.reject = reject
      })
    },
    focusInput () {
      setTimeout(() => {
        const otpInput = this.$refs.otp as any
        otpInput.focus()
      }, 100)
    },
    correct () {
      this.resolve(true)
      this.isActive = false
    },
    cancel () {
      this.resolve(false)
      this.isActive = false
    },
    async verifyCode () {
      await apiAuthService.verifyTwoFactor(this.code, this.rememberMe, this.token).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.correct()
        } else if (response.status === HttpStatus.BAD_REQUEST) {
          this.isError = true
        }
      })
    }
  }
})
