
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      essentialsLoaded: false,
      file: null as any
    }
  },
  computed: {
    relation (): boolean {
      return this.$store.getters['relation/relation']
    }
  },
  methods: {
    async onSelectedImage (e: any) {
      const file = e.target.files[0]

      if (file) {
        this.file = file
      }
    },

    async submit () {
      const formData = new FormData()
      formData.append('file', this.file)

      await apiAdministrationService.praxisBtw(formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const errorData = document.getElementById('error-data')
          const warningData = document.getElementById('warning-data')
          if (errorData) {
            errorData.innerHTML = JSON.stringify(response.data.errors, undefined, 4)
          }
          if (warningData) {
            warningData.innerHTML = JSON.stringify(response.data.warnings, undefined, 4)
          }

          this.file = null
        }
      })
    }
  }
})
