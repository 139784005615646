export const RouteNames = {
  invoicing: {
    name: 'invoicing',
    invoice: {
      name: 'invoice',
      overview: {
        name: 'invoice_overview'
      },
      create: {
        name: 'invoice_create'
      },
      edit: {
        name: 'invoice_edit'
      }
    },
    product: {
      name: 'product',
      overview: {
        name: 'product_overview'
      }
    }
  }
}
