<template>
    <v-tooltip :bottom="bottom" :top="bottom" :left="left" :right="right" max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="tooltip__wrapper"
        >
          <slot></slot>
        </div>
      </template>
      <div class="tooltip--label" v-html="label" />
    </v-tooltip>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    top: {
      type: Boolean,
      required: false
    },
    bottom: {
      type: Boolean,
      required: false,
      default: true
    },
    left: {
      type: Boolean,
      required: false,
      default: true
    },
    right: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss">
.tooltip--label {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.tooltip__wrapper {
  display: flex;
  height: fit-content;
  width: fit-content
}
</style>
