import { TaskStatusEnum } from '../enums/TaskStatus'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { CommentModel } from './Comment'
import { AttachmentModel } from './Attachment'
import { RelationModel } from '@/globals/models/relation/Relation'
import { TaskUserModel } from './TaskUser'
import { TaskUserType } from '../enums/TaskUserType'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { apiTaskService } from '@/network/api/api-task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { timeHelper } from '@/globals/helpers/TimeHelper'
export class TaskModel {
  id = 0
  title = ''
  body = ''
  relation_to = new RelationModel()
  status = new TaskStatusEnum().ToDo.value
  duration = 0
  period_at = null as string|null
  planned_at = null as string|null
  task_users = [] as Array<TaskUserModel>
  comments = [] as Array<CommentModel>
  attachments = [] as Array<AttachmentModel>
  done_at = null as null|Date
  created_at = new Date()

  // Private properties
  isUpdating = false
  isCommentsLoaded = false
  isCommentsLoading = false
  sharedToIds = [] as Array<number>

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: TaskModel) {
    this.id = data.id
    this.title = data.title
    this.body = data.body
    this.relation_to = data.relation_to ?? new RelationModel()
    this.status = data.status
    this.duration = data.duration
    this.period_at = data.period_at
    this.planned_at = data.planned_at
    this.task_users = data.task_users
    this.attachments = data.attachments
    this.done_at = data.done_at
    this.created_at = data.created_at
  }

  fromResponse (data: TaskModel) {
    this.setData(data)

    if (data.period_at) {
      this.period_at = dateHelper.toDateString(data.period_at)
    }
    if (data.planned_at) {
      this.planned_at = dateHelper.toDateString(data.planned_at)
    }
    if (data.created_at) {
      this.created_at = dateHelper.toDate(data.created_at)
    }

    if (data.task_users) {
      const taskUsers = [] as Array<TaskUserModel>
      data.task_users.forEach((taskUserRaw: any) => {
        const taskUser = new TaskUserModel().fromResponse(taskUserRaw)
        if (taskUser.type === TaskUserType.CREATED_AND_ASSIGNED) {
          taskUser.type = TaskUserType.CREATED_BY
          const assignedTo = new TaskUserModel().fromResponse(taskUserRaw)
          assignedTo.type = TaskUserType.ASSIGNED_TO
          taskUsers.push(assignedTo)
        }
        taskUsers.push(taskUser)
      })
      this.task_users = taskUsers
    }

    if (data.attachments) {
      this.attachments = []
      data.attachments.forEach((attachmentRaw: AttachmentModel) => {
        this.attachments.push(new AttachmentModel().fromResponse(attachmentRaw))
      })
    }

    if (data.relation_to) {
      this.relation_to = new RelationModel().fromResponse(data.relation_to)
    }

    arrayHelper.clear(this.sharedToIds)
    this.getSharedTo().forEach(taskUser => {
      this.sharedToIds.push(taskUser.user_id)
    })

    return this
  }

  getPlannedDate (): Date {
    return dateHelper.toDateZeroTime(this.planned_at ?? new Date())
  }

  getPlannedDateUnix (): number|null {
    return this.planned_at ? dateHelper.toUnix(this.planned_at) : null
  }

  isPlannedDatePassed (): boolean {
    if (this.planned_at) {
      const planned = dateHelper.toDateZeroTime(this.planned_at)
      const now = dateHelper.toDateZeroTime(new Date())

      if (planned < now) {
        return true
      }
    }
    return false
  }

  getCreatedBy (): TaskUserModel {
    return this.task_users.find(taskUser => taskUser.type === TaskUserType.CREATED_BY) ?? new TaskUserModel()
  }

  getAssignedTo (): TaskUserModel {
    return this.task_users.find(taskUser => taskUser.type === TaskUserType.ASSIGNED_TO) ?? new TaskUserModel()
  }

  getSharedTo (): Array<TaskUserModel> {
    return this.task_users.filter(taskUser => taskUser.type === TaskUserType.SHARED_TO)
  }

  getSharedToUser (userId: number): TaskUserModel|undefined {
    return this.getSharedTo().find(taskUser => taskUser.user_id === userId)
  }

  isCreatedByUser (userId: number): boolean {
    return this.getCreatedBy().user_id === userId
  }

  isAssignedToUser (userId: number): boolean {
    return this.getAssignedTo().user_id === userId
  }

  isSharedToUser (userId: number): boolean {
    return this.getSharedToUser(userId) !== undefined
  }

  isNew (): boolean {
    return this.title === '' &&
        this.body === '' &&
        this.relation_to.id === 0 &&
        this.duration === 0 &&
        this.planned_at === null &&
        this.sharedToIds.length === 0 &&
        this.attachments.length === 0 &&
        this.done_at === null
  }

  async fetchComments () {
    this.isCommentsLoading = true
    await apiTaskService.fetchComments(this.id).then((response: any) => {
      if (response.status === HttpStatus.OK) {
        arrayHelper.clear(this.comments)
        response.data.forEach((commentRaw: any) => {
          const comment = new CommentModel().fromResponse(commentRaw)
          this.comments.push(comment)
        })
      }
    }).finally(() => { this.isCommentsLoading = false })
  }

  getDurationTimeString (isShort = false): string {
    const duration = timeHelper.getHoursAndMinutesFromMinutes(this.duration)
    const hours = duration.hours
    const minutes = duration.minutes

    let response = ''

    if (hours) {
      response = hours + (isShort ? 'u' : ' uur')
    }

    if (minutes) {
      response = response + (isShort ? (' ' + minutes + 'm') : (' en ' + minutes + ' minuten'))
    }

    return response
  }
}
