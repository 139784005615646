
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconMoreDots from '@/globals/components/icons/IconMoreDots.vue'
import { ShortcutModel } from '@/globals/models/Shortcut'
import Vue from 'vue'
import EditShortcut from './edit-shortcut/EditShortcut.vue'
export default Vue.extend({
  components: { EditShortcut, IconBase, IconMoreDots },
  props: {
    shortcut: {
      type: ShortcutModel,
      required: true
    }
  },
  data () {
    return {
      isEditShortcutModal: false
    }
  },
  computed: {
    confirmDialog (): any {
      return this.$store.getters['dialog/confirmDialog']
    }
  },
  methods: {
    goToUrl () {
      (window as any).open(this.shortcut.url, '_blank').focus()
    },
    async remove () {
      // todo remove shortcut
      const isConfirmed = await this.confirmDialog.open({ isDeleting: true })

      if (isConfirmed) {
        this.$emit('delete', this.shortcut)
      }
    }
  }
})
