
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import ProjectsDataTable from './components/projects-datatable/ProjectsDataTable.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton,
    ProjectsDataTable,
    IconBase,
    IconLookingGlass
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      search: ''
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  methods: {
    navigateToCreateRelation () {
      this.$router.push({ name: 'project_create' })
    }
  }
})
