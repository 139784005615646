
import Vue from 'vue'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import BookImage from '../../../book-image/BookImage.vue'
import FullscreenModal from '@/globals/components/modals/FullscreenModal.vue'

export default Vue.extend({
  components: { BookImage, FullscreenModal },
  props: {
    currentBookingItem: {
      type: BookingItem,
      required: true
    }
  },
  data () {
    return {
      bookingItem: null as null|BookingItem
    }
  },
  computed: {
    bookingItems (): Array<BookingItem> {
      return this.$store.getters['book/bookingItems']
    }
  },
  beforeMount () {
    this.findAndSetBookingItem()
  },
  methods: {
    findAndSetBookingItem () {
      this.bookingItem = this.bookingItems.find(bookingItem => bookingItem.id !== this.currentBookingItem.id && bookingItem.invoice_number === this.currentBookingItem.invoice_number) ?? null
    }
  }
})
