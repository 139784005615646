export class BookingItemLine {
  id: number|null = null
  booking_item_id: number|null = null
  ledger_account_id: number|null = null
  cost_place_ledger_account_id: number|null = null
  project_ledger_account_id: number|null = null
  value = 0.00
  vat_percentage = 0
  vat_ledger_account_id = null as null|number
  vat = 0.00
  foreign_sale_type = null as null|number
  supplier_tax_code = null as null|string
  description = ''
  invoice_product_id = null as null|number

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.booking_item_id = data.booking_item_id
      this.ledger_account_id = data.ledger_account_id
      this.cost_place_ledger_account_id = data.cost_place_ledger_account_id
      this.project_ledger_account_id = data.project_ledger_account_id
      this.value = data.value
      this.vat_ledger_account_id = data.vat_ledger_account_id
      this.foreign_sale_type = data.foreign_sale_type
      this.supplier_tax_code = data.supplier_tax_code
      this.description = data.description
      this.invoice_product_id = data.invoice_product_id
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }

  isNotEmpty (): boolean {
    return this.ledger_account_id !== null ||
      this.cost_place_ledger_account_id !== null ||
      this.project_ledger_account_id !== null ||
      this.value !== 0 ||
      this.vat_percentage !== 0 ||
      this.vat_ledger_account_id !== null ||
      this.vat !== 0 ||
      this.foreign_sale_type !== null ||
      this.supplier_tax_code !== null ||
      this.invoice_product_id !== null ||
      this.description !== ''
  }
}
