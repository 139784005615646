
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { ProjectModel } from '../../models/Project'

export default Vue.extend({
  props: {
    _project: {
      type: Object,
      required: true
    }
  },
  components: {
    PrimaryButton,
    InputField,
    Dropdown
  },
  data () {
    return {
      project: this._project,
      errors: entityHelper.generateErrorEntity(new ProjectModel()) as any,
      yesOrNo: [
        { text: 'Ja', value: true },
        { text: 'Nee', value: false }
      ]
    }
  },
  methods: {
    async submitForm () {
      this.clearErrors()
    },
    clearErrors () {
      this.errors = entityHelper.generateErrorEntity(this.project)
    },
    validationErrors () {
      let error = false

      if (!this.project.description) {
        this.errors.description.push('Beschrijving is verplicht')
        error = true
      }

      return error
    },

    mergeProjectErrors (errors: object) {
      this.errors = {
        ...this.errors,
        ...errors
      }
    }
  }
})
