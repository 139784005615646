
import Vue from 'vue'

import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { RelationModel } from '@/globals/models/relation/Relation'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { TaxDefaultModel } from '@/globals/models/TaxDefault'
import { VatEnum } from '@/globals/enums/Vat'

export default Vue.extend({
  components: {
    PrimaryButton,
    BlankSlate,
    Dropdown
  },
  data () {
    return {
      isEssentialsLoaded: false,
      organisations: [] as Array<any>,
      selectedOrganisation: null as null|number,
      ledgerAccounts: [] as Array<any>,
      ledgerVats: [] as Array<any>,
      tags: [] as Array<any>,
      taxes: [] as Array<any>,
      twinfieldOffice: null,
      enterprises: [] as Array<any>,
      selectedEnterprise: null as any,
      submitLedgerAccountsDisabled: true,
      submitVatsDisabled: true,
      loadingState: this.$store.state.loading
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    taxDefaults (): Array<TaxDefaultModel> {
      return this.$store.getters['administration/taxDefaults']
    }
  },
  watch: {
    selectedOrganisation () {
      arrayHelper.clear(this.enterprises)
      this.getEnterprises()
    },
    taxDefaults () {
      this.setTaxDefaults()
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.setConnections()
      await this.fetchOrganisations()
      if (this.isTwinfieldConnected) {
        await this.fetchLedgerAccounts()

        if (!this.taxDefaults.length) {
          await this.$store.dispatch('administration/setTaxDefaults')
        } else {
          this.setTaxDefaults()
        }
        // await this.getTaxes()
      }

      this.isEssentialsLoaded = true
    },

    async setConnections () {
      await apiAdministrationService.checkConnection().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.commit('user/setConnections', response.data)
          this.twinfieldOffice = response.data.office
        }
      })
    },

    async fetchOrganisations () {
      await apiAdministrationService.getOrganisations().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.organisations = response.data
        }
      })
    },

    async fetchLedgerAccounts () {
      await apiAdministrationService.getLedgerAccounts().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          arrayHelper.clear(this.ledgerAccounts)
          arrayHelper.clear(this.tags)
          response.data.forEach((tag: any) => {
            if (tag.type === LedgerAccount.Balance || tag.type === LedgerAccount.ProfitAndLoss) {
              this.ledgerAccounts.push({ value: tag.id, text: tag.code + ' - ' + tag.name })
            }
            if (tag.type === LedgerAccount.TagType) {
              this.tags.push({ value: tag.id, text: tag.code + ' - ' + tag.name, default: tag.default_ledger_account, active: tag.active })
            }
          })
        }
      })
    },

    async setTaxDefaults () {
      // const taxDefaults = [] as Array<{tax_id: number; default: number|null; ledger_account_id: number|null}>
      // await apiAdministrationService.getTwinfieldTaxDefaults().then((response: any) => {
      //   if (response.status === HttpStatus.OK) {
      arrayHelper.clear(this.ledgerVats)
      this.taxDefaults.forEach((taxDefault: any) => {
        // const ledgerAccount = taxDefault.vat_ledger_account
        // taxDefaults.push({ tax_id: taxDefault.tax_id, default: taxDefault.default_ledger_account_id, ledger_account_id: taxDefault.ledger_account_id })
        if (taxDefault.is_sale) {
          this.ledgerVats.push({ value: taxDefault.vat_ledger_account.id, text: (taxDefault.vat_ledger_account.code ? taxDefault.vat_ledger_account.code + ' - ' : '') + taxDefault.vat_ledger_account.name })
        }
      })
      //   }
      // })
      const taxes = new VatEnum().getVats()
      if (taxes) {
        arrayHelper.clear(this.taxes)
        taxes.forEach(tax => {
          const foundTax = this.taxDefaults.find(x => x.tax_id === tax.value)
          this.taxes.push({ value: tax.value, text: tax.text, default: foundTax ? foundTax.default_ledger_account_id : null, ledger_account_vat_id: foundTax ? foundTax.ledger_account_id : null })
        })
      }
    },

    submitLedgerAccountsForm (e: any) {
      e.preventDefault()
      if (this.tags.length > 0) {
        this.setDefaultTags()
      }
    },

    async setDefaultTags () {
      await apiAdministrationService.setTwinfieldDefaults(this.tags).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.submitLedgerAccountsDisabled = true
          new Notify().success('Gelukt!', 'Je dagboekkoppelingen zijn succesvol gekoppeld')
        }
      })
    },

    async syncLedgerAccounts () {
      await apiAdministrationService.syncLedgerAccounts().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await this.loadEssentials()
        }
        if (response.status === HttpStatus.BAD_REQUEST) {
          await this.getEnterprises()
        }
      })
    },
    async getEnterprises () {
      if (this.selectedOrganisation !== null) {
        await apiAdministrationService.getTwinfieldEnterprises(this.selectedOrganisation).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.buildEnterprises(response.data)
          }
        })
      }
    },
    buildEnterprises (data: any) {
      data.forEach((enterprise: { code: string; name: string }) => {
        this.enterprises.push({ value: enterprise, text: enterprise.name })
      })
    },

    async saveEnterprise () {
      if (this.selectedOrganisation !== null) {
        await apiAdministrationService.saveTwinfieldEnterprise(this.relation.id, this.selectedEnterprise.code, this.selectedEnterprise.name, this.selectedOrganisation).then(async (response: any) => {
          if (response.status === HttpStatus.OK) {
            await this.syncLedgerAccounts()
          }
        })
      }
    },

    async saveVatLedgers () {
      await apiAdministrationService.setTwinfieldTaxDefaults(this.taxes).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new Notify().success('Gelukt!', 'Je dagboekkoppelingen zijn succesvol gekoppeld')
          this.submitVatsDisabled = true
        }
      })
    }
  }
})
