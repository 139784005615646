
import Vue from 'vue'
import InputField from '@/modules/auth/components/form-components/InputField.vue'
import SubmitButton from '@/modules/auth/components/form-components/SubmitButton.vue'

import { User } from '@/modules/auth/models/User'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    InputField,
    SubmitButton
  },
  data () {
    return {
      email: '',
      errors: entityHelper.generateErrorEntity(new User()) as any,
      success: false,
      routeHelper
    }
  },
  methods: {
    async checkForm (e: any) {
      e.preventDefault()
      this.errors = entityHelper.generateErrorEntity(new User())
      let error = false

      if (!this.email) {
        this.errors.email.push('E-mail is verplicht.')
        error = true
      }

      if (!error) {
        await apiAuthService.requestResetPassword(this.email).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.success = true
          }
          if (response.status === HttpStatus.BAD_REQUEST) {
            this.errors.email = ['E-mailadres is onjuist']
          }
        })
      }
    }
  }
})
