
import Vue from 'vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { InvoiceRepeat } from '@/modules/invoicing/enums/InvoiceRepeat'
import StatusColor from '../StatusColor.vue'
import { InvoiceRepeatSettings } from '@/modules/invoicing/models/invoice/InvoiceRepeatSettings'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  props: {
    invoicePrefix: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: true
    }
  },
  components: {
    StatusColor
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      invoiceStatus: new InvoiceStatus() as InvoiceStatus,
      invoiceRepeat: new InvoiceRepeat() as InvoiceRepeat,
      headers: [
        { text: 'Frequentie', value: 'frequence' },
        { text: 'Relatie', value: 'contact' },
        { text: 'Totaalbedrag', value: 'total_price', align: 'right' },
        { text: 'Aantal herhalingen', value: 'recurrence_count' },
        { text: 'Volgende factuur', value: 'next_invoice_date' }
      ],
      invoices: [] as Array<any>
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false
      await this.fetchRecurrenceInvoices()
      this.$emit('recurrence_count', this.invoices.length)
      this.essentialsLoaded = true
    },
    async fetchRecurrenceInvoices () {
      await apiInvoiceService.getRecurrenceInvoices().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((recurrenceRaw: any) => {
            if (recurrenceRaw.active && recurrenceRaw.base_invoice) {
              this.buildInvoices(recurrenceRaw)
            }
          })
        }
      })
    },
    buildInvoices (recurrenceRaw: any) {
      const recurrence = new InvoiceRepeatSettings().fromResponse(recurrenceRaw)
      const invoice = new Invoice().fromResponse(recurrenceRaw.base_invoice)
      const frequence = this.invoiceRepeat.getTextByValue(recurrence.recurrence_type)
      const contact = invoice.to_relation ? invoice.to_relation.getRelationName() : ''
      const totalPrice = priceHelper.getLocalPrice(invoice.total)
      const nextInvoiceDate = recurrence.next_date ? dateHelper.toShortMonthDateString(recurrence.next_date) : ''
      const tableItem = {
        id: invoice.id,
        frequence,
        contact,
        recurrence_count: recurrence.recurrence_count + (recurrence.max_recurrences > 0 ? ' / ' + recurrence.max_recurrences : ''),
        total_price: '€ ' + totalPrice,
        next_invoice_date: nextInvoiceDate,
        invoiceStatus: this.getStatus(invoice.id, recurrence.invoices)
      }

      this.invoices.push(tableItem)
    },
    getStatus (baseId: number|null, invoices: Array<any>) {
      let status = this.invoiceStatus.sent

      invoices.forEach(invoice => {
        if (baseId !== invoice.id && invoice.status !== this.invoiceStatus.sent.value) {
          status = this.invoiceStatus.getInvoiceStateByValue(invoice.status)
        }
      })

      return status
    },

    goToInvoice (id: number) {
      this.$store.dispatch('invoicing/setInvoice', null)
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id: id.toString() } })
    }
  }
})
