
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import OpenTasks from '../../components/open-tasks/OpenTasks.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import LatestQuestions from '../../components/latest-questions/LatestQuestions.vue'
import Shortcuts from '../../components/shortcuts/Shortcuts.vue'
import UploadDocuments from '@/modules/administration/components/documents/upload-documents/UploadDocuments.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import { TaskModel } from '@/modules/task/models/Task'
import LatestTasks from '../../components/latest-tasks/LatestTasks.vue'
import AccountantCounters from '../../components/counters/accountant/AccountantCounters.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'

export default Vue.extend({
  components: {
    PrimaryButton,
    OpenTasks,
    Dropdown,
    LatestQuestions,
    Shortcuts,
    UploadDocuments,
    LatestTasks,
    AccountantCounters
  },
  data () {
    return {
      isStatsLoaded: false,
      isTasksLoaded: false,
      selectedFilterDates: null as null|Array<Date|null>,
      selectedFilterDate: 2,
      counters: {
        booking_items: 0,
        pending_booking_items: 0,
        relations: 0,
        users: 0
      },
      lastInvoices: [] as Array<Invoice>,
      lastRelations: [] as Array<any>,
      tasks: [] as TaskModel[],
      openTasksYear: new Date().getFullYear(),
      isUploadModalActive: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    invoices: {
      get (): Array<Invoice> {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Array<Invoice>) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    }
  },
  beforeMount () {
    this.loadEssentials()
    this.fetchTasks()
  },
  methods: {
    async loadEssentials () {
      this.fetchStats()
    },
    async fetchStats () {
      await apiAdministrationService.getDashboardStats().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.counters = response.data
        }
      }).finally(() => { this.isStatsLoaded = true })
    },

    async fetchTasks () {
      await apiTaskService.fetchTasks().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.tasks.push(task)
          })
        }
      }).then(() => { this.isTasksLoaded = true })
    },

    getYearSelections (): Array<number> {
      const years = []
      const currentYear = new Date().getFullYear()

      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i)
      }

      return years
    },

    navigateToInvoices () {
      this.$router.replace({ name: routeHelper.invoicingRoutes.invoicing.invoice.overview.name }).catch()
    },

    navigateToTasks () {
      this.$router.replace({ name: routeHelper.taskRoutes.task.overview.name }).catch()
    },

    navigateToQuestions () {
      this.$router.replace({ name: routeHelper.questionRoutes.question.overview.name }).catch()
    },

    navigateToRelations () {
      this.$router.replace({ name: routeHelper.relationRoutes.relation.overview.name }).catch()
    }
  }
})
