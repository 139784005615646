
import Vue from 'vue'
import { routeHelper } from '../../../helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import NavLink from './nav-link/NavLink.vue'

export default Vue.extend({
  components: { NavLink },
  data () {
    return {
      userStore: this.$store.state.user,
      relationStore: this.$store.state.relation,
      routeHelper
    }
  },
  computed: {
    isEnterprise (): boolean {
      return this.$store.getters['user/isEnterprise']
    },
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isPraxis (): boolean {
      return this.$store.getters['relation/isPraxis']
    },
    isBookingEnabled (): boolean {
      return this.$store.getters['relation/isBookingEnabled']
    },
    isRelationEnterprise (): boolean {
      return this.$store.getters['relation/isEnterprise']
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    accountingSubroutes (): Array<{ name: string; routeName: string; active: boolean }> {
      return [
        { name: 'Import weekstaat', routeName: routeHelper.administrationRoutes.administration.praxis.name, active: this.isPraxis },
        { name: 'Import BTW', routeName: routeHelper.administrationRoutes.administration.praxis.btw.name, active: this.isPraxis }
      ]
    },
    invoicingSubroutes (): Array<{ name: string; routeName: string; active: boolean }> {
      return [
        { name: 'Facturen overzicht', routeName: routeHelper.invoicingRoutes.invoicing.invoice.overview.name, active: true },
        { name: 'Beheer producten', routeName: routeHelper.invoicingRoutes.invoicing.product.overview.name, active: true },
        { name: 'Nieuwe factuur', routeName: routeHelper.invoicingRoutes.invoicing.invoice.create.name, active: true }
      ]
    }
  }
})
