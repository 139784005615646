
import Vue from 'vue'

import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryModal,
    InputField,
    PrimaryButton
  },
  props: {
    currentPaid: {
      type: Number,
      required: true
    },
    totalInvoicePrice: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      priceHelper,
      paid: 0.00
    }
  },
  methods: {
    submitForm () {
      this.$emit('paid', this.paid)
    }
  }
})

