
import Vue from 'vue'

export default Vue.extend({
  props: {
    state: {
      type: String,
      required: true
    }
  },

  data () {
    return {
    }
  },

  methods: {
  }
})
