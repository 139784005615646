export class InvoiceSend {
  emails = [] as Array<string>
  message = ''
  is_reminder = false

  constructor (data?: any) {
    if (data) {
      this.emails = data.emails
      this.message = data.message
      this.is_reminder = data.is_reminder
    }
  }
}
