import { AdministrationRelationModel } from '../relation/AdministrationRelation'
import { RelationModel } from '../relation/Relation'
import { TokenModel } from './Token'

export class ImpersonateModel {
  relation = new RelationModel()
  token = new TokenModel()
  twinfield_connection = { office: null, is_connected: false }
  relations = [] as Array<AdministrationRelationModel>

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: ImpersonateModel) {
    if (data) {
      this.relation = data.relation
      this.token = data.token
      this.twinfield_connection = data.twinfield_connection
    }
  }

  fromResponse (data: ImpersonateModel) {
    this.setData(data)

    if (data.relation) {
      this.relation = new RelationModel().fromResponse(data.relation)
    }

    if (data.token) {
      this.token = new TokenModel().fromResponse(data.token)
    }
    if (data.relations.length) {
      this.relations = []
      data.relations.forEach(administrationRelation => {
        const relation = new AdministrationRelationModel().fromResponse(administrationRelation)
        this.relations.push(relation)
      })
    }

    return this
  }
}
