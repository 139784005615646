import { InvoiceRepeat } from '@/modules/invoicing/enums/InvoiceRepeat'
import { dateHelper } from '@/globals/helpers/DateHelper'
export class InvoiceRepeatSettings {
  id: number|null = null
  base_invoice_id: number|null = null
  recurrence_type = new InvoiceRepeat().monthly.value
  next_period_start: string = dateHelper.toDateString(new Date())
  next_date: string = dateHelper.toDateString(new Date())
  send_automatically = true
  recurrence_count = 0
  max_recurrences = 0
  active = false
  last_invoice_id: number|null = null
  invoices = [] as Array<any>

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: any) {
    this.id = data.id
    this.base_invoice_id = data.base_invoice_id
    this.recurrence_type = data.recurrence_type
    this.next_period_start = data.next_period_start
    this.next_date = data.next_date
    this.send_automatically = data.send_automatically
    this.recurrence_count = data.recurrence_count
    this.max_recurrences = data.max_recurrences
    this.active = data.active
    this.last_invoice_id = data.last_invoice_id
    this.invoices = data.invoices ?? []
  }

  fromResponse (data: any) {
    this.setData(data)

    if (data.next_period_start) {
      this.next_period_start = dateHelper.toDateString(data.next_period_start)
    }
    if (data.next_date) {
      this.next_date = dateHelper.toDateString(data.next_date)
    }

    return this
  }

  isStartDateInFuture (): boolean {
    const start_date = dateHelper.toDate(this.next_period_start)
    const current_date = dateHelper.toDateZeroTime()
    return start_date.getTime() >= current_date.getTime()
  }
}
