
import Vue from 'vue'
import SideNav from '@/modules/settings/views/company/components/SideNav.vue'
import CompanySettings from '@/modules/settings/components/company/settings/CompanySettings.vue'
import { RelationModel } from '@/globals/models/relation/Relation'

export default Vue.extend({
  components: {
    SideNav,
    CompanySettings
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  }
})
