import { UserModel } from '@/globals/models/user/User'
export class BookingItemNote {
  id: number|null = null
  message = ''
  created_at = new Date()
  user = new UserModel()

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.message = data.message
      this.created_at = new Date(data.created_at)
      this.user = new UserModel(data.user)
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
