
import Vue from 'vue'
import { TaskModel } from '@/modules/task/models/Task'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { apiTaskService } from '@/network/api/api-task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import Tooltip from '@/globals/components/tooltip/Tooltip.vue'
import AttachmentMenu from './attachment-menu/AttachmentMenu.vue'
import { AttachmentModel } from '@/modules/task/models/Attachment'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'

export default Vue.extend({
  components: {
    Tooltip,
    AttachmentMenu,
    IconBase,
    IconPlus
  },
  props: {
    isEditDisabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      dateHelper,
      newComment: '',
      isNewAttachmentLoading: false
    }
  },
  computed: {
    task: {
      get (): TaskModel {
        return this.taskStore.selectedTask ?? new TaskModel()
      },
      set (task: TaskModel) {
        return task
      }
    }
  },
  methods: {
    selectAttachment () {
      const element = this.$refs.uploadAttachment as any
      element.click()
    },
    async onSelectedFile (e: any) {
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        this.isNewAttachmentLoading = true
        await this.uploadAttachment(file)
        this.isNewAttachmentLoading = false
      }
      e.target.value = null
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadAttachment (file: any) {
      const formData = new FormData()
      formData.append('file', file)
      await apiTaskService.uploadAttachment(this.task.id, formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const attachment = new AttachmentModel().fromResponse(response.data)
          this.task.attachments.push(attachment)
          new Notify().success('Gelukt!', 'de bijlage is toegevoegd')
        }
      })
    },

    async deleteAttachment (id: number, index: number) {
      await apiTaskService.deleteAttachment(this.task.id, id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.task.attachments.splice(index, 1)
        }
      })
    },

    openAttachment (url: string) {
      window.open(url, '_blank')
    }
  }
})
