
import Vue from 'vue'
import CompanySettings from '@/modules/settings/components/company/settings/CompanySettings.vue'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    Breadcrumbs,
    CompanySettings
  },
  data: () => ({
  }),
  methods: {
    navigateToOverview () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.administration.overview.name })
    }
  }
})
