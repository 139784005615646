
import Vue from 'vue'
import RelationsDataTable from '@/modules/relation/views/overview/components/RelationsDataTable.vue'
import ClientsDataTable from '@/modules/relation/views/overview/components/ClientsDataTable.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton,
    RelationsDataTable,
    ClientsDataTable,
    IconBase,
    IconPlus,
    IconLookingGlass
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      search: ''
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isOwner (): boolean {
      return this.relationStore.isOwner
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    }
  },
  methods: {
    navigateToCreateRelation () {
      this.$router.push({ name: routeHelper.relationRoutes.relation.create.name })
    }
  }
})
