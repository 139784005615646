const state = () => ({
  isRefreshing: false
})

const getters = {
}

const actions = {
  setIsRefreshing ({ commit }: any, isRefreshing: boolean) {
    commit('setIsRefreshing', isRefreshing)
  }
}

const mutations = {
  setIsRefreshing (state: any, isRefreshing: boolean) {
    state.isRefreshing = isRefreshing
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
