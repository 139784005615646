import { RelationModel } from '@/globals/models/relation/Relation'
import { AdministrationUserRelation } from './AdministrationUserRelation'
import { parserHelper } from '../../helpers/ParserHelper'

export class AdministrationRelationModel {
  id = 0
  booking_enabled = 0 as number
  is_parent = 0 as number
  enterprise = new RelationModel()
  user_relation = new AdministrationUserRelation()

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.booking_enabled = data.booking_enabled
      this.is_parent = data.is_parent
      this.enterprise = data.enterprise
      this.user_relation = data.user_relation
    }
  }

  fromResponse (data?: any) {
    this.setData(data)
    this.enterprise = new RelationModel().fromResponse(data.enterprise)
    this.user_relation = new AdministrationUserRelation().fromResponse(data.user_relation)

    return this
  }

  isBookingEnabled (): boolean {
    return parserHelper.numToBool(this.booking_enabled)
  }
}
