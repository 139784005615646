import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      // refresh variables
      refreshing: false as boolean,
      registration: null as any,
      updateExists: false as boolean
    }
  },
  computed: {
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    }
  },

  created () {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', this.onRefresh)
  },

  destroyed () {
    document.removeEventListener('swUpdated', this.updateAvailable)
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable (event: any) {
      this.registration = event.detail
      this.updateExists = true
      this.refreshApp()
    },

    // Called when the user accepts the update
    refreshApp () {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onRefresh () {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    }
  }
})
