import { render, staticRenderFns } from "./CompanySettings.vue?vue&type=template&id=d19c375c&scoped=true&"
import script from "./CompanySettings.vue?vue&type=script&lang=ts&"
export * from "./CompanySettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d19c375c",
  null
  
)

export default component.exports