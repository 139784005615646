
import Vue from 'vue'
import Chart from '@/globals/components/charts/Chart.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'

export default Vue.extend({
  props: {
    invoices: {
      type: Array,
      required: true
    }
  },
  components: {
    Chart
  },
  data () {
    return {
      invoiceStatus: new InvoiceStatus(),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            text: 'Top 5 klanten'
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (item: any) {
                return priceHelper.toEUR(item.raw).format()
              }
            }
          }
        },
        interaction: {
          intersect: false
        },
        scales: {
          y: {
            display: true,
            min: 0,
            ticks: {
              callback: function (label: any) {
                return priceHelper.toEUR(label, 0).format() + ',-'
              }
            }
          }
        }
      }
    }
  },
  computed: {
    chartData (): object {
      const list = [] as Array<{ name: string; value: number }>
      const chartData = {
        labels: [] as Array<string>,
        datasets: [
          {
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            data: [] as Array<number>
          }
        ]
      }

      for (let i = 0; i < this.invoices.length; i++) {
        const invoice = this.invoices[i] as Invoice

        if (invoice.status >= new InvoiceStatus().sent.value) {
          const name = invoice.to_relation?.getRelationName() ?? ''
          let value = 0
          const found = list.find(e => e.name === name)

          if (found) {
            value = found.value
          }

          value = value + (invoice.total_ex_tax ?? 0)

          if (found) {
            found.value = value
          } else {
            list.push({ name, value })
          }
        }
      }

      list.sort(function (a, b) {
        return a.value < b.value ? -1 : 1
      })

      if (list.length > 5) {
        list.splice(0, Math.abs(list.length - 5))
      }

      list.forEach(e => {
        chartData.labels.push(e.name)
        chartData.datasets[0].data.push(e.value)
      })

      return chartData
    }
  },
  methods: {
  }
})
