
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { ShortcutModel } from '@/globals/models/Shortcut'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { objectHelper } from '@/globals/helpers/ObjectHelper'
import { Notify } from '@/globals/controllers/Notification'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
export default Vue.extend({
  components: { PrimaryModal, InputField, PrimaryButton },
  props: {
    _shortcut: {
      type: ShortcutModel,
      required: true
    }
  },
  data () {
    return {
      shortcut: new ShortcutModel(),
      errors: entityHelper.generateErrorEntity(new ShortcutModel()) as any
    }
  },
  computed: {
    isSubmitDisabled (): boolean {
      const oldShortcut = JSON.stringify(this._shortcut)
      const newShortcut = JSON.stringify(this.shortcut)

      return oldShortcut === newShortcut
    }
  },
  beforeMount () {
    this.shortcut = objectHelper.cloneObject(this._shortcut)
  },
  methods: {
    submit () {
      const isValidationError = this.isValidationError()

      if (!isValidationError) {
        this.updateShortcut()
      }
    },
    isValidationError (): boolean {
      this.errors = entityHelper.generateErrorEntity(new ShortcutModel())
      let isError = false

      if (!this.shortcut.name) {
        this.errors.name.push('Naam is verplicht')
        isError = true
      }

      if (!this.shortcut.url) {
        this.errors.url.push('Url is verplicht')
        isError = true
      }

      return isError
    },

    async updateShortcut () {
      await apiAuthService.updateShortcut(this.shortcut).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this._shortcut.setData(this.shortcut)
          new Notify().updated()
          this.$emit('close')
        }
      })
    }
  }
})
