<template>
  <div id="auth">
    <div id="activate">
      <activate-component/>
    </div>
  </div>
</template>

<script>
import ActivateComponent from '@/modules/auth/components/activate/ActivateComponent.vue'

export default {
  components: {
    ActivateComponent
  }
}
</script>

<style scoped lang="scss">
  #activate {
    position: absolute;
    left: 0;
    right: 0;
    top: 10vw;
    display: flex;
    justify-content: center;
  }
</style>
