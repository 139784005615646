var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input__field"},[(_vm.label || _vm.forceLabel)?_c('label',{class:{
    'height--small': _vm.labelHeightSmall
  },style:(_vm.labelStyle),domProps:{"innerHTML":_vm._s(_vm.forceLabel ? '<br>' : _vm.label)}}):_vm._e(),_c('div',{class:{ 'form-input--group': true, 'is--disabled': _vm.disabled }},[(_vm.icon)?_c('i',{class:'prepend--icon ' + _vm.icon}):_vm._e(),_vm._t("prependIcon"),(_vm.isPrice)?_c('currency-input',{class:{
        'form--input': true,
        'currency__input': true,
        'is--disabled': _vm.disabled,
        'validation--error': _vm.errorModel.length || _vm.isError,
        'prepend--icon': _vm.icon || _vm.isPrependIcon,
        'height--small': _vm.height === 'small',
        'height--large': _vm.height === 'large',
        'align--left': _vm.align === 'left',
        'align--right': _vm.align === 'right',
        'align--center': _vm.align === 'center'
      },attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"options":_vm.currencyOptions},on:{"input":function($event){return _vm.$emit('input', $event)},"blur":function($event){return _vm.$emit('blur', $event)}}}):_c('input',{ref:"input",class:{
        'form--input': true,
        'validation--error': _vm.errorModel.length || _vm.isError,
        'height--small': _vm.height === 'small',
        'height--large': _vm.height === 'large',
        'align--left': _vm.align === 'left',
        'align--right': _vm.align === 'right',
        'align--center': _vm.align === 'center',
        'prepend--icon': _vm.icon || _vm.isPrependIcon,
        'append--icon': _vm.isAppendIcon || _vm.showPassword || _vm.isNowLoading || _vm.isDate,
        'input--clear': _vm.isNoStyle,
        'is-style--btn': _vm.isBtnStyle,
        'is--search': _vm.isSearch,
        'is--disabled': _vm.disabled
      },style:(_vm.inputStyle),attrs:{"placeholder":_vm.placeholder,"readonly":_vm.readonly,"type":_vm.inputType,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"max":_vm.max,"name":_vm.name,"autocomplete":_vm.type === 'password' ? 'new-password' : '',"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.handleInput($event)},"blur":function($event){return _vm.handleBlur($event)}}}),(_vm.showPassword)?_c('i',{class:{
      'append--icon': true,
      'password--eye': true,
      'fas fa-eye': !_vm.isPasswordShown,
      'fas fa-eye-slash': _vm.isPasswordShown
    },on:{"click":function($event){return _vm.showHidePassword()}}}):_vm._e(),(_vm.isDate)?_c('i',{class:{
      'append--icon': true,
      'far fa-calendar': true
    }}):_vm._e(),(_vm.isAppendIcon && _vm.isAppendIconClass)?_c('i',{class:'append--icon ' + _vm.isAppendIconClass}):_vm._e(),_vm._t("appendIcon"),(_vm.isNowLoading)?_c('div',{staticClass:"loading__container"},[_c('i',{class:{
      'append--icon' :true,
      'fas fa-circle-notch fa-spin' :true
      }})]):_vm._e()],2),(_vm.value && _vm.value.length === _vm.maxLength && !_vm.hideErrors)?_c('ul',{staticClass:"form__validation"},[_c('li',[_vm._v("Maximale lengte van "+_vm._s(_vm.maxLength)+" tekens bereikt")])]):_vm._e(),(_vm.errorModel.length && !_vm.hideErrors)?_c('ul',{staticClass:"form__validation"},_vm._l((_vm.errorModel),function(error){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(error !== ''),expression:"error !== ''"}],key:error.id},[_vm._v(_vm._s(error))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }