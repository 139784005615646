import { RelationModel } from '@/globals/models/relation/Relation'
import { GenderEnum } from '@/globals/enums/GenderEnum'
import { CurrencyEnum } from '@/globals/enums/CurrencyEnum'
import { dateHelper } from '@/globals/helpers/DateHelper'

export class AdministrationPersonRequestModel {
  id: number|null = null
  name = ''
  initial = ''
  first_name = ''
  last_name = ''
  email_text = ''
  email = ''
  phone = ''
  mobile = ''
  gender = new GenderEnum().male.value
  address = ''
  postal_code = ''
  city = ''
  country = 'NL'
  bank_account = ''
  bsn = null
  memo = ''
  married_to = ''
  children = 0
  date_of_birth = dateHelper.toDateString(new Date())
  note = ''
  hidden = false

  currency: number = new CurrencyEnum().euro.value

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: any) {
    this.id = data.id
    this.name = data.name
    this.initial = data.initial
    this.first_name = data.first_name
    this.last_name = data.last_name
    this.email = data.email
    this.email_text = data.email_text
    this.phone = data.phone
    this.mobile = data.mobile
    this.gender = data.gender
    this.address = data.address
    this.postal_code = data.postal_code
    this.city = data.city
    this.country = data.country ?? 'NL'
    this.bank_account = data.bank_account
    this.bsn = data.bsn
    this.memo = data.memo
    this.married_to = data.married_to
    this.date_of_birth = data.date_of_birth ? dateHelper.toDateString(data.date_of_birth) : dateHelper.toDateString(new Date())
    this.note = data.note
    this.hidden = data.hidden === 1
  }

  fromRelationModel (relation: RelationModel): AdministrationPersonRequestModel {
    let data = {}
    data = {
      ...relation,
      ...relation.private
    }

    this.setData(data)
    this.id = relation.id

    return this
  }
}
