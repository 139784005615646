
import Vue from 'vue'
import EnterprisesDataTable from '@/modules/administration/components/relation/relations-datatable/RelationsDataTable.vue'
import InfoBar from '@/globals/components/blank-slates/InfoBar.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    EnterprisesDataTable,
    InfoBar,
    PrimaryButton
  },
  data () {
    return {
      isTwinfieldConnected: false
    }
  },
  beforeMount () {
    this.isTwinfieldConnected = this.$store.getters['user/isTwinfieldConnected']
  },
  methods: {
    navigateToTwinfieldSettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.twinfield.name })
    }
  }
})
