
import Vue from 'vue'

import Company from '@/modules/relation/components/relation/company/Company.vue'
import Client from '@/modules/relation/components/client/Client.vue'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import Person from '../../components/relation/person/Person.vue'
import { ClientModel } from '@/globals/models/client/Client'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: {
    Breadcrumbs,
    Company,
    Client,
    Person
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      isEssentialsLoaded: false,
      isAccountant: this.$store.getters['user/isAccountant'],
      isEnterprise: this.$store.getters['user/isEnterprise'],
      relationToEdit: null as any
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      if (this.isEnterprise) {
        await this.getClient()
      } else {
        await this.getRelation()
      }

      this.isEssentialsLoaded = true
    },
    async getRelation () {
      const relationId = this.$route.params.id
      if (relationId) {
        await apiRelationService.getRelation(relationId).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.relationToEdit = new RelationModel().fromResponse(response.data)
          }
        })
      }
    },
    async getClient () {
      const clientId = this.$route.params.id
      if (clientId) {
        await apiRelationService.getClient(this.relation.id, clientId).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.relationToEdit = new ClientModel().fromResponse(response.data)
          }
        })
      }
    },

    navigateToRelationOverview () {
      this.$router.push({ name: routeHelper.relationRoutes.relation.overview.name })
    }
  }
})
