
import Vue from 'vue'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'

export default Vue.extend({
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    recurrenceCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      invoiceStatus: new InvoiceStatus(),
      invoicingStore: this.$store.state.invoicing
    }
  },
  computed: {
    counters (): object {
      const counted = {
        all: this.data.length,
        concept: 0,
        sent: 0,
        expired: 0,
        fully_paid: 0,
        recurrence_template: this.recurrenceCount
      } as any

      (this.data as Array<Invoice>).forEach((invoiceItem: any) => {
        const invoice = invoiceItem.invoice as Invoice
        switch (invoice.status) {
          case this.invoiceStatus.fully_paid.value:
            counted.fully_paid++
            break
          case this.invoiceStatus.partially_paid.value:
            counted.sent++
            break
          case this.invoiceStatus.sent.value:
            counted.sent++
            break
          case this.invoiceStatus.concept.value:
            counted.concept++
            break
          case this.invoiceStatus.recurrence_template.value:
            counted.recurrence_template++
            break
          default:
            break
        }

        if (invoice.isExpired() && invoice.status === this.invoiceStatus.sent.value) {
          counted.expired++
        }
      })
      return counted
    }
  }
})
