import { UserModel } from '@/globals/models/user/User'
import { RoleEnum } from '@/globals/enums/Role'
import { apiAdministrationService } from '../../network/api/api-administration-service'
import { HttpStatus } from '../../globals/enums/HttpStatus'
import { TokenModel } from '@/globals/models/auth/Token'
import { ImpersonateModel } from '@/globals/models/auth/Impersonate'

const state = () => ({
  user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') ?? '') : '',
  token: sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token') ?? '') : '',
  qrCode: null,
  twinfieldConnection: sessionStorage.getItem('twinfield_connection') ? JSON.parse(sessionStorage.getItem('twinfield_connection') ?? '') : '',
  impersonation: sessionStorage.getItem('impersonation') ? JSON.parse(sessionStorage.getItem('impersonation') ?? '') : null
})

const getters = {
  user: (state: any): UserModel => {
    return new UserModel().fromResponse(state.user)
  },
  token: (state: any): TokenModel => {
    return new TokenModel().fromResponse(state.token)
  },
  twinfieldConnection: (state: any): any => {
    return state.twinfieldConnection
  },
  impersonation: (state: any): ImpersonateModel|null => {
    return state.impersonation !== null ? new ImpersonateModel().fromResponse(state.impersonation) : null
  },
  isImpersonated: (state: any): boolean => {
    return state.impersonation !== null
  },
  isAccountant: (state: any): boolean => {
    return state.token.type === RoleEnum.ACCOUNTANT
  },
  isEnterprise: (state: any): boolean => {
    return state.token.type === RoleEnum.ENTERPRISE
  },
  isTwinfieldConnected: (state: any): boolean => {
    return getters.isAccountant(state) ? state.twinfieldConnection.is_connected : state.twinfieldConnection.is_connected && state.twinfieldConnection.office !== null
  }
}

const actions = {
  setUser ({ commit }: any, user: UserModel) {
    commit('setUser', user)
  },
  setToken ({ commit }: any, token: TokenModel) {
    commit('setToken', token)
  },
  setImpersonation ({ commit }: any, impersonation: ImpersonateModel) {
    commit('setImpersonation', impersonation)
  },
  unsetImpersonation ({ commit }: any) {
    commit('unsetImpersonation')
  },
  setQrCode ({ commit }: any, qrCode: string) {
    commit('setQrCode', qrCode)
  },
  async setConnections ({ commit }: any, data: any) {
    if (data) {
      commit('setConnections', data)
    } else {
      await apiAdministrationService.checkConnection().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          commit('setConnections', response.data)
        }
      })
    }
  }
}

const mutations = {
  setUser (state: any, user: UserModel) {
    sessionStorage.setItem('user', JSON.stringify(user))
    state.user = user
  },
  setToken (state: any, token: TokenModel) {
    sessionStorage.setItem('token', JSON.stringify(token))
    state.token = token
  },
  setImpersonation (state: any, impersonation: ImpersonateModel) {
    sessionStorage.setItem('impersonation', JSON.stringify(impersonation))
    state.impersonation = impersonation
  },
  unsetImpersonation (state: any) {
    state.impersonation = null
  },
  setConnections (state: any, data: any) {
    sessionStorage.setItem('twinfield_connection', JSON.stringify(data))
    state.twinfieldConnection = data
  },
  setQrCode (state: any, qrCode: string) {
    state.qrCode = qrCode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
