
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import InvoiceLineChart from '../../components/charts/InvoiceLineChart.vue'
import InvoiceBarChart from '../../components/charts/InvoiceBarChart.vue'
import OpenTasks from '../../components/open-tasks/OpenTasks.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import LatestQuestions from '../../components/latest-questions/LatestQuestions.vue'
import Shortcuts from '../../components/shortcuts/Shortcuts.vue'
import UploadDocuments from '@/modules/administration/components/documents/upload-documents/UploadDocuments.vue'
import TwinfieldCounters from '../../components/counters/twinfield/TwinfieldCounters.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import { TaskModel } from '@/modules/task/models/Task'
import LatestTasks from '../../components/latest-tasks/LatestTasks.vue'
import DayaEnterpriseCounters from '../../components/counters/daya-enterprise/DayaEnterpriseCounters.vue'
import LatestInvoices from '../../components/latest-invoices/LatestInvoices.vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import { dateHelper } from '@/globals/helpers/DateHelper'
import FilterDate from '@/globals/components/filters/filter-date/FilterDate.vue'
import MonthFilterDate from '../../components/month-filter-date/MonthFilterDate.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    InvoiceLineChart,
    InvoiceBarChart,
    OpenTasks,
    Dropdown,
    LatestQuestions,
    Shortcuts,
    UploadDocuments,
    TwinfieldCounters,
    LatestTasks,
    DayaEnterpriseCounters,
    LatestInvoices,
    BlankSlate,
    FilterDate,
    MonthFilterDate
  },
  data () {
    return {
      isLoaded: false,
      isReloading: false,
      dateHelper,
      isStatsLoaded: false,
      isInvoicesLoaded: false,
      isTasksLoaded: false,
      selectedFilterDate: 0,
      selectedFilterDates: [],
      counters: {
        invoices_open: 0,
        invoices_sent: 0,
        recurrences: 0,
        relation_count: 0
      },
      lastInvoices: [] as Array<Invoice>,
      lastRelations: [] as Array<any>,
      tasks: [] as TaskModel[],
      openTasksYear: new Date().getFullYear(),
      isUploadModalActive: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    invoices: {
      get (): Array<Invoice> {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Array<Invoice>) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    }
  },
  beforeMount () {
    this.loadEssentials()
    this.fetchTasks()
  },
  methods: {
    async loadEssentials () {
      this.isLoaded = false
      if (!this.isTwinfieldConnected) {
        this.selectedFilterDate = 2
      } else {
        this.selectedFilterDate = 0
      }
      this.fetchStats()

      if (this.invoices.length) {
        this.isInvoicesLoaded = true
      } else {
        this.fetchInvoices()
      }

      this.isLoaded = true
    },
    async fetchStats () {
      await apiInvoiceService.getDashboardStats().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.counters = response.data
          const invoicesRaw = response.data.last_invoices
          arrayHelper.clear(this.lastInvoices)
          invoicesRaw.forEach((invoiceRaw: any) => {
            const invoice = new Invoice().fromResponse(invoiceRaw)
            const relation = new RelationModel().fromResponse(invoiceRaw.to_relation.client)
            invoice.to_relation = relation
            this.lastInvoices.push(invoice)
          })
          if (response.data.last_relations) {
            this.lastRelations = response.data.last_relations
          }
        }
      }).finally(() => { this.isStatsLoaded = true })
    },

    async fetchInvoices () {
      await apiInvoiceService.getAllInvoices().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const invoicesRaw = response.data as Array<any>
          const invoices = [] as Array<Invoice>

          invoicesRaw.forEach(invoiceRaw => {
            const invoice = new Invoice().fromResponse(invoiceRaw)
            invoices.push(invoice)
          })
          this.invoices = invoices
        }
      }).finally(() => { this.isInvoicesLoaded = true })
    },

    async fetchTasks () {
      await apiTaskService.fetchTasks().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.tasks.push(task)
          })
        }
      }).then(() => { this.isTasksLoaded = true })
    },

    getYearSelections (): Array<number> {
      const years = []
      const currentYear = new Date().getFullYear()

      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i)
      }

      return years
    },

    navigateToInvoices () {
      this.$router.replace({ name: routeHelper.invoicingRoutes.invoicing.invoice.overview.name }).catch()
    },

    navigateToCreateInvoice () {
      this.$router.replace({ name: routeHelper.invoicingRoutes.invoicing.invoice.create.name }).catch()
    },

    navigateToTasks () {
      this.$router.replace({ name: routeHelper.taskRoutes.task.overview.name }).catch()
    },

    navigateToQuestions () {
      this.$router.replace({ name: routeHelper.questionRoutes.question.overview.name }).catch()
    },

    navigateToRelations () {
      this.$router.replace({ name: routeHelper.relationRoutes.relation.overview.name }).catch()
    }
  }
})
