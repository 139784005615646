
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    IconBase,
    IconChevronDown
  },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      documentStatus: new DocumentState()
    }
  },
  computed: {
    isToBeBookedSelected (): boolean {
      return (this.files as Array<BookingItem>).findIndex(item => item.status === this.documentStatus.TOBEBOOKED.value) >= 0
    },
    isSelectedInProgress (): boolean {
      return (this.files as Array<BookingItem>).findIndex(item => item.status === this.documentStatus.INPROGRESS.value) >= 0
    },
    isSelectedToProgress (): boolean {
      return (this.files as Array<BookingItem>).findIndex(item => item.status === this.documentStatus.TOPROGRESS.value) >= 0
    },
    isSelectedMultiplePages (): boolean {
      return (this.files as Array<BookingItem>).findIndex(item => item.amount_pages > 1) >= 0
    },
    isCutDisabled (): boolean {
      return this.files.length !== 1 || !this.isSelectedMultiplePages
    },
    isMergeDisabled (): boolean {
      return this.files.length < 2 || !this.isSelectedToProgress
    },
    isTagDisabled (): boolean {
      return !this.isSelectedToProgress
    }
  }
})
