<template>
  <div id="welcome">
    <welcome />
  </div>
</template>

<script>
import Welcome from '@/modules/auth/components/welcome/Welcome.vue'

export default {
  components: {
    Welcome
  },
  beforeMount () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
  #welcome {
    position: absolute;
    left: 0;
    right: 0;
    top: 10vw;
    display: flex;
    justify-content: center;
  }
</style>
