
import Vue from 'vue'
import { TaskStatusEnum } from '../../enums/TaskStatus'
import { TaskModel } from '../../models/Task'
import DropLane from './components/droplane/DropLane.vue'
import EditTask from './components/edit-task/EditTask.vue'
import { apiTaskService } from '@/network/api/api-task-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiAuthService } from '@/network/api/api-auth-service'
import Filters from './components/filters/Filters.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { ClientModel } from '@/globals/models/client/Client'
import { UserModel } from '@/globals/models/user/User'
import { AdministrationModel } from '@/globals/models/Administration'
import Echo from 'laravel-echo'
import { TokenModel } from '@/globals/models/auth/Token'

export default Vue.extend({
  components: { DropLane, EditTask, Filters },
  data () {
    return {
      userStore: this.$store.state.user,
      taskStore: this.$store.state.task,
      relationStore: this.$store.state.relation,
      essentialsLoaded: false,
      taskStatusEnum: new TaskStatusEnum(),
      isEditTask: false,
      isSpacer: false,
      echo: null as null|Echo,
      search: ''
    }
  },
  computed: {
    task: {
      get (): TaskModel {
        return this.$store.getters['task/task']
      },
      set (task: TaskModel) {
        this.$store.dispatch('task/setSelectedTask', task)
        return task
      }
    },
    isTaskSelected (): boolean {
      return this.task.id !== 0
    },
    isAccountant (): string {
      return this.$store.getters['user/isAccountant']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    token (): TokenModel {
      return this.$store.getters['user/token']
    },
    isDev (): boolean {
      return this.$store.getters['app/isDev']
    },
    administrationRelations (): Array<AdministrationRelationModel> {
      return this.$store.getters['administration/administrationRelations']
    },
    clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    },
    administrations (): Array<AdministrationModel> {
      return this.$store.getters['relation/administrations']
    }
  },
  watch: {
    task () {
      if (this.isTaskSelected) {
        this.isEditTask = true
        this.isSpacer = true
      } else {
        setTimeout(() => { this.isSpacer = false; this.isEditTask = false }, 200)
      }
    },
    isEditTask () {
      if (!this.isEditTask) {
        setTimeout(() => { this.$store.dispatch('task/setSelectedTask', new TaskModel()) }, 200)
      }
    },
    administrationRelations () {
      this.setAdministrationRelations(this.administrationRelations)
    },
    clients () {
      this.setClientRelations(this.clients)
    },
    administrations () {
      this.$store.dispatch('task/setAdministrations', this.administrations)
    }
  },
  async beforeMount () {
    (window as any).Pusher = require('pusher-js')
    await this.loadEssentials()
    this.initEcho()
  },
  mounted () {
    if (this.isTaskSelected) {
      this.isEditTask = true
      this.isSpacer = true
    }
  },
  beforeDestroy () {
    (window as any).Pusher = undefined
    // eslint-disable-next-line no-unused-expressions
    this.echo?.disconnect()
    this.$store.dispatch('task/setSelectedTask', new TaskModel())
  },
  methods: {
    initEcho () {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: this.isDev ? 'sweet_key' : 'gdtpFr',
        wsHost: 'soketi' + (this.isDev ? '-dev' : '') + '.daya.nl',
        wsPort: 6002,
        wssPort: 6002,
        authEndpoint: 'https://task-api' + (this.isDev ? '-dev' : '') + '.daya.nl/broadcasting/auth',
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.token.token
          }
        },
        forceTLS: true,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss']
      })

      this.echo.private('tasks-users.' + this.user.id).listen('TaskUpdated', this.onEchoResponse).error(async (err: any) => {
        if (err.status === HttpStatus.UNAUTHORIZED) {
          await apiAuthService.refresh()
          this.initEcho()
        }
      })
    },
    onEchoResponse (res: { status: HttpStatus; task: TaskModel }) {
      if (res.status === HttpStatus.UPDATED) {
        const oldTask = this.$store.getters['task/findTaskById'](res.task.id) as TaskModel
        const updated = new TaskModel().fromResponse(res.task)

        if (oldTask) {
          this.setUpdatedTask(oldTask, updated)

          if (this.task.id === updated.id) {
            this.task.fetchComments()
          }
        } else {
          this.setNewTask(updated)
        }
      } else if (res.status === HttpStatus.DELETED) {
        const list = this.$store.getters['task/getListByStatus'](res.task.status) as Array<TaskModel>
        const index = list.findIndex(task => task.id === res.task.id)

        if (this.task.id === res.task.id) {
          this.isEditTask = false
        }

        if (index >= 0) {
          list.splice(index, 1)
        }
      }
    },
    setUpdatedTask (old: TaskModel, updated: TaskModel) {
      if (updated.status !== old.status) {
        const newList = this.$store.getters['task/getListByStatus'](updated.status) as Array<TaskModel>
        const oldList = this.$store.getters['task/getListByStatus'](old.status) as Array<TaskModel>
        const oldIndex = oldList.findIndex(task => task.id === old.id)
        if (oldIndex >= 0) {
          oldList.splice(oldIndex, 1)
          newList.push(updated)
        }
      } else {
        old.fromResponse(updated)
        old.isCommentsLoaded = false
      }
    },
    setNewTask (updated: TaskModel) {
      const list = this.$store.getters['task/getListByStatus'](updated.status) as Array<TaskModel>
      list.push(updated)
    },

    async loadEssentials () {
      this.essentialsLoaded = false
      this.taskStore.isLoaded = false
      this.clearStore()

      if (this.administrations.length === 0) {
        this.$store.dispatch('relation/setAdministrations')
      } else {
        this.$store.dispatch('task/setAdministrations', this.administrations)
      }

      await this.fetchTasks()
      this.essentialsLoaded = true
      this.taskStore.isLoaded = true
    },
    clearStore () {
      this.taskStore.toDoTasks = []
      this.taskStore.plannedTasks = []
      this.taskStore.inProgressTasks = []
      this.taskStore.waitingForResponseTasks = []
      this.taskStore.doneTasks = []
    },
    async fetchUsers () {
      await apiAuthService.getAllUsers().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const users = [] as Array<{ id: number; name: string }>
          response.data.forEach((userRaw: any) => {
            users.push({ id: userRaw.id, name: `${userRaw.name} ${userRaw.last_name}` })
          })
          this.taskStore.users = users
        }
      })
    },
    setAdministrationRelations (administrationRelations: Array<AdministrationRelationModel>) {
      const users = [] as Array<{ id: number; name: string }>
      administrationRelations.forEach((administrationRelation: AdministrationRelationModel) => {
        if (!administrationRelation.enterprise.isHidden()) {
          users.push({ id: administrationRelation.enterprise.id, name: administrationRelation.enterprise.getRelationName() })
        }
      })
      this.taskStore.relations = users
    },
    setClientRelations (clients: Array<ClientModel>) {
      const users = [] as Array<{ id: number; name: string }>
      clients.forEach((client: ClientModel) => {
        if (!client.client.isHidden()) {
          users.push({ id: client.client.id, name: client.client.getRelationName() })
        }
      })
      this.taskStore.relations = users
    },
    async fetchTasks () {
      await apiTaskService.fetchTasks().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          response.data.forEach((taskRaw: any) => {
            const task = new TaskModel().fromResponse(taskRaw)
            this.$store.dispatch('task/addTaskEnd', task)
          })
        }
      })
    },

    focusOut () {
      const isTasksUpdating = this.$store.getters['task/isTasksUpdating'] as boolean
      if (this.isTaskSelected && !isTasksUpdating) {
        this.isEditTask = false
        const scrollContainer = document.getElementById('task-overview-wrapper')

        if (scrollContainer) {
          scrollContainer.scrollTo(0, scrollContainer.scrollTop)
        }
      }
    }
  }
})
