
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton,
    DatePicker
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      essentialsLoaded: false,
      date: new Date().toISOString().slice(0, 10),
      file: null as any,
      filesImportedCount: 0 as number,
      filesImported: null as any,
      errors: null as any,
      warnings: null as any
    }
  },
  computed: {
    relation (): boolean {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      await this.fetchImportedFiles()
      this.essentialsLoaded = true
    },
    async fetchImportedFiles () {
      await apiAdministrationService.fetchImportedFiles().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.filesImportedCount = response.data.length ? response.data[0].length : 0
          this.filesImported = JSON.stringify(response.data, undefined, 4)
        }
      })
    },

    async onSelectedImage (e: any) {
      const file = e.target.files[0]

      if (file) {
        this.file = file
      }
    },

    async submit () {
      const formData = new FormData()
      formData.append('date', this.date)
      formData.append('file', this.file)

      await apiAdministrationService.dannyImport(formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.errors = JSON.stringify(response.data.errors, undefined, 4)
          this.warnings = JSON.stringify(response.data.warnings, undefined, 4)
        }
        this.file = null
      })
    }
  }
})
