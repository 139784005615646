import { render, staticRenderFns } from "./DashboardAccountant.vue?vue&type=template&id=51fae903&scoped=true&"
import script from "./DashboardAccountant.vue?vue&type=script&lang=ts&"
export * from "./DashboardAccountant.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardAccountant.vue?vue&type=style&index=0&id=51fae903&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fae903",
  null
  
)

export default component.exports