import { RouteConfig } from 'vue-router'
import Project from '../views/Project.vue'
import Overview from '../views/overview/Overview.vue'
import Create from '../views/create/Create.vue'

const relationRoutes: Array<RouteConfig> = [
  {
    path: '/project',
    name: 'project',
    component: Project,
    redirect: { name: 'project_overview' },
    meta: {
      name_nl: 'overzicht'
    },
    children: [
      {
        path: 'overview',
        name: 'project_overview',
        component: Overview,
        meta: {
          name_nl: 'overzicht'
        }
      },
      {
        path: 'create',
        name: 'project_create',
        component: Create,
        meta: {
          name_nl: 'nieuw'
        }
      }
    ]
  }
]

export default relationRoutes
