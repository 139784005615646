
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiRelationService } from '@/network/api/api-relation-service'
import { Notify } from '@/globals/controllers/Notification'
import { RelationModel } from '@/globals/models/relation/Relation'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { landCodes } from '@/globals/enums/LandCodesEnum'
import TextArea from '@/globals/components/forms/TextArea.vue'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    currentRelation: {
      type: Object,
      required: false
    },
    organisations: {
      required: true
    }
  },
  components: {
    PrimaryButton,
    InputField,
    Dropdown,
    TextArea
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      essentialsLoaded: false,
      relation: new EnterpriseRelationRequestModel(),
      relationErrors: entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel()) as any,
      relationActive: [
        { text: 'Ja', value: false },
        { text: 'Nee', value: true }
      ],
      relationMayBook: [
        { text: 'Ja', value: true },
        { text: 'Nee', value: false }
      ],
      selectedOrganisation: null as null|number,
      enterprises: [] as any,
      selectedEnterprise: null as any,
      isCocChanged: false,
      isEdit: false,
      submitDisabled: true,
      landCodes,
      isLoadingEnterprises: false
    }
  },
  computed: {
    selfRelation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  watch: {
    selectedOrganisation () {
      this.enterprises.length = 0
      this.getEnterprises()
    }
  },
  beforeMount () {
    if (this.currentRelation) {
      this.relation = new EnterpriseRelationRequestModel().fromRelationModel(this.currentRelation)
      this.isEdit = true
    }
  },
  methods: {
    async getEnterprises () {
      if (this.selectedOrganisation !== null) {
        this.isLoadingEnterprises = true
        await apiAdministrationService.getTwinfieldEnterprises(this.selectedOrganisation).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.buildEnterprises(response.data)
          }
        }).finally(() => { this.isLoadingEnterprises = false })
      }
    },
    buildEnterprises (data: any) {
      data.forEach((enterprise: { code: string; name: string }) => {
        this.enterprises.push({ value: enterprise, text: enterprise.name })
      })
    },

    async setCocData (input: any) {
      if (this.isCocChanged) {
        this.isCocChanged = false
        this.clearErrors()
        await apiRelationService.getCocData(input).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            const info = response.data as any
            this.relation.name = info.name
            this.relation.address = info.address
            this.relation.postal_code = info.postal_code
            this.relation.city = info.city
          }

          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.mergeRelationErrors(response.data)
          }
        })
      }
    },

    async submitForm (e: any) {
      e.preventDefault()
      this.clearErrors()
      const validationError = this.validateform()

      if (!validationError) {
        if (this.isEdit) {
          await this.editRelation()
        } else {
          await this.createRelation()
        }
      }
    },
    clearErrors () {
      this.relationErrors = entityHelper.generateErrorEntity(new EnterpriseRelationRequestModel())
    },
    validateform (): boolean {
      let error = false

      if (!this.relation.name) {
        this.relationErrors.name.push('Bedrijfsnaam is verplicht.')
        error = true
      }
      if (!this.relation.email) {
        this.relationErrors.email.push('Algemene email is verplicht.')
        error = true
      }

      return error
    },

    async createRelation () {
      await apiRelationService.createAdministrationRelation(this.relationId, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const relation = new RelationModel().fromResponse(response.data.relation)
          await this.toggleRelationActiveState(relation.id)

          if (this.selectedEnterprise) {
            await this.saveEnterprise(relation.id)
          }

          new Notify().create('Gelukt!', 'De relatie is succesvol aagemaakt')
          this.afterCreateOrUpdate(relation, false)
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },

    async editRelation () {
      await apiRelationService.updateRelation(this.relation.id, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          const relation = new RelationModel().fromResponse(response.data.relation)
          await this.toggleRelationActiveState(relation.id)

          if (this.relation.booking_enabled !== this.currentRelation.administration_relation.booking_enabled) {
            await this.updateBooking(relation.id)
          }

          new Notify().create('Gelukt!', 'De relatie is succesvol gewijzigd')
          this.afterCreateOrUpdate(relation, true)
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeRelationErrors(response.data)
        }
      })
    },
    afterCreateOrUpdate (relation: RelationModel, isUpdate: boolean) {
      relation.hidden = this.relation.hidden
      const administrationRelation = new AdministrationRelationModel()
      administrationRelation.enterprise = relation
      administrationRelation.booking_enabled = this.relation.booking_enabled ? 1 : 0

      if (isUpdate) {
        this.$store.dispatch('administration/updateAdministrationRelation', administrationRelation)
      } else {
        this.$store.dispatch('administration/addAdministrationRelation', administrationRelation)
      }
      this.navigateToRelationOverview()
    },
    async toggleRelationActiveState (id: number) {
      if (this.relation.hidden) {
        await this.setRelationNonActive(id)
      } else {
        await this.setRelationActive(id)
      }
    },
    async setRelationActive (id: number) {
      await apiRelationService.showRelation(this.relationId, id)
    },
    async setRelationNonActive (id: number) {
      await apiRelationService.hideRelation(this.relationId, id)
    },

    async updateBooking (relationId: number) {
      await apiRelationService.updateBooking(this.relationId, relationId, this.relation.booking_enabled)
    },

    async saveEnterprise (relationId: number|string) {
      if (this.selectedOrganisation !== null) {
        await apiAdministrationService.saveTwinfieldEnterprise(relationId, this.selectedEnterprise.code, this.selectedEnterprise.name, this.selectedOrganisation)
      }
    },

    async deactivateRelation () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze relatie wil verwijderen?', isDeleting: true })

      if (isConfirmed && this.relation.id !== null) {
        await apiRelationService.deactivateRelation(this.selfRelation.id, this.relation.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            this.$store.dispatch('administration/removeAdministrationRelationByRelationId', this.relation.id)
            this.navigateToRelationOverview()
          }
        })
      }
    },

    mergeRelationErrors (errors: object) {
      this.relationErrors = {
        ...this.relationErrors,
        ...errors
      }
    },
    navigateToRelationOverview () {
      this.$emit('nav_to_overview')
    }
  }
})
