
import Vue from 'vue'
import SideNav from '@/modules/settings/views/company/components/SideNav.vue'
import Users from './components/UsersDatatable.vue'
import { RelationModel } from '@/globals/models/relation/Relation'

export default Vue.extend({
  components: {
    SideNav,
    Users
  },
  data () {
    return {
      relationStore: this.$store.state.relation
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  }
})
