
import Vue from 'vue'
import { Product } from '../../../models/product/Product'
import { ProductUnit } from '../../../enums/ProductUnit'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import TextArea from '@/globals/components/forms/TextArea.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  props: {
    _product: {
      type: Product,
      required: false
    }
  },
  components: {
    PrimaryModal,
    InputField,
    TextArea,
    PrimaryButton,
    Dropdown
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      essentialsLoaded: false,
      product: new Product(),
      errors: entityHelper.generateErrorEntity(new Product()) as any,
      units: new ProductUnit().getAll(),
      taxes: [] as Array<any>,
      isEdit: false
    }
  },
  beforeMount () {
    if (this._product) {
      this.product = new Product(this._product)
      this.isEdit = true
    }
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.essentialsLoaded = false
      await this.getTaxes()
      this.essentialsLoaded = true
    },
    async getTaxes () {
      await apiInvoiceService.getTaxes().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await response.data.forEach((tax: any) => {
            this.taxes.push({ text: tax.name, value: tax.id })
          })
        }
      })
    },
    async submitForm () {
      if (!this.isFormError()) {
        if (this.isEdit) {
          await this.updateProduct()
        } else {
          await this.createProduct()
        }
      }
    },
    isFormError () {
      this.errors = entityHelper.generateErrorEntity(new Product())
      let error = false

      if (!this.product.title) {
        this.errors.title.push('Naam is verplicht')
        error = true
      }

      if (!this.product.value) {
        this.errors.value.push('Bedrag is verplicht')
        error = true
      }

      if (!this.product.tax_id) {
        this.errors.tax_id.push('BTW is verplicht')
        error = true
      }

      return error
    },

    async createProduct () {
      apiInvoiceService.createProduct(this.product).then((response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const productId = response.data.result.id
          this.product.id = productId
          this.product.value = response.data.result.value.toString()
          new Notify().success('Gelukt!', this.product.title + ' is aangemaakt')
          this.$emit('created', this.product)
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handleApiErrors(response.data)
        }
      })
    },

    async updateProduct () {
      if (this.product.id) {
        await apiInvoiceService.updateProduct(this.product.id, this.product).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            new Notify().updated()
            this.$emit('updated', this.product)
          } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.handleApiErrors(response.data)
          }
        })
      }
    },

    async deleteProduct () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je ' + this.product.title + ' wil verwijderen?', isDeleting: true })

      if (isConfirmed && this.product.id) {
        await apiInvoiceService.deleteProduct(this.product.id).then((response: any) => {
          if (response.status === HttpStatus.DELETED) {
            new Notify().deleted()
            this.$emit('deleted', this.product)
          } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.handleApiErrors(response.data)
          }
        })
      }
    },

    handleApiErrors (errors: any) {
      this.errors = {
        ...this.errors,
        ...errors
      }
    }
  }
})
