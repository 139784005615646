
import Vue from 'vue'

import { BookingItem } from '@/modules/administration/models/BookingItem'
import { DocumentState } from '@/modules/administration/enums/DocumentState'

import DocumentStateColor from './DocumentStateColor.vue'
import ActionButton from './action-button/ActionButton.vue'
import UploadDocuments from '../upload-documents/UploadDocuments.vue'
import BookDocument from '../book-document/BookDocument.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InfoBar from '@/globals/components/blank-slates/InfoBar.vue'
import BlankSlate from '@/globals/components/blank-slates/GreyArea.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { dateHelper } from '@/globals/helpers/DateHelper'
import SelectBookingItemTag from '../../modals/select-booking-item-tag/SelectBookingItemTag.vue'
import MoveBookingItem from '../../modals/move-booking-item/MoveBookingItem.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import PdfCutModal from '@/modules/administration/components/modals/pdf-cutter/PdfCutter.vue'
import MergeFilesModal from '@/modules/administration/components/modals/merge-files/MergeFiles.vue'
import { priceHelper } from '@/globals/helpers/PriceHelper'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import { TablePaginationModel } from '@/globals/models/TablePagination'
import { VatEnum } from '@/globals/enums/Vat'
import HorizontalTabbar from './tabbar/HorizontalTabbar.vue'
import { DocumentTableTab } from '../../../enums/DocumentTableTab'
import { DocumentTableFilter } from '../../../models/DocumentTableFilter'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconPlus from '@/globals/components/icons/IconPlus.vue'
import IconArrowDownSmall from '@/globals/components/icons/IconArrowDownSmall.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'
import Tooltip from '@/globals/components/tooltip/Tooltip.vue'
import { DownloadFile, downloadHelper } from '@/globals/helpers/DownloadHelper'

export default Vue.extend({
  components: {
    PrimaryButton,
    InputField,
    DocumentStateColor,
    UploadDocuments,
    BookDocument,
    InfoBar,
    BlankSlate,
    ActionButton,
    SelectBookingItemTag,
    MoveBookingItem,
    PdfCutModal,
    MergeFilesModal,
    HorizontalTabbar,
    IconBase,
    IconPlus,
    IconArrowDownSmall,
    IconLookingGlass,
    Tooltip
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      isEssentialsLoaded: false,
      documentState: new DocumentState(),
      isUploadModalActive: false,
      isBookModalActive: false,
      isSelectTagModalActive: false,
      search: '',
      tabs: [
        { value: DocumentTableTab.ALL, name: 'Alle documenten' },
        { value: DocumentTableTab.BOOKED, name: 'Geboekt' },
        { value: DocumentTableTab.TO_BOOK, name: 'Te boeken' },
        { value: DocumentTableTab.QUESTIONS, name: 'Vragen' },
        { value: DocumentTableTab.TO_PROGRESS, name: 'Te verwerken' }
      ],
      headers: [
        { text: '', sortable: false, value: 'file', width: 35 },
        { text: 'Nr.', align: 'start', value: 'invoiceNumber', width: 150 },
        { text: 'Relatie', align: 'start', value: 'supplierName', width: 150 },
        { text: 'Label', value: 'tag', width: 150 },
        { text: 'Aanleverdatum', value: 'uploadDateUnix', width: 150 },
        { text: 'Factuurdatum', value: 'invoiceDateUnix', width: 150 },
        { text: 'Totaalbedrag', value: 'amount', align: 'right', width: 150 },
        { text: 'Status', value: 'tableStatus', width: 150 },
        { text: '', value: 'search', sortable: false, width: 0, class: 'header--hidden' }
      ],
      tableOptions: {
        sortBy: ['uploadDateUnix'] as string[],
        sortDesc: [true] as boolean[]
      },
      selected: [] as Array<any>,
      documents: [] as Array<any>,
      isMoveBookingItemModalActive: false,
      isCutPdfModalActive: false,
      isMergeFilesModalActive: false,
      taxes: new VatEnum().getVats()
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isDev (): boolean {
      return this.$store.getters['app/isDev']
    },
    isLoading (): boolean {
      return this.$store.getters['loading/isLoading']
    },
    isTwinfieldConnected (): boolean {
      return this.$store.getters['user/isTwinfieldConnected']
    },
    ledgerAccounts (): Array<LedgerAccountModel> {
      return this.$store.getters['administration/ledgerAccounts']
    },
    tags (): Array<LedgerAccountModel> {
      return this.ledgerAccounts.filter((ledger) => ledger.type === LedgerAccount.TagType)
    },
    taxDefaults (): Array<{tax_id: number; default: number|null; ledger_account_id: number|null}> {
      return this.$store.getters['administration/taxDefaults']
    },
    bookingItems (): Array<BookingItem> {
      return this.$store.getters['book/bookingItems']
    },
    currentBookingItem (): null|BookingItem {
      return this.$store.getters['book/currentBookingItem']
    },
    filter: {
      get (): DocumentTableFilter {
        return this.$store.getters['book/filter']
      },
      set (filter: DocumentTableFilter) {
        this.$store.dispatch('book/setFilter', filter)
        return filter
      }
    },
    tablePagination (): TablePaginationModel {
      return this.$store.getters['book/tablePagination']
    },
    filteredBookingItems (): Array<any> {
      return this.documents.filter((item) => {
        const bookingItem = item.bookingItem as BookingItem
        const isValid = this.filterTabs(bookingItem)

        return isValid
      })
    },
    filesSelected (): Array<BookingItem> {
      const selected = [] as Array<BookingItem>
      this.selected.forEach((item: any) => {
        selected.push(item.bookingItem)
      })

      return selected
    },
    isToBeBookedSelected (): boolean {
      return this.filesSelected.findIndex(item => item.status === this.documentState.TOBEBOOKED.value) >= 0
    },
    isSelectedInProgress (): boolean {
      return this.filesSelected.findIndex(item => item.status === this.documentState.INPROGRESS.value) >= 0
    },
    isVatsConnected (): boolean {
      let isConnected = true
      this.taxes.forEach(tax => {
        const foundTax = this.taxDefaults.find(x => x.tax_id === tax.value)
        if (!foundTax) {
          isConnected = false
        }
      })
      return isConnected
    }
  },
  watch: {
    bookingItems: {
      handler: function () {
        this.clearDocuments()
        this.buildDocumentsTable(this.bookingItems)
      },
      deep: true
    }
  },
  async beforeMount () {
    await this.loadEssentials()
    const queryId = this.$route.query.id as undefined | string
    if (queryId) {
      const id = parseInt(queryId)
      const bookingItem = this.bookingItems.find(b => b.id === id)
      if (bookingItem) {
        this.selectItem(bookingItem)
      }
    }
  },
  methods: {
    async loadEssentials () {
      if (!this.ledgerAccounts.length || !this.tags.length) {
        this.$store.dispatch('administration/setLedgerAccounts')
      }
      if (!this.taxDefaults.length) {
        this.$store.dispatch('administration/setTaxDefaults')
      }
      if (!this.bookingItems.length) {
        await this.fetchDocuments()
      } else {
        this.buildDocumentsTable(this.bookingItems)
      }
      this.isEssentialsLoaded = true
    },

    async fetchDocuments () {
      await apiAdministrationService.getBookingItems().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const bookingItems = this.documentsFromResponse(response.data)
          this.$store.dispatch('book/setBookingItems', bookingItems)
          this.buildDocumentsTable(bookingItems)
        }
      })
    },
    documentsFromResponse (documentsRaw: Array<unknown>): Array<BookingItem> {
      const documents = [] as Array<BookingItem>

      documentsRaw.forEach(data => {
        const document = new BookingItem().fromResponse(data)
        documents.push(document)
      })

      return documents
    },
    buildDocumentsTable (documents: Array<BookingItem>) {
      documents.forEach(document => {
        this.insertDocument(document)
      })
    },
    insertDocument (document: BookingItem) {
      const documentState = this.documentState.getStateFromValue(document.status)
      const tag = this.getTagFromCode(document.tag)
      const invoiceNumber = document.invoice_number ?? '(nog geen nr.)'
      const invoiceDate = document.date && documentState !== this.documentState.TOPROGRESS ? dateHelper.toShortMonthDateString(document.date) : ''
      const uploadDate = document.uploaded_date ? dateHelper.toShortMonthDateString(document.uploaded_date) : ''

      let ledgerSearch = ''
      let costPlaceSearch = ''
      let projectSearch = ''

      document.booking_lines.forEach((line) => {
        if (line.ledger_account_id !== null) {
          const ledger = this.ledgerAccounts.find(ledger => ledger.id === line.ledger_account_id)

          if (ledger) {
            ledgerSearch = ledgerSearch + ledger.getLedgerCodeName()
          }
        }
        if (line.cost_place_ledger_account_id !== null) {
          const ledger = this.ledgerAccounts.find(ledger => ledger.id === line.cost_place_ledger_account_id)

          if (ledger) {
            costPlaceSearch = costPlaceSearch + ledger.getLedgerCodeName()
          }
        }
        if (line.project_ledger_account_id !== null) {
          const ledger = this.ledgerAccounts.find(ledger => ledger.id === line.project_ledger_account_id)

          if (ledger) {
            projectSearch = projectSearch + ledger.getLedgerCodeName()
          }
        }
      })

      let errors = [] as string[]
      const bookingItemErrors = document.booking_errors ? JSON.parse(document.booking_errors) : null
      if (bookingItemErrors) {
        const errorMessages = this.getErrorMessage(bookingItemErrors)
        errors = errors.concat(errorMessages)
      }

      const tableItem = {
        id: document.id,
        invoiceNumber,
        fileName: document.file_name,
        url: document.url,
        creditor: document.creditor,
        invoiceDate,
        invoiceDateUnix: document.date && documentState !== this.documentState.TOPROGRESS ? dateHelper.toUnix(document.date) : 0,
        uploadDate,
        uploadDateUnix: document.uploaded_date ? dateHelper.toUnix(document.uploaded_date) : 0,
        amount: document.amount,
        amount_local: document.amount ? priceHelper.getLocalPrice(document.amount) : '',
        tag: document.tag,
        tagName: tag ? tag.name : '',
        supplierName: document.supplier ? document.supplier.getRelationName() : '',
        status: document.status,
        documentState,
        tableStatus: document.status === this.documentState.TOBEBOOKED.value && document.booking_errors ? 0.9 : document.status,
        isBookingError: document.booking_errors,
        errors,
        bookingItem: document,
        search: invoiceNumber + invoiceDate + uploadDate + ledgerSearch + costPlaceSearch + projectSearch,
        merge: false,
        row_class: 'row--click',
        isSelectable: document.status !== this.documentState.INPROGRESS.value && document.status !== this.documentState.BOOKED.value
      }
      this.documents.push(tableItem)
    },
    getErrorMessage (val: Array<string>|string): Array<string> {
      let errors = [] as Array<string>
      if (typeof val === 'string') {
        errors.push(val)
      } else if (typeof val === 'object') {
        Object.values(val).forEach((value: any) => {
          const errorMessages = this.getErrorMessage(value)
          errors = errors.concat(errorMessages)
        })
      }

      return errors
    },
    getTagFromCode (code: string|null) {
      let tag = null
      for (let i = 0; i < this.tags.length; i++) {
        const item = this.tags[i] as any
        if (code === item.code) {
          tag = item
          break
        }
      }
      return tag
    },

    filterTabs (bookingItem: BookingItem) {
      if (this.filter.tab === DocumentTableTab.BOOKED) {
        return bookingItem.status === this.documentState.BOOKED.value
      } else if (this.filter.tab === DocumentTableTab.TO_BOOK) {
        return bookingItem.status === this.documentState.TOBEBOOKED.value
      } else if (this.filter.tab === DocumentTableTab.QUESTIONS) {
        return bookingItem.status === this.documentState.QUESTIONS.value
      } else if (this.filter.tab === DocumentTableTab.TO_PROGRESS) {
        return bookingItem.status === this.documentState.TOPROGRESS.value
      } else {
        return true
      }
    },

    showUploadModal () {
      this.isUploadModalActive = true
    },
    hideUploadModal () {
      this.isUploadModalActive = false
    },
    showBookModal () {
      this.isBookModalActive = true
    },
    hideBookModal () {
      this.isBookModalActive = false
    },
    showSelectTagModal () {
      this.isSelectTagModalActive = true
    },
    hideSelectTagModal () {
      this.isSelectTagModalActive = false
    },
    showMoveBookingItemModal () {
      this.isMoveBookingItemModalActive = true
    },
    hideMoveBookingItemModal () {
      this.isMoveBookingItemModalActive = false
    },
    showCutPdfModal () {
      this.isCutPdfModalActive = true
    },
    hideCutPdfModal () {
      this.isCutPdfModalActive = false
    },
    showMergeFilesModal () {
      this.isMergeFilesModalActive = true
    },
    hideMergeFilesModal () {
      this.isMergeFilesModalActive = false
    },

    getUploadUrl (): string {
      return apiAdministrationService.getBookingItemUploadUrl()
    },

    clearDocuments () {
      arrayHelper.clear(this.documents)
    },

    selectItem (bookingItem: BookingItem) {
      this.setSelectedDocument(bookingItem)
      this.showBookModal()
    },

    cutPdf (bookingItem: BookingItem) {
      if (bookingItem && bookingItem.amount_pages > 1 && bookingItem.status === this.documentState.TOPROGRESS.value) {
        this.setSelectedDocument(bookingItem)
        this.showCutPdfModal()
      }
    },

    setSelectedDocument (item: BookingItem|null) {
      this.$store.commit('book/setCurrentBookingItem', item)
    },

    async deleteFiles () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Deze ' + this.filesSelected.length + ' items verwijderen?', isDeleting: true })
      if (isConfirmed) {
        this.filesSelected.forEach(async (item: BookingItem) => {
          await apiAdministrationService.deleteBookingItem(item.id).then((response: any) => {
            if (response.status === HttpStatus.OK) {
              this.removeBookingItem(item.id)
              this.removeFromSelected(item.id)
            }
          })
        })
      }
    },

    merged () {
      arrayHelper.clear(this.selected)
      this.clearDocuments()
      this.hideMergeFilesModal()
      this.fetchDocuments()
    },

    async refreshDocuments () {
      this.isEssentialsLoaded = false
      arrayHelper.clear(this.selected)
      this.clearDocuments()
      await this.fetchDocuments()
      this.isEssentialsLoaded = true
    },

    removeBookingItems (items: Array<BookingItem>) {
      items.forEach((item: BookingItem) => {
        this.removeBookingItem(item.id)
        this.removeFromSelected(item.id)
      })
    },
    removeBookingItem (id: number) {
      const index = this.bookingItems.findIndex(bookingItem => bookingItem.id === id)

      if (index >= 0) {
        this.bookingItems.splice(index, 1)
      }
    },
    removeFromSelected (id: number) {
      const index = this.selected.findIndex(item => item.id === id)

      if (index >= 0) {
        this.selected.splice(index, 1)
      }
    },

    setTablePagination (tablePagination: TablePaginationModel) {
      this.$store.dispatch('book/setTablePagination', tablePagination)
    },
    setTableItems (e: Array<any>) {
      this.$store.dispatch('book/setTableItems', e)
    },

    navigateToTwinfieldSettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.twinfield.name })
    },

    onTabChange (value: DocumentTableTab) {
      this.tablePagination.page = 1
      this.filter.tab = value
      this.$store.dispatch('book/setFilter', this.filter)
    },

    goNextBookingItem () {
      this.$store.dispatch('book/goToNextBookingItem')
    },

    async downloadAll () {
      const toDownloads = [] as DownloadFile[]
      const items = this.bookingItems.filter(item => item.tag !== null).sort((a, b) => {
        // First, sort by tag in ascending order
        // eslint-disable-next-line
        if (a.tag! < b.tag!) return -1
        // eslint-disable-next-line
        if (a.tag! > b.tag!) return 1

        // If tags are the same, sort by date in descending order
        // eslint-disable-next-line
        if (a.date > b.date) return -1
        // eslint-disable-next-line
        if (a.date < b.date) return 1
        return 0
      })
      items.forEach(item => {
        const toDownload = {
          fileName: item.file_name,
          url: item.url
        } as DownloadFile
        toDownloads.push(toDownload)
      })

      await downloadHelper.downloadMultipleUrls(toDownloads)
    }
  }
})
