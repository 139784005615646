
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'
import { ShortcutModel } from '@/globals/models/Shortcut'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
export default Vue.extend({
  components: { PrimaryModal, InputField, PrimaryButton },
  data () {
    return {
      shortcut: new ShortcutModel(),
      errors: entityHelper.generateErrorEntity(new ShortcutModel()) as any
    }
  },
  methods: {
    submit () {
      const isValidationError = this.isValidationError()

      if (!isValidationError) {
        this.createShortcut()
      }
    },
    isValidationError (): boolean {
      this.errors = entityHelper.generateErrorEntity(new ShortcutModel())
      let isError = false

      if (!this.shortcut.url) {
        this.errors.url.push('Url is verplicht')
        isError = true
      }

      return isError
    },
    async createShortcut () {
      await apiAuthService.createShortcut(this.shortcut).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const shortcut = new ShortcutModel().fromResponse(response.data)
          new Notify().success('Gelukt!', 'Snelkoppeling ' + shortcut.name + ' is aangemaakt')
          this.$emit('created', shortcut)
        }
      })
    }
  }
})
