import { render, staticRenderFns } from "./Btw.vue?vue&type=template&id=bab61dc4&scoped=true&"
import script from "./Btw.vue?vue&type=script&lang=ts&"
export * from "./Btw.vue?vue&type=script&lang=ts&"
import style0 from "./Btw.vue?vue&type=style&index=0&id=bab61dc4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab61dc4",
  null
  
)

export default component.exports