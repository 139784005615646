
import Vue from 'vue'
import InvoiceProductsComponent from '@/modules/invoicing/components/invoice/invoice-products/InvoiceProductsComponent.vue'
import InvoiceRepeat from '@/modules/invoicing/components/invoice/invoice-repeat/InvoiceRepeatSettings.vue'
import RecurrenceInvoices from './recurrence-invoices-datatable/RecurrenceInvoicesDataTable.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import InfoBar from '@/globals/components/blank-slates/InfoBar.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import ClientForm from '@/globals/components/client/ClientForm.vue'
import ColoredState from '@/globals/components/colored-state/ColoredState.vue'
import PaidButton from './invoice-to-paid/paid-button/PaidButton.vue'
import InvoiceSendButton from '../invoice/invoice-send-button/InvoiceSendButton.vue'
import InvoiceAttachments from './invoice-attachments/InvoiceAttachments.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceProduct } from '@/modules/invoicing/models/invoice/InvoiceProduct'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { Tax } from '@/modules/invoicing/enums/Tax'
import { InvoiceAttachment } from '@/modules/invoicing/models/invoice/InvoiceAttachment'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { EnterpriseRelationTypeEnum } from '@/globals/enums/EnterpriseRelationType'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { Notify } from '@/globals/controllers/Notification'
import { downloadHelper } from '@/globals/helpers/DownloadHelper'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { platformHelper } from '@/globals/helpers/PlatformHelper'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { ClientModel } from '@/globals/models/client/Client'
import { PaymentPeriod } from '@/globals/enums/PaymentPeriod'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'
import Breadcrumbs from '@/globals/components/breadcrumbs/Breadcrumbs.vue'
import TextArea from '@/globals/components/forms/TextArea.vue'
import { InvoiceRepeatSettings } from '../../models/invoice/InvoiceRepeatSettings'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconAddClient from '@/globals/components/icons/IconAddClient.vue'
import IconCheckmark from '@/globals/components/icons/IconCheckmark.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    DatePicker,
    InfoBar,
    Dropdown,
    InputField,
    ClientForm,
    InvoiceProductsComponent,
    InvoiceRepeat,
    ColoredState,
    RecurrenceInvoices,
    PaidButton,
    InvoiceSendButton,
    InvoiceAttachments,
    Breadcrumbs,
    TextArea,
    IconBase,
    IconAddClient,
    IconCheckmark
  },
  props: {
    isFirstInvoice: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      invoicingStore: this.$store.state.invoicing,
      relationStore: this.$store.state.relation,
      dialogStore: this.$store.state.dialog,
      invoiceErrors: entityHelper.generateErrorEntity(new Invoice()) as any,
      invoiceState: new InvoiceStatus(),
      clients: [] as Array<{ text: string; value: number }>,
      showCreateClientModal: false,
      showUpdateClientModal: false,
      showRepeatModal: false,
      files: null as any,
      downloadHelper,
      dateHelper,
      platformHelper,
      oldInvoice: '',
      oldRecurrence: ''
    }
  },
  computed: {
    invoice: {
      get (): Invoice {
        return this.$store.getters['invoicing/invoice']
      },
      set (invoice: Invoice) {
        this.$store.dispatch('invoicing/setInvoice', invoice)
        return invoice
      }
    },
    invoices: {
      get (): Array<Invoice> {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Array<Invoice>) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    isSubmitDisabled (): boolean {
      return JSON.stringify(this.invoice) === this.oldInvoice
    },
    repeatSettings (): InvoiceRepeatSettings {
      return this.invoice.recurrence
    },
    isDev (): boolean {
      return this.$store.getters['app/isDev']
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    _clients (): Array<ClientModel> {
      return this.$store.getters['client/clients']
    },
    selectedRelation (): RelationModel|null {
      const client = this._clients.find(c => c.client.id === this.invoice.to_relation_id)
      return client ? client.client : null
    },
    isInvoiceSaved (): boolean {
      return this.invoice.id !== null
    },
    invoiceNumber (): string {
      return this.invoice.getInvoiceNumber(this.relation.invoice_prefix, this.invoice.isStatusConcept())
    },
    isStateConcept (): boolean {
      return this.invoice.status === this.invoiceState.concept.value
    },
    isUnsavedConcept (): boolean {
      return this.invoice.isStatusConcept() && this.invoice.id === null
    },
    isSavedConcept (): boolean {
      return this.invoice.isStatusConcept() && this.invoice.id !== null
    },
    isStateSent (): boolean {
      return this.invoice.status === this.invoiceState.sent.value
    },
    isStateFullyPaid (): boolean {
      return this.invoice.status === this.invoiceState.fully_paid.value
    },
    isStatePartiallyPaid (): boolean {
      return this.invoice.status === this.invoiceState.partially_paid.value
    },
    isStateRecurrenceTemplate (): boolean {
      return this.invoice.status === this.invoiceState.recurrence_template.value
    },
    isRecurring (): boolean {
      return this.invoice.recurrence.active
    },
    isBaseInvoice (): boolean {
      return this.invoice.recurrence.base_invoice_id !== null && this.invoice.id === this.invoice.recurrence.base_invoice_id
    },
    isRecurrenceChanged (): boolean {
      return JSON.stringify(this.invoice.recurrence) !== this.oldRecurrence
    }
  },
  watch: {
    _clients () {
      this.setClients(this._clients)
    },
    selectedRelation () {
      this.setExpirationDate()
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  mounted () {
    this.setOldInvoice()
    this.setOldRecurrence()
  },
  methods: {
    async loadEssentials () {
      this.invoice.from_relation_id = this.relation.id
      this.setClients(this._clients)
    },
    setClients (clients: Array<ClientModel>) {
      arrayHelper.clear(this.clients)
      clients.forEach((client) => {
        if (client.type === EnterpriseRelationTypeEnum.CLIENT && client.client.active && !client.client.hidden) {
          const selectClient = this.toSelectableRelation(client.client)
          this.clients.push(selectClient)
        }
      })
    },
    toSelectableRelation (relation: RelationModel) {
      const text = relation.getRelationName()

      return { text, value: relation.id }
    },
    setExpirationDate () {
      if (this.selectedRelation && this.selectedRelation.payment_period !== null) {
        const paymentPeriod = new PaymentPeriod()
        let expirationDate = new Date()
        expirationDate = new Date(this.invoice.invoice_date)
        const dayOfMonth = expirationDate.getDate()

        if (this.selectedRelation.payment_period === paymentPeriod.month.value) {
          expirationDate.setMonth(expirationDate.getMonth() + 1)
          if (dayOfMonth === 1) {
            expirationDate.setDate(expirationDate.getDate() - 1)
          }
        } else {
          expirationDate.setDate(expirationDate.getDate() + this.selectedRelation.payment_period)
        }

        this.invoice.expiration_date = expirationDate.toISOString().slice(0, 10)
      }
    },

    navigateToInvoices () {
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.overview.name })
    },
    navigateToInvoice (id: any) {
      this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id } })
    },

    async downloadInvoice () {
      await apiInvoiceService.downloadInvoice(this.invoice.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const filename = this.invoice.getInvoiceNumber(this.relation.invoice_prefix)
          this.downloadHelper.downloadPdf(response.data, filename)
        }
      })
    },
    async downloadInvoiceXml () {
      await apiInvoiceService.downloadInvoiceXml(this.invoice.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const filename = this.invoice.getInvoiceNumber(this.relation.invoice_prefix)
          this.downloadHelper.downloadXml(response.data, filename)
        }
      })
    },
    async previewInvoice () {
      if (!this.isValidationError()) {
        await apiInvoiceService.previewInvoice(this.invoice).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.downloadHelper.downloadPdf(response.data, 'invoice-concept')
          }
        })
      } else {
        new Notify().entityError()
      }
    },

    isNumeric (str: any) {
      if (typeof str !== 'string') return false
      // @ts-ignore
      return !isNaN(str) && !isNaN(parseFloat(str))
    },

    async creditInvoice () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze factuur wil crediteren?' })
      if (isConfirmed) {
        await apiInvoiceService.creditInvoice(this.invoice.id).then((response: any) => {
          if (response.status === HttpStatus.CREATED) {
            const invoice = new Invoice().fromResponse(response.data.invoice)
            this.invoices.push(invoice)
            new Notify().success('Gelukt!', 'Gecrediteerd factuur is aangemaakt')
            this.navigateToInvoice(response.data.invoice.id)
          }
        })
      }
    },
    submitForm () {
      if (!this.isValidationError()) {
        if (this.isInvoiceSaved) { this.updateInvoice() } else { this.createInvoice() }
      } else {
        new Notify().entityError()
      }
    },
    isValidationError (): boolean {
      this.invoiceErrors = entityHelper.generateErrorEntity(new Invoice()) as any
      let error = false
      if (!this.invoice.to_relation_id) {
        this.invoiceErrors.to_relation_id.push('Klant is verplicht')
        error = true
      }

      if (!this.isRecurring && !this.isStateRecurrenceTemplate && !this.invoice.invoice_date) {
        this.invoiceErrors.invoice_date.push('Aanmaakdatum is verplicht')
        error = true
      }

      if (!this.isRecurring && !this.isStateRecurrenceTemplate && !this.invoice.expiration_date) {
        this.invoiceErrors.expiration_date.push('Vervaldatum is verplicht')
        error = true
      }

      if (!this.isStateRecurrenceTemplate && this.invoice.expiration_date && this.invoice.expiration_date < this.invoice.invoice_date) {
        this.invoiceErrors.expiration_date.push('Vervaldatum moet later zijn dan de aanmaakdatum')
        error = true
      }

      for (let i = 0; i < this.invoice.products.length; i++) {
        const product = this.invoice.products[i] as InvoiceProduct

        if (i === this.invoice.products.length - 1 && !product.title) {
          this.invoice.products.splice(i, 1)
          break
        }

        if (!product.title) {
          error = true
        }

        if (!product.amount || !product.tax_id) {
          error = true
        }

        if (product.value === null) {
          error = true
        }

        if (product.tax_id === Tax.BTW_VERLEGD && this.selectedRelation) {
          if (this.selectedRelation.isPrivate() || (this.selectedRelation.enterprise !== null && !this.selectedRelation.enterprise.tax_number)) {
            this.invoiceErrors.to_relation_id.push('BTW nummer verplicht bij BTW verlegd')
            error = true
          }
        }
      }

      if (this.invoice.products.length === 0) {
        this.invoiceErrors.products.push('Product is verplicht')
        error = true
      }

      if (this.isFirstInvoice && (this.isUnsavedConcept || this.isStateRecurrenceTemplate)) {
        if (!this.invoice.prefix_id) {
          this.invoiceErrors.prefix_id.push('Factuur startnummer is verplicht')
          error = true
        }
        if (this.invoice.prefix_id !== null && !this.isNumeric(this.invoice.prefix_id)) {
          this.invoiceErrors.prefix_id.push('Factuur startnummer moet een nummer zijn')
          error = true
        }
        if (this.isNumeric(this.invoice.prefix_id) && this.invoice.prefix_id < 1) {
          this.invoiceErrors.prefix_id.push('Factuur startnummer mag niet lager dan 1 zijn')
          error = true
        }
      }

      return error
    },
    async updateInvoice () {
      if (this.isRecurrenceChanged) {
        await this.saveRepeat(this.invoice.id)
      }
      await apiInvoiceService.updateInvoice(this.invoice.id, this.invoice.toRequest()).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.invoice.fromResponse(response.data.invoice)
          this.setUpdatedInvoiceInList()
          this.setOldInvoice()
          this.setOldRecurrence()
          new Notify().updated()
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handleUnprocessableEntity(response.data)
        }
      })
    },
    setProductIds (productsRaw: Array<any>) {
      for (let i = 0; i < this.invoice.products.length; i++) {
        this.invoice.products[i].id = productsRaw[i].product_id
      }
    },

    setUpdatedInvoiceInList () {
      const index = this.invoices.findIndex(invoice => invoice.id === this.invoice.id)
      this.invoice.to_relation = this.selectedRelation
      this.invoices[index] = this.invoice
    },

    async createInvoice () {
      this.invoice.prefix = this.relation.invoice_prefix
      await apiInvoiceService.createInvoice(this.invoice.toRequest()).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const invoice = new Invoice().fromResponse(response.data.invoice)
          const invoiceId = invoice.id as number

          if (this.isRecurrenceChanged) {
            invoice.recurrence = this.invoice.recurrence
            await this.saveRepeat(invoiceId)
            invoice.recurrence.id = this.invoice.recurrence.id
          }

          if (this.files !== null) {
            await this.uploadFiles(invoiceId)
          }
          invoice.to_relation = this.selectedRelation
          this.invoices.push(invoice)
          new Notify().success('Gelukt!', 'Factuur is aangemaakt')
          this.$router.push({ name: routeHelper.invoicingRoutes.invoicing.invoice.edit.name, params: { id: invoiceId.toString(), isCostProjectLoaded: 'true' } })
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.handleUnprocessableEntity(response.data)
        }
      })
    },
    handleUnprocessableEntity (errors: any) {
      this.invoiceErrors = {
        ...this.invoiceErrors,
        ...errors
      }
    },

    async saveRepeat (invoiceId: number|null) {
      this.invoice.recurrence.base_invoice_id = invoiceId
      if (this.invoice.recurrence.id) {
        await apiInvoiceService.updateRecurrence(this.invoice.recurrence)
      } else {
        if (this.isRecurring) {
          await apiInvoiceService.createRecurrence(this.invoice.recurrence).then((response: any) => {
            if (response.status === HttpStatus.OK) {
              this.invoice.recurrence.id = response.data.id
              response.data.invoices.forEach((invoiceRaw: any) => {
                const invoice = new Invoice().fromResponse(invoiceRaw)
                this.invoices.push(invoice)
              })
            }
          })
        }
      }
      this.setUpdatedInvoiceInList()
    },

    isFormEditable () {
      return this.invoice.isStatusConcept() || this.isStateRecurrenceTemplate
    },

    navigateToCompanySettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name })
    },

    async onSelectedAttachment (e: any) {
      const files = e.target.files

      if (files) {
        let isCorrectSize = true

        for (let i = 0; i < files.length; i++) {
          isCorrectSize = this.isCorrectAttachmentSize(files[i])
          if (!isCorrectSize) {
            break
          }
        }

        if (isCorrectSize) {
          this.files = files

          if (this.invoice.id) {
            await this.uploadFiles(this.invoice.id)
            this.files = null
            this.setOldInvoice()
            new Notify().success('Gelukt!', 'Bijlages zijn toegevoegd')
          }
        }
      }
    },
    isCorrectAttachmentSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadFiles (invoiceId: number) {
      const formData = new FormData()

      for (let i = 0; i < this.files.length; i++) {
        await formData.append('file' + i + '', this.files[i])
      }

      await apiInvoiceService.uploadAttachments(invoiceId, formData).then((response: any) => {
        if (response.status === HttpStatus.CREATED) {
          response.data.result.forEach((attachmentRaw: any) => {
            const attachment = new InvoiceAttachment(attachmentRaw)
            this.invoice.attachments.push(attachment)
          })
        }
      })
    },

    stateToFullyPaid () {
      this.invoice.status = this.invoiceState.fully_paid.value
      this.setUpdatedInvoiceInList()
      this.setOldInvoice()
    },
    stateToPartiallyPaid (amount: number) {
      this.invoice.amount_paid = amount
      this.invoice.status = this.invoiceState.partially_paid.value
      this.setUpdatedInvoiceInList()
      this.setOldInvoice()
    },

    selectDocument () {
      const element = this.$refs.uploadAttachment as any
      element.click()
    },

    async exitInvoice () {
      if ((this.invoice.isStatusConcept() || this.isStateRecurrenceTemplate) && !this.isSubmitDisabled) {
        const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je zeker dat je wil afsluiten zonder op te slaan?', isAgreeButtonError: true })
        if (isConfirmed) {
          this.navigateToInvoices()
        }
      } else {
        this.navigateToInvoices()
      }
    },

    setOldInvoice () {
      this.oldInvoice = JSON.stringify(this.invoice)
    },
    setOldRecurrence () {
      this.oldRecurrence = JSON.stringify(this.invoice.recurrence)
    }
  }
})
