
import Vue from 'vue'
import DashboardAccountant from './accountant/DashboardAccountant.vue'
import DashboardEnterprise from './enterprise/DashboardEnterprise.vue'
export default Vue.extend({
  components: { DashboardAccountant, DashboardEnterprise },
  data () {
    return {
      isReloading: false
    }
  },
  computed: {
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant'] as boolean
    }
  },
  watch: {
    $route () {
      this.clearUrl()
      this.isReloading = true
      setTimeout(() => {
        this.isReloading = false
      }, 20)
    }
  },
  beforeMount () {
    this.clearUrl()
  },
  methods: {
    clearUrl () {
      const newURL = location.href.split('?')[0]
      window.history.pushState('object', document.title, newURL)
      delete this.$router.currentRoute.query.refresh
    }
  }
})
