
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Invoice } from '@/modules/invoicing/models/invoice/Invoice'
import { InvoiceStatus } from '@/modules/invoicing/enums/InvoiceStatus'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { Notify } from '@/globals/controllers/Notification'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    IconBase,
    IconChevronDown
  },
  props: {
    _selected: {
      type: Array,
      required: true
    },
    _isDisabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isEmployee: this.$store.getters['user/isEmployee'],
      invoiceStatus: new InvoiceStatus()
    }
  },
  computed: {
    invoices: {
      get (): Array<Invoice> {
        return this.$store.getters['invoicing/invoices']
      },
      set (invoices: Array<Invoice>) {
        this.$store.dispatch('invoicing/setInvoices', invoices)
        return invoices
      }
    },
    invoiceReminders (): Array<Invoice> {
      return (this._selected as Array<Invoice>).filter((invoice) => {
        return (invoice.status === this.invoiceStatus.sent.value || invoice.status === this.invoiceStatus.partially_paid.value) && invoice.isExpired()
      })
    },
    invoicesToPayedStatus (): Array<Invoice> {
      return (this._selected as Array<Invoice>).filter((invoice) => {
        return invoice.status === this.invoiceStatus.sent.value || invoice.status === this.invoiceStatus.partially_paid.value
      })
    }
  },
  methods: {
    async sendBulkInvoices () {
      const ids = Array.from((this._selected as Array<Invoice>), (invoice: Invoice) => invoice.id) as Array<number>
      await apiInvoiceService.sendInvoiceBulk(ids).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          new Notify().success('Gelukt!', 'Een e-mail is is verzonden voor ' + ids.length + ' facturen')
          this.$emit('reload_table_data')
        }
      })
    },
    async sendBulkInvoiceReminders () {
      const ids = Array.from(this.invoiceReminders, (invoice: Invoice) => invoice.id) as Array<number>
      await apiInvoiceService.sendInvoiceReminders(ids, []).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          new Notify().success('Gelukt!', 'Een herinnering is verstuurd voor ' + ids.length + ' facturen')
        }
      })
    },
    async setStatusToPayed () {
      const ids = Array.from(this.invoicesToPayedStatus, (invoice: Invoice) => invoice.id) as Array<number>
      await apiInvoiceService.setInvoiceStatusPayedBulk(ids).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          new Notify().success('Gelukt!', 'De status is veranderd voor ' + ids.length + ' facturen')
          this.$emit('reload_table_data')
        }
      })
    }
  }
})
