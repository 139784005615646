
import Vue from 'vue'
import { User } from '@/modules/auth/models/User'
import InputField from '@/modules/auth/components/form-components/InputField.vue'
import PrivacyAgreementComponent from '@/modules/auth/components/register/PrivacyAgreementComponent.vue'
import SubmitButton from '@/modules/auth/components/form-components/SubmitButton.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { localAuthService } from '@/network/local/local-auth-service'
import { apiRelationService } from '@/network/api/api-relation-service'
import { RoleEnum } from '@/globals/enums/Role'
import { EnterpriseRelationRequestModel } from '@/globals/models/relation/request/EnterpriseRelation'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import TwoFactor from '../twofactor/TwoFactor.vue'

export default Vue.extend({
  props: {
    isAdministration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    InputField,
    SubmitButton,
    PrivacyAgreementComponent,
    TwoFactor
  },
  data () {
    return {
      user: new User() as User,
      errors: entityHelper.generateErrorEntity(new User()) as any,
      showPrivacyModal: false,
      routeHelper,
      isAdviser: false
    }
  },
  computed: {
    isLive (): boolean {
      return this.$store.getters['app/isLive']
    }
  },
  watch: {
    isAdviser () {
      this.$emit('setIsAdviser', this.isAdviser)
    }
  },
  beforeMount () {
    this.isAdviser = this.isAdministration
  },
  methods: {
    checkRegisterForm (e: any) {
      e.preventDefault()
      this.errors = entityHelper.generateErrorEntity(new User())

      let error = false

      if (!this.user.name) {
        this.errors.name.push('Voornaam is verplicht.')
        error = true
      }
      if (!this.user.last_name) {
        this.errors.last_name.push('Achternaam is verplicht.')
        error = true
      }
      if (!this.user.company_name) {
        this.errors.company_name.push('Bedrijfsnaam is verplicht.')
        error = true
      }
      if (!this.user.email) {
        this.errors.email.push('E-mail is verplicht.')
        error = true
      }
      if (!this.user.password) {
        this.errors.password.push('Wachtwoord is verplicht.')
        error = true
      }
      if (!this.user.agreePrivacy) {
        this.errors.agreePrivacy.push('Ga akkoord met de algemene voorwaarden')
        error = true
      }

      if (!error) {
        this.register()
      }
    },
    async register () {
      this.user.password_confirm = this.user.password
      await apiAuthService.register(this.user).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          await this.login()
          this.$emit('created')
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },
    async login () {
      await apiAuthService.login(this.user).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const token = response.data
          localAuthService.storeToken(token)
          await this.createRelation()
          await localAuthService.storeUserConnections()
          if (this.isLive) {
            await (this.$refs.twofactor as any).open(token.token, token.qr_code_url)
          }
        }
      })
    },
    async createRelation () {
      const relation = new EnterpriseRelationRequestModel()
      relation.first_name = this.user.name
      relation.last_name = this.user.last_name
      relation.name = this.user.company_name
      relation.email = this.user.email

      if (this.isAdviser) {
        relation.relation_user_type = RoleEnum.ACCOUNTANT
        await this.createAdministrationRelation(relation)
      } else {
        await this.createEnterpriseRelation(relation)
      }
    },
    async createEnterpriseRelation (relation: any) {
      await apiRelationService.createRelation(relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const relationId = response.data.relation.id
          await this.setDefaultRelation(relationId)
          await this.setRelation()
        }
      })
    },
    async createAdministrationRelation (relation: any) {
      await apiRelationService.createAdministration(relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const relationId = response.data.relation.id
          await this.setDefaultRelation(relationId)
          await this.setRelation()
        }
      })
    },

    async setDefaultRelation (relationId: number) {
      await apiRelationService.setDefaultRelation(relationId).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          await this.switchRole(relationId)
        }
      })
    },
    async switchRole (relationId: number) {
      await apiAuthService.switchRole(relationId).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const token = response.data
          await localAuthService.storeToken(token)
        }
      })
    },

    async setRelation () {
      await apiRelationService.getDefaultRelation().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.$store.commit('relation/setRelation', response.data.relation)
          this.$store.commit('relation/setIsOwner', response.data.is_owner)
        }
      })
    },
    closeTwoFactorModal () {
      (this.$refs.twofactor as any).cancel()
    }
  }
})
