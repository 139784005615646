
import Vue from 'vue'

import { HttpStatus } from '@/globals/enums/HttpStatus'
import { InvoicePrefixModel } from '@/globals/models/invoice/InvoicePrefix'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { dateHelper } from '@/globals/helpers/DateHelper'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { platformHelper } from '@/globals/helpers/PlatformHelper'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { RelationModel } from '@/globals/models/relation/Relation'
import { localRelationService } from '@/network/local/local-relation-service'

export default Vue.extend({
  components: {
    PrimaryModal,
    InputField,
    DatePicker,
    PrimaryButton
  },
  props: {
    _prefix: {
      type: InvoicePrefixModel,
      required: false
    }
  },
  data () {
    return {
      isEssentialsLoaded: true,
      dateHelper,
      platformHelper,
      prefix: new InvoicePrefixModel()
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    isNew (): boolean {
      return this._prefix === null
    }
  },
  beforeMount () {
    if (this._prefix) {
      this.prefix.setData(this._prefix)
    } else {
      this.prefix.prefix = this.relation.invoice_prefix
    }
  },
  methods: {
    async executeForm () {
      if (this._prefix) {
        await this.updatePrefix()
      } else {
        await this.createPrefix()
      }
    },
    async updatePrefix () {
      await apiInvoiceService.updatePrefix(this.prefix).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await this.onUpdatedOrCreated()
          this.$emit('updated', this.prefix)
        }
      })
    },
    async createPrefix () {
      await apiInvoiceService.createPrefix(this.prefix).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const newPrefix = new InvoicePrefixModel().fromResponse(response.data)
          await this.onUpdatedOrCreated()
          this.$emit('created', newPrefix)
        }
      })
    },
    async onUpdatedOrCreated () {
      const isToday = dateHelper.isDateToday(this.prefix.start_date)

      if (isToday) {
        await localRelationService.getRelation()
      }
    }
  }
})

