import { UserModel } from '@/globals/models/user/User'
export class CommentModel {
  id = 0
  user = new UserModel()
  user_id = 0
  task_id = 0
  content = ''
  is_log = false
  created_at = ''
  updated_at = ''

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: CommentModel) {
    this.id = data.id
    this.user = data.user
    this.user_id = data.user_id
    this.task_id = data.task_id
    this.content = data.content
    this.is_log = data.is_log
    this.created_at = data.created_at
    this.updated_at = data.updated_at
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
