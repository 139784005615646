
import Vue from 'vue'
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    pulse: {
      type: Boolean,
      required: false,
      default: false
    },
    outline: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    stateNormal: {
      type: Boolean,
      required: false,
      default: false
    },
    statePrimary: {
      type: Boolean,
      required: false,
      default: false
    },
    stateInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    stateSuccess: {
      type: Boolean,
      required: false,
      default: false
    },
    stateWarning: {
      type: Boolean,
      required: false,
      default: false
    },
    stateDanger: {
      type: Boolean,
      required: false,
      default: false
    },
    textStateLight: {
      type: Boolean,
      required: false,
      default: false
    },
    textStateDark: {
      type: Boolean,
      required: false,
      default: false
    },
    textStateBlue: {
      type: Boolean,
      required: false,
      default: false
    },
    textStateDanger: {
      type: Boolean,
      required: false,
      default: false
    },
    textStateBrandRed: {
      type: Boolean,
      required: false,
      default: false
    },
    bgColorGrey: {
      type: Boolean,
      required: false,
      default: false
    },
    bgColorBrandRed: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeNone: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeFull: {
      type: Boolean,
      required: false,
      default: false
    },
    heightFitContent: {
      type: Boolean,
      required: false,
      default: false
    },
    heightXs: {
      type: Boolean,
      required: false,
      default: false
    },
    heightSmall: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading,
      isLoading: false
    }
  },
  computed: {
    isStateLoading (): boolean {
      return (this.loadingState.isLoading as boolean) && this.isLoading
    }
  },
  watch: {
    loadingState: {
      handler: function () {
        if (!this.loadingState.isLoading) {
          this.isLoading = false
        }
      },
      deep: true
    }
  },
  methods: {
    handleClick () {
      if (this.showLoading) {
        this.isLoading = true
      }

      this.$emit('click')
    }
  }
})
