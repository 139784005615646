import { RoleEnum } from '@/globals/enums/Role'

export class AdministrationUserRelation {
  id = 0
  type = RoleEnum.ENTERPRISE

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.type = data.type
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
