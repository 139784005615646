import { RouteConfig } from 'vue-router'
import Task from '../views/Task.vue'
import Overview from '../views/overview/Overview.vue'
import { RouteNames } from './RouteNames'

const routes = RouteNames
const taskRoutes: Array<RouteConfig> = [
  {
    path: '/task',
    name: routes.task.name,
    component: Task,
    redirect: { name: routes.task.overview.name },
    meta: {
      name_nl: 'overzicht'
    },
    children: [
      {
        path: 'overview',
        name: routes.task.overview.name,
        component: Overview,
        meta: {
          name_nl: 'overzicht'
        }
      }
    ]
  }
]

export default taskRoutes
