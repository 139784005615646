
import Vue from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { BookingItemNote } from '@/modules/administration/models/BookingItemNote'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { UserModel } from '@/globals/models/user/User'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton,
    Avatar
  },
  props: {
    _notes: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      bookStore: this.$store.state.book,
      notes: this._notes as Array<any>,
      message: '',
      dateHelper,
      documentState: new DocumentState()
    }
  },
  computed: {
    currentBookingItem (): BookingItem {
      return this.bookStore.currentBookingItem
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    }
  },
  methods: {
    async createNote () {
      if (this.message) {
        await apiAdministrationService.createBookingItemLog(this.currentBookingItem.id, this.message).then(async (response: any) => {
          if (response.status === HttpStatus.CREATED) {
            if (this.currentBookingItem.status !== this.documentState.BOOKED.value) {
              this.currentBookingItem.status = this.documentState.QUESTIONS.value
              await this.updateBookingItem()
            }
            this.message = ''
            const log = new BookingItemNote().fromResponse(response.data.result)
            this.$emit('note_created', log)
          }
        })
      }
    },

    async updateBookingItem () {
      await apiAdministrationService.updateBookingItem(this.currentBookingItem, false).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          const bookingItem = new BookingItem().fromResponse(response.data)
          this.$store.dispatch('book/updateCurrentBookingItem', bookingItem)
        }
      })
    }
  }
})
