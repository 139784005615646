
import Vue from 'vue'
import FileUploader from './FileUploader.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'

export default Vue.extend({
  components: {
    PrimaryModal,
    FileUploader
  },
  data () {
    return {
      uploadUrl: apiAdministrationService.getBookingItemUploadUrl()
    }
  },

  methods: {
  }
})
