import Vue from 'vue'
import { Notify } from '../controllers/Notification'

export default Vue.extend({
  data () {
    return {
      file: null as null|File
    }
  },

  methods: {
    selectFile () {
      this.clearInputFile((this.$refs.fileUploadInput as any));
      (this.$refs.fileUploadInput as any).click()
    },
    onSelectedImage (e: any) {
      const file = e.target.files[0]
      if (file && this.validateFileSize(file)) {
        this.file = file
      }
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        new Notify().error('Foutmelding', 'Geselecteerde bestand mag niet groter zijn dan 5 MB')
        return false
      }

      return true
    },

    clearInputFile (f: any) {
      if (f.value) {
        try {
          f.value = '' // for IE11, latest Chrome/Firefox/Opera...
        } catch (err) { }
        if (f.value) { // for IE5 ~ IE10
          const form = document.createElement('form')
          const parentNode = f.parentNode; const ref = f.nextSibling
          form.appendChild(f)
          form.reset()
          parentNode.insertBefore(f, ref)
        }
      }
    }
  }
})
