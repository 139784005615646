import { LedgerAccountModel } from './LedgerAccount'

export class TaxDefaultModel {
  id = 0
  date = ''
  tax_id = 0
  percentage = 0
  ledger_account_id = 0
  is_sale = 1
  default_ledger_account_id = 0
  default_ledger_account = null as null|LedgerAccountModel
  vat_ledger_account = null as null|LedgerAccountModel

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: TaxDefaultModel) {
    if (data) {
      this.id = data.id
      this.date = data.date
      this.tax_id = data.tax_id
      this.percentage = data.percentage
      this.ledger_account_id = data.ledger_account_id
      this.is_sale = data.is_sale
      this.default_ledger_account_id = data.default_ledger_account_id
      this.default_ledger_account = data.default_ledger_account
      this.vat_ledger_account = data.vat_ledger_account
    }
  }

  fromResponse (data: TaxDefaultModel) {
    this.setData(data)

    if (data.default_ledger_account) {
      this.default_ledger_account = new LedgerAccountModel().fromResponse(data.default_ledger_account)
    }
    if (data.vat_ledger_account) {
      this.vat_ledger_account = new LedgerAccountModel().fromResponse(data.vat_ledger_account)
    }

    return this
  }
}
