
import Vue from 'vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  data () {
    return {
      settingsAccountProfileRoute: routeHelper.settingsRoutes.settings.account.profile.name,
      settingsAccountPasswordRoute: routeHelper.settingsRoutes.settings.account.password.name
    }
  }
})
