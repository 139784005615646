
import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import SubmitButton from '@/modules/auth/components/form-components/SubmitButton.vue'
import { apiAuthService } from '@/network/api/api-auth-service'
import { Notify } from '@/globals/controllers/Notification'
import InputField from '@/modules/auth/components/form-components/InputField.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'

export default Vue.extend({
  components: { InputField, SubmitButton },
  data () {
    return {
      mounted: true,
      code: this.$route.query.code,
      password: '',
      errors: { password: [] as Array<string> }
    }
  },
  methods: {
    async submitForm (e: any) {
      e.preventDefault()
      this.errors.password = []
      let error = false

      if (!this.password) {
        this.errors.password.push('Wachtwoord is verplicht.')
        error = true
      }

      if (!error) {
        await apiAuthService.resetPassword(this.password, this.code.toString()).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            new Notify().success('Je wachtwoord is veranderd', 'Log opnieuw in met je nieuwe wachtwoord')
            this.$router.push({ name: routeHelper.authRoutes.login.name })
          }
          if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            this.errors = response.data
          }
        })
      }
    }
  }
})
