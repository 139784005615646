
import Vue from 'vue'
import Stepper from '@/modules/auth/components/onboarding/stepper/Stepper.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    Stepper,
    PrimaryButton
  },
  data () {
    return {
      skipped: false
    }
  },
  beforeMount () {
    window.scrollTo(0, 0)
    if (this.$route.query.skipped) {
      this.skipped = (this.$route.query.skipped === 'true')
    }
  },
  methods: {
    navigateToDashboard () {
      this.$router.replace({ name: 'dashboard' })
    }
  }
})
