import { RouteConfig } from 'vue-router'
import { RouteNames } from './RouteNames'
import Login from '../views/auth/login/Login.vue'
import Register from '../views/auth/register/Register.vue'
import AdviserRegister from '../views/auth/adviser/Register.vue'
import ForgotPassword from '../views/auth/forgot-password/ForgotPassword.vue'
import ResetPassword from '../views/auth/forgot-password/ResetPassword.vue'
import Activate from '../views/auth/activate/Activate.vue'
import Onboarding from '../views/onboarding/Onboarding.vue'
import OnboardingWelcome from '../views/onboarding/welcome/Welcome.vue'
import OnboardingAdviserWelcome from '../views/onboarding/adviser/Welcome.vue'
import OnboardingAdviserRelations from '../views/onboarding/adviser/Relations.vue'
import OnboardingAdviserFinished from '../views/onboarding/adviser/Finished.vue'
import AcceptInvitation from '../views/AcceptInvitation.vue'

const routes = RouteNames

const authRoutes: Array<RouteConfig> = [

  {
    path: '/login',
    name: routes.login.name,
    component: Login
  },
  {
    path: '/register',
    name: routes.register.name,
    component: Register
  },
  {
    path: '/adviser/register',
    name: routes.adviser.register.name,
    component: AdviserRegister
  },
  {
    path: '/forgotpassword',
    name: routes.forgotPassword.name,
    component: ForgotPassword
  },
  {
    path: '/resetpassword',
    name: routes.resetPassword.name,
    component: ResetPassword
  },
  {
    path: '/activate',
    name: routes.activate.name,
    component: Activate
  },
  {
    path: '/onboarding/',
    name: routes.onboarding.name,
    component: Onboarding,
    redirect: { name: routes.onboarding.adviser.welcome.name },
    children: [
      {
        path: 'welcome',
        name: routes.onboarding.welcome.name,
        component: OnboardingWelcome
      },
      {
        path: 'adviser/welcome',
        name: routes.onboarding.adviser.welcome.name,
        component: OnboardingAdviserWelcome
      },
      {
        path: 'adviser/relations',
        name: routes.onboarding.adviser.relations.name,
        component: OnboardingAdviserRelations
      },
      {
        path: 'adviser/finished',
        name: routes.onboarding.adviser.finished.name,
        component: OnboardingAdviserFinished
      }
    ]
  },
  {
    path: '/acceptinvitation',
    name: routes.acceptInvitation.name,
    component: AcceptInvitation
  }
]

export default authRoutes
