import { RelationModel as Relation } from '../relation/Relation'
import { EnterpriseRelationTypeEnum as EnterpriseRelationType } from '../../enums/EnterpriseRelationType'

export class ClientModel {
  id: number|null = null
  type = EnterpriseRelationType.CLIENT
  client = new Relation()

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.type = data.type
      this.client = data.client
    }
  }

  fromResponse (data?: any) {
    this.setData(data)
    this.client = new Relation().fromResponse(data.client)
    return this
  }

  isClient (): boolean {
    return this.type === EnterpriseRelationType.CLIENT
  }

  isSupplier (): boolean {
    return this.type === EnterpriseRelationType.SUPPLIER
  }
}
