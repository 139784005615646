
import Vue from 'vue'

export default Vue.extend({
  props: {
    step: {
      type: Number,
      required: false,
      default: 1
    }
  }
})
