import { RouteConfig } from 'vue-router'
import Administration from '../views/Administration.vue'
import AdministrationDocument from '../views/document/Document.vue'
import DocumentOverview from '../views/document/overview/Overview.vue'
import AdministrationAccountant from '../views/accountant/Accountant.vue'
import AccountantRelationOverview from '../views/accountant/relation/overview/Overview.vue'
import AdministrationPraxis from '../views/praxis/Praxis.vue'
import AdministrationPraxisOverview from '../views/praxis/overview/Overview.vue'
import AdministrationPraxisBtw from '../views/praxis/btw/Btw.vue'
import store from '@/store/index'
import { RouteNames } from './RouteNames'

const globalStore = store as any
const routes = RouteNames

const administrationRoutes: Array<RouteConfig> = [
  {
    path: '/administration',
    name: routes.administration.name,
    component: Administration,
    redirect: { name: routes.administration.document.name },
    children: [
      {
        path: 'document',
        name: routes.administration.document.name,
        component: AdministrationDocument,
        redirect: { name: routes.administration.document.overview.name },
        beforeEnter: (to: any, from: any, next: any) => {
          const isEnterprise = globalStore.getters['user/isEnterprise'] as boolean
          if (isEnterprise) {
            next()
          } else {
            next({ name: routes.administration.accountant.relation.overview.name })
          }
        },
        children: [
          {
            path: 'overview',
            name: routes.administration.document.overview.name,
            component: DocumentOverview
          }
        ]
      },
      {
        path: 'accountant',
        name: routes.administration.accountant.name,
        component: AdministrationAccountant,
        redirect: { name: routes.administration.accountant.relation.overview.name },
        beforeEnter: (to: any, from: any, next: any) => {
          const isAccountant = globalStore.getters['user/isAccountant'] as boolean

          if (isAccountant) {
            next()
          } else {
            next({ name: routes.administration.document.name })
          }
        },
        children: [
          {
            path: 'relation/overview',
            name: routes.administration.accountant.relation.overview.name,
            component: AccountantRelationOverview
          }
        ]
      },
      {
        path: 'praxis',
        component: AdministrationPraxis,
        beforeEnter: (to: any, from: any, next: any) => {
          const isAccountant = globalStore.getters['user/isAccountant'] as boolean
          const isPraxis = globalStore.getters['relation/isPraxis'] as boolean

          if (isAccountant && isPraxis) {
            next()
          } else {
            next({ name: routes.administration.document.name })
          }
        },
        children: [
          {
            path: '',
            name: routes.administration.praxis.name,
            component: AdministrationPraxisOverview
          },
          {
            path: 'btw',
            name: routes.administration.praxis.btw.name,
            component: AdministrationPraxisBtw
          }
        ]
      }
    ]
  }
]

export default administrationRoutes
