export class BaseEnum {
  protected getItemByValue (items: Array<any>, value: number) {
    let result = items[0]

    items.forEach((item: any) => {
      if (item.value === value) {
        result = item
      }
    })

    return result
  }
}

export interface Enum {
  text: string;
  value: number;
}
