<template>
      <div class="blank-slate__grey-area" :style="_style">
        <div class="grey-area__content">
          <span v-if="title">{{title}}</span>
          <span v-if="subtitle" class="subtitle">{{subtitle}}</span>
          <slot></slot>
        </div>
      </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    _style: {
      type: Object,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/_globals';

  .blank-slate__grey-area {
    @include flex(center);
    background-color: rgba(229, 230, 243, 0.5);
    border-radius: 6px;
    padding: 80px 0px;

    .grey-area__content {
      @include flex(center, center);
      flex-direction: column;
      width: 347px;

      >span {
        text-align: center;
        display: block;
        line-height: 24px;

        &:nth-child(1) {
          font-size: 20px;
          font-weight: 500;
        }
        &:nth-child(2) {
          padding-top: 12px;
        }
      }

      >button {
        margin-top: 12px;
      }
    }
  }
</style>
