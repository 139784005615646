
import Vue from 'vue'
import { DocumentState } from '@/modules/administration/enums/DocumentState'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      documentStatus: new DocumentState()
    }
  },
  computed: {
    counters (): object {
      const counted = {
        all: this.data.length,
        booked: 0,
        to_book: 0,
        questions: 0,
        to_progress: 0
      } as any

      (this.data as Array<BookingItem>).forEach((bookingItem: BookingItem) => {
        switch (bookingItem.status) {
          case this.documentStatus.BOOKED.value:
            counted.booked++
            break
          case this.documentStatus.TOBEBOOKED.value:
            counted.to_book++
            break
          case this.documentStatus.QUESTIONS.value:
            counted.questions++
            break
          case this.documentStatus.TOPROGRESS.value:
            counted.to_progress++
            break
          default:
            break
        }
      })
      return counted
    }
  }
})
