
import Vue from 'vue'

import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import TextArea from '@/globals/components/forms/TextArea.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { Notify } from '@/globals/controllers/Notification'
import { InvoiceTemplateModel } from '@/globals/models/invoice/InvoiceTemplate'
import { apiInvoiceService } from '@/network/api/api-invoice-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    PrimaryModal,
    TextArea,
    PrimaryButton
  },
  props: {
    _template: {
      type: InvoiceTemplateModel,
      required: false
    }
  },
  data () {
    return {
      isEssentialsLoaded: true,
      invoiceTemplate: new InvoiceTemplateModel(),
      invoiceTemplateErrors: entityHelper.generateErrorEntity(new InvoiceTemplateModel()) as any,
      isDisabled: true
    }
  },
  async beforeMount () {
    if (this._template) {
      this.invoiceTemplate = new InvoiceTemplateModel(this._template)
    } else {
      await this.loadEssentials()
    }
    if (!this.invoiceTemplate.id) {
      this.invoiceTemplate.template = this.getDefaultTemplate()
    }
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.setInvoiceTemplate()
      this.isEssentialsLoaded = true
    },

    async setInvoiceTemplate () {
      await apiInvoiceService.getInvoiceTemplate().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          if (response.data) {
            this.invoiceTemplate = new InvoiceTemplateModel().fromResponse(response.data)
          }
        }
      })
    },

    copyText (e: any) {
      const text = e.srcElement.parentElement.innerText
      navigator.clipboard.writeText(text)
      new Notify().success('Text gekopieerd', text + ' is gekopieerd naar je klembord')
    },

    async submitForm (e: any) {
      e.preventDefault()
      const isErrors = this.validationErrors()

      if (!isErrors) {
        if (this.invoiceTemplate.id) {
          await this.updateTemplate()
        } else {
          await this.createTemplate()
        }
      }
    },
    validationErrors () {
      this.invoiceTemplateErrors = entityHelper.generateErrorEntity(new InvoiceTemplateModel())
      let error = false

      if (!this.invoiceTemplate.template) {
        this.invoiceTemplateErrors.template.push('Bericht is verplicht')
        error = true
      }

      return error
    },

    async updateTemplate () {
      await apiInvoiceService.updateInvoiceTemplate(this.invoiceTemplate).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.returnTemplate()
        }
      })
    },
    async createTemplate () {
      await apiInvoiceService.createInvoiceTemplate(this.invoiceTemplate).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.invoiceTemplate.fromResponse(response.data)
          this.returnTemplate()
        }
      })
    },

    getDefaultTemplate (): string {
      const defaultMessage = 'Beste {to_company_name},\n\n' +
      'Namens {from_company_name} ontvang je hierbij factuur {invoice_number} met een totaalbedrag van € {invoice_amount}. ' +
      'Het bovenstaande totaalbedrag kun je onder vermelding van het factuurnummer op ons rekeningnummer {bank_account} overmaken.\n\n' +
      'Wanneer je vragen hebt over deze factuur verzoeken wij je contact op te nemen met {from_contact_name} van {from_company_name}. Dit kun je doen door te reageren op deze mail of een mail te sturen naar {from_email}.\n\n' +
      'Met vriendelijke groet,\n\n' +
      '{from_contact_name}\n' +
      '{from_company_name}'

      return defaultMessage
    },

    returnTemplate () {
      this.$emit('updated', this.invoiceTemplate)
    }
  }
})

