
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconChevronDown from '@/globals/components/icons/IconChevronDown.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    IconBase,
    IconChevronDown
  },
  props: {
    isCutEnabled: {
      type: Boolean,
      required: true
    }
  }
})
