import JsZip from 'jszip'
import store from '@/store/index'

export interface DownloadFile {
  fileName: string,
  url: string
}

export const downloadHelper = {
  openUrl (url: string) {
    window.open(url, '_blank')
  },

  downloadUrl (mimeType: string, url: string, fileName: string) {
    const link = document.createElement('a') as any
    link.download = fileName
    link.href = 'data:' + mimeType + ',' + url
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  downloadPdf (base64: string, name: string) {
    const fileURL = this.base64ToFile(base64, 'application/pdf;base64')
    this.createLink(name + '.pdf', fileURL)
  },

  downloadXml (base64: string, name: string) {
    const fileURL = this.base64ToFile(base64, 'text/xml;base64')
    this.createLink(name, fileURL)
  },

  base64ToFile (base64: string, type: string) {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type })
    return URL.createObjectURL(file)
  },
  createLink (name: string, url: string) {
    const link = document.createElement('a')
    link.download = name
    link.href = url
    link.click()
  },

  async downloadMultipleUrls (toDownloads: DownloadFile[]) {
    const zip = JsZip()
    store.commit('loading/setIsLoading', true)

    for (let i = 0; i < toDownloads.length; i++) {
      const element = toDownloads[i]
      try {
        const url = element.url.replace('http://', 'https://')
        await fetch(url).then(async resp => {
          const blob = await resp.blob()
          zip.file(element.fileName, blob)
        })
      } catch {}
    }
    store.commit('loading/setIsLoading', false)

    zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const currentDate = new Date().getTime()
      const fileName = `daya-${currentDate}.zip`
      const file = URL.createObjectURL(zipFile)
      return this.createLink(fileName, file)
    })
  }
}
