
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    windowColor: {
      type: String,
      default: '#5B5C6D'
    }
  }
})
