
import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { LedgerAccount } from '@/globals/enums/LedgerAccount'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    bookingItemId: {
      type: Number,
      required: true
    },
    isPurchaseTag: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    PrimaryModal,
    PrimaryButton,
    Dropdown
  },
  data () {
    return {
      submitDisabled: true,
      isEssentialsLoaded: false,
      ledgerClients: [] as Array<{ value: number; text: string }>,
      ledgerClientErrors: { selectedLedgerId: [] as Array<string> },
      selectedLedgerId: null as any,
      isNewLedgerClient: false
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false

      await this.getUncoupledClientRelations()

      if (!this.ledgerClients.length) {
        this.isNewLedgerClient = true
      }

      this.isEssentialsLoaded = true
    },
    async getUncoupledClientRelations () {
      await apiAdministrationService.getUncoupledClientRelations().then((response: any) => {
        this.setLedgerClients(response.data)
      })
    },
    setLedgerClients (ledgerAccounts: any) {
      ledgerAccounts.forEach((ledgerAccount: any) => {
        if (this.isPurchaseTag && ledgerAccount.ledger_account.type === LedgerAccount.Suppliers) {
          this.addLedgerToRelations(ledgerAccount)
        } else if (!this.isPurchaseTag && ledgerAccount.ledger_account.type === LedgerAccount.Customers) {
          this.addLedgerToRelations(ledgerAccount)
        }
      })
    },

    addLedgerToRelations (ledgerAccount: any) {
      this.ledgerClients.push({ value: ledgerAccount.id, text: ledgerAccount.ledger_account.code + ' - ' + ledgerAccount.name })
    },

    async submitForm (e: any) {
      e.preventDefault()

      if (!this.validationErrors()) {
        this.linkTwinfieldClient()
      }
    },
    validationErrors () {
      let error = false
      this.ledgerClientErrors.selectedLedgerId = []
      if (!this.isNewLedgerClient && !this.selectedLedgerId) {
        this.ledgerClientErrors.selectedLedgerId.push('Twinfield koppeling is verplicht')
        error = true
      }

      return error
    },

    async linkTwinfieldClient () {
      if (this.isNewLedgerClient) {
        await this.createTwinfieldClient()
      } else {
        await this.linkToTwinfieldClient()
      }
      this.$emit('linked', this.relationId)
    },
    async createTwinfieldClient () {
      await apiAdministrationService.createTwinfieldClientRelation(this.relationId, this.bookingItemId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.createSuccesMessage()
        }
      })
    },
    async linkToTwinfieldClient () {
      await apiAdministrationService.updateLinkToTwinfieldClient(this.relationId, this.selectedLedgerId).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          this.createSuccesMessage()
        }
      })
    },

    createSuccesMessage () {
      new Notify().success('Geluk!', 'Je relatie is nu gekoppeld')
    }

  }
})
