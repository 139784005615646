
import Vue from 'vue'
import MainNav from '@/globals/components/navbar/main-nav/MainNav.vue'
import { localAuthService } from '@/network/local/local-auth-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'
import { UserModel } from '@/globals/models/user/User'
import SelectRelation from './select-relation/SelectRelation.vue'
import ProfileMenu from './profile-menu/ProfileMenu.vue'
import SelectAdministration from './select-administration/SelectAdministration.vue'

export default Vue.extend({
  components: {
    MainNav,
    SelectRelation,
    ProfileMenu,
    SelectAdministration
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      isProfileSubmenuShowing: false,
      initial: '',
      firstname: '',
      lastname: '',
      showMobileNav: false,
      isLoading: false
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    impersonation (): any {
      return this.$store.getters['user/impersonation']
    },
    isImpersonated (): boolean {
      return this.$store.getters['user/isImpersonated']
    },
    isAccountant (): boolean {
      return this.$store.getters['user/isAccountant']
    }
  },
  beforeMount () {
    localAuthService.getUser()
  },
  methods: {
    showProfileSubmenu () {
      this.isProfileSubmenuShowing = true
    },
    hideProfileSubmenu () {
      this.isProfileSubmenuShowing = false
    },
    closeProfileSubmenu () {
      const el = document.querySelector(':focus') as HTMLElement

      if (el) {
        el.blur()
      }
    },
    getName () {
      return this.firstname + ' ' + this.lastname
    },
    navigateToCompanySettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name }).catch()
    },
    navigateToProfile () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.account.profile.name }).catch()
    },
    navigateToDashboard () {
      // eslint-disable-next-line
      this.$router.push({ name: routeHelper.dashboardRoutes.dashboard.name }).catch(err => { })
    },

    async leaveImpersonated () {
      this.isLoading = true
      await localAuthService.unImpersonate()
      this.isLoading = false
      this.hideProfileSubmenu()
      this.navigateToDashBoard()
    },

    navigateToDashBoard () {
      this.$router.push({ name: 'dashboard', query: { refresh: 'true' } })
    }
  }
})
