import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import authRoutes from '@/modules/auth/router/router'
import dashboardRoutes from '@/modules/dashboard/router/router'
import settingsRoutes from '@/modules/settings/router/router'
import relationRoutes from '@/modules/relation/router/router'
import invoicingRoutes from '@/modules/invoicing/router/router'
import administrationRoutes from '@/modules/administration/router/router'
import projectRoutes from '@/modules/project/router/router'
import taskRoutes from '@/modules/task/router/router'
import questionRoutes from '@/modules/question/router/router'
import { localAuthService } from '../network/local/local-auth-service'
import { routeHelper } from '../globals/helpers/RouteHelper'

Vue.use(VueRouter)

const baseRoutes: Array<RouteConfig> = [
  { path: '/', redirect: { name: 'dashboard' } }
]

const routes = baseRoutes.concat(
  authRoutes,
  dashboardRoutes,
  settingsRoutes,
  relationRoutes,
  invoicingRoutes,
  administrationRoutes,
  projectRoutes,
  taskRoutes,
  questionRoutes,
  { path: '*', redirect: routeHelper.dashboardRoutes.dashboard.name }
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isNoAuthRoute = routeHelper.noAuthRequiredRoutes().includes(to.name ?? '')

  if (isNoAuthRoute && localAuthService.isAuthenticated()) {
    next({ name: routeHelper.dashboardRoutes.dashboard.name })
  } else if (!isNoAuthRoute && !localAuthService.isAuthenticated()) {
    next({ name: routeHelper.authRoutes.login.name, params: { redirect: to.path } })
  } else {
    next()
  }
})

export default router
