
import Vue from 'vue'
import { BookingItem } from '@/modules/administration/models/BookingItem'
import { component as Viewer } from 'v-viewer'
import 'viewerjs/dist/viewer.css'

export default Vue.extend({
  components: {
    Viewer
  },
  props: {
    bookingItem: {
      type: BookingItem,
      required: true
    },
    isFitWidth: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      viewerOptions: {
        inline: true,
        navbar: false,
        title: false,
        button: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          prev: false,
          play: false,
          next: false,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true
        }
      }
    }
  },
  computed: {
    iframeSrc (): string {
      return this.bookingItem.url + '#view=fit' + (this.isFitWidth ? 'H' : '') + '&navpanes=0'
    },
    isImage (): boolean {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(this.bookingItem.url)
    }
  }
})
