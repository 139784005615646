
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { localAuthService } from '@/network/local/local-auth-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { UserModel } from '@/globals/models/user/User'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    search: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    PrimaryButton
  },
  data () {
    return {
      essentialsLoaded: false,
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      loadingStore: this.$store.state.loading,
      dialogStore: this.$store.state.dialog,
      selected: [],
      headers: [
        { text: 'Bedrijfsnaam', value: 'name' },
        { text: 'Contactpersoon', value: 'contact' },
        { text: 'E-mail', value: 'email' },
        { text: 'Telefoon', value: 'phone' },
        { text: 'Impersonate', value: 'impersonate', sortable: false, width: 195 }
      ],
      relations: [] as Array<any>
    }
  },
  computed: {
    isOwner (): boolean {
      return this.relationStore.isOwner
    },
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    administrationRelations (): Array<AdministrationRelationModel> {
      return this.$store.getters['administration/administrationRelations']
    }
  },
  watch: {
    administrationRelations () {
      this.buildRelationsTable(this.administrationRelations)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      if (!this.administrationRelations.length) {
        if (this.isOwner) {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelations', this.relation.id)
        } else {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelationsNonOwner', { relationId: this.relation.id, userId: this.user.id })
        }
      } else {
        this.buildRelationsTable(this.administrationRelations)
      }
      this.essentialsLoaded = true
    },

    buildRelationsTable (administrationRelations: Array<AdministrationRelationModel>) {
      this.relations = []
      administrationRelations.forEach(administrationRelation => {
        if (administrationRelation.enterprise.active) {
          this.insertRelation(administrationRelation.enterprise)
        }
      })
    },
    insertRelation (relation: RelationModel) {
      const tableItem = {
        relation,
        id: relation.id,
        name: relation.enterprise != null ? relation.enterprise.name : '',
        contact: relation.getContactName(),
        email: relation.email,
        phone: relation.isPrivate() ? relation.private?.mobile : relation.enterprise?.phone,
        isLoading: false,
        row_class: relation.hidden ? 'relation--hidden' : ''
      }

      this.relations.push(tableItem)
    },

    isRelations (): boolean {
      return this.relations.length > 0
    },

    async impersonate (relationId: number) {
      await localAuthService.switchToAdministration(relationId, true).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.navigateToDashboard()
        }
      })
    },
    navigateToDashboard () {
      this.$router.push({ name: 'dashboard' })
    },

    navigateToEditRelation (relationId: number) {
      const id = relationId.toString()
      this.$router.push({ name: routeHelper.relationRoutes.relation.edit.name, params: { id } })
    }
  }
})
