
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import Dropdown from '@/globals/components/forms/SingleSelectDropdown.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { GenderEnum } from '@/globals/enums/GenderEnum'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { AdministrationPersonRequestModel } from '../../../models/request/AdministrationPerson'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import { platformHelper } from '@/globals/helpers/PlatformHelper'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { RelationModel } from '@/globals/models/relation/Relation'
import { landCodes } from '@/globals/enums/LandCodesEnum'
import TextArea from '@/globals/components/forms/TextArea.vue'

export default Vue.extend({
  props: {
    relationId: {
      type: Number,
      required: true
    },
    currentRelation: {
      type: Object,
      required: false
    }
  },
  components: {
    PrimaryButton,
    InputField,
    Dropdown,
    DatePicker,
    TextArea
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      essentialsLoaded: false,
      relation: new AdministrationPersonRequestModel(),
      errors: entityHelper.generateErrorEntity(new AdministrationPersonRequestModel()) as any,
      isEdit: false,
      submitDisabled: true,
      hidden: [
        { text: 'Ja', value: false },
        { text: 'Nee', value: true }
      ],
      genderEnum: new GenderEnum(),
      platformHelper,
      landCodes
    }
  },
  computed: {
    selfRelation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    if (this.currentRelation) {
      this.relation = new AdministrationPersonRequestModel().fromRelationModel(this.currentRelation)
      this.isEdit = true
    }
  },
  methods: {
    submitForm (e: any) {
      e.preventDefault()
      this.clearErrors()
      const validationError = this.validateform()

      if (!validationError) {
        if (this.isEdit) {
          this.updateRelation()
        } else {
          this.createPrivateRelation()
        }
      }
    },
    clearErrors () {
      this.errors = entityHelper.generateErrorEntity(new AdministrationPersonRequestModel())
    },
    validateform (): boolean {
      let error = false

      if (!this.relation.first_name) {
        this.errors.first_name.push('Voornaam is verplicht.')
        error = true
      }
      if (!this.relation.last_name) {
        this.errors.last_name.push('Achternaam is verplicht.')
        error = true
      }

      return error
    },

    async createPrivateRelation () {
      await apiRelationService.createAdministrationPrivateRelation(this.relationId, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.CREATED) {
          const relation = new RelationModel().fromResponse(response.data.relation)
          await this.toggleRelationActiveState(relation.id)

          new Notify().create('Gelukt!', 'Relatie ' + this.relation.first_name + ' ' + this.relation.last_name + ' is succesvol aangemaakt')
          this.afterCreateOrUpdate(relation, false)
        }
        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },
    async updateRelation () {
      await apiRelationService.updateRelation(this.relation.id, this.relation).then(async (response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          const relation = new RelationModel().fromResponse(response.data.relation)
          await this.toggleRelationActiveState(relation.id)

          new Notify().create('Gelukt!', 'Relatie ' + this.relation.first_name + ' ' + this.relation.last_name + ' is succesvol gewijzigd')
          this.afterCreateOrUpdate(relation, true)
        }

        if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.mergeErrors(response.data)
        }
      })
    },
    afterCreateOrUpdate (relation: RelationModel, isUpdate: boolean) {
      relation.hidden = this.relation.hidden
      const administrationRelation = new AdministrationRelationModel()
      administrationRelation.enterprise = relation

      if (isUpdate) {
        this.$store.dispatch('administration/updateAdministrationRelation', administrationRelation)
      } else {
        this.$store.dispatch('administration/addAdministrationRelation', administrationRelation)
      }
      this.navigateToRelationOverview()
    },

    async toggleRelationActiveState (id: any) {
      if (this.relation.hidden) {
        await apiRelationService.hideRelation(this.relationId, id)
      } else {
        await apiRelationService.showRelation(this.relationId, id)
      }
    },

    async deactivateRelation () {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze relatie wil verwijderen?', isDeleting: true })

      if (isConfirmed && this.relation.id !== null) {
        await apiRelationService.deactivateRelation(this.selfRelation.id, this.relation.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            this.$store.dispatch('administration/removeAdministrationRelationByRelationId', this.relation.id)
            this.navigateToRelationOverview()
          }
        })
      }
    },

    mergeErrors (errors: object) {
      this.errors = {
        ...this.errors,
        ...errors
      }
    },
    navigateToRelationOverview () {
      this.$emit('nav_to_overview')
    }
  }
})
