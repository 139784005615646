
import Vue, { VueConstructor } from 'vue'
import InputField from '@/globals/components/forms/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { entityHelper } from '@/globals/helpers/EntityHelper'
import { localAuthService } from '@/network/local/local-auth-service'
import { apiAuthService } from '@/network/api/api-auth-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { Notify } from '@/globals/controllers/Notification'
import FileUpload from '@/globals/mixins/FileUpload'
import EditAvatarModal from './avatar/EditAvatarModal.vue'
import Avatar from '@/globals/components/avatar/Avatar.vue'
import { UserModel } from '@/globals/models/user/User'
import { objectHelper } from '@/globals/helpers/ObjectHelper'

export default (Vue as VueConstructor<Vue & InstanceType<typeof FileUpload>>).extend({
  components: {
    InputField,
    PrimaryButton,
    EditAvatarModal,
    Avatar
  },
  data () {
    return {
      user: new UserModel(),
      userErrors: {} as any,
      isEditAvatarModal: false,
      submitDisabled: true
    }
  },
  mixins: [FileUpload],
  computed: {
    currentUser (): UserModel {
      return this.$store.getters['user/user']
    }
  },
  watch: {
    file () {
      if (this.file !== null) {
        this.isEditAvatarModal = true
      }
    }
  },
  beforeMount () {
    this.setUser()
    this.userErrors = entityHelper.generateErrorEntity(this.user)
  },
  methods: {
    setUser () {
      this.user = objectHelper.cloneObject(this.currentUser)
    },

    submitForm (e: any) {
      e.preventDefault()
      this.userErrors = entityHelper.generateErrorEntity(this.user)

      if (!this.isValidationError()) { this.updateUser() }
    },
    isValidationError () {
      let error = false

      if (!this.user.name) {
        this.userErrors.name.push('Voornaam is verplicht.')
        error = true
      }
      if (!this.user.last_name) {
        this.userErrors.last_name.push('Achternaam is verplicht.')
        error = true
      }
      if (!this.user.email) {
        this.userErrors.email.push('E-mailadres is verplicht.')
        error = true
      }
      return error
    },
    async updateUser () {
      await apiAuthService.updateUser(this.user).then((response: any) => {
        if (response.status === HttpStatus.UPDATED) {
          localAuthService.storeUser(response.data.user)
          new Notify().updated()
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          this.userErrors = {
            ...this.userErrors,
            ...response.data
          }
        }
      })
    },

    async uploadAvatar (blob: Blob) {
      const formData = new FormData()
      formData.append('file', blob)

      await apiAuthService.changeAvatar(formData).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          this.user.avatar_url = response.data.avatar_url
          localAuthService.storeUser(this.user)
          this.setUser()
          this.isEditAvatarModal = false
        }
      })
    }
  }
})
