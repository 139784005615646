import { GenderEnum } from '@/globals/enums/GenderEnum'
export class PrivateRelationModel {
  id: number|null = null
  first_name = ''
  last_name = ''
  initial = ''
  bsn = null
  email = ''
  married_to = ''
  memo = ''
  gender: number = new GenderEnum().male.value
  mobile = ''
  date_of_birth = ''

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.initial = data.initial
      this.bsn = data.bsn
      this.email = data.email
      this.married_to = data.married_to
      this.memo = data.memo
      this.gender = data.gender
      this.mobile = data.mobile
      this.date_of_birth = data.date_of_birth
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }
}
