import { client } from '../client'
import { InvoiceTemplateModel } from '@/globals/models/invoice/InvoiceTemplate'
import { InvoicePrefixModel } from '@/globals/models/invoice/InvoicePrefix'

export const apiInvoiceService = {

  baseUrl: (window as any).appConfig.API_INVOICE,
  baseUrlInvoice: (window as any).appConfig.API_INVOICE + 'invoice/',
  baseUrlInvoiceTemplate: (window as any).appConfig.API_INVOICE + 'invoicetemplate/',

  async getAllInvoices () {
    const url = this.baseUrlInvoice

    return await client.get(url)
  },

  async getInvoice (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId

    return await client.get(url)
  },

  async getNextPrefix () {
    const url = this.baseUrlInvoice + 'next_prefix'

    return await client.get(url)
  },

  async getTaxes () {
    const url = this.baseUrl + 'tax'

    return await client.get(url)
  },

  async createInvoice (data: any) {
    const url = this.baseUrlInvoice

    return await client.post(url, data)
  },

  async updateInvoice (invoiceId: any, data: any) {
    const url = this.baseUrlInvoice + invoiceId

    return await client.put(url, data)
  },

  async deleteInvoice (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId

    return await client.delete(url)
  },

  async copyInvoice (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId + '/copy'

    return await client.post(url)
  },

  async creditInvoice (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId + '/credit'

    return await client.post(url)
  },

  async invoiceStateCreated (invoiceId: any, data: any) {
    const url = this.baseUrlInvoice + invoiceId + '/created'

    return await client.put(url, data)
  },

  async invoiceStateSent (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId + '/sent'

    return await client.put(url)
  },

  async downloadInvoice (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId + '/download'

    return await client.get(url)
  },

  async downloadInvoiceXml (invoiceId: any) {
    const url = this.baseUrlInvoice + invoiceId + '/downloadxml'

    return await client.get(url)
  },

  async previewInvoice (data: any) {
    const url = this.baseUrlInvoice + 'download'

    return await client.post(url, data)
  },

  async sendInvoice (invoiceId: any, data: any) {
    const url = this.baseUrlInvoice + invoiceId + '/send'

    return await client.post(url, data)
  },

  async sendInvoiceBulk (invoiceIds: Array<number>) {
    const url = this.baseUrlInvoice + 'send/multiple'

    return await client.put(url, { invoice_ids: invoiceIds })
  },

  async sendInvoiceReminders (invoiceIds: Array<number>, emails: Array<string>) {
    const url = this.baseUrlInvoice + 'send/reminders'

    return await client.post(url, { invoice_ids: invoiceIds, emails })
  },

  async setInvoiceStatusPayedBulk (invoiceIds: Array<number>) {
    const url = this.baseUrlInvoice + 'payment/multiple'

    return await client.put(url, { invoice_ids: invoiceIds })
  },

  async getRecurrenceInvoices () {
    const url = this.baseUrl + 'recurrence'

    return await client.get(url)
  },

  async getRecurrenceInvoicesById (invoiceId: number) {
    const url = this.baseUrlInvoice + invoiceId + '/recurrence'

    return await client.get(url)
  },

  async createRecurrence (data: any) {
    const url = this.baseUrl + 'recurrence'

    return await client.post(url, data)
  },

  async updateRecurrence (data: any) {
    const url = this.baseUrl + 'recurrence'

    return await client.put(url, data)
  },

  async getDashboardStats () {
    const url = this.baseUrl + 'stats'

    return await client.get(url)
  },

  async uploadAttachments (invoiceId: number, data: any) {
    const url = this.baseUrlInvoice + invoiceId + '/attachments'

    return await client.post(url, data)
  },

  async deleteAttachment (invoiceId: number, id: number) {
    const url = this.baseUrlInvoice + invoiceId + '/attachments/' + id

    return await client.delete(url)
  },

  // Product endpoints
  async getAllProducts () {
    const url = this.baseUrl + 'product'

    return await client.get(url)
  },

  async createProduct (data: any) {
    const url = this.baseUrl + 'product'

    return await client.post(url, data)
  },

  async updateProduct (id: number, data: any) {
    const url = this.baseUrl + 'product/' + id

    return await client.put(url, data)
  },

  async deleteProduct (id: number) {
    const url = this.baseUrl + 'product/' + id

    return await client.delete(url)
  },

  async invoiceStateToPayed (invoiceId: number, completed: boolean, payedAmount: number) {
    const url = this.baseUrlInvoice + invoiceId + '/payment'

    return await client.put(url, { completed, payment: payedAmount })
  },

  // Invoicetemplate endpoints
  async getInvoiceTemplate () {
    const url = this.baseUrlInvoiceTemplate

    return await client.get(url)
  },

  async createInvoiceTemplate (invoiceTemplateModel: InvoiceTemplateModel) {
    const url = this.baseUrlInvoiceTemplate

    return await client.post(url, invoiceTemplateModel)
  },

  async updateInvoiceTemplate (invoiceTemplateModel: InvoiceTemplateModel) {
    const url = this.baseUrlInvoiceTemplate

    return await client.put(url, invoiceTemplateModel)
  },

  async deleteInvoiceTemplate () {
    const url = this.baseUrlInvoiceTemplate

    return await client.delete(url)
  },
  async fetchPrefixes () {
    const url = this.baseUrl + 'prefix'

    return await client.get(url)
  },

  async createPrefix (prefix: InvoicePrefixModel) {
    const url = this.baseUrl + 'prefix'

    return await client.post(url, prefix)
  },

  async updatePrefix (prefix: InvoicePrefixModel) {
    const url = this.baseUrl + 'prefix/' + prefix.id

    return await client.put(url, prefix)
  },

  async deletePrefix (id: number) {
    const url = this.baseUrl + 'prefix/' + id

    return await client.delete(url)
  }
}
