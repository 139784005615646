import { dateHelper } from '@/globals/helpers/DateHelper'

export class InvoicePrefixModel {
  id = 0
  relation_id = 0
  start_date = this.getStartDateString()
  prefix = ''
  start_number = 1

  constructor (data?: any) {
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.relation_id = data.relation_id
      this.start_date = dateHelper.toDateString(data.start_date)
      this.prefix = data.prefix
      this.start_number = data.start_number
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    return this
  }

  getStartDateString (): string {
    const now = new Date().getFullYear()
    const nextYear = now + 1
    return nextYear + '-01-01'
  }
}
