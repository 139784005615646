
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'var(--primary-dark)'
    }
  }
})
