import { UserModel } from '@/globals/models/user/User'
import { TaskUserType } from '../enums/TaskUserType'
export class TaskUserModel {
  id = 0
  user = new UserModel()
  user_id = 0
  task_id = 0
  type = TaskUserType.CREATED_BY
  order = 0

  constructor (data?: any) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data: TaskUserModel) {
    this.id = data.id
    this.user = data.user
    this.user_id = data.user_id
    this.task_id = data.task_id
    this.type = data.type
    this.order = data.order
  }

  fromResponse (data: TaskUserModel) {
    this.setData(data)

    if (data.user) {
      this.user = new UserModel().fromResponse(data.user)
    }

    return this
  }
}
