export const storage = {
  get (key: string) {
    return sessionStorage.getItem(key)
  },

  store (key: string, value: string) {
    sessionStorage.setItem(key, value)
  },

  exists (key: string) {
    return sessionStorage.getItem(key) != null
  },

  remove (key: string) {
    sessionStorage.removeItem(key)
  }
}
