
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { RelationModel } from '@/globals/models/relation/Relation'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  data () {
    return {
      relationStore: this.$store.state.relation,
      companyName: ''
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    }
  },
  beforeMount () {
    this.companyName = this.relation.enterprise ? this.relation.enterprise.name : ''
  },
  methods: {
    navigateToCompanySettings () {
      this.$router.push({ name: routeHelper.settingsRoutes.settings.company.edit.name })
    },
    navigateToDashboard () {
      this.$router.push({ name: 'dashboard' })
    }
  }
})
