import { storage } from './storage'
import { localRelationService } from './local-relation-service'
import { HttpStatus } from '../../globals/enums/HttpStatus'
import { apiAuthService } from '../api/api-auth-service'
import store from '@/store'
import { RelationModel } from '../../globals/models/relation/Relation'
import { ImpersonateModel } from '@/globals/models/auth/Impersonate'
import { TokenModel } from '@/globals/models/auth/Token'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { UserModel } from '@/globals/models/user/User'

export const localAuthService = {

  tokenKey: 'token',
  userKey: 'user',

  isAuthenticated () {
    return storage.exists(this.tokenKey)
  },

  getToken () {
    const token = storage.get(this.tokenKey)
    return token != null ? JSON.parse(token).token : ''
  },

  getTokenObject (): TokenModel {
    const token = storage.get(this.tokenKey) as string
    return JSON.parse(token)
  },

  async handlePostLogin (token: any) {
    this.storeToken(token)
    this.storeUserConnections()
  },
  storeToken (token: any) {
    store.commit('user/setToken', token)
  },
  async storeUserConnections () {
    await store.dispatch('user/setConnections')
  },

  storeRefreshToken (refreshTokenObject: any) {
    const tokenObject = this.getTokenObject()
    tokenObject.token = refreshTokenObject.token

    this.storeToken(tokenObject)
  },

  getUserObject () {
    return this.haveUser() ? JSON.parse(storage.get(this.userKey) ?? '') : null
  },

  haveUser () {
    return storage.exists(this.userKey)
  },

  storeUser (user: any) {
    store.dispatch('user/setUser', user)
  },
  async getUser (refresh = false): Promise<UserModel> {
    if (!refresh && this.haveUser()) {
      return this.getUserObject()
    } else {
      return await apiAuthService.getUser().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const user = response.data
          this.storeUser(user)

          return user
        }
        return null
      })
    }
  },

  async switchToAdministration (relationId: number, isImpersonating: boolean) {
    return await apiAuthService.switchRole(relationId).then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        const token = response.data
        const user = this.getUserObject()

        let impersonateObject = this.getImpersonateObject()
        if (isImpersonating && impersonateObject === null) {
          impersonateObject = this.createImpersonateObject()
        }

        sessionStorage.clear()
        this.clearStores()
        this.storeUser(user)

        await this.handlePostLogin(token)
        await localRelationService.getRelation()

        if (isImpersonating) {
          store.commit('user/setImpersonation', impersonateObject)
        }

        store.commit('relation/setIsOwner', response.data.is_owner)
      }

      return response
    })
  },
  createImpersonateObject (): ImpersonateModel {
    const impersonateModel = new ImpersonateModel()
    impersonateModel.relation = store.getters['relation/relation'] as RelationModel
    impersonateModel.token = store.getters['user/token'] as TokenModel
    impersonateModel.twinfield_connection = store.getters['user/twinfieldConnection']
    impersonateModel.relations = store.getters['administration/administrationRelations'] as Array<AdministrationRelationModel>

    return impersonateModel
  },

  getImpersonateObject (): ImpersonateModel|null {
    return store.getters['user/impersonation']
  },

  async unImpersonate () {
    const impersonation = this.getImpersonateObject()

    if (impersonation !== null) {
      await this.switchBackToOwnAdministration(impersonation.relation.id)
      store.commit('relation/setIsOwner', impersonation.token.is_owner)
      store.commit('relation/setRelation', impersonation.relation)
      store.commit('user/setConnections', impersonation.twinfield_connection)
    }

    this.clearStores()
    store.commit('user/unsetImpersonation')
  },
  clearStores () {
    store.dispatch('invoicing/clearStore')
    store.dispatch('client/clearStore')
    store.dispatch('book/clearStore')
    store.dispatch('administration/clearStoreImpersonate')
  },
  async switchBackToOwnAdministration (relationId: number) {
    await apiAuthService.switchRole(relationId).then(async (response: any) => {
      if (HttpStatus.OK) {
        const token = response.data
        const user = sessionStorage.getItem(localAuthService.userKey)
        sessionStorage.clear()

        if (user) {
          sessionStorage.setItem(localAuthService.userKey, user)
        }
        localAuthService.storeToken(token)
      }
    })
  }
}
