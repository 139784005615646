
import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/forms/InputField.vue'
import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { RelationModel } from '@/globals/models/relation/Relation'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { dateHelper } from '@/globals/helpers/DateHelper'
import { localAuthService } from '@/network/local/local-auth-service'
import { routeHelper } from '@/globals/helpers/RouteHelper'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { UserModel } from '@/globals/models/user/User'
import IconBase from '@/globals/components/icons/IconBase.vue'
import IconLookingGlass from '@/globals/components/icons/IconLookingGlass.vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    InputField,
    IconBase,
    IconLookingGlass
  },
  data () {
    return {
      essentialsLoaded: false,
      loadingState: this.$store.state.loading,
      relationStore: this.$store.state.relation,
      userStore: this.$store.state.user,
      dialogStore: this.$store.state.dialog,
      selected: [],
      search: '',
      headers: [
        { text: 'Relatie', align: 'start', value: 'name' },
        { text: 'Aangeleverd', value: 'total', align: 'right' },
        { text: 'Te boeken', value: 'to_book', align: 'right' },
        { text: 'Geboekt', value: 'booked', align: 'right' },
        { text: 'Te verwerken', value: 'to_process', align: 'right' },
        { text: 'Laatste upload', value: 'uploaded_at' },
        { text: '', value: 'actions', align: 'right', sortable: false, width: 230 }
      ],
      relations: [] as Array<any>,
      tableData: [] as Array<any>,
      preventClickRow: false as boolean
    }
  },
  computed: {
    relation (): RelationModel {
      return this.$store.getters['relation/relation']
    },
    user (): UserModel {
      return this.$store.getters['user/user']
    },
    isOwner (): boolean {
      return this.relationStore.isOwner
    },
    administrationRelations (): Array<AdministrationRelationModel> {
      return this.$store.getters['administration/administrationRelations']
    },
    administrationRelationStats (): any {
      return this.$store.getters['administration/administrationRelationStats']
    }
  },
  watch: {
    administrationRelations () {
      this.relations = []
      this.buildRelationsTable(this.administrationRelations)
      this.addStatsToRelations(this.administrationRelationStats)
    },
    administrationRelationStats () {
      this.relations = []
      this.buildRelationsTable(this.administrationRelations)
      this.addStatsToRelations(this.administrationRelationStats)
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      if (!this.administrationRelations.length) {
        if (this.isOwner) {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelations', this.relation.id)
        } else {
          await this.$store.dispatch('administration/loadAndSetAdministrationRelationsNonOwner', { relationId: this.relation.id, userId: this.user.id })
        }
      } else {
        this.buildRelationsTable(this.administrationRelations)
      }

      if (!this.administrationRelationStats) {
        if (this.isOwner) {
          await this.$store.dispatch('administration/setAdministrationRelationStats')
        } else {
          await this.$store.dispatch('administration/setAdministrationRelationStatsNonOwner')
        }
      } else {
        this.addStatsToRelations(this.administrationRelationStats)
      }
      this.essentialsLoaded = true
    },

    buildRelationsTable (administrationRelations: Array<AdministrationRelationModel>) {
      administrationRelations.forEach(administrationRelation => {
        if (administrationRelation.enterprise.isEnterprise() && administrationRelation.enterprise.active) {
          this.insertRelation(administrationRelation.enterprise)
        }
      })
    },
    insertRelation (relation: RelationModel) {
      const tableItem = {
        relation,
        id: relation.id,
        name: relation.getRelationName(),
        row_class: relation.hidden ? 'relation--hidden' : ''
      }

      this.relations.push(tableItem)
    },

    isRelations (): boolean {
      return this.relations.length > 0
    },

    async getStats () {
      await apiAdministrationService.getStats().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.addStatsToRelations(response.data)
        }
      })
    },

    async getStatsNoOwner () {
      await apiAdministrationService.getStatsNoOwner().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.addStatsToRelations(response.data)
        }
      })
    },
    addStatsToRelations (stats: any) {
      this.relations.forEach((relation: any, index) => {
        if (stats !== null && stats[relation.id] !== null) {
          const relationStats = stats[relation.id]
          this.relations[index].total = relationStats.total
          this.relations[index].booked = relationStats.booked
          this.relations[index].to_book = relationStats.to_book
          this.relations[index].to_process = relationStats.to_process

          if (relationStats.last_uploaded) {
            this.relations[index].uploaded_at = dateHelper.toUnix(relationStats.last_uploaded)
            this.relations[index].uploaded_at_local = dateHelper.toShortMonthDateString(relationStats.last_uploaded)
          }
        }
      })
    },

    async switchRole (relationId: number) {
      await localAuthService.switchToAdministration(relationId, true).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.navigateToRelation()
        }
      })
    },
    navigateToRelation () {
      this.$router.push({ name: routeHelper.administrationRoutes.administration.document.overview.name })
    },

    navigateToEditRelation (relationId: number) {
      const id = relationId.toString()
      this.$router.push({ name: routeHelper.relationRoutes.relation.edit.name, params: { id } })
    },

    async deactivateRelation (item: any) {
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: 'Weet je het zeker dat je deze relatie wil verwijderen?', isDeleting: true })
      if (isConfirmed) {
        await apiRelationService.deactivateRelation(this.relation.id, item.id).then((response: any) => {
          if (response.status === HttpStatus.UPDATED) {
            const index = this.relations.indexOf(item)
            this.relations.splice(index, 1)
          }
        })
      }
    }
  }
})
