export class ProjectModel {
  id: number|null = null
  description = ''
  client_id: number|null = null
  start_date = new Date()
  end_date = new Date()
  partial_invoice = false
  invoice_code = 0
  discount = 0
  reference = ''
  notes = ''
  project_work = []

  constructor (data?: any) {
    if (data) {
      this.id = data.id
      this.description = data.description
      this.client_id = data.client_id
    }
  }
}
