export class User {
  name = ''
  last_name = ''
  company_name = ''
  email = ''
  password = ''
  password_confirm = ''
  agreePrivacy = false

  constructor (data?: any) {
    if (data) {
      this.name = data.name
      this.last_name = data.last_name
      this.company_name = data.company_name
      this.email = data.email
      this.password = data.password
      this.password_confirm = data.password_confirm
      this.agreePrivacy = data.agreePrivacy
    }
  }
}
