import { apiRelationService } from '@/network/api/api-relation-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { AdministrationRelationModel } from '@/globals/models/relation/AdministrationRelation'
import { apiAdministrationService } from '@/network/api/api-administration-service'
import { LedgerAccountModel } from '@/globals/models/LedgerAccount'
import { TaxDefaultModel } from '@/globals/models/TaxDefault'
import { arrayHelper } from '@/globals/helpers/ArrayHelper'

const state = () => ({
  administrationRelations: [] as Array<AdministrationRelationModel>,
  administrationRelationStats: null as any,
  ledgerAccounts: [] as Array<LedgerAccountModel>,
  taxDefaults: [] as Array<TaxDefaultModel>
})

const getters = {
  administrationRelations: (state: any): Array<AdministrationRelationModel> => {
    return state.administrationRelations
  },
  administrationRelationStats: (state: any) => {
    return state.administrationRelationStats
  },
  ledgerAccounts: (state: any): Array<LedgerAccountModel> => {
    return state.ledgerAccounts
  },
  taxDefaults: (state: any): Array<TaxDefaultModel> => {
    return state.taxDefaults
  }
}

const actions = {
  async loadAndSetAdministrationRelations ({ commit }: any, relationId: number) {
    await apiRelationService.getAdministrationRelations(relationId).then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const administrationRelations = [] as Array<AdministrationRelationModel>
        response.data.forEach((administrationRelationRaw: any) => {
          const administrationRelation = new AdministrationRelationModel().fromResponse(administrationRelationRaw)
          administrationRelations.push(administrationRelation)
        })
        commit('setAdministrationRelations', administrationRelations)
      }
    })
  },
  async loadAndSetAdministrationRelationsNonOwner ({ commit }: any, data: { relationId: number; userId: number }) {
    await apiRelationService.getAdministrationNonOwner(data.relationId, data.userId).then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const administrationRelations = [] as Array<AdministrationRelationModel>
        response.data.forEach((administrationRelationRaw: any) => {
          const administrationRelation = new AdministrationRelationModel().fromResponse(administrationRelationRaw)
          administrationRelations.push(administrationRelation)
        })
        commit('setAdministrationRelations', administrationRelations)
      }
    })
  },
  setAdministrationRelations ({ commit }: any, administrationRelations: Array<AdministrationRelationModel>) {
    commit('setAdministrationRelations', administrationRelations)
  },
  async setAdministrationRelationStats ({ commit }: any) {
    await apiAdministrationService.getStats().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        commit('setAdministrationRelationStats', response.data)
      }
    })
  },
  async setAdministrationRelationStatsNonOwner ({ commit }: any) {
    await apiAdministrationService.getStatsNoOwner().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        commit('setAdministrationRelationStats', response.data)
      }
    })
  },
  async setLedgerAccounts ({ commit }: any) {
    await apiAdministrationService.getLedgerAccounts().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const ledgerAccounts = [] as Array<LedgerAccountModel>
        response.data.forEach((ledgerRaw: any) => {
          const ledgerAccount = new LedgerAccountModel().fromResponse(ledgerRaw)
          ledgerAccounts.push(ledgerAccount)
        })
        commit('setLedgerAccounts', ledgerAccounts)
      }
    })
  },
  async setTaxDefaults ({ commit }: any) {
    await apiAdministrationService.getTwinfieldTaxDefaults().then((response: any) => {
      if (response.status === HttpStatus.OK) {
        const taxDefaults = [] as Array<TaxDefaultModel>
        response.data.forEach((taxDefaultRaw: any) => {
          const taxDefault = new TaxDefaultModel().fromResponse(taxDefaultRaw)
          taxDefaults.push(taxDefault)
        })
        commit('setTaxDefaults', taxDefaults)
      }
    })
  },
  addAdministrationRelation ({ commit }: any, administrationRelation: AdministrationRelationModel) {
    commit('addAdministrationRelation', administrationRelation)
  },
  updateAdministrationRelation ({ commit }: any, administrationRelation: AdministrationRelationModel) {
    commit('updateAdministrationRelation', administrationRelation)
  },
  removeAdministrationRelationByRelationId ({ commit }: any, id: number) {
    commit('removeAdministrationRelationByRelationId', id)
  },
  clearStore ({ commit }: any) {
    commit('clearStore')
  },
  clearStoreImpersonate ({ commit }: any) {
    commit('clearStoreImpersonate')
  }
}

const mutations = {
  setAdministrationRelations (state: any, administrationRelations: Array<AdministrationRelationModel>) {
    state.administrationRelations = administrationRelations
  },
  setAdministrationRelationStats (state: any, administrationRelationStats: any) {
    state.administrationRelationStats = administrationRelationStats
  },
  setLedgerAccounts (state: any, ledgerAccounts: Array<LedgerAccountModel>) {
    state.ledgerAccounts = ledgerAccounts
  },
  setTaxDefaults (state: any, taxDefaults: Array<TaxDefaultModel>) {
    state.taxDefaults = taxDefaults
  },
  addAdministrationRelation (state: any, administrationRelation: AdministrationRelationModel) {
    state.administrationRelations.push(administrationRelation)
  },
  removeAdministrationRelationByRelationId (state: any, id: number) {
    const index = (state.administrationRelations as Array<AdministrationRelationModel>).findIndex(ar => ar.enterprise.id === id)

    if (index >= 0) {
      state.administrationRelations.splice(index, 1)
    }
  },
  updateAdministrationRelation (state: any, administrationRelation: AdministrationRelationModel) {
    const administrationRelationIndex = (state.administrationRelations as Array<AdministrationRelationModel>).findIndex(r => r.enterprise.id === administrationRelation.enterprise.id)

    if (administrationRelationIndex >= 0) {
      state.administrationRelations.splice(administrationRelationIndex, 1)
      state.administrationRelations.push(administrationRelation)
    }
  },
  clearStore (state: any) {
    arrayHelper.clear(state.administrationRelations)
    arrayHelper.clear(state.ledgerAccounts)
    arrayHelper.clear(state.taxDefaults)
    state.administrationRelationStats = null
  },
  clearStoreImpersonate (state: any) {
    arrayHelper.clear(state.ledgerAccounts)
    arrayHelper.clear(state.taxDefaults)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
