var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.styleWrapper)},[(_vm.isLabel)?_c('label',{class:{
    'height--small': _vm.labelHeightSmall
  }},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"textarea__wrapper"},[_c('textarea',{class:{
        'form--textarea': !_vm.errorModel.length,
        'form--textarea validation--error': _vm.errorModel.length,
        'height--small': _vm.heightSmall,
        'is--disabled': _vm.disabled
      },style:(_vm.styleObject),attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)}}}),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"loading__bar"},[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"2"}})],1):_vm._e()]),(_vm.value && _vm.value.length === _vm.maxLength)?_c('ul',{staticClass:"form__validation"},[_c('li',[_vm._v("Maximale lengte van "+_vm._s(_vm.maxLength)+" tekens bereikt")])]):_vm._e(),(_vm.errorModel.length)?_c('ul',{staticClass:"form__validation"},_vm._l((_vm.errorModel),function(error){return _c('li',{key:error.id},[_vm._v(_vm._s(error))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }