import { BaseEnum, Enum } from '@/globals/enums/BaseEnum'

export class TaskStatusEnum extends BaseEnum {
  ToDo: Enum = {
    text: 'Nog te doen',
    value: 0
  }

  Planned: Enum = {
    text: 'Gepland',
    value: 1
  }

  InProgress: Enum = {
    text: 'In uitvoering',
    value: 2
  }

  WaitingForResponse: Enum = {
    text: 'Wachten op antwoord',
    value: 3
  }

  Done: Enum = {
    text: 'Afgerond',
    value: 4
  }

  getAll (): Array<any> {
    return [
      this.ToDo,
      this.Planned,
      this.InProgress,
      this.WaitingForResponse,
      this.Done
    ]
  }

  getByValue (value: number): Enum {
    const list = this.getAll()

    return super.getItemByValue(list, value)
  }
}
