import { TaskModel } from '../models/Task'
import { TaskStatusEnum } from '../enums/TaskStatus'
import { AdministrationModel } from '@/globals/models/Administration'

const state = () => ({
  isLoaded: false,
  administrations: [] as Array<{ relationId: number; name: string }>,
  taskStatusEnum: new TaskStatusEnum(),
  toDoTasks: [] as Array<TaskModel>,
  plannedTasks: [] as Array<TaskModel>,
  inProgressTasks: [] as Array<TaskModel>,
  waitingForResponseTasks: [] as Array<TaskModel>,
  doneTasks: [] as Array<TaskModel>,
  isDragging: false,
  selectedTask: new TaskModel(),
  filters: {
    assignedTo: null,
    sharedTo: null,
    relationTo: null
  }
})

const getters = {
  task: (state: any) => {
    return state.selectedTask
  },

  getListByStatus: (state: any) => (status: number): Array<TaskModel> => {
    if (status === state.taskStatusEnum.ToDo.value) {
      return state.toDoTasks
    } else if (status === state.taskStatusEnum.Planned.value) {
      return state.plannedTasks
    } else if (status === state.taskStatusEnum.InProgress.value) {
      return state.inProgressTasks
    } else if (status === state.taskStatusEnum.WaitingForResponse.value) {
      return state.waitingForResponseTasks
    } else {
      return state.doneTasks
    }
  },
  findTaskById: (state: any) => (id: number): TaskModel|undefined => {
    const list = getters.getCombinedTaskLists(state)
    return list.find((task: TaskModel) => task.id === id)
  },
  findTaskIndexById: (state: any) => (id: number, status: number): number|undefined => {
    const list = getters.getListByStatus(state)(status)
    return list.findIndex((task: TaskModel) => task.id === id)
  },
  getCombinedTaskLists: (state: any): Array<TaskModel> => {
    return state.toDoTasks.concat(state.plannedTasks, state.inProgressTasks, state.waitingForResponseTasks, state.doneTasks)
  },
  isTasksUpdating: (state: any): boolean => {
    const tasks = getters.getCombinedTaskLists(state)
    const index = tasks.findIndex(task => task.isUpdating)
    return index >= 0
  }
}

const actions = {
  addTaskStart ({ commit }: any, task: TaskModel) {
    commit('addTaskStart', task)
  },
  addTaskEnd ({ commit }: any, task: TaskModel) {
    commit('addTaskEnd', task)
  },
  setIsDragging ({ commit }: any, isDragging: boolean) {
    commit('setIsDragging', isDragging)
  },
  setSelectedTask ({ commit }: any, task: TaskModel) {
    commit('setSelectedTask', task)
  },
  setAdministrations ({ commit }: any, administrations: Array<AdministrationModel>) {
    commit('setAdministrations', administrations)
  },
  setTaskList ({ commit }: any, { status, tasks }: any) {
    const taskStatusEnum = new TaskStatusEnum()
    if (status === taskStatusEnum.ToDo.value) {
      commit('setToDoTasks', tasks)
    } else if (status === taskStatusEnum.Planned.value) {
      commit('setPlannedTasks', tasks)
    } else if (status === taskStatusEnum.InProgress.value) {
      commit('setInProgressTasks', tasks)
    } else if (status === taskStatusEnum.WaitingForResponse.value) {
      commit('setWaitingForResponseTasks', tasks)
    } else {
      commit('setDoneTasks', tasks)
    }
  },
  clearTaskLists ({ commit }: any) {
    const tasks = [] as Array<TaskModel>
    commit('setToDoTasks', tasks)
    commit('setPlannedTasks', tasks)
    commit('setInProgressTasks', tasks)
    commit('setWaitingForResponseTasks', tasks)
    commit('setDoneTasks', tasks)
  }
}

const mutations = {
  setToDoTasks (state: any, tasks: Array<TaskModel>) {
    state.toDoTasks = tasks
  },
  setPlannedTasks (state: any, tasks: Array<TaskModel>) {
    state.plannedTasks = tasks
  },
  setInProgressTasks (state: any, tasks: Array<TaskModel>) {
    state.inProgressTasks = tasks
  },
  setWaitingForResponseTasks (state: any, tasks: Array<TaskModel>) {
    state.waitingForResponseTasks = tasks
  },
  setDoneTasks (state: any, tasks: Array<TaskModel>) {
    state.doneTasks = tasks
  },
  setIsDragging (state: any, isDragging: boolean) {
    state.isDragging = isDragging
  },
  setSelectedTask (state: any, task: TaskModel) {
    state.selectedTask = task
  },
  setAdministrations (state: any, administrations: Array<AdministrationModel>) {
    administrations.forEach(administration => {
      state.administrations.push({ relationId: administration.relation_id, name: administration.relation.getRelationName() })
    })
  },
  addTaskStart (state: any, task: TaskModel) {
    if (task.status === state.taskStatusEnum.ToDo.value) {
      state.toDoTasks.unshift(task)
    } else if (task.status === state.taskStatusEnum.Planned.value) {
      state.plannedTasks.unshift(task)
    } else if (task.status === state.taskStatusEnum.InProgress.value) {
      state.inProgressTasks.unshift(task)
    } else if (task.status === state.taskStatusEnum.WaitingForResponse.value) {
      state.waitingForResponseTasks.unshift(task)
    } else {
      state.doneTasks.unshift(task)
    }
  },
  addTaskEnd (state: any, task: TaskModel) {
    if (task.status === state.taskStatusEnum.ToDo.value) {
      state.toDoTasks.push(task)
    } else if (task.status === state.taskStatusEnum.Planned.value) {
      state.plannedTasks.push(task)
    } else if (task.status === state.taskStatusEnum.InProgress.value) {
      state.inProgressTasks.push(task)
    } else if (task.status === state.taskStatusEnum.WaitingForResponse.value) {
      state.waitingForResponseTasks.push(task)
    } else {
      state.doneTasks.push(task)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
